import React, { useMemo, useState, useEffect } from 'react';
import TableContainer from "Common/TableContainer";
import { getByStaffDetail, getStaffDocumentDetail } from 'helpers/saibackend_helper';
import { Offcanvas, Button, Row, Col, Card, Table, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import profile from 'assets/images/auth/profile.png'
import SimpleBar from 'simplebar-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useProfile } from 'Common/Hooks/UserHooks';


const PayrollTable = ({ data, currentPage, totalPages, onPageChange, handleSortChange, handleArchivePayroll }:
    {
        data: any,
        currentPage: number;
        totalPages: number;
        onPageChange: (page: number) => void;
        handleSortChange: (column: string, direction: string) => void;
        handleArchivePayroll: (id: number) => Promise<void>;
    }) => {

    const getStatusClass = (status: string) => {
        switch (status) {
            case 'Pay Approved':
                return 'bg-warning';
            case 'Pay Processed':
                return 'bg-success';
            case 'Archived':
                return 'bg-danger';
            case 'Draft':
                return 'bg-secondary';
            default:
                return '';
        }
    };

    const { userProfile } = useProfile();
    const userRoles = userProfile.roles;
    const allowedRoles = ['DEVELOPER', 'SUPER_ADMIN', 'ADMIN', 'MANAGER'];
    const hasRoles = (roles: string[]) => roles.some(role => userRoles.includes(role));
    const canAccess = hasRoles(allowedRoles);

    const columns = useMemo(
        () => [
            {
                header: "Payroll Number",
                disableFilters: true,
                accessorKey: "pay_run_no",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps: any) => {
                    return (
                        <div style={{ color: '#F0484F' }}>
                            {cellProps.row.original.pay_run_no ? cellProps.row.original.pay_run_no : " - "}
                        </div>
                    );
                },
            },
            {
                header: "Pay Run",
                disableFilters: true,
                accessorKey: "pay_run_name",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Pay Status",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                accessorKey: "status",
                cell: (cellProps: any) => {
                    return (
                        <Badge className={getStatusClass(cellProps.row.original.status)}>
                            {cellProps.row.original.status}
                        </Badge>
                    );
                },
            },
            {
                header: "Pay Date",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                accessorKey: "generated_on",
            },
            {
                header: "Total Staff",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                accessorKey: "total_staff",
            },
            {
                header: "Total Hours",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                accessorKey: "total_hrs",
            },
            {
                header: "Wages",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                accessorKey: "total_pay_amount",
                cell: (cellProps: any) => {
                    const total_earning = cellProps.row.original.total_pay_amount;
                    return (
                        total_earning > 0
                            ? <span>${total_earning}</span>
                            : <b className='text-danger'>0</b>
                    );
                }
            },
            {
                header: "Action",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const itemId = cellProps.row.original.id;
                    const formattedId = `payrun_${itemId.toString().padStart(3, '0')}`;
                    return (

                        <div className="d-flex gap-2 list-unstyled mb-0">
                            <div className="edit">
                                <Link to={`/payroll/details/${formattedId}`}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="view-tooltip">View</Tooltip>}>
                                        <i className="ri-eye-line link-info p-0" style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                                    </OverlayTrigger>
                                </Link>
                            </div>

                            {canAccess && userRoles[0] !== "MANAGER" && (
                                <div className="edit">
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}>
                                        <i onClick={() => handleArchivePayroll(cellProps.row.original.id)} className="ri-delete-bin-line link-danger" style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                                    </OverlayTrigger>
                                </div>
                            )}
                        </div>
                    );
                },
            },
        ],
        []
    );

    return (
        <React.Fragment>
            {data.length > 0 ?
                <TableContainer
                    isPagination={true}
                    columns={(columns || [])}
                    data={data}
                    isBordered={false}
                    customPageSize={10}
                    PaginationClassName="align-items-center mt-4 pt-2"
                    tableClass="table-centered align-middle table-custom-effect table-nowrap mb-0"
                    theadClass="text-muted table-light"
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                    onSortChange={handleSortChange}
                />
                :
                <div className="noresult">
                    <div className="text-center py-4">
                        <div className="avatar-md mx-auto mb-4">
                            <div className="avatar-title bg-light text-primary rounded-circle fs-4xl">
                                <i className="bi bi-search"></i>
                            </div>
                        </div>
                        <h5 className="mt-2">No Payroll Available</h5>

                    </div>
                </div>
            }
        </React.Fragment >
    );
}

export default PayrollTable;