import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Card, Button, Offcanvas, Form, Modal, Badge, Spinner } from 'react-bootstrap';
import BreadCrumb from 'Common/BreadCrumb';
import LocationTable from './LocationTable';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { getLocationDetail, archiveLocationDetail, restoreLocationDetail, exportLocationDetail, getLocationClientFilterList, getLocationSubcontractorFilterList } from 'helpers/saibackend_helper';
import CountUp from 'react-countup';
import Select from "react-select";
import { useProfile } from "Common/Hooks/UserHooks";

interface LocationData {
    id: number;
    name: string;
    client_id: number;
    address: string;
    number_of_employees: string;
    status: string;
}


interface LocationSummary {
    payable_count: number,
    internal_count: number,
    total_archived_location: number
}

interface ClientData {
    value: number;
    label: string;
}

interface PayloadValue {
    page: number;
    page_size: string;
    search_term: string;
    sort: {
        sort_order: string;
        sort_by: string;
    };
    is_archive: number;
    client: number | number[];
    type: number | number[];
    subcontractor: number | number[]
}

const Location = () => {
    document.title = "Locations | The Guard Duty";

    const { userProfile } = useProfile();
    const userRoles = userProfile.roles;
    const allowedRoles = ['DEVELOPER', 'SUPER_ADMIN', 'ADMIN', 'MANAGER'];
    const hasRoles = (roles: string[]) => roles.some(role => userRoles.includes(role));
    const canAccess = hasRoles(allowedRoles);
   
    const [courseFilters, setCourseFilters] = useState<boolean>(false);
    const handleFilterShow = () => setCourseFilters(!courseFilters);

    // Filter API Integration //
    // ============================================================================================================================//

    const type = [
        {
            id: 1,
            name: 'Payable'
        },
        {
            id: 0,
            name: 'Internal'
        }
    ]

    const [selectedClient, setSelectedClient] = useState<number[]>([]);
    const [selectedType, setSelectedType] = useState<number[]>([]);
    const [selectedSub, setSelectedSub] = useState<number[]>([]);
    const [isArchived, setIsArchived] = useState(false);

    const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        if (e.target.checked) {
            const newSelected = [...selectedType, id];
            setSelectedType(newSelected);
        } else {
            const newSelected = selectedType.filter(typeId => typeId !== id);
            setSelectedType(newSelected);
        }
    };

    const clearClient = (() => {
        setSelectedClient([]);
        setPayloadValue(prevState => ({
            ...prevState,
            client: [],
            page: 1
        }));
    })

    const clearType = (() => {
        setSelectedType([]);
        setPayloadValue(prevState => ({
            ...prevState,
            type: [],
            page: 1
        }));
    })

    const clearSub = (() => {
        setSelectedSub([]);
        setPayloadValue(prevState => ({
            ...prevState,
            subcontractor: [],
            page: 1
        }));
    })

    const handleApplyFilters = () => {
        setPayloadValue(prevState => ({
            ...prevState,
            client: selectedClient?.map((option: any) => option.value) ?? [],
            type: selectedType,
            subcontractor: selectedSub ? selectedSub.map((option: any) => option.value) : [],
            is_archive: Number(isArchived),
            page: 1
        }));
        setCourseFilters(false)
    };

    const handleResetFilters = () => {
        setSelectedClient([]);
        setSelectedType([]);
        setSelectedSub([]);
        setIsArchived(false)
        setPayloadValue(prevState => ({
            ...prevState,
            client: [],
            type: [],
            subcontractor: [],
            is_archive: 0,
            page: 1
        }));
        setCourseFilters(false)
    }

    // Subcontractor Filter List API Integration //
    // ============================================================================================================================//

    const [SubDetail, setSubDetail] = useState<ClientData[]>([]);
    const [subContractorSearch, setSubContractorSearch] = useState('');

    const fetchSubDetails = async () => {
        try {
            const response = await getLocationSubcontractorFilterList(subContractorSearch);
            setSubDetail(response.data.data_list);
        } catch (error) {
            console.log(error);

        }
    };

    // Client Filter List API Integration //
    // ============================================================================================================================//

    const [clientDetail, setClientDetail] = useState<ClientData[]>([]);
    const [clientSearch, setClientSearch] = useState('')

    const fetchClientDetails = async () => {
        try {
            const response = await getLocationClientFilterList(clientSearch);
            setClientDetail(response.data.data_list);
        } catch (error) {
            console.log(error);

        }
    };

    useEffect(() => {
        if (courseFilters) {
            fetchClientDetails();
            fetchSubDetails();
        }
    }, [courseFilters, clientSearch, subContractorSearch]);

    // Location List API Integration //
    // ============================================================================================================================//

    const [LocationDetail, setLocationDetail] = useState<LocationData[]>([]);
    const [LocationSummary, setLocationSummary] = useState<LocationSummary>({
        payable_count: 0,
        internal_count: 0,
        total_archived_location: 0
    });
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [payloadValue, setPayloadValue] = useState<PayloadValue>({
        page: 1,
        page_size: "10",
        search_term: "",
        sort: {
            sort_order: "DESC",
            sort_by: ""
        },
        is_archive: 0,
        client: 0,
        type: 0,
        subcontractor: 0
    });

    const fetchLocationDetails = async () => {
        try {
            const response = await getLocationDetail(
                payloadValue.page,
                payloadValue.page_size,
                payloadValue.search_term,
                payloadValue.sort.sort_order,
                payloadValue.sort.sort_by,
                payloadValue.is_archive,
                payloadValue.client,
                payloadValue.type,
                payloadValue.subcontractor
            );
            setLocationDetail(response.data.data_list);
            setTotalPages(response.data.total_pages);
            setLocationSummary(response.data.summary)
            setCurrentPage(payloadValue.page);
            setTotalRecords(response.data.total_records);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleCardClick = (typeId: any) => {
        setPayloadValue((prev) => ({
            ...prev,
            page: 1, 
            type: [typeId], 
        }));
        setLoading(true);
    };

    const handleSortChange = (column: string) => {
        setPayloadValue(prevState => ({
            ...prevState,
            sort: {
                sort_order: prevState.sort.sort_by === column && prevState.sort.sort_order === "ASC" ? "DESC" : "ASC",
                sort_by: column
            },
            page: 1
        }));
    };

    useEffect(() => {
        fetchLocationDetails();
    }, [payloadValue]);

    const handlePageChange = (page: number) => {
        setPayloadValue({ ...payloadValue, page: page });
    };

    const navigate = useNavigate();

    const handleClick = () => {
        localStorage.removeItem('LocationID');
        navigate('/add-location');
    };

    // Location Search Integration //
    // ============================================================================================================================//

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        const handler = setTimeout(() => {
            setPayloadValue((prevPayload) => ({
                ...prevPayload,
                search_term: inputValue,
            }));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const [archiveId, setArchiveId] = useState<number | undefined>(undefined);
    const [archiveLoader, setArchiveLoader] = useState(false)

    const handleArchiveLocation = async (id: number): Promise<void> => {
        setArchiveId(id);
        setmodal_center(true);
    };


    const [modal_center, setmodal_center] = useState<boolean>(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }



    const archiveLocation = async (id: number) => {
        setArchiveLoader(true)
        try {
            const response = await archiveLocationDetail(id);
            fetchLocationDetails();
            toast.success(response.message);
            setmodal_center(false)
            setArchiveLoader(false)
        } catch (error) {
            console.error("Error Archive Subcontractor:", error);
            toast.error("Error Archive Please try again later.");
        }
    };

    const [restoreId, setRestoreId] = useState<number | undefined>(undefined);
    const [restoreLoader, setRestoreLoader] = useState(false)

    const handleRestoreLocation = async (id: number): Promise<void> => {
        setRestoreId(id);
        setmodal_restore_center(true);
    };


    const [modal_restore_center, setmodal_restore_center] = useState<boolean>(false);
    function tog_restore_center() {
        setmodal_restore_center(!modal_restore_center);
    }

    const restoreLocation = async (id: number) => {
        setRestoreLoader(true)
        try {
            const response = await restoreLocationDetail(id);
            fetchLocationDetails();
            toast.success(response.message);
            setmodal_restore_center(false);
            setRestoreLoader(false)
        } catch (error) {
            console.error("Error Restore Location:", error);
            toast.error("Error Restore Please try again later.");
        }
    };
    const [expLoader, setExpLoader] = useState(false)

    const downloadLocation = async () => {
        setExpLoader(true)
        try {
            await exportLocationDetail(
                payloadValue.page,
                payloadValue.page_size,
                payloadValue.search_term,
                payloadValue.sort.sort_order,
                payloadValue.sort.sort_by,
                payloadValue.is_archive,
                payloadValue.client,
                payloadValue.type,
                payloadValue.subcontractor
            )
            setExpLoader(false)
        } catch (error) {
            console.error("Error downloading Location:", error);
            toast.error("Error downloading Location. Please try again later.");
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Location" pageTitle="Dashboard" />
                    {
                        loading ?
                            <Row className="placeholder-glow">
                                <Col lg={4}>
                                    <div className="placeholder bg-light card-placeholder col-12"></div>
                                </Col>
                                <Col lg={4}>
                                    <div className="placeholder bg-light card-placeholder col-12"></div>
                                </Col>
                                <Col lg={4}>
                                    <div className="placeholder bg-light card-placeholder col-12"></div>
                                </Col>
                                <Col lg={12} className="mt-4">
                                    <div className="placeholder bg-light table-placeholder col-12"></div>
                                </Col>
                            </Row>
                            :
                            <Row className="workforce-staff">
                                <Col lg={4}>
                                    <Card className="border-bottom border-3 card-animate border-info">
                                        <Card.Body onClick={() => handleCardClick(1)}>
                                            <div className="avatar-sm float-end">
                                                <div className="avatar-title bg-info-subtle text-info fs-3xl rounded">
                                                    <i className="bi bi-people"></i>
                                                </div>
                                            </div>
                                            <h4 className="mb-4"><span className="counter-value" data-target="40"><CountUp end={LocationSummary?.payable_count ? LocationSummary.payable_count : "-"} separator=',' /></span></h4>

                                            <p className="text-muted fw-medium text-uppercase mb-0">Total Payable Location</p>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={4}>
                                    <Card className="border-bottom border-3 card-animate border-success">
                                        <Card.Body onClick={() => handleCardClick(0)}>
                                            <div className="avatar-sm float-end">
                                                <div className="avatar-title bg-success-subtle text-success fs-3xl rounded">
                                                    <i className="bi bi-people"></i>
                                                </div>
                                            </div>
                                            <h4 className="mb-4"><span className="counter-value" data-target="40"><CountUp end={LocationSummary?.internal_count ? LocationSummary.internal_count : "-"} separator=',' /></span></h4>

                                            <p className="text-muted fw-medium text-uppercase mb-0">Total Internal Location</p>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={12}>
                                    <Card className="staff-tabel-card">
                                        <Card.Body>
                                            <Row className="align-items-center g-2">
                                                <Col lg={5} className="btn-employee-section mb-3">
                                                    <h5>
                                                        Location List  <Badge>{totalRecords}</Badge>
                                                    </h5>
                                                </Col>
                                                <Col lg={7} className="btn-section mb-3">
                                                    <div className="app-search d-none d-md-inline-flex">
                                                        <div className="position-relative">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Search"
                                                                id="search-options"
                                                                value={inputValue}
                                                                onChange={handleChange}
                                                            />
                                                            <span className="mdi mdi-magnify search-widget-icon"></span>
                                                            <span
                                                                className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                                                id="search-close-options"
                                                            ></span>
                                                        </div>
                                                    </div>
                                                    <Button className='filter-btn' onClick={handleFilterShow}>
                                                        <i className="ri-list-settings-line align-baseline me-1"></i> Filters
                                                    </Button>
                                                    {canAccess && userRoles[0] !== "MANAGER" &&
                                                        <Button className='next-btn' onClick={handleClick}>
                                                            <i className="ri-add-fill"></i>  Add New
                                                        </Button>
                                                    }
                                                    {expLoader ? (
                                                        <Button
                                                            color="primary"
                                                            className="export-btn"
                                                            disabled
                                                        >
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </Button>
                                                    ) : (
                                                        <Button onClick={downloadLocation} className='export-btn'>
                                                            <i className="bx bx-export me-1 "></i>  Export
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col lg={12} className="leave-table mt-0 px-4">
                                                    <LocationTable
                                                        data={LocationDetail}
                                                        currentPage={currentPage}
                                                        totalPages={totalPages}
                                                        onPageChange={handlePageChange}
                                                        handleSortChange={handleSortChange}
                                                        handleArchiveLocation={handleArchiveLocation}
                                                        handleRestoreLocation={handleRestoreLocation}
                                                        canAccess={canAccess}
                                                        userRoles={userRoles}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                    }
                </Container>
            </div>
            <Offcanvas className="filter-canvas" show={courseFilters} placement="end" onHide={handleFilterShow}>
                <Offcanvas.Header id="courseFilters" className='border-bottom filter-canvas' closeButton>
                    <Offcanvas.Title id="courseFiltersLabel"> <i className="ri-list-settings-line align-baseline me-1"></i> Filters</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        <Row>
                            <Col lg={12}>
                                <Row className='filter-1'>
                                    <Col lg={12} className="d-flex justify-content-between">
                                        <h5>Client</h5>
                                        <p style={{ cursor: 'pointer' }} onClick={clearClient}>Clear All</p>
                                    </Col>
                                    <Col lg={12}>
                                        <Select
                                            options={clientDetail}
                                            isMulti
                                            value={selectedClient}
                                            onChange={(newValue: any) => setSelectedClient(newValue || [])}
                                            onInputChange={(newValue: string) => setClientSearch(newValue)}
                                            getOptionLabel={(option: any) => option.label}
                                            getOptionValue={(option: any) => option.value}
                                            placeholder="Select clients"
                                        />
                                    </Col>
                                </Row>
                                {canAccess &&
                                    <Row className='filter-2'>
                                        <Col lg={12} className="d-flex justify-content-between">
                                            <h5>Subcontractor</h5>
                                            <p onClick={clearSub} style={{ cursor: 'pointer' }}>Clear All</p>
                                        </Col>
                                        <Col lg={12}>
                                            <Select
                                                options={SubDetail}
                                                isMulti
                                                value={selectedSub}
                                                onChange={(newValue: any) => setSelectedSub(newValue || [])}
                                                onInputChange={(newValue: string) => setSubContractorSearch(newValue)}
                                                getOptionLabel={(option: any) => option.label}
                                                getOptionValue={(option: any) => option.value}
                                                placeholder="Select Subcontractor"
                                            />
                                        </Col>
                                    </Row>
                                }
                                <Row className='filter-2'>
                                    <Col lg={12} className="d-flex justify-content-between">
                                        <h5>Type</h5>
                                        <p style={{ cursor: 'pointer' }} onClick={clearType}>Clear All</p>
                                    </Col>
                                    {type.map((type) => (
                                        <Col lg={6} key={type.id}>
                                            <div className="form-check mb-2">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={`Type${type.id}`}
                                                    checked={selectedType.includes(type.id)}
                                                    onChange={(e) => handleTypeChange(e, type.id)}
                                                />
                                                <label className="form-check-label" htmlFor={`Type${type.id}`}>
                                                    {type.name}
                                                </label>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                                <Row className='filter-2'>
                                    <Col lg={12}>
                                        <div className="form-check mb-2">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="Allstatus"
                                                checked={isArchived}
                                                onChange={() => setIsArchived(!isArchived)}
                                            />
                                            <label className="form-check-label font-weight-bold" htmlFor="Allstatus">
                                                <b>Show Archive List</b>
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Offcanvas.Body>
                <Row>
                    <Col lg="12">
                        <div className="offcanvas-footer border-top-0 p-3 text-center">
                            <Row className='px-2 approve-reject-btn'>
                                <Col lg={6} className='clear-section'>
                                    <Button color="primary" onClick={handleResetFilters} className="clear-btn">Clear Filters</Button>
                                </Col>
                                <Col lg={6} className='Apply-section'>
                                    <Button color="primary" onClick={handleApplyFilters} className="next-btn">Apply Filters</Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Offcanvas>
            <Modal
                show={modal_center}
                onHide={() => {
                    tog_center();
                }}
                size="sm"
                centered
            >

                <Modal.Body className="text-center p-4">
                    <i className="ri-delete-bin-line text-danger display-5"></i>
                    <div className="mt-4">
                        <h4 className="mb-3">Are you sure?</h4>
                        <p className="text-muted mb-4">You want to archive this Location.</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
                            {archiveLoader ? (
                                <Button
                                    color="primary"
                                    className="next-btn"
                                    disabled
                                >
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                            ) : (
                                <Button variant="danger" onClick={() => {
                                    if (archiveId !== undefined) {
                                        archiveLocation(archiveId);
                                    } else {
                                        toast.error("No Archive ID provided.");
                                    }
                                }}>Delete</Button>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={modal_restore_center}
                onHide={() => {
                    tog_restore_center();
                }}
                size="sm"
                centered
            >
                <Modal.Body className="text-center p-4">
                    <i className=" ri-restart-line text-success display-5"></i>
                    <div className="mt-4">
                        <h4 className="mb-3">Are you sure?</h4>
                        <p className="text-muted mb-4">You want to restore this Location.</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button variant="light" onClick={() => setmodal_restore_center(false)}>Close</Button>
                            {restoreLoader ? (
                                <Button
                                    variant="success"
                                    className="next-btn"
                                    disabled
                                >
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                            ) : (
                                <Button variant="success" onClick={() => {
                                    if (restoreId !== undefined) {
                                        restoreLocation(restoreId);
                                    } else {
                                        toast.error("No Restore ID provided.");
                                    }
                                }}>Restore</Button>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default Location;
