import React, { ChangeEvent, useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  Button,
  Offcanvas,
  Form,
  Dropdown,
  Badge,
  Modal,
  Nav,
  Spinner,
  Tab,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import PatrolLocationTable from "./PatrolLocationTable";
import {
  getPatrolClientDetail,
  getPatrolLocationDetail,
  addPatrolLcoationDetail,
  getSubContractorDropdownList,
  exportPatrolLocationDetail,
  getPatolLocationList,
  updatePatrolLcoationDetail,
  GetByPatrolLocationList,
  ArchivePatrolLocationList,
  getPayGroupList,
  getStateMasterDetail,
} from "helpers/saibackend_helper";
import Select from "react-select";
import SimpleBar from "simplebar-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface subData {
  value: number;
  label: string;
}

interface PayGroupData {
    value: number;
    label: string;
  }

interface RowData {
  area_name: string;
  client_ids: Option[];
  location_ids: Option[];
  subcontractor_id: number;
  // pay_group_id:number;
}

interface Option {
  id: number;
  name: string;
  locationName?: string;
}

interface ClientData {
  value: number;
  label: string;
}

interface LocationData {
  id: number;
  name: string;
}

const PatrolLocation = () => {
  document.title = "Patrol Location | The Guard Duty";

  const [isBottom, setIsBottom] = useState(false);
  const toggleBottomCanvas = () => {
    setIsBottom(!isBottom);
  };

  const initialRow: RowData = {
    area_name: "",
    client_ids: [],
    location_ids: [],
    subcontractor_id: 0,
    // pay_group_id: 0
  };

  interface StateMasterData {
    id: number;
    state_name: string
}

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<RowData[]>([initialRow]);
  const [selectedClient, setSelectedClient] = useState<number[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<number[]>([]);

  const handleAddRow = () => {
    setRows([...rows, initialRow]);
  };

  const handleDeleteRow = (index: number) => {
    setRows(rows.filter((_, i) => i !== index));
  };

  const handleChange = (index: number, field: keyof RowData, value: any) => {
    const newRows = [...rows];
    newRows[index] = {
      ...newRows[index],
      [field]: value,
    };
    setRows(newRows);
  };

  const [clientDropdownDetail, setClientDropdownDetail] = useState<
    ClientData[]
  >([]);

  const fetchClientDetails = async () => {
    try {
      const searchTerm = "";
      const response = await getPatrolClientDetail(searchTerm);
      setClientDropdownDetail(response.data.data_list);
    } catch (error) {
      console.log(error);
    }
  };

  const [defaultLocationValue, setDefaultLocationValue] = useState({
    search_term: "",
  });

  const [locationDropdownDetail, setLocationDropdownDetail] = useState<
    LocationData[]
  >([]);

  const fetchLocationDetails = async () => {
    try {
      if (selectedClient.length > 0) {
        const response = await getPatrolLocationDetail(
          defaultLocationValue.search_term,
          selectedClient
        );
        setLocationDropdownDetail(response.data.data_list);
      } else {
        setLocationDropdownDetail([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchLocationDetails();
  }, [defaultLocationValue, selectedClient]);

  useEffect(() => {
    if (isBottom) {
      fetchClientDetails();
      fetchSubContractorDetails();
    }
  }, [isBottom]);


  const [defaultValue, setDefaultValue] = useState({
    page: 1,
    page_size: "10",
    search_term: "",
    sort: {
      sort_order: "ASC",
      sort_by: "",
    },
    is_archive: 0,
  });

  // const fetchPayGroupDetails = async () => {
  //   try {
  //     const response = await getPayGroupList(
  //       defaultValue.page,
  //       defaultValue.page_size,
  //       defaultValue.search_term,
  //       defaultValue.sort.sort_order,
  //       defaultValue.sort.sort_by,
  //       defaultValue.is_archive
  //     );
  //     const filteredData = response.data.data_list
  //     .filter((item: any) => item.for_patrol === 1)
  //     .map((item: any) => ({
  //       value: item.id,
  //       label: item.group_name, 
  //     }));
  //     setPayGroupDetail(filteredData);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  const validationSchema = Yup.object().shape({
    region_name: Yup.string().required("Region Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      region_name: "",
      state: "",
      location_data: [
        {
          area_name: "",
          client_ids: [],
          location_ids: [],
          // pay_group_id: ""
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        // const hasEmptyAreaName = values.location_data.some(row => row.area_name.trim() === "");
        // if (hasEmptyAreaName) {
        //     toast.error("Area name cannot be empty.");
        //     setSubmitting(false);
        //     return;
        // }

        const updatedValues = {
          ...values,
          location_data: rows
            .map(
              (row: {
                area_name: any;
                client_ids: any[];
                location_ids: any[];
                subcontractor_id: any;
                // pay_group_id: number
              }) => {
                if (row.area_name) {
                  return {
                    area_name: row.area_name,
                    client_ids: row.client_ids.map(
                      (client: any) => client.value
                    ),
                    location_ids: row.location_ids.map(
                      (location: any) => location.id
                    ),
                    subcontractor_id: row.subcontractor_id,
                    // pay_group_id: row.pay_group_id
                  };
                }
                return null;
              }
            )
            .filter((row) => row !== null),
        };
        if (isEditing && editId !== null) {
          const updateValue = {
            ...values,
            client_id: editId,
            location_data: rows
              .map(
                (row: {
                  area_name: any;
                  client_ids: any[];
                  location_ids: any[];
                  subcontractor_id: any;
                  // pay_group_id:number
                }) => {
                  if (row.area_name) {
                    return {
                      area_name: row.area_name,
                      client_ids: row.client_ids.map(
                        (client: any) => client.value
                      ),
                      location_ids: row.location_ids.map(
                        (location: any) => location.id
                      ),
                      subcontractor_id: row.subcontractor_id,
                      // pay_group_id: row.pay_group_id
                    };
                  }
                  return null;
                }
              )
              .filter((row) => row !== null),
          };
          const response = await updatePatrolLcoationDetail(updateValue);
          console.log(updateValue)
          if (response.status === 1) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
            return;
          }
        } else {
          const response = await addPatrolLcoationDetail(updatedValues);

          if (response.status === 1) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
            return;
          }
        }
        fetchPatrolLocationDetails();
        setIsBottom(false);
        setRows([initialRow]);
        formik.resetForm();
      } catch (error) {
        console.error("Error Adding/Updating Patrol Location:", error);
        toast.error("Error Patrol Location. Please try again later.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const [PatrolDetail, setPatrolDetail] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchPatrolLocationDetails = async () => {
    try {
      const response = await getPatolLocationList(
        defaultValue.page,
        defaultValue.page_size,
        defaultValue.search_term,
        defaultValue.sort.sort_order,
        defaultValue.sort.sort_by,
        defaultValue.is_archive
      );
      setPatrolDetail(response.data.data_list);
      setTotalPages(response.data.total_pages);
      setCurrentPage(defaultValue.page);
      setLoading(false);
     
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPatrolLocationDetails();
  }, [defaultValue]);

  const handleSortChange = (column: string) => {
    setDefaultValue((prevState) => ({
      ...prevState,
      sort: {
        sort_order:
          prevState.sort.sort_by === column &&
          prevState.sort.sort_order === "ASC"
            ? "DESC"
            : "ASC",
        sort_by: column,
      },
      page: 1,
    }));
  };

  const handlePageChange = (page: number) => {
    setDefaultValue({ ...defaultValue, page: page });
  };

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDefaultValue((prevPayload) => ({
        ...prevPayload,
        search_term: inputValue,
      }));
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState<number | null>(null);

  const editMaster = async (id: number) => {
    try {
      const response = await GetByPatrolLocationList(id);
      const data = response.data;
      formik.setValues({
        region_name: data.region_name,
        state:data.state,
        location_data: data.location_data,
      });
      const updatedRows = data.location_data.map((row: any) => ({
        area_name: row.area_name,
        subcontractor_id: row.subcontractor_id,
        client_ids: row.client_ids.map((client: { id: any; name: any }) => ({
          value: client.id,
          label: client.name,
        })),
        location_ids: row.location_ids.map(
          (location: { id: any; name: any }) => ({
            id: location.id,
            name: location.name,
          })
        ),
        // pay_group_id: row.pay_group_id
      }));
      setSelectedClient(
        data.location_data.map(
          (location: { client_ids: any }) => location.client_ids
        )
      );
      setIsEditing(true);
      setRows(updatedRows);
      setIsBottom(true);
      setEditId(id);
    } catch (error) {
      console.error("Error fetching Pay Group details:", error);
      toast.error("Error fetching details. Please try again later.");
    }
  };

  const [archiveId, setArchiveId] = useState<number | undefined>(undefined);
  const [archiveLoader, setArchiveLoader] = useState(false);

  const handleArchivePetrolLocation = async (id: number): Promise<void> => {
    setArchiveId(id);
    setmodal_center(true);
  };

  const [modal_center, setmodal_center] = useState<boolean>(false);
  function tog_center() {
    setmodal_center(!modal_center);
  }

  const archiveMaster = async (id: number) => {
    setArchiveLoader(true);
    try {
      const response = await ArchivePatrolLocationList(id);
      fetchPatrolLocationDetails();
      toast.success(response.message);
      setmodal_center(false);
      setArchiveLoader(false);
    } catch (error) {
      console.error("Error Archive Patrol Location :", error);
      toast.error("Error Archive Please try again later.");
    }
  };

  const openAddForm = () => {
    setIsEditing(false);
    setEditId(null);
    formik.resetForm();
    setRows([initialRow]);
    setIsBottom(true);
  };

  const [expLoader, setExpLoader] = useState(false);

  const downloadPatrolLocation = async () => {
    setExpLoader(true);
    try {
      await exportPatrolLocationDetail(
        defaultValue.page,
        defaultValue.page_size,
        defaultValue.search_term,
        defaultValue.sort.sort_order,
        defaultValue.sort.sort_by,
        defaultValue.is_archive
      );
      setExpLoader(false);
    } catch (error) {
      console.error("Error downloading Patrol Location:", error);
      toast.error("Error downloading Patrol Location. Please try again later.");
    }
  };

  const [subcontractorDetail, setSubcontractorDetail] = useState<subData[]>([]);

  const fetchSubContractorDetails = async () => {
    try {
      const searchTerm = "";
      const response = await getSubContractorDropdownList(searchTerm);
      setSubcontractorDetail(response.data.data_list);
    } catch (error) {
      console.log(error);
    }
  };

  function handleRowChange(index: number, field: string, value: any) {
    setRows((prevRows) =>
      prevRows.map((row, rowIndex) =>
        rowIndex === index
          ? { ...row, [field]: value }
          : row
      )
    );
  }

  //Added State/Province 


  const [StateMasterDetail, setStateMasterDetail] = useState<StateMasterData[]>([])
    const [defaultPayloadStateValue, setDefaultPayloadStateValue] = useState({
          page: 1,
          page_size: "10",
          search_term: "",
          sort_order: "DESC",
          is_archive: 0,
          state : "",
      })
//  const [selectedState, setSelectedState] = useState<string>('2');
 

   const fetchStateetails = async () => {
          try {
              const response = await getStateMasterDetail(defaultPayloadStateValue.page, defaultPayloadStateValue.page_size, defaultPayloadStateValue.search_term, defaultPayloadStateValue.sort_order, defaultPayloadStateValue.is_archive);
              setStateMasterDetail(response.data.data_list);
          } catch (error) {
              return;
          }
      };

        useEffect(() => {
                fetchStateetails()
            }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Patrol Location" pageTitle="Dashboard" />
          {loading ? (
            <Row className="placeholder-glow">
              <Col lg={12} className="mt-4">
                <div className="placeholder bg-light table-placeholder col-12"></div>
              </Col>
            </Row>
          ) : (
            <Row className="workforce-staff">
              <Col lg={12}>
                <Card className="staff-tabel-card">
                  <Card.Body>
                    <Row className="align-items-center g-2">
                      <Col lg={12} className="btn-section mb-3">
                        <div className="app-search d-none d-md-inline-flex">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              id="search-options"
                              value={inputValue}
                              onChange={handleSearchChange}
                            />
                            <span className="mdi mdi-magnify search-widget-icon"></span>
                            <span
                              className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                              id="search-close-options"
                            ></span>
                          </div>
                        </div>
                        <Button onClick={openAddForm} className="next-btn">
                          <i className="ri-add-fill"></i> Add New
                        </Button>
                        {expLoader ? (
                          <Button
                            color="primary"
                            className="export-btn"
                            disabled
                          >
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          </Button>
                        ) : (
                          <Button
                            onClick={downloadPatrolLocation}
                            className="export-btn"
                          >
                            <i className="bx bx-export me-1 "></i> Export
                          </Button>
                        )}
                      </Col>
                      <Col lg={12} className="leave-table mt-0">
                        <PatrolLocationTable
                          data={PatrolDetail}
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                          handleSortChange={handleSortChange}
                          editMaster={editMaster}
                          handleArchivePetrolLocation={
                            handleArchivePetrolLocation
                          }
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <Offcanvas
        className="add-role-canvas"
        show={isBottom}
        onHide={toggleBottomCanvas}
        placement="bottom"
        style={{ minHeight: "700px" }}
      >
        <Form onSubmit={formik.handleSubmit}>
          <Offcanvas.Header className="add-header border-bottom" closeButton>
            <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
              {isEditing ? "Edit" : "Add"} Patrol Location
            </Offcanvas.Title>
            <div>
              {formik.isSubmitting ? (
                <Button color="primary" className="next-btn" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button type="submit" className="next-btn pr-2">
                  {isEditing ? "Update" : "Add"}
                </Button>
              )}
            </div>
          </Offcanvas.Header>
          <>
            <Offcanvas.Body>
              <Row>
  {/* Region Name */}
  <Col lg={3} md={6} sm={12}>
    <Form.Group className="mb-3 px-3">
      <Form.Label>Region name</Form.Label>
      <div className="position-relative form-icon">
        <Form.Control
          type="text"
          name="region_name"
          className="form-control-lg form-control-icon name-input"
          placeholder="Enter Region name"
          value={formik.values.region_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={
            !!(formik.touched.region_name && formik.errors.region_name)
          }
        />
        <i style={{ fontSize: "16px" }} className="ri-book-2-fill"></i>
        <Form.Control.Feedback type="invalid">
          {formik.errors.region_name}
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  </Col>

  {/* State/Province */}
  <Col lg={3} md={6} sm={12}>
    <Form.Group className="mb-3 form-icon px-3">
      <Form.Label>State/Province</Form.Label>
      <div className="position-relative">
        <Form.Select
          className="form-select-lg form-control-icon"
          name="state"
          // value={selectedState || ""}
          value={formik.values.state}
          onChange={formik.handleChange}
        >
          {StateMasterDetail.map((stateDetail) => (
            <option key={stateDetail.id} value={stateDetail.state_name}>
              {stateDetail.state_name}
            </option>
          ))}
        </Form.Select>
        <i style={{ fontSize: "16px" }} className="ri-map-pin-line"></i>
      </div>
    </Form.Group>
  </Col>

                <Col lg="12">
                  <SimpleBar style={{ maxHeight: "460px" }} className="px-3">
                    {rows.map((row, index) => (
                      <Col lg="12" key={index}>
                        <Row>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>Area name</Form.Label>
                              <div className="position-relative form-icon">
                                <Form.Control
                                  type="text"
                                  name="areaName"
                                  className="form-control-lg form-control-icon name-input"
                                  placeholder="Enter Area name"
                                  value={row.area_name}
                                  onChange={(e) =>
                                    handleChange(
                                      index,
                                      "area_name",
                                      e.target.value
                                    )
                                  }
                                />
                                <i
                                  style={{ fontSize: "16px" }}
                                  className="ri-book-2-fill"
                                ></i>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className="mb-3 form-icon">
                              <Form.Label>Subcontractor</Form.Label>
                              <Select
                                inputId="subcontractor_id"
                                name="subcontractor_id"
                                options={subcontractorDetail}
                                value={subcontractorDetail.find(
                                  (option) =>
                                    option.value === row.subcontractor_id
                                )}
                                onChange={(selectedOption: any) =>
                                  handleChange(
                                    index,
                                    "subcontractor_id",
                                    selectedOption ? selectedOption.value : null
                                  )
                                }
                                classNamePrefix="react-select"
                                className="react-select"
                                menuPlacement="auto"
                                menuPosition="fixed"
                              />
                              <i
                                style={{ fontSize: "16px" }}
                                className="ri-account-circle-line mt-4"
                              ></i>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className="mb-3 form-icon">
                              <Form.Label>Clients</Form.Label>
                              <Select
                                options={clientDropdownDetail}
                                isMulti
                                value={row.client_ids}
                                classNamePrefix="react-select"
                                className="react-select"
                                onChange={(newValue: any) => {
                                  const clientIds = newValue.map(
                                    (client: { value: number }) => client.value
                                  );
                                  handleChange(
                                    index,
                                    "client_ids",
                                    newValue || []
                                  );
                                  setSelectedClient(clientIds);
                                }}
                                getOptionLabel={(option: any) => option.label}
                                getOptionValue={(option: any) => option.value}
                                placeholder="Select clients"
                                menuPlacement="auto"
                                menuPosition="fixed"
                                styles={{
                                  menu: (provided: any) => ({
                                    ...provided,
                                    zIndex: 9999, // Ensure it appears above other elements
                                  }),
                                  menuPortal: (provided: any) => ({
                                    ...provided,
                                    zIndex: 9999, // Ensure portal is above other elements
                                  }),
                                }}
                              />
                              <i
                                style={{ fontSize: "16px" }}
                                className="ri-account-circle-line mt-4"
                              ></i>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className="mb-3 form-icon">
                              <Form.Label>Location</Form.Label>
                              <Select
                                options={locationDropdownDetail}
                                isMulti
                                value={row.location_ids}
                                classNamePrefix="react-select"
                                className="react-select"
                                onChange={(newValue: any) => {
                                  const locationIds = newValue.map(
                                    (location: { id: number }) => location.id
                                  );
                                  handleChange(
                                    index,
                                    "location_ids",
                                    newValue || []
                                  );
                                  setSelectedLocation(locationIds);
                                }}
                                getOptionLabel={(option: any) => option.name}
                                getOptionValue={(option: any) => option.id}
                                placeholder="Select Locations"
                                menuPlacement="auto"
                                menuPosition="fixed"
                              />
                              <i
                                style={{ fontSize: "16px" }}
                                className="ri-map-pin-line mt-4"
                              ></i>
                            </Form.Group>
                          </Col>
                          {/* <Col>
  <Form.Group className="mb-4 form-icon">
    <Form.Label>Pay Group</Form.Label>
    <Select
      inputId={`pay_group_id_${index}`}
      name={`pay_group_id_${index}`}
      options={payGroupDetail}
      classNamePrefix="react-select"
      className="react-select"
      value={
        payGroupDetail.find(
          (option) =>
            option.value ===
            row.pay_group_id
        ) || null
      }
      onChange={(option: any) =>
        handleRowChange(index, "pay_group_id", option?.value)
      }
    />
    <i
      style={{ fontSize: "16px" }}
      className="ri-bank-card-2-line mt-4"
    ></i>
  </Form.Group>
</Col> */}




                          <Col
                            style={{ flex: "0.5px" }}
                            className="d-flex align-items-center mt-2"
                          >
                            <Button
                              className="p-0"
                              style={{ background: "none", border: "none" }}
                              onClick={() => handleDeleteRow(index)}
                            >
                              <i
                                className="bx bx-trash link-danger"
                                style={{ fontSize: "24px" }}
                              ></i>
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                    <Button
                      className="add-btn bg-none d-flex align-items-center gap-2 p-0"
                      style={{ background: "none", border: "none" }}
                      onClick={handleAddRow}
                    >
                      <i className="ri-add-fill"></i> Add Row
                    </Button>
                  </SimpleBar>
                </Col>
              </Row>
            </Offcanvas.Body>
            {/* <Row>
                            <Col lg="12" style={{ position: "absolute", bottom: "0" }}>
                                <div className="offcanvas-footer border-top-0 p-3 pb-0 text-center">
                                    <Row>
                                        <Col lg={12} className="off-bottom-staff justify-content-end">
                                            <div className="off-confirm">
                                                <Button
                                                    onClick={toggleBottomCanvas}
                                                    color="primary"
                                                    className="clear-btn"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    className="next-btn"
                                                    type="submit"
                                                >
                                                    {isEditing ? "Update" : "Add"}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row> */}
          </>
        </Form>
      </Offcanvas>
      <Modal
        show={modal_center}
        onHide={() => {
          tog_center();
        }}
        size="sm"
        centered
      >
        <Modal.Body className="text-center p-4">
          <i className="ri-delete-bin-line text-danger display-5"></i>
          <div className="mt-4">
            <h4 className="mb-3">Are you sure?</h4>
            <p className="text-muted mb-4">
              You want to delete this Patrol Location.
            </p>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setmodal_center(false)}>
                Close
              </Button>
              {archiveLoader ? (
                <Button color="primary" className="next-btn" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button
                  variant="danger"
                  onClick={() => {
                    if (archiveId !== undefined) {
                      archiveMaster(archiveId);
                    } else {
                      toast.error("No Archive ID provided.");
                    }
                  }}
                >
                  Delete
                </Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PatrolLocation;
