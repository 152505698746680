import React, { useMemo, useState, useEffect, ChangeEvent } from "react";
import TableContainer from "Common/TableContainer";
import { Link } from "react-router-dom";
import {
  Form,
  Badge,
  Offcanvas,
  Button,
  Row,
  Col,
  Card,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { getByClientDetail } from "helpers/saibackend_helper";
import { useFormik } from "formik";
import * as Yup from "yup";
import ClientListMap from "../ClientListMap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SimpleBar from "simplebar-react";
import {
  getClientDocumentDetail,
  addClientDocumentDetail,
  getByClientDocumentDetail,
  updateClientDocumentDetail,
  archiveClientDocumentDetail,
} from "helpers/saibackend_helper";

interface ClientData {
  id: number;
  name: string;
  address: string;
  abn_no: string;
  invoice_note: string;
  contact_person: string;
  contact_email: string;
  contact_phone: string;
  location: [];
}

interface ClientDocumentData {
  id: number;
  document_name: string;
  document_path: string;
}

interface LocationList {
  name: string;
  address: string;
}

interface ClientListTableProps {
  data: any;
  currentPage: number;
  totalPages: number;
  editClientMaster: (id: number) => Promise<void>;
  onPageChange: (page: number) => void;
  handleSortChange: (column: string, direction: string) => void;
  archiveClient: (id: number) => void;
  handleArchiveClient: (id: number) => Promise<void>;
  handleRestoreClient: (id: number) => Promise<void>;
  canAccess: boolean;
  userRoles: any;
}

const ClientListTable: React.FC<ClientListTableProps> = ({
  data,
  currentPage,
  totalPages,
  onPageChange,
  editClientMaster,
  archiveClient,
  handleSortChange,
  handleArchiveClient,
  handleRestoreClient,
  canAccess,
  userRoles
}) => {
  const [isBottom, setIsBottom] = useState(false);
  const [clickedItemId, setClickedItemId] = useState<string | null>(null);

  const toggleBottomCanvas = (id: string | null) => {
    setIsBottom(!isBottom);
    if (id) {
      setClickedItemId(id);
      localStorage.setItem("clientId", id);
    } else {
      setClickedItemId(null);
      localStorage.removeItem("clientId");
    }
  };
  const [ClientDetail, setClientDetail] = useState<ClientData | null>(null);

  const editMaster = async () => {
    try {
      if (clickedItemId !== null) {
        const response = await getByClientDetail(parseInt(clickedItemId));
        const data = response.data;
        setClientDetail(data);
      } else {
      }
    } catch (error) {
      console.error("Error fetching Client details:", error);
      toast.error("Error fetching details. Please try again later.");
    }
  };

  useEffect(() => {
    editMaster();
  }, [clickedItemId]);

  const editClientModal = async (id: number) => {
    try {
      editClientMaster(id);
      setIsBottom(false);
    } catch (error) {}
  };

  const locationList = ClientDetail?.location || [];

  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState<number | null>(null);

  const [ClientDocumentDetail, setClientDocumentDetail] = useState<
    ClientDocumentData[]
  >([]);

  const [defaultValue, setDefaultValue] = useState({
    page: 1,
    page_size: "10",
    search_term: "",
    sort: {
      sort_order: "ASC",
      sort_by: "",
    },
    is_archive: 0,
  });

  const fetchClientDetails = async () => {
    try {
      const response = await getClientDocumentDetail(
        defaultValue.page,
        defaultValue.page_size,
        defaultValue.search_term,
        defaultValue.sort.sort_order,
        defaultValue.sort.sort_by,
        defaultValue.is_archive,
        Number(clickedItemId)
      );
      setClientDocumentDetail(response.data.data_list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchClientDetails();
  }, [clickedItemId]);

  const validationSchema = Yup.object().shape({
    document_name: Yup.string().required("Document name is required"),
    document_path: Yup.mixed().required("Document is required"),
  });

  const formik = useFormik({
    initialValues: {
      document_name: "",
      document_path: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let response;
        if (isEditing && editId !== null) {
          response = await updateClientDocumentDetail(
            editId,
            values.document_name,
            values.document_path
          );
          if (response.status === 1) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
            return;
          }
        } else {
          const payload = {
            ...values,
            client_id: clickedItemId,
          };
          console.log(payload);
          response = await addClientDocumentDetail(payload);
          if (response.status === 1) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
            return;
          }
        }
        formik.resetForm();
        fetchClientDetails();
        setIsEditing(false);
        setEditId(null);
      } catch (error) {
        console.error("Error Adding/Updating Client Document:", error);
        toast.error("Error Client Document. Please try again later.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const columns = useMemo(
    () => [
      {
        header: "Client Name",
        accessorKey: "name",
        disableFilters: true,
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => {
          const itemId = cellProps.row.original.id;
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => toggleBottomCanvas(itemId)}
            >
              {" "}
              {cellProps.row.original.name}
            </span>
          );
        },
      },
      {
        header: "No Of Locations",
        accessorKey: "total_area",
        disableFilters: false,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          const location_list = cellProps.row.original.total_area;
          return <>{location_list ? location_list : "0"}</>;
        },
      },
      {
        header: "ABN Number",
        accessorKey: "abn_no",
        disableFilters: false,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          const abn_no = cellProps.row.original.abn_no;
          return <>{abn_no ? abn_no : "0"}</>;
        },
      },
      {
        header: "Status",
        accessorKey: "is_archive",
        disableFilters: true,
        enableColumnFilter: false,
        enableSorting: true,

        cell: (cellProps: any) => {
          return cellProps.row.original.is_archive === 1 ? (
            <span className="badge bg-body-danger rounded-pill border border-danger text-danger">
              ARCHIVE
            </span>
          ) : (
            <span className="badge bg-body-success rounded-pill border border-success text-success">
              ACTIVE
            </span>
          );
        },
      },
      {
        header: "Action",
        disableFilters: false,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          const itemId = cellProps.row.original.id;
          return (
            <div className="d-flex gap-2 list-unstyled mb-0">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="view-tooltip">View</Tooltip>}
              >
                <i
                  className="ri-eye-line link-info p-0"
                  onClick={() => toggleBottomCanvas(itemId)}
                  style={{ fontSize: "18px", cursor: "pointer" }}
                ></i>
              </OverlayTrigger>
              <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="Edit-tooltip">Edit</Tooltip>}
                >
              <i
                                onClick={() => editClientMaster(itemId)}
                                className="ri-edit-2-line link-success"
                                style={{ fontSize: '18px', cursor: 'pointer' }}
                            ></i>
                </OverlayTrigger>            
              {canAccess && userRoles[0] !== "MANAGER" && cellProps.row.original.is_archive == 0 ? (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}
                >
                  <i
                    onClick={() => handleArchiveClient(itemId)}
                    className="ri-delete-bin-line link-danger"
                    style={{ fontSize: "18px", cursor: "pointer" }}
                  ></i>
                </OverlayTrigger>
              ) : (canAccess && userRoles[0] !== "MANAGER" &&
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="restore-tooltip">Restore</Tooltip>}
                >
                  <i
                    className="ri-restart-line link-success"
                    onClick={() => handleRestoreClient(itemId)}
                    style={{ fontSize: "18px", cursor: "pointer" }}
                  ></i>
                </OverlayTrigger>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  const baseurl = process.env.REACT_APP_IMAGE_URL;

  const handleOpenDocument = (path: string) => {
    const fullPath = `${baseurl}/${path}`;
    window.open(fullPath, "_blank");
  };

  return (
    <React.Fragment>
      {data.length > 0 ? (
        <TableContainer
          isPagination={true}
          columns={columns || []}
          data={data}
          isBordered={false}
          customPageSize={10}
          PaginationClassName="align-items-center mt-4 pt-2"
          divClassName="table-responsive table-card mt-0"
          tableClass="table-centered align-middle table-custom-effect table-nowrap mb-0"
          theadClass="text-muted table-light"
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
          onSortChange={handleSortChange}
        />
      ) : (
        <div className="noresult">
          <div className="text-center py-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light text-primary rounded-circle fs-4xl">
                <i className="bi bi-search"></i>
              </div>
            </div>
            <h5 className="mt-2">No Client Available</h5>
          </div>
        </div>
      )}
      <Offcanvas
        style={{ minHeight: "90vh" }}
        className="client-profile-offcanvas"
        show={isBottom}
        onHide={toggleBottomCanvas}
        placement="bottom"
      >
        <Offcanvas.Header className="add-header border-bottom">
          <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
            {ClientDetail?.name ? `${ClientDetail?.name} Profile` : "-"}
          </Offcanvas.Title>
          <div>
            {canAccess && userRoles[0] !== "MANAGER" && (<Button
              onClick={() => {
                if (clickedItemId) {
                  editClientModal(parseInt(clickedItemId));
                }
              }}
              className="edit-btn"
            >
              Edit
            </Button>)}
            <Button
              className="close-btn"
              onClick={() => {
                setIsBottom(false);
              }}
            >
              <i className="ri-close-line"></i>
            </Button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col lg={6}>
              <Card className="details-section-second">
                <Card.Body>
                  <h5>
                    <i className=" ri-contacts-book-2-line"></i>General &
                    Contact Details
                  </h5>
                  <Row>
                    {/* <h5>General Details</h5> */}
                    {/* <Col lg="12">
                                        <p className='detail-people'><i className='ri-phone-line icon-profile'></i> +61 12345 67890</p>
                                        <p className='detail-people1'><i className='ri-mail-line icon-profile'></i> adams.mitchell@gmail.com</p>
                                    </Col> */}
                    <Col lg={4}>
                      <p className="detail-title">Client Name</p>
                      <p className="detail-value">
                        {ClientDetail?.name ? ClientDetail?.name : "-"}
                      </p>
                    </Col>
                    <Col lg={4}>
                      <p className="detail-title">ABN Number</p>
                      <p className="detail-value">
                        {ClientDetail?.abn_no ? ClientDetail?.abn_no : "-"}
                      </p>
                    </Col>
                    <Col lg={4}>
                      <p className="detail-title">Invoice Note</p>
                      <p className="detail-value">
                        {ClientDetail?.invoice_note
                          ? ClientDetail?.invoice_note
                          : "-"}
                      </p>
                    </Col>
                    <Col lg={4} className="mt-4">
                      <p className="detail-title">Contact Person Name</p>
                      <p className="detail-value">
                        {ClientDetail?.contact_person
                          ? ClientDetail?.contact_person
                          : "-"}
                      </p>
                    </Col>
                    <Col lg={4} className="mt-4">
                      <p className="detail-title">Contact Person Email</p>
                      <p className="detail-value">
                        {ClientDetail?.contact_email
                          ? ClientDetail?.contact_email
                          : "-"}
                      </p>
                    </Col>
                    <Col lg={4} className="mt-4">
                      <p className="detail-title">Contact Person Number</p>
                      <p className="detail-value">
                        {ClientDetail?.contact_phone
                          ? ClientDetail?.contact_phone
                          : "-"}
                      </p>
                    </Col>
                    <Col lg={12} className="mt-4">
                      <p className="detail-title">Address</p>
                      <p className="detail-value">
                        {ClientDetail?.address ? ClientDetail?.address : "-"}
                      </p>
                    </Col>
                  </Row>
                  {/* <h5 className='mt-3'>Contact Details</h5> */}
                  <Row></Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} className="ClientListMap">
              <ClientListMap locations={ClientDetail?.location} />
            </Col>
            <Col lg="6">
              <Card className="details-section-third mt-4">
                <Card.Body>
                  <Row>
                    <Col lg="12">
                      <h5>
                        <i className=" ri-map-pin-line"></i>Location List
                      </h5>
                    </Col>
                    <Col lg="12">
                      <SimpleBar className="py-0" style={{ height: "350px" }}>
                        {locationList && locationList?.length > 0 ? (
                          <div className="table-responsive mt-0">
                            <Table className="m-0">
                              <tbody>
                                {locationList?.map(
                                  (item: LocationList, key) => (
                                    <tr key={key}>
                                      <td>
                                        <div className="d-flex align-items-center gap-1">
                                          <div className="flex-grow-1">
                                            <h6 className="fs-md mb-1">
                                              <Link
                                                to="#"
                                                className="text-reset"
                                              >
                                                {item?.name}
                                              </Link>
                                            </h6>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center justify-content-center gap-1 p-4">
                            <div className="flex-grow-1 d-flex justify-content-center">
                              <h6 className="fs-md mb-1">
                                No Location Available
                              </h6>
                            </div>
                          </div>
                        )}
                      </SimpleBar>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="6">
              <Card className="details-section-third mt-4">
                <Card.Body>
                  <Row>
                    <Col lg="12">
                      <h5>
                        <i className="ri-file-text-line"></i>
                        Documents
                      </h5>
                    </Col>
                    <Col lg="12">
                      <SimpleBar className="py-0" style={{ height: "350px" }}>
                        {ClientDocumentDetail &&
                        ClientDocumentDetail?.length > 0 ? (
                          <Table responsive className="table table-nowrap">
                            <thead>
                              <tr>
                                <th scope="col">Document Name</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {ClientDocumentDetail.map((document) => (
                                <tr key={document.id}>
                                  <td>
                                    {document?.document_name
                                      ? document.document_name
                                      : "-"}
                                  </td>
                                  <td>
                                    {/* <i onClick={() => editClientDocument(document.id)} className="ri-pencil-line"></i> */}
                                    <i
                                      onClick={() =>
                                        handleOpenDocument(
                                          document.document_path
                                        )
                                      }
                                      className="ri-eye-line link-info"
                                    ></i>
                                    {/* <i onClick={() => archiveMaster(document.id)} className="ri-delete-bin-line"></i> */}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        ) : (
                          <div className="d-flex align-items-center justify-content-center gap-1 p-4">
                            <div className="flex-grow-1 d-flex justify-content-center">
                              <h6 className="fs-md mb-1">
                                No Documents Available
                              </h6>
                            </div>
                          </div>
                        )}
                      </SimpleBar>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
};

export default ClientListTable;
