import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "Common/TableContainer";
import { Link } from "react-router-dom";
import {
  Offcanvas,
  Button,
  Row,
  Col,
  Card,
  Table,
  Modal,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import SimpleBar from "simplebar-react";
import {
  getBySubcontractorDetail,
  getStaffDocumentDetail,
  getByStaffDetail,
  SubcontractorSendEmail,
} from "helpers/saibackend_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface SubcontractorData {
  id: number;
  name: string;
  abbreviation: string;
  abn: string;
  contact_person: string;
  contact_number: string;
  email: string;
  address: string;
  total_staff: number;
  assigned_locations: number;
  staff_list: [];
  location_list: [];
  subcontractor_documents: [];
  prefere_location_list: [];
  is_archive: number;
  is_reset_password: number;
}

interface SubcontractorViewDetail {
  location_list?: { name: string }[];
}

interface Props {
  subcontractorViewDetail?: SubcontractorViewDetail;
}

interface StaffMember {
  first_name: string;
  last_name: string;
}

interface LocationList {
  name: string;
  address: string;
}

interface SubcontractorViewDetail {
  staff_list?: StaffMember[];
}

interface SubCompliance {
  compliance_name: string;
  compliance_path: string;
  document_number: number;
  date: string;
  reminder_days: number;
  is_critical: number;
}

interface Props {
  subcontractorViewDetail?: SubcontractorViewDetail;
}

const SubContractorTable = ({
  data,
  handleSortChange,
  currentPage,
  handleArchiveSubcontractor,
  handleRestoreSubcontractor,
  archiveMaster,
  totalPages,
  onPageChange,
  canAccess,
  userRoles
}: {
  data: any;
  handleSortChange: (column: string, direction: string) => void;
  currentPage: number;
  handleArchiveSubcontractor: (id: number) => Promise<void>;
  handleRestoreSubcontractor: (id: number) => Promise<void>;
  archiveMaster: (id: number) => void;
  totalPages: number;
  onPageChange: (page: number) => void;
  canAccess: boolean;
  userRoles: any;
}) => {
  const [isBottom, setIsBottom] = useState(false);
  const [clickedItemId, setClickedItemId] = useState<string | null>(null);

  const toggleBottomCanvas = (id: string | null) => {
    setIsBottom(!isBottom);
    if (id) {
      setClickedItemId(id);
      localStorage.setItem("subcontractorID", id);
      console.log(id);
    } else {
      setClickedItemId(null);
      localStorage.removeItem("subcontractorID");
    }
  };

  const [subcontractorViewDetail, setSubcontractorViewDetail] =
    useState<SubcontractorData | null>(null);

  const editMaster = async () => {
    try {
      if (clickedItemId !== null) {
        const response = await getBySubcontractorDetail(
          parseInt(clickedItemId)
        );
        const data = response.data;
        setSubcontractorViewDetail(data);
      } else {
      }
    } catch (error) {
      console.error("Error fetching Subcontractor details:", error);
      toast.error("Error fetching details. Please try again later.");
    }
  };

  useEffect(() => {
    editMaster();
  }, [clickedItemId]);

  const locationList = subcontractorViewDetail?.prefere_location_list || [];
  const staffList = subcontractorViewDetail?.staff_list || [];

  const doc_url = process.env.REACT_APP_IMAGE_URL;

  const handleOpenDocument = (path: string) => {
    const fullPath = `${doc_url}/${path}`;
    window.open(fullPath, "_blank");
  };

  const [StaffViewDetail, setStaffViewDetail] = useState<any | null>(null);

  const editStaffMaster = async (id: any) => {
    try {
      const response = await getByStaffDetail(id);
      const data = response.data;
      setStaffViewDetail(data);
      tog_standard();
    } catch (error) {
      return;
    }
  };

  const [StaffDocumentDetail, setStaffDocumentDetail] = useState<any[]>([]);

  const fetchStaffDocumentDetails = async (id: any) => {
    try {
      const response = await getStaffDocumentDetail("", id);
      setStaffDocumentDetail(response.data.data_list);
    } catch (error) {
      console.log(error);
    }
  };

  const [modal_standard, setmodal_standard] = useState<boolean>(false);
  function tog_standard() {
    setmodal_standard(!modal_standard);
  }

  const imageSrc = `${doc_url}/${StaffViewDetail?.avatar}`;

  const leaveNames =
    StaffViewDetail?.staff_leaves?.map(
      (leave: { leave_name: string }) => leave.leave_name
    ) || [];

  const getAbbreviation = (name: string) => {
    return name
      ?.split(" ")
      ?.map((word) => word.charAt(0))
      ?.join("")
      ?.toUpperCase()
      ?.slice(0, 2);
  };

  const [sendMailLoader, setSendMailLoader] = useState(false);

  const sendsubcontractoremail = async () => {
    setSendMailLoader(true);
    try {
      const subid = localStorage.getItem("subcontractorID");
      if (subid !== null) {
        const response = await SubcontractorSendEmail(parseInt(subid));
        if (response.status === 1) {
          toast.success(response.message);
          setSendMailLoader(false);
        } else {
          toast.error(response.message);
          setSendMailLoader(false);
        }
      } else {
        toast.error("Please try again later.");
      }
    } catch (error) {
      toast.error("Error fetching details. Please try again later.");
    }
  };

  const columns = useMemo(
    () => [
      // {
      //   header: (
      //     <Form.Check>
      //       {" "}
      //       <Form.Check.Input
      //         type="checkbox"
      //         id="checkAll"
      //         value="option"
      //       />{" "}
      //     </Form.Check>
      //   ),
      //   cell: (cellProps: any) => {
      //     return (
      //       <Form.Check>
      //         {" "}
      //         <Form.Check.Input
      //           type="checkbox"
      //           name="chk_child"
      //           defaultValue="option1"
      //         />{" "}
      //       </Form.Check>
      //     );
      //   },
      //   id: "#",
      //   enableColumnFilter: false,
      //   enableSorting: false,
      // },
      {
        header: "Subcontractor Name",
        accessorKey: "name",
        disableFilters: true,
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => {
          const name = cellProps.row.original.name;
          const itemId = cellProps.row.original.id;
          return (
            <>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => toggleBottomCanvas(itemId)}
              >
                {" "}
                {name ? name : "-"}{" "}
              </span>
            </>
          );
        },
      },
      {
        header: "Total Guards",
        accessorKey: "total_staff",
        disableFilters: false,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          const total_staff = cellProps.row.original.total_staff;
          return <>{total_staff ? total_staff : "0"}</>;
        },
      },
      {
        header: "Assigned Locations",
        accessorKey: "assigned_locations",
        disableFilters: false,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          const assigned_locations = cellProps.row.original.assigned_locations;
          return <>{assigned_locations ? assigned_locations : "0"}</>;
        },
      },
      {
        header: "Contact No",
        accessorKey: "contact_number",
        disableFilters: false,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          const contact_number = cellProps.row.original.contact_number;
          return <>{contact_number ? contact_number : "-"}</>;
        },
      },
      {
        header: "Status",
        accessorKey: "is_archive",
        disableFilters: true,
        enableColumnFilter: false,
        enableSorting: true,

        cell: (cellProps: any) => {
          return cellProps.row.original.is_archive === 1 ? (
            <span className="badge bg-body-danger rounded-pill border border-danger text-danger">
              ARCHIVE
            </span>
          ) : (
            <span className="badge bg-body-success rounded-pill border border-success text-success">
              ACTIVE
            </span>
          );
        },
      },
      {
        header: "Action",
        disableFilters: false,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          const itemId = cellProps.row.original.id;
          return (
            <div className="d-flex gap-2 list-unstyled mb-0">
              <div className="edit">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="view-tooltip">View</Tooltip>}
                >
                  <i
                    onClick={() => toggleBottomCanvas(itemId)}
                    className="ri-eye-line link-info"
                    style={{ fontSize: "18px", cursor: "pointer" }}
                  ></i>
                </OverlayTrigger>
              </div>
              {canAccess && userRoles[0] !== "MANAGER" && (<div className="edit">
                {cellProps.row.original.is_archive == 0 ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}
                  >
                    <i
                      onClick={() => handleArchiveSubcontractor(itemId)}
                      className="ri-delete-bin-line link-danger"
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    ></i>
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="restore-tooltip">Restore</Tooltip>}
                  >
                    <i
                      className="ri-restart-line link-success"
                      onClick={() => handleRestoreSubcontractor(itemId)}
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    ></i>
                  </OverlayTrigger>
                )}
              </div>)}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {data.length > 0 ? (
        <TableContainer
          isPagination={true}
          columns={columns || []}
          data={data}
          isBordered={false}
          customPageSize={10}
          PaginationClassName="align-items-center mt-4 pt-2"
          tableClass="table-centered align-middle table-custom-effect table-nowrap mb-0"
          theadClass="text-muted table-light"
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
          onSortChange={handleSortChange}
        />
      ) : (
        <div className="noresult">
          <div className="text-center py-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light text-primary rounded-circle fs-4xl">
                <i className="bi bi-search"></i>
              </div>
            </div>
            <h5 className="mt-2">No Subcontractor Available</h5>
          </div>
        </div>
      )}

      <Offcanvas
        className="view-subcontractor"
        show={isBottom}
        onHide={toggleBottomCanvas}
        placement="bottom"
        style={{ minHeight: "90vh" }}
      >
        <Offcanvas.Header className="add-header border-bottom">
          <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
            {subcontractorViewDetail?.name
              ? `${subcontractorViewDetail?.name} Details`
              : "-"}
          </Offcanvas.Title>
          <div className="d-flex gap-2">
            {subcontractorViewDetail?.is_archive == 1 ? (
              <span
                className="badge bg-body-danger rounded-pill border border-danger text-danger"
                style={{ fontSize: "1rem" }}
              >
                ARCHIVE
              </span>
            ) : (
              <span className="d-flex gap-2 align-items-center">
                <Button className="edit-btn" href="/edit-subcontractor">
                  Edit
                </Button>
                {subcontractorViewDetail?.is_reset_password === 0 &&
                  (sendMailLoader ? (
                    <Button color="primary" className="edit-btn" disabled>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </Button>
                  ) : (
                    <Button
                      className="edit-btn"
                      onClick={sendsubcontractoremail}
                      style={{ width: "auto" }}
                    >
                      Re - Invite
                    </Button>
                  ))}
              </span>
            )}

            <Button
              className="close-btn"
              onClick={() => {
                setIsBottom(false);
              }}
            >
              <i className="ri-close-line"></i>
            </Button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <div style={{ flex: "2" }}>
              <Row>
                <Col className="user-details-section-col" lg={7}>
                  <Card className="details-section-second">
                    <Card.Body>
                      <h5>
                        <i className=" ri-map-pin-line"></i>General Details
                      </h5>
                      <Row>
                        <Col lg={6} className="user-details-col">
                          <p className="detail-title">Company Name</p>
                          <p className="detail-value">
                            {subcontractorViewDetail?.name
                              ? subcontractorViewDetail?.name
                              : "-"}
                          </p>
                        </Col>
                        <Col lg={6} className="user-details-col">
                          <p className="detail-title">Company ABN</p>
                          <p className="detail-value">
                            {subcontractorViewDetail?.abn
                              ? subcontractorViewDetail?.abn
                              : "-"}
                          </p>
                        </Col>
                        <Col lg={6} className="emp-type user-details-col">
                          <p className="detail-title">Address</p>
                          <p className="detail-value">
                            {subcontractorViewDetail?.address
                              ? subcontractorViewDetail?.address
                              : "-"}
                          </p>
                        </Col>
                        <Col lg={6} className="emp-type user-details-col">
                          <p className="detail-title">Contact Person Name</p>
                          <p className="detail-value">
                            {subcontractorViewDetail?.contact_person
                              ? subcontractorViewDetail?.contact_person
                              : "-"}{" "}
                          </p>
                        </Col>
                        <Col lg={6} className="emp-type user-details-col">
                          <p className="detail-title">Email</p>
                          <p className="detail-value">
                            {subcontractorViewDetail?.email
                              ? subcontractorViewDetail?.email
                              : "-"}
                          </p>
                        </Col>
                        <Col lg={6} className="emp-type user-details-col">
                          <p className="detail-title">Mobile Number</p>
                          <p className="detail-value">
                            {subcontractorViewDetail?.contact_number
                              ? subcontractorViewDetail?.contact_number
                              : "-"}
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="5">
                  <Card className="details-section-third">
                    <Card.Body>
                      <Row>
                        <Col lg="12">
                          <h5>
                            <i className=" ri-map-pin-line"></i>Location List
                          </h5>
                        </Col>
                        <Col lg="12">
                          <SimpleBar
                            className="py-0"
                            style={{ height: "187px" }}
                          >
                            <div className="table-responsive mt-0">
                              <Table className="m-0">
                                <tbody>
                                  {locationList?.length > 0 ? (
                                    locationList?.map(
                                      (item: LocationList, key) => (
                                        <tr key={key}>
                                          <td>
                                            <div className="d-flex align-items-center gap-1">
                                              <div className="flex-grow-1">
                                                <h6 className="fs-md mb-1">
                                                  <Link
                                                    to="#"
                                                    className="text-reset"
                                                  >
                                                    {item?.name}
                                                  </Link>
                                                </h6>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    )
                                  ) : (
                                    <tr>
                                      <td style={{ border: "none" }}>
                                        <div className="d-flex align-items-center justify-content-center gap-1">
                                          <div className="flex-grow-1 d-flex justify-content-center">
                                            <h6 className="fs-md mb-1">
                                              No Location Available
                                            </h6>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </SimpleBar>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card className="details-section-third">
                    <Card.Body>
                      <Row>
                        <Col lg="12">
                          <h5>
                            <i className="ri-file-text-line"></i>Subcontractor
                            Documents
                          </h5>
                        </Col>
                        <Col lg="12">
                          <SimpleBar
                            className="py-0"
                            style={{ height: "290px" }}
                          >
                            {subcontractorViewDetail?.subcontractor_documents &&
                              subcontractorViewDetail?.subcontractor_documents
                                .length > 0 ? (
                              <Table responsive className="table table-nowrap">
                                <thead>
                                  <tr>
                                    <th scope="col">Document Name</th>
                                    <th scope="col">
                                      License / Policy name / Reference
                                    </th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Reminder</th>
                                    <th scope="col">Critical</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {subcontractorViewDetail.subcontractor_documents.map(
                                    (document: SubCompliance, index) => (
                                      <tr key={index}>
                                        <td>
                                          {document?.compliance_name
                                            ? document.compliance_name
                                            : "-"}
                                        </td>
                                        <td>
                                          {document?.document_number
                                            ? document.document_number
                                            : "-"}
                                        </td>
                                        <td>
                                          {document?.date ? document.date : "-"}
                                        </td>
                                        <td>
                                          {document?.reminder_days
                                            ? document.reminder_days
                                            : "-"}
                                        </td>
                                        <td>
                                          {document?.is_critical ? "Yes" : "No"}
                                        </td>
                                        <td>
                                          <OverlayTrigger placement="top" overlay={<Tooltip id="view-tooltip">View</Tooltip>}>
                                            <i
                                              className="ri-eye-line link-info"
                                              style={{ background: "none", cursor: "pointer" }}
                                              onClick={() => handleOpenDocument(document.compliance_path)}
                                            ></i>
                                          </OverlayTrigger>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            ) : (
                              <div className="d-flex align-items-center justify-content-center gap-1 p-4">
                                <div className="flex-grow-1 d-flex justify-content-center">
                                  <h6 className="fs-md mb-1">
                                    No Subcontractor Documents Available
                                  </h6>
                                </div>
                              </div>
                            )}
                          </SimpleBar>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card className="details-section-third">
                    <Card.Body>
                      <Row>
                        <Col lg="12">
                          <h5>
                            <i className="ri-map-pin-user-line"></i>Guards List
                          </h5>
                        </Col>
                        <Col lg="12">
                          <SimpleBar
                            className="py-0"
                            style={{ height: "290px" }}
                          >
                            <div className="table-responsive mt-0">
                              <Table className="m-0">
                                <tbody>
                                  {staffList.length > 0 ? (
                                    staffList.map((item: any, key: number) => {
                                      const initials = item.last_name
                                        ? `${item.first_name.charAt(
                                          0
                                        )}${item.last_name.charAt(0)}`
                                        : `${item.first_name.slice(0, 2)}`;
                                      return (
                                        <tr key={key}>
                                          <td>
                                            <div className="d-flex align-items-center gap-3">
                                              <div className="avatar-sm">
                                                <div className="avatar-title rounded bg-danger-subtle text-danger">
                                                  {initials}
                                                </div>
                                              </div>
                                              <div className="flex-grow-1">
                                                <h6 className="fs-md mb-1">
                                                  <Link
                                                    to="#"
                                                    className="text-reset"
                                                    style={{
                                                      display: "flex",
                                                      gap: "4px",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    {item.first_name}{" "}
                                                    {item.last_name}{" "}
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id="info-tooltip">Info</Tooltip>}>
                                                      <i
                                                        onClick={() => {
                                                          if (item?.staff_id) {
                                                            editStaffMaster(item.staff_id);
                                                            fetchStaffDocumentDetails(item.staff_id);
                                                          }
                                                        }}
                                                        className="ri-information-line link-info"
                                                        style={{
                                                          cursor: "pointer",
                                                          fontSize: "18px",
                                                        }}
                                                      ></i>
                                                    </OverlayTrigger>
                                                  </Link>
                                                </h6>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td style={{ border: "none" }}>
                                        <div className="d-flex align-items-center justify-content-center gap-1">
                                          <div className="flex-grow-1 d-flex justify-content-center">
                                            <h6 className="fs-md mb-1">
                                              No Guard Available
                                            </h6>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </SimpleBar>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        id="myModal"
        size="sm"
        show={modal_standard}
        onHide={() => {
          tog_standard();
        }}
      >
        {/* <Modal.Header className="modal-title fs-xl" id="myModalLabel">
                                {StaffViewDetail?.first_name} {StaffViewDetail?.last_name}
                            </Modal.Header> */}
        <Modal.Body>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 position-relative">
              {StaffViewDetail?.avatar ? (
                <img src={imageSrc} alt="" className="avatar-sm rounded" />
              ) : (
                <div className="avatar-sm">
                  <div className="avatar-title rounded bg-danger-subtle text-danger">
                    {getAbbreviation(
                      StaffViewDetail?.employee_name
                        ? StaffViewDetail?.employee_name
                        : "-"
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="flex-grow-1 ms-2">
              <h5 className="fs-md mb-0">
                <Link to="/pages-profile" className="text-dark">
                  {StaffViewDetail?.first_name} {StaffViewDetail?.last_name}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                {" "}
                {StaffViewDetail?.access_level
                  ? StaffViewDetail?.access_level
                  : "-"}{" "}
              </p>
              <p className="text-muted mb-0">
                {" "}
                {StaffViewDetail?.employee_status
                  ? StaffViewDetail?.employee_status
                  : "-"}{" "}
              </p>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              right: "5px",
              top: "5px",
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            <i onClick={tog_standard} className="ri-close-line"></i>
          </div>
          <div className="mt-4">
            <p className="text-muted mb-2">
              <i className="bi bi-telephone align-baseline me-1"></i>
              {StaffViewDetail?.mobile_no ? (
                <Link
                  to={`tel:${StaffViewDetail.mobile_no}`}
                  className="text-muted"
                  style={{ paddingLeft: "4px" }}
                >
                  {StaffViewDetail.mobile_no}
                </Link>
              ) : (
                "-"
              )}
            </p>
            <p className="text-muted mb-2">
              <i className="bi bi-envelope align-baseline me-1"></i>
              {StaffViewDetail?.email ? (
                <Link
                  to={`mailto:${StaffViewDetail.email}`}
                  className="text-muted"
                  style={{ paddingLeft: "4px" }}
                >
                  {StaffViewDetail.email}{" "}
                </Link>
              ) : (
                "-"
              )}
            </p>
            <p className="text-muted mb-2">
              <i className="bi bi-geo-alt align-baseline me-1"></i>{" "}
              {StaffViewDetail?.address ? StaffViewDetail?.address : "-"}{" "}
            </p>
            <p className="text-muted mb-2">
              <i className="mdi mdi-gender-transgender align-baseline me-1"></i>{" "}
              {StaffViewDetail?.gender ? StaffViewDetail?.gender : "-"}{" "}
            </p>
            <p className="text-muted mb-0">
              <i className="ri-cake-line align-baseline me-1"></i>{" "}
              {StaffViewDetail?.dob ? StaffViewDetail?.dob : "-"}{" "}
            </p>
          </div>
          {StaffDocumentDetail.filter((doc) => doc.document_type === "Licenses")
            .length > 0 && (
              <div className="border-bottom border-top border-dashed py-4 mt-4">
                <h5 className="card-title mb-3">License</h5>
                <div className="vstack gap-3">
                  {StaffDocumentDetail.filter(
                    (doc) => doc.document_type === "Licenses"
                  ).map((doc, index) => (
                    <div
                      key={index}
                      className="d-flex gap-2 align-items-center position-relative"
                    >
                      <div className="flex-shrink-0">
                        <div className="avatar-sm border border rounded">
                          <div className="avatar-title bg-body-secondary text-secondary rounded fs-lg">
                            <i className="bi bi-file-pdf"></i>
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6>License No : {doc.ref_no}</h6>
                        <p className="text-muted mb-0">
                          Expiry Date : {doc.expiry_date ? doc.expiry_date : "-"}
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <OverlayTrigger placement="top" overlay={<Tooltip id="view-tooltip">View</Tooltip>}>
                          <Link
                            to={`#`}
                            onClick={() => handleOpenDocument(doc.document_path)}
                            className="icon-link text-reset stretched-link fs-xl"
                            style={{ transform: "translate3d(0, -.125rem, 0)" }}
                          >
                            <i className="ri-eye-line link-info"></i>
                          </Link>
                        </OverlayTrigger>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default SubContractorTable;
