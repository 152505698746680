import { useProfile } from "Common/Hooks/UserHooks";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DataTable from "react-data-table-component";

interface IncidentTemplateMasterData {
  id: number;
  name: string;
}

type SortOrder = "asc" | "desc";

interface IncidentTemplateTableProps {
  data: IncidentTemplateMasterData[];
  archiveMaster: (id: number) => Promise<void>;
  editMaster: (id: number) => Promise<void>;
  handlePageChange: (page: number) => void;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handleArchiveCompliance: (id: number) => Promise<void>;
  paginationTotalRows: number;
  handleSort: (column: string, sortDirection: "asc" | "desc") => void;
}

const TemplateTable: React.FC<IncidentTemplateTableProps> = ({
  data,
  archiveMaster,
  handlePageChange,
  editMaster,
  handlePerRowsChange,
  paginationTotalRows,
  handleArchiveCompliance,
  handleSort,
}) => {

  const { userProfile } = useProfile();
  const userRoles = userProfile.roles;
  const allowedRoles = ['DEVELOPER', 'SUPER_ADMIN', 'ADMIN', 'MANAGER'];
  const hasRoles = (roles: string[]) => roles.some(role => userRoles.includes(role));
  const canAccess = hasRoles(allowedRoles);
  
  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Incident Name</span>,
      selector: (row: any) => row.name,
      sortField: "name",
      sortable: true,
      left: true,
      cell: (row: any) => <div style={{ textAlign: "left" }}>{row.name}</div>,
    },
    {
      name: canAccess && userRoles[0] !== "MANAGER" ? (
        <span className="font-weight-bold fs-sm">Action</span>
      ) : null,
      // sortable: true,
      right: true,
      cell: (row: any) => {
        return ( canAccess && userRoles[0] !== "MANAGER" &&
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}
          >
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="edit-tooltip">Edit</Tooltip>}
            >
              <i
                onClick={() => editMaster(row.id)}
                className="ri-edit-2-line link-success"
                style={{ fontSize: "18px", cursor: "pointer" }}
              ></i>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}
            >
              <i
                onClick={() => handleArchiveCompliance(row.id)}
                style={{ fontSize: "18px", cursor: "pointer" }}
                className="ri-delete-bin-line link-danger"
              ></i>
            </OverlayTrigger>
          </div>
        );
      },
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      pagination
      paginationServer
      paginationTotalRows={paginationTotalRows}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerRowsChange}
      onSort={(column: any, sortDirection: SortOrder) => {
        if (column.sortField) {
          handleSort(column.sortField, sortDirection);
        }
      }}
      sortServer
    />
  );
};

export default TemplateTable;
