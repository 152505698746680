import React, { ChangeEvent, useState, useEffect } from "react";
import { Container, Col, Row, Card, Button, Offcanvas, Form, Dropdown, Badge, Modal, Nav, Tab, Spinner } from "react-bootstrap";
import BreadCrumb from 'Common/BreadCrumb';
import LeaveTable from "./workforcetables/LeaveTable";
import Flatpickr from "react-flatpickr"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CountUp from 'react-countup';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import * as Yup from 'yup';
import moment from 'moment';
import Cleave from 'cleave.js/react';
import { getTimeOffDetail, getTimeOffEmployeeDetail, getByLeaveDetail, approveTimeOffDetail, getByLeaveBalanceDetail, restoreTimeOffDetail, addTimeOffDetail, getByTimeOffDetail, archiveTimeOffDetail, updateTimeOffDetail } from "helpers/saibackend_helper";
import Select from 'react-select';

interface TimeOffData {
    id: number;
    first_name: string;
    last_name: string;
    start_date: string,
    end_date: string,
    leave_name: string,
    status: string,
    time_id: number
}

interface TimeOffEmployeeData {
    id: number;
    first_name: string;
    last_name: string;
}

interface TimeOffLeaveData {
    id: number;
    leave_name: string;
    default_hours: string;
    available_hours: number;
    leave_type_id: number;
    value: number;
    label: string;
}

interface TimeOffLeaveBalanceData {
    total_leave_time: string;
}

interface TimeOffSummary {
    today_leave: number,
    total_approve: number,
    total_pending: number,
    total_reject: number
}

type PayloadValueType = {
    page: number;
    page_size: string;
    search_term: string;
    sort: {
        sort_order: string;
        sort_by: string;
    };
    is_archive: number;
    staff_id: string[],
    leave_type_id: string[],
    status: string[];
    start_date: string;
    end_date: string;
};


const LeavePage = (() => {

    document.title = "Time Off | The Guard Duty";

    const [courseFilters, setCourseFilters] = useState<boolean>(false);
    const handleFilterShow = () => setCourseFilters(!courseFilters);

    const [isBottom, setIsBottom] = useState(false);
    const toggleBottomCanvas = () => {
        setIsBottom(!isBottom);
    };

    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState<number | null>(null);

    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);

    const [TimeOffDetail, setTimeOffDetail] = useState<TimeOffData[]>([])
    const [TimeOffEmployeeDetail, setTimeOffEmployeeDetail] = useState<TimeOffEmployeeData[]>([])
    const [TimeOffLeaveDetail, setTimeOffLeaveDetail] = useState<TimeOffLeaveData[]>([])

    const [defaultValue, setDefaultValue] = useState<PayloadValueType>({
        page: 1,
        page_size: "10",
        search_term: "",
        sort: {
            sort_order: "ASC",
            sort_by: ""
        },
        is_archive: 0,
        staff_id: [],
        leave_type_id: [],
        status: [],
        start_date: "",
        end_date: "",

    })

    const [defaultEmployeeValue, setDefaultEmployeeValue] = useState({
        search_term: "",
    })

    const [TimeOffSummary, setTimeOffSummary] = useState<TimeOffSummary>({
        today_leave: 0,
        total_approve: 0,
        total_pending: 0,
        total_reject: 0
    });

    const fetchTimeOffEmployeeDetails = async () => {
        try {
            const searchTerm = "";
            const response = await getTimeOffEmployeeDetail(searchTerm);
            setTimeOffEmployeeDetail(response.data);
        } catch (error) {
            console.log(error);

        }
    };

    useEffect(() => {
        if (courseFilters || isBottom) {
            fetchTimeOffEmployeeDetails()
        }
    }, [courseFilters, isBottom]);


    const fetchTimeOffDetails = async () => {
        try {
            const response = await getTimeOffDetail(defaultValue.page, defaultValue.page_size, defaultValue.search_term, defaultValue.sort.sort_order, defaultValue.sort.sort_by, defaultValue.is_archive, defaultValue.staff_id, defaultValue.leave_type_id, defaultValue.status, defaultValue.start_date, defaultValue.end_date);
            setTimeOffDetail(response.data.data_list);
            setTotalPages(response.data.total_pages);
            setTotalRecords(response.data.total_records);
            setTimeOffSummary(response.data.summary);
            setCurrentPage(defaultValue.page);
            setLoading(false);
        } catch (error) {
            console.log(error);

        }
    };

    useEffect(() => {
        fetchTimeOffDetails();
    }, [defaultValue]);

    const handleCardClick = (statusId: any) => {
        setDefaultValue((prev) => ({
            ...prev,
            page: 1, 
            status: [statusId], 
        }));
        setLoading(true);
    };


    const handleSortChange = (column: string) => {
        setDefaultValue(prevState => ({
            ...prevState,
            sort: {
                sort_order: prevState.sort.sort_by === column && prevState.sort.sort_order === "ASC" ? "DESC" : "ASC",
                sort_by: column
            },
            page: 1
        }));
        setTimeoffIds([]);
        setSelectAll(false)
    };

    const handlePageChange = (page: number) => {
        setDefaultValue({ ...defaultValue, page: page });
        setTimeoffIds([]);
        setSelectAll(false)
    };

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        const handler = setTimeout(() => {
            setDefaultValue((prevPayload) => ({
                ...prevPayload,
                search_term: inputValue,
                page: 1
            }));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const [leaveBalance, setLeaveBalance] = useState<number | null>(null);


    const TimeOffStaffDetail = TimeOffEmployeeDetail.map(employee => ({
        value: employee.id,
        label: `${employee.first_name} ${employee.last_name}`
    }));


    const handleEmployeeChange = (selectedOption: { value: number; label: string } | null) => {
        if (selectedOption) {
            getLeave(selectedOption.value);
            formik.setFieldValue('staff_id', selectedOption.value);
        }
    };

    const leaveOptions = TimeOffLeaveDetail.map(leave => ({
        value: leave.leave_type_id,
        label: leave.leave_name
    }));

    const handleLeaveTypeChange = (selectedOption: { value: number; label: string } | null) => {
        if (selectedOption) {
            const selectedLeave = TimeOffLeaveDetail.find(leave => leave.leave_type_id === selectedOption.value);
            formik.setFieldValue('leave_type_id', selectedOption.value);
            if (selectedLeave) {
                setLeaveBalance(selectedLeave.available_hours);
            } else {
                setLeaveBalance(null);
            }
        }
    };

    const getLeave = async (id: number) => {
        try {
            const response = await getByLeaveDetail(id);
            const data = response.data;
            setTimeOffLeaveDetail(data)

        } catch (error) {
            console.error("Error fetching Leave details:", error);
            toast.error("Error fetching details. Please try again later.");
        }
    };
    //  Add Timeoff API Integration //
    // =================================================================================================//

    const handleTimeChange = (time: string, field: string) => {
        const formattedTime = time.replace(/^(\d{2})(\d{2})$/, '$1:$2');
        formik.setFieldValue(field, formattedTime);
    };

    const validationSchema = Yup.object().shape({
        staff_id: Yup.number().positive('Staff is Required').required('Staff is Required'),
        leave_type_id: Yup.number().positive('Leave Type is Required').required('Leave Type is Required'),
        start_date: Yup.string().required("Start Date is required"),
        start_time: Yup.string().required("Start Time is required"),
        end_date: Yup.string().required("End Date is required"),
        end_time: Yup.string().required("End Time is required"),
    });

    const formik = useFormik({
        initialValues: {
            staff_id: 0,
            leave_type_id: 0,
            start_date: '',
            start_time: '',
            end_date: '',
            end_time: '',
            note: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (isEditing && editId !== null) {

                    const updatedValue = {
                        ...values,
                        id: editId
                    }
                    const response = await updateTimeOffDetail(updatedValue);
                    if (response.status === 1) {
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                        return;
                    }
                } else {
                    const response = await addTimeOffDetail(values);
                    if (response.status === 1) {
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                        return;
                    }
                }
                fetchTimeOffDetails()
                toggleBottomCanvas()
                formik.resetForm();
                setLeaveBalance(null);
            } catch (error) {
                console.error("Error Adding/Updating Compliance Master:", error);
                toast.error("Error Compliance Master. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        }
    });


    const convertTimeFormat = (time: string): string => {
        const [hours, minutes] = time.split(":");
        return `${hours}:${minutes}`;
    };

    const editTimeOff = async (id: number) => {
        try {
            const response = await getByTimeOffDetail(id);
            const data = response.data;

            formik.setValues({
                staff_id: data.staff_id,
                leave_type_id: data.leave_type_id,
                start_date: data.start_date,
                start_time: convertTimeFormat(data.start_time),
                end_date: data.end_date,
                end_time: convertTimeFormat(data.end_time),
                note: data.note,
            });
            setIsEditing(true);
            setEditId(id);
            setLeaveBalance(data.default_hours)
            getLeave(data.staff_id)
            toggleBottomCanvas()
        } catch (error) {
            console.error("Error fetching Timesheet details:", error);
            toast.error("Error fetching details. Please try again later.");
        }
    };


    const [archiveId, setArchiveId] = useState<number | undefined>(undefined);
    const [archiveLoader, setArchiveLoader] = useState(false)

    const handleArchiveTimeOff = async (id: number): Promise<void> => {
        setArchiveId(id);
        setmodal_center(true);
    };

    const [modal_center, setmodal_center] = useState<boolean>(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }


    const archiveMaster = async (id: number) => {
        setArchiveLoader(true)
        try {
            const response = await archiveTimeOffDetail(id);
            fetchTimeOffDetails()
            toast.success(response.message);
            setmodal_center(false)
            setArchiveLoader(false)
        } catch (error) {
            console.error("Error Archive Time off :", error);
            toast.error("Error Archive Please try again later.");
        }
    };

    const [restoreId, setRestoreId] = useState<number | undefined>(undefined);
    const [restoreLoader, setRestoreLoader] = useState(false)

    const handleRestoreTimeoff = async (id: number): Promise<void> => {
        setRestoreId(id);
        setmodal_restore_center(true);
    };


    const [modal_restore_center, setmodal_restore_center] = useState<boolean>(false);
    function tog_restore_center() {
        setmodal_restore_center(!modal_restore_center);
    }

    const restoreTimeoff = async (id: number) => {
        setRestoreLoader(true)
        try {
            const response = await restoreTimeOffDetail(id);
            fetchTimeOffDetails();
            toast.success(response.message);
            setmodal_restore_center(false);
            setRestoreLoader(false)
        } catch (error) {
            console.error("Error Restore Staff:", error);
            toast.error("Error Restore Please try again later.");
        }
    };

    const openAddForm = () => {
        setIsEditing(false);
        setEditId(null);
        formik.resetForm()
        setIsBottom(true);
        setLeaveBalance(null);
    };

    //  Filter //
    // =================================================================================================//

    const status = [
        {
            value: 0,
            label: "Rejected"
        },
        {
            value: 1,
            label: "Pending"
        },
        {
            value: 2,
            label: "Approved"
        },
        {
            value: 3,
            label: "Today"
        },
    ]

    const [selectedDates, setSelectedDates] = useState<Date[]>([]);
    const [dateKey, setDateKey] = useState(0);
    const [statusID, setStatusID] = useState('')
    const [staffID, setStaffID] = useState<any>('')
    const [leaveID, setLeaveID] = useState('')
    const [isArchived, setIsArchived] = useState(false);
    const [TimeOffLeaveFilterDetail, setTimeOffLeaveFilterDetail] = useState<TimeOffLeaveData[]>([])

    const formatDate = (date: Date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };


    const handleDateRangeChange = (dates: Date[]) => {
        if (dates.length === 2) {
            setSelectedDates(dates);
        } else {
            console.error('Invalid date range selection:', dates);
        }
    };

    const handleStatusFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = event.target.value;
        if (selectedId) {
            setStatusID(selectedId)
        }
    };

    const handleEmployeeFilterChange = (selectedOption: { value: any; label: any } | null) => {
        if (selectedOption) {
            getFilterLeave(Number(selectedOption.value))
            setStaffID(selectedOption.value);
        }
    };

    const handleLeaveFilterChange = (selectedOption: { value: any; label: any } | null) => {
        if (selectedOption) {
            setLeaveID(selectedOption.value)
        }
    };

    const getFilterLeave = async (id: any) => {
        try {
            const response = await getByLeaveDetail(id);
            const data = response.data.map((leave: any) => ({
                value: leave.leave_type_id,
                label: leave.leave_name
            }));
            setTimeOffLeaveFilterDetail(data)
        } catch (error) {
            console.error("Error fetching Leave details:", error);
            toast.error("Error fetching details. Please try again later.");
        }
    };

    const applyFilters = () => {
        if (selectedDates.length === 2) {
            setDefaultValue((prevValue) => ({
                ...prevValue,
                staff_id: staffID ? [staffID] : [],
                leave_type_id: leaveID ? [leaveID] : [],
                status: statusID ? [statusID] : [],
                start_date: formatDate(selectedDates[0]),
                end_date: formatDate(selectedDates[1]),
                is_archive: Number(isArchived),
                page: 1
            }));
            setCourseFilters(false)
        } else {
            setDefaultValue((prevValue) => ({
                ...prevValue,
                staff_id: staffID ? [staffID] : [],
                leave_type_id: leaveID ? [leaveID] : [],
                status: statusID ? [statusID] : [],
                is_archive: Number(isArchived),
                page: 1
            }));
            setCourseFilters(false)
        }
    };

    const clearFilters = () => {
        setDefaultValue((prevValue) => ({
            ...prevValue,
            staff_id: [],
            leave_type_id: [],
            status: [],
            start_date: "",
            end_date: "",
            is_archive: 0,
            page: 1
        }));
        setDateKey((prevKey) => prevKey + 1);
        setSelectedDates([])
        setTimeOffLeaveFilterDetail([])
        setStatusID('')
        setStaffID('')
        setLeaveID('')
        setIsArchived(false)
        setCourseFilters(false)
    }

    //  Approve Time Off //
    // =================================================================================================//

    const [isByIdApprove, setIsByIdApprove] = useState(0);
    const [timeoffIds, setTimeoffIds] = useState<number[]>([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectedTimeoffIdsChange = (ids: number[]) => {
        setTimeoffIds(ids);
    };

    useEffect(() => {
        handleSelectedTimeoffIdsChange(timeoffIds);
    }, [timeoffIds]);

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, id: number) => {
        const isChecked = event.target.checked;
        const updatedIds = isChecked ? [...timeoffIds, id] : timeoffIds.filter(timeoffIds => timeoffIds !== id);
        setTimeoffIds(updatedIds);
        setSelectAll(updatedIds.length === TimeOffDetail.length);
    };

    const handleCheckAllChange = (event: ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        const updatedIds = isChecked ? TimeOffDetail.map((timeoffIds: { time_id: any; }) => timeoffIds.time_id) : [];
        setSelectAll(isChecked);
        setTimeoffIds(updatedIds);
    };


    const approveFormik = useFormik({
        initialValues: {
            time_off_id: [],
            status: 0
        },
        onSubmit: async (values: any, { setSubmitting }: any) => {
            try {
                const updatedValues = {
                    ...values,
                    time_off_id: timeoffIds,
                    status: isByIdApprove
                }

                const response = await approveTimeOffDetail(updatedValues)

                if (response.status === 1) {
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                    return;
                }
                setTimeoffIds([]);
                setSelectAll(false)
                approveFormik.resetForm()
                fetchTimeOffDetails()
            } catch (error) {
                console.error("Error Approve/Reject Time off:", error);
                toast.error("Error Time off. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        }
    });
    

    const handleApproveByID = () => {
        setIsByIdApprove(2);
        approveFormik.handleSubmit();
    };

    const handleRejectByID = () => {
        setIsByIdApprove(0);
        approveFormik.handleSubmit();
    };

    const handlePendingByID = () => {
        setIsByIdApprove(1);
        approveFormik.handleSubmit();
    };
    
    
    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Time Off" pageTitle="Dashboard" />
                        {
                            loading ?
                                <Row className="placeholder-glow">
                                    <Col lg={3}>
                                        <div className="placeholder bg-light card-placeholder col-12"></div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="placeholder bg-light card-placeholder col-12"></div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="placeholder bg-light card-placeholder col-12"></div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="placeholder bg-light card-placeholder col-12"></div>
                                    </Col>
                                    <Col lg={12} className="mt-4">
                                        <div className="placeholder bg-light table-placeholder col-12"></div>
                                    </Col>
                                </Row>
                                :
                                <Row className='workforce-staff'>
                                    <Col lg={3}>
                                        <Card className="border-bottom border-3 card-animate border-primary">
                                            <Card.Body onClick={() => handleCardClick(2)}>
                                                <div className="avatar-sm float-end">
                                                    <div className="avatar-title bg-primary-subtle text-primary fs-3xl rounded">
                                                        <i className="bi bi-people"></i>
                                                    </div>
                                                </div>
                                                <h4 className="mb-4"><span className="counter-value" data-target="40"><CountUp end={TimeOffSummary?.total_approve ? TimeOffSummary.total_approve : "0"} separator=',' /></span></h4>

                                                <p className="text-muted fw-medium text-uppercase mb-0">Approved Timeoff</p>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={3}>
                                        <Card className="border-bottom border-3 card-animate border-warning">
                                            <Card.Body>
                                                <div className="avatar-sm float-end">
                                                    <div className="avatar-title bg-warning-subtle text-warning fs-3xl rounded">
                                                        <i className="bi bi-people"></i>
                                                    </div>
                                                </div>
                                                <h4 className="mb-4"><span className="counter-value" data-target="40"><CountUp end={TimeOffSummary?.today_leave ? TimeOffSummary.today_leave : "0"} separator=',' /></span></h4>

                                                <p className="text-muted fw-medium text-uppercase mb-0">Today's Timeoff</p>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={3}>
                                        <Card className="border-bottom border-3 card-animate border-success">
                                            <Card.Body onClick={() => handleCardClick(1)}>
                                                <div className="avatar-sm float-end">
                                                    <div className="avatar-title bg-success-subtle text-success fs-3xl rounded">
                                                        <i className="bi bi-people"></i>
                                                    </div>
                                                </div>
                                                <h4 className="mb-4"><span className="counter-value" data-target="40"><CountUp end={TimeOffSummary?.total_pending ? TimeOffSummary.total_pending : "0"} separator=',' /></span></h4>

                                                <p className="text-muted fw-medium text-uppercase mb-0">Pending Timeoff Request</p>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={3}>
                                        <Card className="border-bottom border-3 card-animate border-info">
                                            <Card.Body onClick={() => handleCardClick(0)}>
                                                <div className="avatar-sm float-end">
                                                    <div className="avatar-title bg-info-subtle text-info fs-3xl rounded">
                                                        <i className="bi bi-people"></i>
                                                    </div>
                                                </div>
                                                <h4 className="mb-4"><span className="counter-value" data-target="40"><CountUp end={TimeOffSummary?.total_reject ? TimeOffSummary.total_reject : "0"} separator=',' /></span></h4>

                                                <p className="text-muted fw-medium text-uppercase mb-0">Reject Timeoff Request</p>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={12}>
                                        <Card className='staff-tabel-card'>
                                            <Card.Body>
                                                <Row className="align-items-center g-2">
                                                    <Col lg={5} className="btn-employee-section mb-3">
                                                        <h5>
                                                            TimeOff List <Badge>{totalRecords}</Badge>
                                                        </h5>
                                                    </Col>
                                                    <Col lg={7} className="btn-section mb-3">
                                                        <div className="app-search d-none d-md-inline-flex">
                                                            <div className="position-relative">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Search"
                                                                    id="search-options"
                                                                    value={inputValue}
                                                                    onChange={handleChange}
                                                                />
                                                                <span className="mdi mdi-magnify search-widget-icon"></span>
                                                                <span
                                                                    className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                                                    id="search-close-options"
                                                                ></span>
                                                            </div>
                                                        </div>
                                                        <Button className='filter-btn' onClick={handleFilterShow}>
                                                            <i className="ri-list-settings-line align-baseline me-1"></i> Filters
                                                        </Button>
                                                        {!isArchived &&
                                                            <Dropdown>
                                                                <Dropdown.Toggle hidden={timeoffIds.length === 0} variant="primary" size='lg'>
                                                                    Approve
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={handleApproveByID}>Approve</Dropdown.Item>
                                                                    <Dropdown.Item onClick={handleRejectByID}>Reject</Dropdown.Item>
                                                                    <Dropdown.Item onClick={handlePendingByID}>Pending</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        }
                                                        <Button onClick={openAddForm} className='next-btn'>
                                                            <i className="ri-add-fill"></i>  Apply Time Off
                                                        </Button>
                                                    </Col>
                                                    <Col lg={12} className="leave-table">
                                                        <LeaveTable leavedata={TimeOffDetail}
                                                            currentPage={currentPage}
                                                            totalPages={totalPages}
                                                            onPageChange={handlePageChange}
                                                            handleSortChange={handleSortChange}
                                                            editTimeOff={editTimeOff}
                                                            handleArchiveTimeOff={handleArchiveTimeOff}
                                                            handleRestoreTimeoff={handleRestoreTimeoff}
                                                            timeoffIds={timeoffIds}
                                                            selectAll={selectAll}
                                                            handleCheckboxChange={handleCheckboxChange}
                                                            handleCheckAllChange={handleCheckAllChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                        }
                    </Container>
                </div>
                <Offcanvas className="filter-canvas" show={courseFilters} placement="end" onHide={handleFilterShow}>
                    <Offcanvas.Header id="courseFilters" className='border-bottom filter-canvas' closeButton>
                        <Offcanvas.Title id="courseFiltersLabel"> <i className="ri-list-settings-line align-baseline me-1"></i> Filters</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Form>
                            <Row>
                                <Col lg={12}>
                                    <Row className="filter-1">
                                        <Col lg={12}>
                                            <h5>Staff</h5>
                                        </Col>
                                        <Col lg="12">
                                            <Form.Group className="form-icon">
                                                <Select
                                                    inputId="staff_id"
                                                    name="staff_id"
                                                    options={TimeOffStaffDetail}
                                                    value={TimeOffStaffDetail.find(option => option.value === Number(staffID))}
                                                    onChange={handleEmployeeFilterChange}
                                                    classNamePrefix="react-select"
                                                    className="react-select"
                                                />
                                                <i style={{ fontSize: '16px' }} className="bx bx-face"></i>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={12}>
                                    <Row className="filter-2">
                                        <Col lg={12}>
                                            <h5>Leave</h5>
                                        </Col>
                                        <Col lg="12">
                                            <Form.Group className="form-icon">
                                                <Select
                                                    name="leave_type_id"
                                                    value={TimeOffLeaveFilterDetail.find(option => option.value === Number(leaveID)) || null}
                                                    onChange={handleLeaveFilterChange}
                                                    options={TimeOffLeaveFilterDetail}
                                                    classNamePrefix="react-select"
                                                    id="leave_type_id"
                                                    isDisabled={TimeOffLeaveFilterDetail.length === 0}
                                                />
                                                <i style={{ fontSize: '16px' }} className=" ri-calendar-event-line"></i>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={12}>
                                    <Row className="filter-2">
                                        <Col lg={12}>
                                            <h5>Status</h5>
                                        </Col>
                                        <Col lg="12">
                                            <Form.Group className="form-select-icon">
                                                <Form.Select
                                                    className="form-select-lg form-control-icon"
                                                    id="floatingSelect"
                                                    name="status"
                                                    value={statusID}
                                                    onChange={handleStatusFilterChange}
                                                >
                                                    <option value="" hidden>Select Status</option>
                                                    {status.map((status) => {
                                                        return (
                                                            <option key={status.value} value={status.value}>{status.label}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={12}>
                                    <Row className="filter-2">
                                        <Col lg={12}>
                                            <h5>Date Period</h5>
                                        </Col>
                                        <Col lg="12" className="mb-3">
                                            <Flatpickr
                                                className="form-control"
                                                key={dateKey}
                                                placeHolder="Select a date range"
                                                options={{
                                                    mode: 'range',
                                                    dateFormat: 'd-m-Y',
                                                    defaultDate: selectedDates.length ? selectedDates : [null, null]
                                                }}
                                                value={selectedDates}
                                                onChange={(dates: Date[]) => handleDateRangeChange(dates)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={12}>
                                    <div className="form-check mb-2 mt-3">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Allstatus"
                                            checked={isArchived}
                                            onChange={() => setIsArchived(!isArchived)}
                                        />
                                        <label className="form-check-label font-weight-bold" htmlFor="Allstatus">
                                            <b>Show Archive List</b>
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Offcanvas.Body>
                    <Row>
                        <Col lg="12">
                            <div className="offcanvas-footer border-top-0 p-3 text-center">
                                <Row className='px-2 approve-reject-btn'>
                                    <Col lg={6} className='clear-section'>
                                        <Button color="primary" onClick={clearFilters} className="clear-btn">Clear Filters</Button>
                                    </Col>
                                    <Col lg={6} className='Apply-section'>
                                        <Button color="primary" onClick={applyFilters} className="next-btn">Apply Filters</Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Offcanvas>
                <Offcanvas className="add-leave" show={isBottom} onHide={toggleBottomCanvas} placement='bottom'>
                    <Offcanvas.Header className="add-header border-bottom" closeButton>
                        <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">{isEditing ? "Edit" : "Add"} Time Off</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Form onSubmit={formik.handleSubmit}>
                        <Offcanvas.Body>
                            <Row>
                                <Col lg="3">
                                    <Form.Group className="mb-4 form-icon">
                                        <Form.Label>Staff Name</Form.Label>
                                        <div className={`react-select-container ${formik.touched.staff_id && formik.errors.staff_id ? 'is-invalid' : ''}`}>
                                            <Select
                                                inputId="staff_id"
                                                name="staff_id"
                                                options={TimeOffStaffDetail}
                                                value={TimeOffStaffDetail.find(option => option.value === formik.values.staff_id)}
                                                onChange={handleEmployeeChange}
                                                onBlur={formik.handleBlur}
                                                classNamePrefix="react-select"
                                                className="react-select"
                                            />
                                            {formik.touched.staff_id && formik.errors.staff_id ? (
                                                <div className="invalid-feedback d-block">
                                                    {formik.errors.staff_id}
                                                </div>
                                            ) : null}
                                        </div>
                                        <i style={{ fontSize: '16px' }} className="bx bx-face mt-4"></i>
                                    </Form.Group>
                                </Col>
                                <Col lg="3">
                                    <Form.Group className="mb-4 form-icon">
                                        <Form.Label>Leave Type</Form.Label>
                                        <div className={`react-select-container ${formik.touched.staff_id && formik.errors.staff_id ? 'is-invalid' : ''}`}>
                                            <Select
                                                name="leave_type_id"
                                                value={leaveOptions.find(option => option.value === formik.values.leave_type_id) || null}
                                                onChange={handleLeaveTypeChange}
                                                onBlur={formik.handleBlur}
                                                options={leaveOptions}
                                                classNamePrefix="react-select"
                                                id="leave_type_id"
                                            />
                                            <i style={{ fontSize: '16px' }} className=" ri-calendar-event-line mt-4"></i>
                                            {formik.touched.leave_type_id && formik.errors.leave_type_id ? (
                                                <div className="invalid-feedback d-block">
                                                    {formik.errors.leave_type_id}
                                                </div>
                                            ) : null}
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col lg="3">
                                    <Form.Group className="mb-4">
                                        <Form.Label>Leave Balance</Form.Label>
                                        <div className="position-relative form-icon">
                                            <Form.Control type="text" name="Balance" className="form-control-lg form-control-icon name-input" value={leaveBalance !== null ? leaveBalance.toString() : ""} disabled />
                                            <i style={{ fontSize: '16px' }} className="ri-calendar-event-line"></i>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col lg="3">
                                </Col>
                                <Col lg="3">
                                    <div className="mb-3 d-flex flex-column form-icon">
                                        <Form.Label htmlFor="JoiningdatInput">Start Date</Form.Label>
                                        <Flatpickr
                                            className={`form-control form-control-lg form-control-icon ${formik.touched.start_date && formik.errors.start_date ? 'is-invalid' : ''}`}
                                            name="start_date"
                                            placeholder="Select Start date"
                                            value={formik.values.start_date}
                                            onChange={(dates: any[]) => {
                                                const formattedDate = format(dates[0], 'yyyy-MM-dd');
                                                formik.setFieldValue("start_date", formattedDate);
                                            }}
                                        />
                                        <i style={{ fontSize: '16px' }} className="ri-calendar-event-line mt-4"></i>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.start_date}
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Start Time</Form.Label>
                                        <div className="position-relative form-icon">
                                            {/* <Flatpickr
                                                className={`form-control form-control-lg form-control-icon ${formik.touched.start_time && formik.errors.start_time ? 'is-invalid' : ''}`}
                                                style={{ height: "45px" }}
                                                placeholder="Select Start Time"
                                                name="start_time"
                                                value={formik.values.start_time}
                                                onChange={(dates: { getHours: () => any; getMinutes: () => any; getSeconds: () => any }[]) => formik.setFieldValue("start_time", formatTime(dates[0]))}
                                                options={{
                                                    enableTime: true,
                                                    noCalendar: true,
                                                    dateFormat: "H:i",
                                                    time_24hr: true,
                                                    defaultDate: new Date().setHours(0, 0, 0, 0),
                                                }}
                                            /> */}
                                            <Cleave
                                                placeholder="hh:mm"
                                                options={{
                                                    time: true,
                                                    timePattern: ['h', 'm']
                                                }}
                                                value={formik.values.start_time}
                                                onChange={(e: any) => handleTimeChange(e.target.rawValue, "start_time")}
                                                className={`form-control form-control-lg form-control-icon ${formik.touched.start_time && formik.errors.start_time ? 'is-invalid' : ''}`}
                                            />
                                            <i
                                                style={{ fontSize: "16px" }}
                                                className="ri-time-line"
                                            ></i>
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.start_time}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col lg="3">
                                    <div className="mb-3 d-flex flex-column form-icon">
                                        <Form.Label htmlFor="JoiningdatInput">End Date</Form.Label>
                                        <Flatpickr
                                            className={`form-control form-control-lg form-control-icon ${formik.touched.end_date && formik.errors.end_date ? 'is-invalid' : ''}`}
                                            name="end_date"
                                            placeholder="Select End date"
                                            options={{
                                                minDate: formik.values.start_date,
                                            }}
                                            value={formik.values.end_date}
                                            onChange={(dates: any[]) => {
                                                const formattedDate = format(dates[0], 'yyyy-MM-dd');
                                                formik.setFieldValue("end_date", formattedDate);
                                            }}
                                        />
                                        <i style={{ fontSize: '16px' }} className="ri-calendar-event-line mt-4"></i>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.end_date}
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>End Time</Form.Label>
                                        <div className="position-relative form-icon">
                                            <Cleave
                                                placeholder="hh:mm"
                                                options={{
                                                    time: true,
                                                    timePattern: ['h', 'm']
                                                }}
                                                value={formik.values.end_time}
                                                onChange={(e: any) => handleTimeChange(e.target.rawValue, "end_time")}
                                                className={`form-control form-control-lg form-control-icon ${formik.touched.end_time && formik.errors.end_time ? 'is-invalid' : ''}`}
                                            />
                                            <i
                                                style={{ fontSize: "16px" }}
                                                className="ri-time-line"
                                            ></i>
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.end_time}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col lg="12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Note</Form.Label>
                                        <div className="position-relative form-icon">
                                            <Form.Control type="name" name="note" value={formik.values.note} onChange={formik.handleChange} className="form-control-lg form-control-icon name-input" placeholder="Enter Leave Note"
                                            />
                                            <i style={{ fontSize: '16px' }} className="ri-book-open-line"></i>
                                        </div>
                                    </Form.Group>
                                </Col>

                            </Row>
                        </Offcanvas.Body>
                        <Row>
                            <Col lg="12">
                                <div className="offcanvas-footer p-3 pb-0 text-center" style={{
                                    position: "fixed",
                                    bottom: "0",
                                    width: "100%",
                                    left: "0",
                                }}>
                                    <Row>
                                        <Col lg={12} className='off-bottom justify-content-end'>
                                            <div className='off-confirm d-flex'>
                                                <Button
                                                    onClick={toggleBottomCanvas}
                                                    color="primary"
                                                    className="clear-btn"
                                                >
                                                    Cancel
                                                </Button>
                                                {formik.isSubmitting ? (
                                                    <Button
                                                        color="primary"
                                                        className="next-btn "
                                                        disabled
                                                    >
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    </Button>
                                                ) : (
                                                    <Button color="primary" type="submit" className="next-btn">{isEditing ? "Update" : "Add"}</Button>
                                                )}
                                                <div className="ml-[10px]">
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Offcanvas>
                <Modal
                    show={modal_center}
                    onHide={() => {
                        tog_center();
                    }}
                    size="sm"
                    centered
                >
                    <Modal.Body className="text-center p-4">
                        <i className="ri-delete-bin-line text-danger display-5"></i>
                        <div className="mt-4">
                            <h4 className="mb-3">Are you sure?</h4>
                            <p className="text-muted mb-4">You want to delete this Time off.</p>
                            <div className="hstack gap-2 justify-content-center">
                                <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
                                {archiveLoader ? (
                                    <Button
                                        color="primary"
                                        className="next-btn"
                                        disabled
                                    >
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>
                                ) : (
                                    <Button variant="danger" onClick={() => {
                                        if (archiveId !== undefined) {
                                            archiveMaster(archiveId);
                                        } else {
                                            toast.error("No Archive ID provided.");
                                        }
                                    }}>Delete</Button>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={modal_restore_center}
                    onHide={() => {
                        tog_restore_center();
                    }}
                    size="sm"
                    centered
                >
                    <Modal.Body className="text-center p-4">
                        <i className=" ri-restart-line text-success display-5"></i>
                        <div className="mt-4">
                            <h4 className="mb-3">Are you sure?</h4>
                            <p className="text-muted mb-4">You want to restore this Time off.</p>
                            <div className="hstack gap-2 justify-content-center">
                                <Button variant="light" onClick={() => setmodal_restore_center(false)}>Close</Button>
                                {restoreLoader ? (
                                    <Button
                                        variant="success"
                                        className="next-btn"
                                        disabled
                                    >
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>
                                ) : (
                                    <Button variant="success" onClick={() => {
                                        if (restoreId !== undefined) {
                                            restoreTimeoff(restoreId);
                                        } else {
                                            toast.error("No Restore ID provided.");
                                        }
                                    }}>Restore</Button>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        </>
    )
})

export default LeavePage;