import { Col, Container, Row } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <div style={{ fontFamily: "Arial, sans-serif", color: "#333" }}>
      {/* Header Section */}
      <div
        style={{
          backgroundColor: "#E57373",
          padding: "50px 20px",
          textAlign: "center",
          color: "white",
          clipPath: "polygon(0% 0%, 100% 0%, 100% 85%, 50% 100%, 0% 85%)",
        }}
      >
        <h1 style={{ fontSize: "36px", margin: 0, color: "white" }}>
          Privacy Policy
        </h1>
      </div>

      {/* Content Section */}
      <div style={{ maxWidth: "800px", margin: "20px auto", padding: "20px" }}>
        <h2 style={{ color: "#E57373" }}>1. Overview</h2>
        <p>
          This Privacy Policy outlines how we collect, handle, store, and
          protect your data when using Guardduty services. Our goal is to
          maintain transparency regarding the information we gather and how it
          is used to improve your experience.
        </p>
        <h2 style={{ color: "#E57373" }}>2. Introduction</h2>
        <p>
          Guardduty values your privacy and is committed to ensuring that any
          personal information shared with us is protected, confidential, and
          secure. This document is designed to explain our data practices,
          ensuring you have the necessary information to make informed decisions
          while using our services.
        </p>
        <h2 style={{ color: "#E57373" }}>3. Information We Collect</h2>
        <p>
          We gather two main types of information from users: Personal Data and
          Non-Personal Data.
        </p>
        <ul>
          <li>
            <strong>Personal Data:</strong> This refers to any information that
            directly identifies you, such as your name, email address, phone
            number, payment information, and other personal details you may
            provide when signing up or interacting with our platform.
          </li>
          <li>
            <strong>Non-Personal Data:</strong> This covers anonymous data, such
            as usage patterns, browser types, device information, page
            interactions, and aggregate traffic analytics that do not identify
            individual users.
          </li>
        </ul>
        <h2 style={{ color: "#E57373" }}>
          4. Types of Personal Information Collected
        </h2>
        <p>
          During your interaction with Guardduty, we may collect the following
          types of personal information:
        </p>
        <ul>
          <li>
            Basic account information, including name, username, email address,
            and password.
          </li>
          <li>Contact information such as phone number and mailing address.</li>
          <li>
            Business-related details, like company name, employee roles, or
            service preferences.
          </li>
          <li>
            Payment and billing information when you make transactions on the
            platform.
          </li>
          <li>
            Optional information such as profile photos. Access to certain
            features may require further data validation.
          </li>
        </ul>
        <h2 style={{ color: "#E57373" }}>5. Company-Provided Data</h2>
        <p>
          Your organization may supply us with personal information relevant to
          your role within the Guardduty system. This can include your work
          contact details, job title, and related credentials. This information
          is crucial for maintaining user profiles, facilitating access, and
          providing support as required.
        </p>
        <h2 style={{ color: "#E57373" }}>
          6. Device Permissions and Usage Data
        </h2>
        <p>
          To enhance your experience, Guardduty may request access to certain
          device capabilities. Users have full control over allowing or denying
          these permissions:
        </p>
        <ul>
          <li>
            <strong>Location Services:</strong> Necessary for location-based functionalities,
            such as live tracking, geotagged reports, and emergency alert
            systems.
          </li>
          <li>
            <strong>Camera Access:</strong> Used for capturing photos or videos as part of
            security reports or for adding media files to system logs.
          </li>
          <li>
            <strong>Microphone Use:</strong> Enabled for specific tasks like voice memos or
            Push-to-Talk features.
          </li>
          <li>
            <strong>Gallery Access: </strong>Allows attaching pre-existing images or videos from
            your device for report generation.
          </li>
        </ul>
        <p>
          These permissions can be modified in your device settings. Restricting
          permissions may limit certain features.
        </p>
        <h2 style={{ color: "#E57373" }}>7. Data from Website Browsing</h2>
        <p>
          You are not required to provide any personal information when simply
          browsing the Guardduty website. However, in order to access certain
          services, you will need to submit relevant information such as your
          business name, email, and contact details. We may also collect
          technical data, such as your IP address, browser type, and activity
          patterns, through cookies and similar technologies. This data helps us
          improve the website's performance and usability.
        </p>
        <h2 style={{ color: "#E57373" }}>
          8. Account Creation and Information Sharing
        </h2>
        <p>
          When registering for Guardduty, you’ll be asked to provide essential
          information to set up your profile, including an email address,
          company information, and payment details. By creating an account, you
          consent to the storage and use of this information to deliver our
          services effectively.
        </p>
        <h2 style={{ color: "#E57373" }}>9. Children's Privacy</h2>
        <p>
          Guardduty does not target or knowingly collect data from individuals
          under the age of 13. In compliance with the Children’s Online Privacy
          Protection Act (COPPA), no information is collected from minors. Our
          services are intended solely for individuals aged 13 and older.
        </p>
        <h2 style={{ color: "#E57373" }}>10. Data Use and Disclosure</h2>
        <p>Guardduty uses the collected information to:</p>
        <ul>
          <li>Facilitate service delivery and user support.</li>
          <li>
            Improve product offerings, based on feedback and usage trends.
          </li>
          <li>
            Communicate with users for updates, system alerts, and promotional
            offers.
          </li>
          <li>
            Monitor system performance, user behavior, and troubleshooting
            issues.
          </li>
        </ul>
        <p>
          We do not sell or rent your personal data to third parties. Your
          information will only be shared with trusted partners for essential
          service-related tasks, such as payment processing or technical
          support, with your consent.
        </p>
        <h2 style={{ color: "#E57373" }}>11. Protection of Your Information</h2>
        Guardduty implements advanced security measures to ensure your personal
        data is safe from unauthorized access. This includes encryption of
        sensitive information such as payment details, secure server
        communication through SSL technology, and restricted database access to
        authorized personnel only.
        <p>
          After a transaction, your payment details will be securely stored for
          future convenience, unless you opt to remove them.
        </p>
        <h2 style={{ color: "#E57373" }}>12. Security and Breach Response</h2>
        <p>
          We are committed to safeguarding personal data through
          industry-standard security practices. Our internal systems include
          administrative, physical, and technical protocols designed to protect
          your information. In case of a security breach, Guardduty will notify
          affected parties immediately and take all necessary steps to contain
          and resolve the issue, including reporting the incident to relevant
          authorities where required.
        </p>
        <h2 style={{ color: "#E57373" }}>13. Data Retention</h2>
        <p>
          We retain personal information as long as it is necessary for the
          purposes outlined in this Privacy Policy. Upon account deactivation or
          service termination, certain data may be retained for legal or
          regulatory reasons, or for continued provision of services unless
          otherwise requested by the user.
        </p>
        <h2 style={{ color: "#E57373" }}>
          14. Modifications to Our Privacy Policy
        </h2>
        <p>
          Guardduty reserves the right to amend this Privacy Policy at any time.
          We will inform you of significant changes through email or by posting
          an update on our website. Continued use of our services following such
          changes will constitute your acceptance of the revised policy.
        </p>
      </div>
    {/* Footer Section */}
    <footer style={{
        width: "100%",
        backgroundColor: "#f8f9fa",
        padding: "20px 0",
        borderTop: "1px solid #dee2e6",
        marginTop: "auto", 
        flex: "0 0 auto" 
      }}>
        <Container>
          <Row className="align-items-center">
            <Col sm={6} className="text-center text-sm-start">
              <span style={{ fontSize: "14px" }}>
                {new Date().getFullYear()} © SAI.
              </span>
            </Col>
            <Col sm={6} className="text-center text-sm-end">
              <span style={{ fontSize: "14px" }}>
                Design & Develop by <a 
                  href="https://www.adriitsolutions.com/"
                  style={{ 
                    color: "#E57373",
                    textDecoration: "none"
                  }}
                >
                  Adri IT Solutions
                </a>
              </span>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
