import React, { useState, useEffect } from "react";
import { addLeaveDetails, archiveLeaveMasterDetail, getLeaveDetail, getByLeaveMasterDetail, updateLeaveMasterDetail } from "helpers/saibackend_helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Nav,
    Row,
    Tab,
    Dropdown,
    Image,
    Offcanvas,
    Table,
    Modal,
    Spinner
} from "react-bootstrap";
import SimpleBar from "simplebar-react";
import LeaveMasterTable from "./LeaveMasterTable";
import { useProfile } from "Common/Hooks/UserHooks";

interface LeaveMasterData {
    id: number;
    leave_name: string;
    default_hours: number;
}


const LeaveMaster = (() => {

    const [leave, setleave] = useState(false);
    const toggleleaveCanvas = () => {
        setleave(!leave);
    };
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState<number | null>(null);

    const [LeaveMasterDetail, setLeaveMasterDetail] = useState<LeaveMasterData[]>([])
    const [archiveId, setArchiveId] = useState<number | undefined>(undefined);

    const { userProfile } = useProfile();
  const userRoles = userProfile.roles;
  const allowedRoles = ['DEVELOPER', 'SUPER_ADMIN', 'ADMIN', 'MANAGER'];
  const hasRoles = (roles: string[]) => roles.some(role => userRoles.includes(role));
  const canAccess = hasRoles(allowedRoles);


    const handleArchiveCompliance = async (id: number): Promise<void> => {
        setArchiveId(id);
        setmodal_center(true);
    };


    const [modal_center, setmodal_center] = useState<boolean>(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }


    const [defaultValue, setDefaultValue] = useState({
        page: 1,
        page_size: "10",
        search_term: "",
        sort: {
            sort_order: "ASC",
            sort_by: ""
        },
        is_archive: 0
    })

    const handlePageChange = (page: number) => {
        setDefaultValue(prevPayload => ({
            ...prevPayload,
            page: page
        }));
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setDefaultValue(prevPayload => ({
            ...prevPayload,
            page_size: String(newPerPage),
            page: page
        }));
    };

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        const handler = setTimeout(() => {
            setDefaultValue((prevPayload) => ({
                ...prevPayload,
                search_term: inputValue,
            }));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const fetchLeaveDetails = async () => {
        try {
            const response = await getLeaveDetail(defaultValue.page, defaultValue.page_size, defaultValue.search_term, defaultValue.sort.sort_order, defaultValue.sort.sort_by, defaultValue.is_archive);
            setLeaveMasterDetail(response.data.data_list);
            setTotalRows(response.data.total_records);
            setLoading(false)
        } catch (error) {
            return;
        }
    };

    useEffect(() => {
        fetchLeaveDetails();
    }, [defaultValue]);

    const handleSort = (column: any, sortDirection: 'asc' | 'desc') => {
        const columnSelector = typeof column === 'function' ? column.name : column;

        setDefaultValue(prevState => ({
            ...prevState,
            sort: {
                sort_by: columnSelector,
                sort_order: sortDirection.toUpperCase(),
            }
        }));
    };


    const validationSchema = Yup.object().shape({
        leave_name: Yup.string().required('Leave name is required'),
        default_hours: Yup.number()
            .required("Default Hours is required")
            .positive("Default Hours must be a positive number")
    });

    const formik = useFormik({
        initialValues: {
            leave_name: '',
            default_hours: 0,
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (isEditing && editId !== null) {
                    const response = await updateLeaveMasterDetail(editId, values.leave_name, values.default_hours);
                    if (response.status === 1) {
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                    }
                } else {
                    const response = await addLeaveDetails(values);
                    if (response.status === 1) {
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                        return;
                    }
                }
                toggleleaveCanvas();
                fetchLeaveDetails();
                formik.resetForm();
            } catch (error) {
                toast.error("Error Leave. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        }
    });

    const [archiveLoader, setArchiveLoader] = useState(false)

    const archiveMaster = async (id: number) => {
        setArchiveLoader(true)
        try {
            const response = await archiveLeaveMasterDetail(id);
            fetchLeaveDetails();
            toast.success(response.message);
            setmodal_center(false)
            setArchiveLoader(false);
        } catch (error) {
            toast.error("Error Archive Please try again later.");
        }
    };

    const editMaster = async (id: number) => {
        try {
            const response = await getByLeaveMasterDetail(id);
            const data = response.data;
            formik.setValues({
                leave_name: data.leave_name,
                default_hours: data.default_hours,
            });
            setIsEditing(true);
            setEditId(id);
            toggleleaveCanvas();
        } catch (error) {
            toast.error("Error fetching details. Please try again later.");
        }
    };

    const openAddForm = () => {
        setIsEditing(false);
        setEditId(null);
        formik.resetForm()
        setleave(true);
    };

    return (
        <>
            {
                loading ?
                    <Row className="placeholder-glow">
                        <Col lg={6}>
                            <div className="placeholder bg-light card-placeholder col-12"></div>
                        </Col>
                        <Col lg={6}>
                            <div className="placeholder bg-light card-placeholder col-12"></div>
                        </Col>
                        <Col lg={12} className="mt-4">
                            <div className="placeholder bg-light table-placeholder col-12"></div>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col lg="12" className="d-flex align-items-center justify-content-between role-navbar">
                            <h4 className="card-title mb-2">Leave Type Master</h4>
                            <div className="d-flex gap-2">
                                <div className="app-search d-none d-md-inline-flex">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                            id="search-options"
                                            value={inputValue}
                                            onChange={handleChange}
                                        />
                                        <span className="mdi mdi-magnify search-widget-icon"></span>
                                        <span
                                            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                            id="search-close-options"
                                        ></span>
                                    </div>
                                </div>
                                {canAccess && userRoles[0] !== "MANAGER" && (<Button
                                    variant="primary"
                                    className="add-company"
                                    onClick={openAddForm}
                                >
                                    <i className=" ri-add-fill"></i> Add
                                </Button>)}
                            </div>
                        </Col>
                        <Col lg="12">
                            <SimpleBar
                                style={{ maxHeight: "589px" }}
                            >
                                <LeaveMasterTable LeaveMasterDetail={LeaveMasterDetail} archiveMaster={archiveMaster} handleArchiveCompliance={handleArchiveCompliance} editMaster={editMaster} handlePageChange={handlePageChange} handlePerRowsChange={handlePerRowsChange} handleSort={handleSort} paginationTotalRows={totalRows} canAccess={canAccess} userRoles={userRoles}/>
                            </SimpleBar>
                        </Col>
                    </Row>
            }
            <Offcanvas
                className="add-role-canvas"
                show={leave}
                onHide={toggleleaveCanvas}
                placement="bottom"
                style={{ minHeight: "300px" }}
            >
                <Offcanvas.Header className="add-header border-bottom" closeButton>
                    <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
                        {isEditing ? "Edit" : "Add"} Leave Type
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Form onSubmit={formik.handleSubmit}>
                    <Offcanvas.Body>
                        <Row>
                            <Col lg={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Leave Type
                                    </Form.Label>
                                    <div className="position-relative form-icon">
                                        <Form.Control
                                            type="text"
                                            name="leave_name"
                                            className="form-control-lg form-control-icon name-input"
                                            placeholder="Enter Leave Type"
                                            value={formik.values.leave_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={!!(formik.touched.leave_name && formik.errors.leave_name)}
                                        />
                                        <i
                                            style={{ fontSize: "16px" }}
                                            className="ri-logout-box-line"
                                        ></i>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.leave_name}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
  <Form.Group className="mb-3">
    <Form.Label>
      Default Hours
    </Form.Label>
    <div className="position-relative form-icon">
      <Form.Control
        type="text"
        name="default_hours"
        className="form-control-lg form-control-icon name-input"
        placeholder="Enter Default Hours"
        value={formik.values.default_hours || ""}
        onChange={(e) => {
          // Parse the input as a number
          let value = parseInt(e.target.value);
          // Ensure the value is not less than 0
          if (isNaN(value) || value < 0) value = 0; // Set to 0 if it's negative or invalid
          formik.setFieldValue("default_hours", value); // Use the correct field name
        }}
        onBlur={formik.handleBlur}
        isInvalid={!!(formik.touched.default_hours && formik.errors.default_hours)}
      />
      <i
        style={{ fontSize: "16px" }}
        className="ri-time-line"
      ></i>
      {formik.touched.default_hours && formik.errors.default_hours ? (
        <div className="invalid-feedback d-block">{formik.errors.default_hours}</div>
      ) : null}
    </div>
  </Form.Group>
</Col>

                        </Row>
                    </Offcanvas.Body>
                    <Row>
                        <Col lg="12" style={{ position: 'absolute', bottom: '0' }}>
                            <div className="offcanvas-footer border-top-0 p-3 pb-0 text-center">
                                <Row>
                                    <Col lg={12} className="off-bottom-staff justify-content-end">
                                        <div className="off-confirm">
                                            <Button
                                                onClick={toggleleaveCanvas}
                                                color="primary"
                                                className="clear-btn"
                                            >
                                                Cancel
                                            </Button>
                                            {formik.isSubmitting ? (
                                                <Button
                                                    color="primary"
                                                    className="next-btn"
                                                    disabled
                                                >
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </Button>
                                            ) : (
                                                <Button color="primary" type="submit" className="next-btn">
                                                    {isEditing ? "Update" : "Add"}
                                                </Button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Offcanvas>
            <Modal
                show={modal_center}
                onHide={() => {
                    tog_center();
                }}
                size="sm"
                centered
            >

                <Modal.Body className="text-center p-4">
                    <i className="ri-delete-bin-line text-danger display-5"></i>
                    <div className="mt-4">
                        <h4 className="mb-3">Are you sure?</h4>
                        <p className="text-muted mb-4">You want to delete Leave.</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
                            {archiveLoader ? (
                                <Button
                                    color="primary"
                                    className="next-btn"
                                    disabled
                                >
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                            ) : (
                                <Button variant="danger" onClick={() => {
                                    if (archiveId !== undefined) {
                                        archiveMaster(archiveId);
                                    } else {
                                        toast.error("No Archive ID provided.");
                                    }
                                }}>Delete</Button>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
})

export default LeaveMaster;