import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';


// Images
import withRouter from 'Common/withRouter';
import { Link } from 'react-router-dom';

const ParticlesAuth = ({ children }: any) => {
    return (
        <React.Fragment>
            <div className="auth-page-wrapper position-relative d-flex align-items-center justify-content-center min-vh-100">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col lg={11} className='w-100'>
                            <Card className="mb-0">
                                <Row>
                                    {children}
                                    <Col lg={9} className="order-sm-2 auth-side-card">
                                        <Card className="auth-card bg-secondary border-0 shadow-none d-sm-block mb-0">
                                            <Card.Body className=" d-flex justify-content-between flex-column">
                                                <div className="text-center">
                                                    <h3 className="log-in-text">Start your journey with us.</h3>
                                                    <p className="log-in-title">
                                                        It brings together your tasks, projects, timelines, files and more
                                                    </p>
                                                </div>
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <img className='background-side' src='/back.png' />
                                                </div>
                                                <div className="text-center">
                                                    <p className="credit-project opacity-75 mt-3">
                                                        &copy; {new Date().getFullYear()} SAI. Design & Developed <i className="mdi mdi-heart text-danger"></i> by <a href="https://www.adriitsolutions.com/">Adri IT Solutions</a>
                                                    </p>
                                                </div>
                                                <Link to={'/privacy-policy'} style={{ display: 'flex', justifyContent: 'center', color: '#707070' }}>
                                                    <span>Privacy Policy</span>
                                                </Link>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ParticlesAuth);