import { APIClient } from "./api_helper";
import axios from "axios";

import * as url from "./url_helper";

const api = new APIClient();

const ensureAuthHeader = () => {
  const authUserString = localStorage.getItem("authUser");
  const authUser = authUserString ? JSON.parse(authUserString) : null;
  const token = authUser ? authUser.data.token : null

  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};



export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

export const getByUserProfileDetail = async (userId: number) => {
  ensureAuthHeader();
  return api.create(url.GET_USER_PROFILE, {
    user_id: userId,
  });
};

export const updateUserProfileDetail = async (
  user_id: number,
  first_name: string,
  last_name: string,
  contact_no: string,
  dob: string,
  gender: string,
  email: string,
  address: string
) => {
  ensureAuthHeader();
  const payload = {
    user_id,
    first_name,
    last_name,
    contact_no,
    dob,
    gender,
    email,
    address,
  };
  return api.create(url.UPDATE_USER_PROFILE, payload);
};

export const forgotPassword = (data: any) =>
  api.create(url.FORGOT_USER_PWD, data);

export const changePassword = async (data: any) => {
  return api.create(url.CHANGE_PASSWORD, data);
};

export const checkTokenDetail = async (email: string, token: string) => {
  ensureAuthHeader();
  return api.create(url.CHECK_RESET_TOKEN, {
    email: email,
    token: token,
  });
};

export const resetPassword = async (data: any) => {
  return api.create(url.RESET_PASSWORD, data);
};

// ===============================================================================================================//
//LOGIN AND LOGOUT

export const userLogin = async (data: any) => {
  const response = await api.create(url.POST_LOGIN, data);
  return response;
};

export const userLogout = async () => {
  const response = await api.logout(url.LOGOUT);
  return response;
};

// ===============================================================================================================//
// COMPANY DETAILS GET AND UPDATE

export const getCompanyDetail = async (companySettingId: number) => {
  ensureAuthHeader();
  return api.create(url.GET_COMPANY, { company_setting_id: companySettingId });
};

export const updateCompanyDetail = async (data: any) => {
  return api.create(url.UPDATE_COMPANY, data);
};

// ===============================================================================================================//
//STATE MASTER

export const getStateMasterDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_STATE_MASTER, {
    page,
    page_size,
    search_term,
    sort_order,
    is_archive,
  });
};

// ===============================================================================================================//
// COMPLIANCE MASTER

export const getComplianceMasterDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_COMPLIANCE_MASTER, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
  });
};

export const addComplianceMasterDetail = async (data: any) => {
  return api.create(url.ADD_COMPLIANCE_MASTER, data);
};

export const archiveComplianceMasterDetail = async (complianceid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_COMPLIANCE_MASTER, {
    compliance_id: complianceid,
  });
};

export const getByComplianceMasterDetail = async (complianceid: number) => {
  ensureAuthHeader();
  return api.create(url.GETBY_ID_COMPLIANCE_MASTER, {
    compliance_id: complianceid,
  });
};

export const updateComplianceMasterDetail = async (
  compliance_id: number,
  compliance_name: string,
  reminder_days: number,
  is_critical: number,
  is_document_compulsory: number
) => {
  ensureAuthHeader();
  const payload = {
    compliance_id,
    compliance_name,
    reminder_days,
    is_critical,
    is_document_compulsory,
  };
  return api.create(url.UPDATE_COMPLIANCE_MASTER, payload);
};

// ===============================================================================================================//
// HOLIDAY MASTER

export const getHolidayMasterDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number,
  state_id: string,
  year: string
) => {
  ensureAuthHeader();
  return api.create(url.GET_HOLIDAY_MASTER, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
    state_id,
    year,
  });
};

export const addHolidayMasterDetail = async (data: any) => {
  return api.create(url.ADD_HOLIDAY_MASTER, data);
};

export const archiveHolidayMasterDetail = async (holidayid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_HOLIDAY_MASTER, {
    holiday_id: holidayid,
  });
};

export const getByHolidayMasterDetail = async (holidayid: number) => {
  ensureAuthHeader();
  return api.create(url.GETBY_ID_HOLIDAY_MASTER, {
    holiday_id: holidayid,
  });
};

export const updateHolidayMasterDetail = async (
  holiday_id: number,
  year: number,
  state_id: number,
  description: string,
  date: string,
  start_time: string,
  finish_time: string
) => {
  ensureAuthHeader();
  const payload = {
    holiday_id,
    year,
    state_id,
    description,
    date,
    start_time,
    finish_time,
  };
  return api.create(url.UPDATE_HOLIDAY_MASTER, payload);
};

// ===============================================================================================================//
//SUBCONTRACTOR MASTER

export const getSubContractorMasterDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_SUBCONTRACTOR_MASTER, {
    page,
    page_size,
    search_term,
    sort_order,
    is_archive,
  });
};

export const addSubContractorMasterDetail = async (data: any) => {
  return api.create(url.ADD_SUBCONTRACTOR_MASTER, data);
};

export const archiveSubContractorMasterDetail = async (subid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_SUBCONTRACTOR_MASTER, {
    subcontractor_id: subid,
  });
};

export const getBySubContractorMasterDetail = async (subid: number) => {
  ensureAuthHeader();
  return api.create(url.GETBY_ID_SUBCONTRACTOR_MASTER, {
    subcontractor_id: subid,
  });
};

export const updateSubContractorMasterDetail = async (
  subcontractor_id: number,
  name: string,
  abbreviation: string,
  abn: string,
  contact_person: string,
  contact_number: string,
  email: string,
  address: string,
  city: string,
  state: string,
  postal_code: string,
  country: string
) => {
  ensureAuthHeader();
  const payload = {
    subcontractor_id,
    name,
    abbreviation,
    abn,
    contact_person,
    contact_number,
    email,
    address,
    city,
    state,
    postal_code,
    country,
  };
  return api.create(url.UPDATE_SUBCONTRACTOR_MASTER, payload);
};

// ===============================================================================================================//
//STAFF MODULE

export const getStaffDetailsDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number,
  employee_type: number | number[],
  access_level: number | number[],
  status: number | number[]
) => {
  ensureAuthHeader();
  return api.create(url.GET_STAFF, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
    employee_type,
    access_level,
    status,
  });
};

export const addStaffDetails = async (data: any) => {
  return api.create(url.ADD_STAFF, data);
};

export const getByStaffDetail = async (staffid: number) => {
  ensureAuthHeader();
  return api.create(url.GETBY_ID_STAFF, {
    staff_id: staffid,
  });
};

export const getAccessLevelDetail = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.ACCESS_LEVEL, {
    search_term,
  });
};

export const getLocationListDetail = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.LOCATION_LIST, {
    search_term,
  });
};

export const getSubcontractorFilterList = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.SUBCONTRACTOR_LIST_STAFF, {
    search_term,
  });
};

export const getEmpTypeListDetail = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.EMP_TYPE, {
    search_term,
  });
};

export const addBulkStaffDetail = async (data: any) => {
  const formData = new FormData();
  formData.append("csv_file", data.csv_file);

  return api.create(url.BULK_STAFF_ADD, formData);
};

export const archiveStaffDetail = async (staffid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_STAFF, {
    staff_id: staffid,
  });
};

export const restoreStaffDetail = async (staffid: number) => {
  ensureAuthHeader();
  return api.create(url.RESTORE_STAFF, {
    staff_id: staffid,
  });
};

export const getLeaveListDetail = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.LEAVE_LIST, {
    search_term,
  });
};

export const updateStaffDetail = async (payload: any) => {
  return api.create(url.UPDATE_STAFF, payload);
};

export const getExportStaffDetailsDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number,
  employee_type: number | number[],
  access_level: number | number[],
  status: number | number[]
) => {
  ensureAuthHeader();

  // Convert parameters to arrays if they are not already
  const employeeTypes = Array.isArray(employee_type)
    ? employee_type
    : [];
  const accessLevels = Array.isArray(access_level)
    ? access_level
    : [];
  const statuses = Array.isArray(status) ? status : [];

  return api.exportNewFile(
    url.EXPORT_STAFF,
    {
      page,
      page_size,
      search_term,
      is_archive,
      employee_type: employeeTypes,
      access_level: accessLevels,
      status: statuses,
    },
    "Staff_list"
  );
};

export const ChangePasswordTokenDetail = async (
  email: string,
  token: string
) => {
  ensureAuthHeader();
  return api.create(url.CHECK_PASSWORD_TOCKEN, {
    email: email,
    token: token,
  });
};

export const updatePassword = async (data: any) => {
  return api.create(url.UPDATE_PASSWORD, data);
};

export const VerificationTokenDetail = async (email: string, token: string) => {
  ensureAuthHeader();
  return api.create(url.VERIFICATION_TOCKEN, {
    email: email,
    onboarding_token: token,
  });
};

export const onBoardingStaff = async (data: any) => {
  return api.create(url.ONBOARDING_PROCESS_STAFF, data);
};

export const getStaffLocationDetail = async (search_term: string, is_patrol_site?:number) => {
  ensureAuthHeader();
  return api.create(url.STAFF_LOCATION_LIST, {
    search_term,
    is_patrol_site : is_patrol_site??""
  });
};

export const getPayGroupDetail = async (search_term: string, for_patrol?:number) => {
  ensureAuthHeader();
  return api.create(url.PAY_GROUP_LIST, {
    search_term,
    for_patrol
  });
};

export const PayGroupByIdDetail = async (staffid: number) => {
  ensureAuthHeader();
  return api.create(url.PAY_GROUP_BY_ID, {
    pay_group_id: staffid,
  });
};

export const SendOnboardingLink = async (data: any) => {
  return api.create(url.SEND_ONBOARDING_LINK, data);
};


// ===============================================================================================================//
//STAFF DOCUMENT

export const getStaffDocumentDetail = async (
  search_term: string,
  staff_id: number
) => {
  ensureAuthHeader();
  return api.create(url.STAFF_DOCUMENT, {
    search_term,
    staff_id,
  });
};

export const addStaffComplianceDetail = async (data: any) => {
  let formData: FormData | null = null;

  if (data.document_path instanceof File) {
    formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData!.append(key, data[key]);
    });
  }

  return api.create(url.ADD_STAFF_DOCUMENT, formData || data);
};

export const getByStaffDocumentDetail = async (staffid: number) => {
  ensureAuthHeader();
  return api.create(url.GET_BY_STAFF_DOCUMENT, {
    id: staffid,
  });
};

export const updateStaffDocumentDetail = async (
  id: number,
  document_type: string,
  document_path: File | string | null,
  document_name: string,
  ref_no: string,
  reminder: string,
  state_id: string,
  expiry_date: string,
  is_critical: number
) => {
  ensureAuthHeader();
  const payload = new FormData();
  payload.append("id", id.toString());
  payload.append("document_type", document_type ? document_type : "");

  if (document_path) {
    if (document_path instanceof File) {
      payload.append("document_path", document_path);
    } else {
      payload.append("document_path_string", document_path);
    }
  }
  payload.append("document_name", document_name ? document_name : "");
  payload.append("ref_no", ref_no ? ref_no : "");
  payload.append("reminder", reminder ? reminder : "");
  payload.append("state_id", state_id ? state_id : "");
  payload.append("expiry_date", expiry_date ? expiry_date : "");
  payload.append("is_critical", is_critical.toString());

  return api.create(url.UPDATE_STAFF_DOCUMENT, payload);
};

export const archiveStaffDocumentDetail = async (staffid: number) => {
  ensureAuthHeader();
  return api.create(url.DELETE_STAFF_DOCUMENT, {
    id: staffid,
  });
};

export const getDropdownComplianceDetail = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.DROPDOWN_COMPLIANCE, {
    search_term,
  });
};

// ===============================================================================================================//
//COMPANY DOCUMENT MASTER

export const getCompanyDocumentMasterDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_COMPANY_DOCUMENT_MASTER, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
  });
};

export const addCompanyDocumentMasterDetail = async (data: any) => {
  let formData: FormData | null = null;

  if (data.document_path instanceof File) {
    formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData!.append(key, data[key]);
    });
  }

  return api.create(url.ADD_COMPANY_DOCUMENT_MASTER, formData || data);
};

export const archiveCompanyDocumentMasterDetail = async (companyid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_COMPANY_DOCUMENT_MASTER, {
    company_document_id: companyid,
  });
};

export const getByCompanyDocumentMasterDetail = async (companyid: number) => {
  ensureAuthHeader();
  return api.create(url.GETBY_ID_COMPANY_DOCUMENT_MASTER, {
    company_document_id: companyid,
  });
};

export const updateCompanyDocumentMasterDetail = async (
  company_document_id: number,
  document_name: string,
  document_path: File | string | null,
  allow_satff_to_view: string,
  requires_acknowledgement: string,
  requires_signature: string,
  is_requires_renewal: string,
  renewal_period: string
) => {
  ensureAuthHeader();
  const payload = new FormData();
  payload.append("company_document_id", company_document_id.toString());
  payload.append("document_name", document_name);

  if (document_path) {
    if (document_path instanceof File) {
      payload.append("document_path", document_path);
    } else {
      payload.append("document_path_string", document_path);
    }
  }

  payload.append("allow_satff_to_view", allow_satff_to_view);
  payload.append("requires_acknowledgement", requires_acknowledgement);
  payload.append("requires_signature", requires_signature);
  payload.append("is_requires_renewal", is_requires_renewal);
  payload.append("renewal_period", renewal_period);

  return api.create(url.UPDATE_COMPANY_DOCUMENT_MASTER, payload);
};

// ===============================================================================================================//
//LEAVE MASTER

export const getLeaveDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_LEAVE_MASTER, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
  });
};

export const addLeaveDetails = async (data: any) => {
  return api.create(url.ADD_LEAVE_MASTER, data);
};

export const archiveLeaveMasterDetail = async (leaveid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_LEAVE_MASTER, {
    leave_id: leaveid,
  });
};

export const getByLeaveMasterDetail = async (leaveid: number) => {
  ensureAuthHeader();
  return api.create(url.GETBY_ID_LEAVE_MASTER, {
    leave_id: leaveid,
  });
};

export const updateLeaveMasterDetail = async (
  leave_id: number,
  leave_name: string,
  default_hours: number
) => {
  ensureAuthHeader();
  const payload = {
    leave_id,
    leave_name,
    default_hours,
  };
  return api.create(url.UPDATE_LEAVE_MASTER, payload);
};

// ===============================================================================================================//
//INVOICE MASTER

export const getInvoiceDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_INVOICE_MASTER, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
  });
};

export const addInvoiceDetails = async (data: any) => {
  let formData: FormData | null = null;

  if (data.company_logo instanceof File) {
    formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData!.append(key, data[key]);
    });
  }

  return api.create(url.ADD_INVOICE_MASTER, formData || data);
};

export const archiveInvoiceMasterDetail = async (templateid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_INVOICE_MASTER, {
    template_id: templateid,
  });
};

export const getByInvoiceMasterDetail = async (templateid: number) => {
  ensureAuthHeader();
  return api.create(url.GETBY_ID_INVOICE_MASTER, {
    template_id: templateid,
  });
};

export const updateInvoiceMasterDetail = async (
  template_id: number,
  company_name: string,
  company_logo: File | string | null,
  bank_name: string,
  bank_account_number: string,
  bsb_no: string,
  template_name: string,
  notes_1: string,
  notes_2: string,
  notes_3: string,
  notes_4: string
) => {
  ensureAuthHeader();
  const payload = new FormData();
  payload.append("template_id", template_id.toString());
  payload.append("company_name", company_name);

  if (company_logo) {
    if (company_logo instanceof File) {
      payload.append("company_logo", company_logo);
    } else {
      payload.append("company_logo_string", company_logo);
    }
  }
  payload.append("bank_name", bank_name);
  payload.append("bank_account_number", bank_account_number);
  payload.append("bsb_no", bsb_no);
  payload.append("template_name", template_name);
  payload.append("notes_1", notes_1);
  payload.append("notes_2", notes_2);
  payload.append("notes_3", notes_3);
  payload.append("notes_4", notes_4);

  return api.create(url.UPDATE_INVOICE_MASTER, payload);
};

// ===============================================================================================================//
//PAYMENT TERM MASTER

export const getPaymentTermMasterDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_PAYMENT_TERM_MASTER, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
  });
};

export const addPaymentTermMasterDetail = async (data: any) => {
  return api.create(url.ADD_PAYMENT_TERM_MASTER, data);
};

export const archivePaymentTermMasterDetail = async (payid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_PAYMENT_TERM_MASTER, {
    id: payid,
  });
};

export const getByPaymentTermMasterDetail = async (payid: number) => {
  ensureAuthHeader();
  return api.create(url.GETBY_ID_PAYMENT_TERM_MASTER, {
    id: payid,
  });
};

export const updatePaymentTermMasterDetail = async (
  id: number,
  term_name: string
) => {
  ensureAuthHeader();
  const payload = {
    id,
    term_name,
  };
  return api.create(url.UPDATE_PAYMENT_TERM_MASTER, payload);
};

// ===============================================================================================================//
//INCIDENT TEMPLATE MASTER

export const addIncidentTemplateMasterDetail = async (data: any) => {
  return api.create(url.ADD_INCIDENT_TEMPLATE_MASTER, data);
};

export const getIncidentTemplateMasterDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_INCIDENT_TEMPLATE_MASTER, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
  });
};

export const getByIncidentTemplateMasterDetail = async (templateid: number) => {
  ensureAuthHeader();
  return api.create(url.GETBY_ID_INCIDENT_TEMPLATE_MASTER, {
    template_id: templateid,
  });
};

export const archiveInsidentTemplateMasterDetail = async (
  templateid: number
) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_INCIDENT_TEMPLATE_MASTER, {
    template_id: templateid,
  });
};

export const updateIncidentTemplateMasterDetail = async (
  template_id: number,
  name: string,
  form_template_fields: {}
) => {
  ensureAuthHeader();
  const payload = {
    template_id,
    name,
    form_template_fields,
  };
  return api.create(url.UPDATE_INCIDENT_TEMPLATE_MASTER, payload);
};

export const GerIncidentTemplateDropdown = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.GET_INCIDENT_TEMPLATE_DROPDOWN_MASTER, {
    search_term,
  });
};

// ===============================================================================================================//
//INCIDENT REPORT

export const addIncidentReportDetail = async (data: any) => {
  return api.create(url.ADD_INDICENT_REPORT, data);
};

export const getIncidentReportDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_INDICENT_REPORT, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
  });
};

export const archiveIncidentReportDetail = async (reportid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_INDICENT_REPORT, {
    report_id: reportid,
  });
};

export const getByIncidentReportDetail = async (reportid: number) => {
  ensureAuthHeader();
  return api.create(url.GETBY_ID_INDICENT_REPORT, {
    report_id: reportid,
  });
};

export const updateIncidentReportDetail = async (
  report_id: number,
  date: string,
  client_id: string,
  location_id: string,
  incident_type_id: string,
  report_data: {}
) => {
  ensureAuthHeader();
  const payload = {
    report_id,
    date,
    client_id,
    location_id,
    incident_type_id,
    report_data,
  };
  return api.create(url.UPDATE_INDICENT_REPORT, payload);
};

export const getClientDropdownIncidentReportDetail = async (
  search_term: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_DROPDOWN_CLIENT_INDICENT_REPORT, {
    search_term,
    is_archive,
  });
};

export const getLocationDropdownIncidentReportDetail = async (
  client_id: number,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_LOCATION_DROPDOWN_INDICENT_REPORT, {
    client_id,
    is_archive,
  });
};

// ===============================================================================================================//
//SUBCONTRACTOR MODULE

export const getSubcontractorDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_SUBCONTRACTOR, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
  });
};

export const addSubcontractorDetail = async (data: any) => {
  return api.create(url.ADD_SUBCONTRACTOR, data);
};

export const addBulkSubcontractorDetail = async (data: any) => {
  const formData = new FormData();
  formData.append("csv_file", data.csv_file);

  return api.create(url.ADD_BULK_SUBCONTRACTOR, formData);
};

export const getBySubcontractorDetail = async (subid: number) => {
  ensureAuthHeader();
  return api.create(url.GETBY_ID_SUBCONTRACTOR, {
    subcontractor_id: subid,
  });
};

export const updateSubcontractorDetail = async (
  subcontractor_id: number,
  name: string,
  abbreviation: string,
  abn: string,
  contact_person: string,
  contact_number: string,
  email: string,
  address: string,
  is_invite: number
) => {
  ensureAuthHeader();
  const payload = {
    subcontractor_id,
    name,
    abbreviation,
    abn,
    contact_person,
    contact_number,
    email,
    address,
    is_invite,
  };
  return api.create(url.UPDATE_SUBCONTRACTOR, payload);
};

export const archiveSubcontractorDetail = async (subid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_SUBCONTRACTOR, {
    subcontractor_id: subid,
  });
};

export const restoreSubDetail = async (subid: number) => {
  ensureAuthHeader();
  return api.create(url.RESTORE_SUBCONTRACTOR, {
    subcontractor_id: subid,
  });
};

// ===============================================================================================================//
//SUBCONTRACTOR COMPLIANCE

export const addSubcontractorComplianceDetail = async (data: any) => {
  let formData: FormData | null = null;

  if (data.compliance_path instanceof File) {
    formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData!.append(key, data[key]);
    });
  }

  return api.create(url.SUB_ADD_SUBCONTRACTOR, formData || data);
};

export const getSubcontractorComplianceDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number,
  subcontractor_id: number
) => {
  ensureAuthHeader();
  return api.create(url.SUB_GET_SUBCONTRACTOR, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
    subcontractor_id,
  });
};

export const archiveSubcontractorComplianceDetail = async (comid: number) => {
  ensureAuthHeader();
  return api.create(url.SUB_ARCHIVE_SUBCONTRACTOR, {
    compliance_id: comid,
  });
};

export const getBySubcontractorComplianceDetail = async (comid: number) => {
  ensureAuthHeader();
  return api.create(url.SUB_GETBY_ID_SUBCONTRACTOR, {
    compliance_id: comid,
  });
};

export const updateSubcontractorComplianceDetail = async (
  compliance_id: number,
  compliance_name: string,
  compliance_path: File | string | null,
  description: string,
  document_number: string,
  reminder_days: string,
  state_id: string,
  date: string,
  is_critical: number
) => {
  ensureAuthHeader();
  const payload = new FormData();
  payload.append("compliance_id", compliance_id.toString());
  payload.append("compliance_name", compliance_name ? compliance_name : "");

  if (compliance_path) {
    if (compliance_path instanceof File) {
      payload.append("compliance_path", compliance_path);
    } else {
      payload.append("compliance_path_string", compliance_path);
    }
  }
  payload.append("description", description ? description : "");
  payload.append("document_number", document_number ? document_number : "");
  payload.append("reminder_days", reminder_days ? reminder_days : "");
  payload.append("state_id", state_id ? state_id : "");
  payload.append("date", date ? date : "");
  payload.append("is_critical", is_critical.toString());

  return api.create(url.SUB_UPDATE_SUBCONTRACTOR, payload);
};

export const exportSubcontractorDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.exportNewFile(
    url.EXPORT_SUBCONTRACTOR,
    {
      page,
      page_size,
      search_term,
      // sort: {
      //   sort_order,
      //   sort_by,
      // },
      is_archive,
    },
    "Subcontractor_list"
  );
};
export const SubcontractorSendEmail = async (comid: number) => {
  ensureAuthHeader();
  return api.create(url.SUBCONTRACTOR_SEND_EMAIL, {
    subcontractor_id: comid,
  });
};

// ===============================================================================================================//
//CLIENT MODULE

export const getClientDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_CLIENT, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
  });
};

export const addClientDetail = async (data: any) => {
  return api.create(url.ADD_CLIENT, data);
};

export const getByClientDetail = async (clientid: number) => {
  ensureAuthHeader();
  return api.create(url.GET_BY_CLIENT, {
    client_id: clientid,
  });
};

export const updateClientDetail = async (
  client_id: number,
  name: string,
  address: string,
  city: string,
  state: string,
  postal_code: string,
  country: string,
  contact_person: string,
  contact_phone: string,
  contact_email: string,
  abn_no: string,
  invoice_note: string,
  invoice_template_id: number
) => {
  ensureAuthHeader();
  const payload = {
    client_id,
    name,
    address,
    city,
    state,
    postal_code,
    country,
    contact_person,
    contact_phone,
    contact_email,
    abn_no,
    invoice_note,
    invoice_template_id,
  };
  return api.create(url.UPDATE_CLIENT, payload);
};

export const archiveClientDetail = async (subid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_CLIENT, {
    client_id: subid,
  });
};

export const exportClientDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.exportNewFile(
    url.EXPORT_CLIENT,
    {
      page,
      page_size,
      search_term,
      // sort: {
      //   sort_order,
      //   sort_by,
      // },
      is_archive,
    },
    "Client_list"
  );
};

export const restoreClientDetail = async (subid: number) => {
  ensureAuthHeader();
  return api.create(url.RESTORE_CLIENT, {
    client_id: subid,
  });
};

// ===============================================================================================================//
//CLIENT DOCUMENT

export const getClientDocumentDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number,
  client_id: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_CLIENT_DOCUMENT, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
    client_id,
  });
};

export const addClientDocumentDetail = async (data: any) => {
  let formData: FormData | null = null;

  if (data.document_path instanceof File) {
    formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData!.append(key, data[key]);
    });
  }
  return api.create(url.ADD_CLIENT_DOCUMENT, formData || data);
};

export const getByClientDocumentDetail = async (clientDocid: number) => {
  ensureAuthHeader();
  return api.create(url.GET_BY_CLIENT_DOCUMENT, {
    id: clientDocid,
  });
};

export const updateClientDocumentDetail = async (
  id: number,
  document_name: string,
  document_path: File | string | null
) => {
  ensureAuthHeader();
  const payload = new FormData();
  payload.append("id", id.toString());
  payload.append("document_name", document_name ? document_name : "");

  if (document_path) {
    if (document_path instanceof File) {
      payload.append("document_path", document_path);
    } else {
      payload.append("document_path_string", document_path);
    }
  }

  return api.create(url.UPDATE_CLIENT_DOCUMENT, payload);
};

export const archiveClientDocumentDetail = async (comid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_CLIENT_DOCUMENT, {
    id: comid,
  });
};

// ===============================================================================================================//
//LOCATION MODULE

export const getLocationDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number,
  client: number | number[],
  type: number | number[],
  subcontractor: number | number[]
) => {
  ensureAuthHeader();
  return api.create(url.GET_LOCATION, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
    client,
    type,
    subcontractor,
  });
};

export const getLocationClientFilterList = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.LOCATION_CLIENT_FILTER, {
    search_term,
  });
};

export const getLocationIncidentDetail = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.Location_Incident_LIst, {
    search_term,
  });
};

export const getLocationSubcontractorFilterList = async (
  search_term: string
) => {
  ensureAuthHeader();
  return api.create(url.LOCATION_SUBCONTRACTOR_FILTER, {
    search_term,
  });
};

export const addLocationDetail = async (data: any) => {
  return api.create(url.ADD_LOCATION, data);
};

export const getByLocationDetail = async (locationid: number) => {
  ensureAuthHeader();
  return api.create(url.GET_BY_ID_LOCATION, {
    location_id: locationid,
  });
};

export const updateLocationDetail = async (payload: any) => {
  return api.create(url.UPDATE_LOCATION, payload);
};

export const archiveLocationDetail = async (locid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_LOCATION, {
    location_id: locid,
  });
};

export const restoreLocationDetail = async (locid: number) => {
  ensureAuthHeader();
  return api.create(url.RESTORE_LOCATION, {
    location_id: locid,
  });
};

export const exportLocationDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number,
  client: number | number[],
  type: number | number[],
  subcontractor: number | number[]
) => {
  ensureAuthHeader();

  const clientTypes = Array.isArray(client) ? client : [];
  const types = Array.isArray(type) ? type : [];
  const subType = Array.isArray(subcontractor)
    ? subcontractor
    : [];

  return api.exportNewFile(
    url.EXPORT_LOCATION,
    {
      page,
      page_size,
      search_term,
      // sort: {
      //   sort_order,
      //   sort_by,
      // },
      is_archive,
      client: clientTypes,
      type: types,
      subcontractor: subType,
    },
    "Location_list"
  );
};

export const getBillingGroupDropdownDetail = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.DROPDOWN_BILLING_GROUP, {
    search_term,
  });
};

export const getByBillingGroupDetail = async (staffid: number) => {
  ensureAuthHeader();
  return api.create(url.GET_BILLING_GROUP_BY_ID, {
    billing_group_id: staffid,
  });
};

// ===============================================================================================================//
//TIME OFF MODULE

export const getTimeOffDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number,
  staff_id: string | string[],
  leave_type_id: string | string[],
  status: string | string[],
  start_date: string,
  end_date: string
) => {
  ensureAuthHeader();
  return api.create(url.GET_TIME_OFF, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
    staff_id,
    leave_type_id,
    status,
    start_date,
    end_date,
  });
};

export const getTimeOffEmployeeDetail = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.EMPLOYEE_TIME_OFF, {
    search_term,
  });
};

export const getByLeaveDetail = async (staffid: number) => {
  ensureAuthHeader();
  return api.create(url.LEAVE_TIME_OFF, {
    staff_id: staffid,
  });
};

export const getByLeaveBalanceDetail = async (
  staffid: number,
  leavetypeid: number
) => {
  ensureAuthHeader();
  return api.create(url.LEAVE_BALANCED_TIME_OFF, {
    staff_id: staffid,
    leave_type_id: leavetypeid,
  });
};

export const addTimeOffDetail = async (data: any) => {
  return api.create(url.ADD_TIME_OFF, data);
};

export const getByTimeOffDetail = async (comid: number) => {
  ensureAuthHeader();
  return api.create(url.GET_BY_ID_TIME_OFF, {
    time_off_id: comid,
  });
};

export const updateTimeOffDetail = async (data: any) => {
  return api.create(url.UPDATE_TIME_OFF, data);
};

export const archiveTimeOffDetail = async (comid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_TIME_OFF, {
    time_off_id: comid,
  });
};

export const restoreTimeOffDetail = async (comid: number) => {
  ensureAuthHeader();
  return api.create(url.RESTORE_TIME_OFF, {
    time_off_id: comid,
  });
};

export const approveTimeOffDetail = async (data: any) => {
  ensureAuthHeader();
  return api.create(url.APPROVE_BY_ID, data);
};

// ===============================================================================================================//
//Scheduling MODULE

export const getScheduleClientList = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.SCHEDULE_CLIENT_LIST, {
    search_term,
  });
};

export const getScheduleLocationList = async (
  client_id: number,
  search_term: string
) => {
  ensureAuthHeader();
  return api.create(url.SCHEDULE_LOCATION_LIST, {
    search_term,
    client_id,
  });
};

export const getScheduleStaffList = async (
  location_id: number,
  search_term: string
) => {
  ensureAuthHeader();
  return api.create(url.SCHEDULE_STAFF_LIST, {
    search_term,
    location_id,
  });
};

export const createScheduleShift = async (postdata: any) => {
  ensureAuthHeader();
  return api.create(url.SCHEDULE_CREATE, postdata);
};

export const updateScheduleShift = async (postdata: any) => {
  ensureAuthHeader();
  return api.create(url.SCHEDULE_UPDATE_SHIFT, postdata);
};

export const getScheduleList = async (
  client_id: number,
  location_id: number,
  start_date: string,
  end_date: string
) => {
  ensureAuthHeader();
  return api.create(url.SCHEDULE_LIST, {
    client_id,
    location_id,
    start_date,
    end_date,
  });
};

export const getAllLocationScheduleList = async (
  client_id: number,
  start_date: string,
  end_date: string,
  location_ids: any
) => {
  ensureAuthHeader();
  return api.create(url.ALL_LOCATION_SCHEDULE_LIST, {
    client_id,
    start_date,
    end_date,
    location_ids
  });
};

export const getShiftSummary = async (client_id: number,
  start_date: string,
  end_date: string
) => {
  ensureAuthHeader();
  return api.create(url.SCHEDULE_SUMMARY, {
    client_id,
    start_date,
    end_date,
  });
};

export const getShiftDetail = async (event_id: number) => {
  ensureAuthHeader();
  return api.create(url.SCHEDULE_SHIFT_DETAILS, {
    event_id,
  });
};

export const exportPatrolLocationInfoDetail = async (
  shift_id: number
) => {
  ensureAuthHeader();
  return api.exportNewFile(
    url.PETROL_LOCATION_INFO,
    {
      shift_id
    },
    "Patrol-info"
  );
};

export const RejectShiftList = async () => {
  ensureAuthHeader();
  return api.create(url.REJECT_SHIFT, "");
};


export const getCheckPointLogDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number,
  client_id: number | number[],
  staff_id: number | number[],
  start_date: string,
  end_date: string
) => {
  ensureAuthHeader();
  return api.create(url.CHECK_POINT_LOG_DETAILS, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
    client_id,
    staff_id,
    start_date,
    end_date,
  });
};

export const exportCheckpointLogDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number,
  start_date: string,
  end_date: string,
  client_id: number | number[],
  staff_id: number | number[],
) => {
  ensureAuthHeader();

  const client = Array.isArray(client_id) ? client_id : [client_id];
  const staff = Array.isArray(staff_id) ? staff_id : [staff_id];

  return api.exportNewFile(
    url.EXPORT_CHECKPOINT_LOG_DETAILS,
    {
      page,
      page_size,
      search_term,
      // sort: {
      //   sort_order,
      //   sort_by,
      // },
      is_archive,
      start_date,
      end_date,
      client_id: client,
      staff_id: staff,
    },
    "Checkpoint-Log"
  );
};

export const pasteScheduleShift = async (postdata: any) => {
  ensureAuthHeader();
  return api.create(url.SCHEDULE_PASTE_SHIFT, postdata);
};


// ===============================================================================================================//
//TIMESHEET MODULE

export const getTimesheetList = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number,
  start_date: string,
  end_date: string,
  client_id: number | number[],
  location_id: number | number[],
  is_approve: number,
  is_invoice: number,
  is_payroll: number,
  staff_id: number | number[],
) => {
  ensureAuthHeader();
  return api.create(url.GET_TIMESHEET, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
    start_date,
    end_date,
    client_id,
    location_id,
    is_approve,
    is_invoice,
    is_payroll,
    staff_id
  });
};

export const getClientDropdownDetail = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.CLIENT_DROPDOWN_TIMESHEET, {
    search_term,
  });
};

export const getLocationDropdownDetail = async (
  search_term: string,
  client_id: number
) => {
  ensureAuthHeader();
  return api.create(url.LOCATION_DROPDOWN_TIMESHEET, {
    search_term,
    client_id,
  });
};

export const getLocationFilterDropdownDetail = async (
  search_term: string,
  client_ids: number[],
  is_filter: number
) => {
  ensureAuthHeader();
  return api.create(url.LOCATION_DROPDOWN_TIMESHEET, {
    search_term,
    client_ids,
    is_filter,
  });
};

export const getStaffDropdownDetail = async (
  search_term: string,
  location_id: number
) => {
  ensureAuthHeader();
  return api.create(url.STAFF_DROPDOWN_TIMESHEET, {
    search_term,
    location_id,
  });
};

export const getBreakListDetail = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.GET_BREAK_LIST, {
    search_term,
  });
};

export const addTimesheetDetail = async (data: any) => {
  return api.create(url.ADD_TIMESHEET, data);
};

export const getByTimesheetDetail = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.GET_BY_TIMESHEET, {
    timesheet_id: timeid,
  });
};

export const updateTimesheetDetail = async (postdata: any) => {
  ensureAuthHeader();
  return api.create(url.UPDATE_TIMESHEET, postdata);
};

export const approveByIdTimesheetDetail = async (data: any) => {
  return api.create(url.APPROVE_BY_ID_TIMESHEET, data);
};

export const exportTimesheetDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number,
  start_date: string,
  end_date: string,
  client_id: number | number[],
  location_id: number | number[],
  is_approve: number,
  is_invoice: number,
  is_payroll: number
) => {
  ensureAuthHeader();

  const client = Array.isArray(client_id) ? client_id : [client_id];
  const location = Array.isArray(location_id) ? location_id : [location_id];

  return api.exportNewFile(
    url.EXPORT_TIMESHEET,
    {
      page,
      page_size,
      search_term,
      // sort: {
      //   sort_order,
      //   sort_by,
      // },
      is_archive,
      start_date,
      end_date,
      client_id: client,
      location_id: location,
      // is_approve,
      // is_invoice,
      // is_payroll
    },
    "Timesheet_list"
  );
};

export const ExportShift = async (
  client_id: number,
  location_id: any,
  start_date: string,
  end_date: string
) => {
  ensureAuthHeader();

  return api.exportNewFile(
    url.SCHEDULE_EXPORT,
    {
      client_id,
      location_id,
      start_date,
      end_date,
    },
    "shift_list"
  );
};

export const getHistoryDetail = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.GET_HISTORY, {
    timesheet_id: timeid,
  });
};

// ===============================================================================================================//
//INVOICE MODULE

export const getInvoiceList = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number,
  start_date: string,
  end_date: string,
  client_id: number,
  payment_term_id: number,
  invoice_template_id: number,
  is_paid: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_INVOICE, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
    start_date,
    end_date,
    client_id,
    payment_term_id,
    invoice_template_id,
    is_paid,
  });
};

export const archiveInvoiceList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_ID_INVOICE, {
    invoice_id: timeid,
  });
};

export const getClientListInoviceDetail = async (
  search_term: string,
  start_date: string,
  end_date: string
) => {
  ensureAuthHeader();
  return api.create(url.CLIENT_DROPDOWN_INVOICE, {
    search_term,
    start_date,
    end_date,
  });
};

export const getClientListFilterInoviceDetail = async (
  search_term: string,
  filter: number
) => {
  ensureAuthHeader();
  return api.create(url.CLIENT_DROPDOWN_INVOICE, {
    search_term,
    filter,
  });
};

export const TimesheetInvoiceDetail = async (
  client_id: number,
  start_date: string,
  end_date: string,
  location_ids: number[]
) => {
  ensureAuthHeader();
  return api.create(url.INVOICE_TIMESHEET, {
    client_id,
    start_date,
    end_date,
    location_ids,
  });
};

export const getTemplateInoviceDetail = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.INVOICE_TEMPLATE, {
    search_term,
  });
};

export const getPaymentTermInoviceDetail = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.INVOICE_TERM, {
    search_term,
  });
};

export const addInvoiceDetail = async (data: any) => {
  return api.create(url.ADD_INVOICE, data);
};

export const getByInvoiceList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.GET_BY_INVOICE, {
    invoice_id: timeid,
  });
};

export const updateInvoiceDetail = async (postdata: any) => {
  ensureAuthHeader();
  return api.create(url.UPDATE_BY_INVOICE, postdata);
};

export const PaidByInvoiceList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.APPROVE_BY_ID_INVOICE, {
    invoice_id: timeid,
  });
};

export const DownloadInvoice = async (id: number) => {
  ensureAuthHeader();

  return api.exportNewFile(
    url.DOWNLOAD_INVOICE,
    {
      invoice_id: id,
    },
    "invoice"
  );
};

export const ExportInvoice = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number,
  start_date: string,
  end_date: string,
  client_id: number,
  payment_term_id: number,
  invoice_template_id: number,
  is_paid: number
) => {
  ensureAuthHeader();

  return api.exportNewFile(
    url.EXPORT_INVOICE,
    {
      page,
      page_size,
      search_term,
      // sort: {
      //   sort_order,
      //   sort_by,
      // },
      is_archive,
      start_date,
      end_date,
      client_id,
      payment_term_id,
      // invoice_template_id,
      is_paid,
    },
    "Invoice_list"
  );
};

export const GetByInvoicePaymentList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.GET_BY_INOVICE_PAYMENT, {
    invoice_id: timeid,
  });
};

export const AddInvoicePaymentList = async (postdata: any) => {
  ensureAuthHeader();
  return api.create(url.ADD_INVOICE_PAYMENT, postdata);
};

export const RemoveInvoicePaymentList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.REMOVE_INVOICE_PAYMENT, {
    invoice_payment_id: timeid,
  });
};

export const ExportInvoicePaymentList = async (invoice_id: number) => {
  ensureAuthHeader();

  return api.exportNewFile(
    url.EXPORT_INVOICE_PAYMENT,
    {
      invoice_id,
    },
    "Invoice_Payment_list"
  );
};

export const ClientLocationDropdownDetail = async (
  client_id: number,
  search_term: string,
  start_date: string,
  end_date: string
) => {
  ensureAuthHeader();
  return api.create(url.CLIENT_LOCATION_DROPDOWN, {
    client_id,
    search_term,
    start_date,
    end_date,
  });
};

export const getSchedulePublishDetail = async (payload: any) => {
  ensureAuthHeader();
  return api.create(url.SCHEDULE_PUBLISH_DATA, payload);
};

export const publishShifts = async (payload: any) => {
  ensureAuthHeader();
  return api.create(url.SCHEDULE_PUBLISH_SHIFT, payload);
};

export const getSchedulePublishDetailWithLocation = async (payload: any) => {
  ensureAuthHeader();
  return api.create(url.SCHEDULE_PUBLISH_DATA_WITH_LOCATION, payload);
};

export const publishShiftsWithLocation = async (payload: any) => {
  ensureAuthHeader();
  return api.create(url.SCHEDULE_PUBLISH_SHIFT_WITH_LOCATION, payload);
};

export const archiveShiftDetail = async (shift_id: number) => {
  ensureAuthHeader();
  return api.create(url.SCHEDULE_ARCHIVE_SHIFT, { shift_id });
};
/*COMMON DROPDOWN START */
export const getSubContractorDropdownList = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.DROPDOWN_SUBCONTRACTOR, {
    search_term,
  });
};
export const getClientDropdownList = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.DROPDOWN_CLIENT, {
    search_term,
  });
};

export const getStaffDropdownList = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.DROPDOWN_STAFF, {
    search_term,
  });
};

export const getLocationDropdownList = async (client_id:number,search_term: string) => {
  ensureAuthHeader();
  return api.create(url.SCHEDULE_LOCATION_LIST, {
    client_id,
    search_term
  });
};


export const ShiftHistoryList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.SHIFT_HISTORY, {
    shift_id: timeid,
  });
};
/*COMMON DROPDOWN END */

// ===============================================================================================================//
//PAY SCHEDULE MASTER//

export const getScheduleCalenderDetail = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.PAY_SCHUDULE_CALENDER_LIST, {
    search_term,
  });
};

export const getPayScheduleList = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_PAY_SCHEDULE_LIST, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
  });
};

export const addPayScheduleDetail = async (data: any) => {
  return api.create(url.ADD_PAY_SCHEDULE_LIST, data);
};

export const GetByScheduleList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.GET_BY_SCHEDULE_LIST, {
    pay_calendar_id: timeid,
  });
};

export const updatePayScheduleDetail = async (data: any) => {
  return api.create(url.UPDATE_PAY_SCHEDULE_LIST, data);
};

export const archiveScheduleList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.REMOVE_PAY_SCHEDULE_LIST, {
    pay_calendar_id: timeid,
  });
};

// ===============================================================================================================//
//PAY CATEGORIES MASTER//

export const getPayCategoriesList = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_PAY_CATEGORIES_LIST, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
  });
};

export const addPayCategoriesDetail = async (data: any) => {
  return api.create(url.ADD_PAY_CATEGORIES_LIST, data);
};

export const GetByCategoriesList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.GET_BY_CATEGORIES_LIST, {
    pay_categories_id: timeid,
  });
};

export const updatePayCategoriesDetail = async (data: any) => {
  return api.create(url.UPDATE_PAY_CATEGORIES_LIST, data);
};

export const archivePayCategoriesList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.REMOVE_PAY_CATEGORIES_LIST, {
    pay_categories_id: timeid,
  });
};

//PAY GROUP MASTER//
// ===============================================================================================================//

export const getPayCalenderDetail = async (search_term: string,id:number) => {
  ensureAuthHeader();
  return api.create(url.PAY_CALENDER_CATEGORIES_LIST, {
    search_term,
    for_patrol : {id}
  });
};

// export const getPayGroupList = async (
//   page: number,
//   page_size: string,
//   search_term: string,
//   sort_order: string,
//   sort_by: string,
//   is_archive: number,
//   for_patrol: number
// ) => {
//   ensureAuthHeader();
//   return api.create(url.GET_PAY_GROUP_LIST, {
//     page,
//     page_size,
//     search_term,
//     sort: {
//       sort_order,
//       sort_by,
//     },
//     is_archive,
//     for_patrol
//   });
// };

export const getPayGroupList = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number,
  for_patrol?: number 
) => {
  ensureAuthHeader();
  const payload: any = {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
  };

  if (for_patrol !== undefined) {
    payload.for_patrol = for_patrol;
  }

  return api.create(url.GET_PAY_GROUP_LIST, payload);
};


export const addPayGroupDetail = async (data: any) => {
  return api.create(url.ADD_PAY_GROUP_LIST, data);
};

export const GetByGroupList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.GET_BY_GROUP_LIST, {
    pay_group_id: timeid,
  });
};

export const updatePayGroupDetail = async (data: any) => {
  return api.create(url.UPDATE_PAY_GROUP_LIST, data);
};

export const archivePayGroupList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.REMOVE_PAY_GROUP_LIST, {
    pay_group_id: timeid,
  });
};

// PATROL MODULE//
// ===============================================================================================================//

export const getPatrolClientDetail = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.PATROL_CLIENT_DRODOWN, {
    search_term,
  });
};

export const getPatrolLocationDetail = async (
  search_term: string,
  client_ids: number[]
) => {
  ensureAuthHeader();
  return api.create(url.PATROL_LOCATION_DROPDOWN, {
    search_term,
    client_ids,
  });
};

export const addPatrolLcoationDetail = async (data: any) => {
  return api.create(url.ADD_PATROL_LOCATION, data);
};

export const getPatolLocationList = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_PATROL_LOCATION, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
  });
};

export const GetByPatrolLocationList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.GET_BY_PATROL_LOCATION, {
    client_id: timeid,
  });
};

export const ArchivePatrolLocationList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_PATROL_LOCATION, {
    client_id: timeid,
  });
};

export const updatePatrolLcoationDetail = async (data: any) => {
  return api.create(url.UPDATE_PATROL_LOCATION, data);
};

export const exportPatrolLocationDetail = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.exportNewFile(
    url.EXPORT_PATROL_LOCATION,
    {
      page,
      page_size,
      search_term,
      // sort: {
      //   sort_order,
      //   sort_by,
      // },
      is_archive,
    },
    "Patrol_location"
  );
};

// DASHBOARD //
// ===============================================================================================================//

export const getDashboardDetails = async () => {
  ensureAuthHeader();
  return api.create(url.GET_DASHBOARD_DETAILS, {});
};

export const GetClockDashboardDetails = async (shift_id: number) => {
  ensureAuthHeader();
  return api.create(url.GET_CLOCK_DASHBOARD_DETAILS, {
    shift_id,
  });
};


//Location
export const getCheckpointQrImage = async (qr_id: string) => {
  ensureAuthHeader();

  return api.exportNewFile(
    url.DOWNLOAD_CHECKPOINT_QR,
    {
      qr_id,
    },
    "QR_" + qr_id
  );
};

// PAYROLL MODULE //
// ===============================================================================================================//

export const getPayrollList = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number,
  start_date: string,
  end_date: string,
  status: number
) => {
  ensureAuthHeader();
  return api.create(url.PAYROLL_DETAILS, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
    start_date,
    end_date,
    status,
  });
};

export const getPayScheduleDropdownDetail = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.PAY_SCHEDULE_DROPDOWN, {
    search_term,
  });
};

export const addPayrollList = async (data: any) => {
  return api.create(url.ADD_PAYROLL_DETAILS, data);
};

export const GetByPayrollStaffList = async (
  pay_run_id: number,
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string
) => {
  ensureAuthHeader();
  return api.create(url.GET_BY_PAYROLL_DETAILS, {
    pay_run_id,
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
  });
};

export const GetpayrollDataByStaff = async (staff_pay_run_id: number) => {
  ensureAuthHeader();
  return api.create(url.GET_PAYROLL_BY_STAFF, {
    staff_pay_run_id,
  });
};

export const updatePayrollList = async (data: any) => {
  return api.create(url.UPDATE_PAYROLL_DETAILS, data);
};

export const ArchivePayrollList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_PAYROLL_DETAILS, {
    pay_run_id: timeid,
  });
};

export const updatePayrunList = async (data: any) => {
  return api.create(url.UPDATE_PAYRUN_DETAILS, data);
};

export const ArchivePayrunList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_PAYRUN_DETAILS, {
    staff_pay_run_data_id: timeid,
  });
};

export const ArchiveStaffPayRateList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.ARCHIVE_STAFF_PAYRATE_DETAILS, {
    staff_pay_run_id: timeid,
  });
};

export const PayrunApproveList = async (data: any) => {
  return api.create(url.PAY_RUN_APPROVE_BY_ID, data);
};

export const GetPendingTimesheetList = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string
) => {
  ensureAuthHeader();
  return api.create(url.PENDING_TIMESHEET, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
  });
};

export const PendingPayrollByIdList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.PENDING_PAYROLL_BY_ID, {
    staff_id: timeid,
  });
};

export const Reprocess = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.REPROCESS, {
    pay_run_id: timeid,
  });
};

export const exportPayRunDetail = async (pay_run_id: number) => {
  ensureAuthHeader();
  return api.exportNewFile(
    url.PAY_RUN_EXPORT,
    {
      pay_run_id,
    },
    "Payrun-list"
  );
};

// ===============================================================================================================//
//BILLBilling CATEGORIES MASTER//

export const getBillingCategoriesList = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_BILLING_CATEGORIES_LIST, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
  });
};

export const addBillingCategoriesDetail = async (data: any) => {
  return api.create(url.ADD_BILLING_CATEGORIES_LIST, data);
};

export const GetByBillingCategoriesList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.GET_BY_BILLING_CATEGORIES_LIST, {
    billing_category_id: timeid,
  });
};

export const updateBillingCategoriesDetail = async (data: any) => {
  return api.create(url.UPDATE_BILLING_CATEGORIES_LIST, data);
};

export const archiveBillingCategoriesList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.REMOVE_BILLING_CATEGORIES_LIST, {
    billing_category_id: timeid,
  });
};

//BILLLING GROUP MASTER//
// ===============================================================================================================//

export const getBillingCalenderDetail = async (search_term: string) => {
  ensureAuthHeader();
  return api.create(url.BILLING_CALENDER_CATEGORIES_LIST, {
    search_term,
  });
};

export const getBillingGroupList = async (
  page: number,
  page_size: string,
  search_term: string,
  sort_order: string,
  sort_by: string,
  is_archive: number
) => {
  ensureAuthHeader();
  return api.create(url.GET_BILLING_GROUP_LIST, {
    page,
    page_size,
    search_term,
    sort: {
      sort_order,
      sort_by,
    },
    is_archive,
  });
};

export const addBillingGroupDetail = async (data: any) => {
  return api.create(url.ADD_BILLING_GROUP_LIST, data);
};

export const GetByBillingGroupList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.GET_BY_BILLING_GROUP_LIST, {
    billing_group_id: timeid,
  });
};

export const updateBillingGroupDetail = async (data: any) => {
  return api.create(url.UPDATE_BILLING_GROUP_LIST, data);
};

export const archiveBillingGroupList = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.REMOVE_BILLING_GROUP_LIST, {
    billing_group_id: timeid,
  });
};

// NOTIFICATION //
// ===============================================================================================================//

export const NotificationList = async () => {
  ensureAuthHeader();
  return api.create(url.NOTIFICATION_LIST, "");
};

export const NotificationRemoveList = async () => {
  ensureAuthHeader();
  return api.create(url.REMOVE_NOTIFICATION, "");
};

export const copyWeekTemplate = async(payload: any) => {
  ensureAuthHeader();
  return api.create(url.COPY_WEEK_TEMPLATE,payload);
}

export const dutyReportTemplate = async (params: any) => {
  return api.exportDutyReportFile(url.DUTY_REPORT_TEMPLATE, params, "duty-report");
};

export const saveShiftTemplate = async (payload: any) => {
  ensureAuthHeader();
  return api.create(url.SAVE_SHIFT_TEMPLATE, payload);
};

export const loadShiftTemplate = async (payload: any) => {
  ensureAuthHeader();
  return api.create(url.LOAD_SHIFT_TEMPLATE, payload);
};

export const submitShiftTemplate = async (payload: any) => {
  ensureAuthHeader();
  return api.create(url.SUBMIT_SHIFT_TEMPLATE, payload);
};

export const ShiftTemplateArchiveById = async (timeid: number) => {
  ensureAuthHeader();
  return api.create(url.SHIFT_TEMPLATE_ARCHIVE, {
    template_id: timeid,
  });
};

export const getSchedulePublishShiftList = async (payload: any) => {
  ensureAuthHeader();
  return api.create(url.SCHEDULE_PUBLISHED_SHIFT_LIST, payload);
};

export const finishedBulkShift = async (payload: any) => {
  ensureAuthHeader();
  return api.create(url.SCHEDULE_FINISHED_BULK_SHIFT, payload);
};