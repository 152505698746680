
import React, { useState, useEffect, useMemo } from 'react';
import { Dropdown, Form, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';

interface LeaveMasterData {
    id: number;
    leave_name: string;
    default_hours: number;
}

type SortOrder = 'asc' | 'desc';

interface LeaveTableProps {
    LeaveMasterDetail: LeaveMasterData[];
    archiveMaster: (id: number) => Promise<void>;
    editMaster: (id: number) => Promise<void>;
    handlePageChange: (page: number) => void;
    handlePerRowsChange: (newPerPage: number, page: number) => void;
    paginationTotalRows: number;
    handleArchiveCompliance: (id: number) => Promise<void>;
    handleSort: (column: string, sortDirection: 'asc' | 'desc') => void;
    canAccess: boolean;
    userRoles: any;
}

const LeaveMasterTable: React.FC<LeaveTableProps> = ({ LeaveMasterDetail, archiveMaster, editMaster, handlePageChange, handlePerRowsChange, paginationTotalRows, handleArchiveCompliance, handleSort, canAccess,
    userRoles }) => {

    const columns = [
        {
            name: <span className='font-weight-bold fs-sm'>Leave Type</span>,
            selector: (row: any) => row.leave_name,
            sortable: true,
            sortField: 'leave_name',
        },
        {
            name: <span className='font-weight-bold fs-sm'>Default Hours</span>,
            selector: (row: any) => row.default_hours,
            sortable: true,
            sortField: 'default_hours',
        },
        // {
        //     name: <span className='font-weight-bold fs-sm'>Employment type</span>,
        //     selector: (row: any) => row.emp_type,
        //     sortable: true
        // },
        // {
        //     name: <span className='font-weight-bold fs-sm'>Leave Status</span>,
        //     sortable: true,
        //     cell: (row: any) => {
        //         return (
        //             <>
        //                 {row.leave_status === 0 ? <span className="badge rounded-pill bg-danger">Inactive</span> : <span className="badge rounded-pill bg-success">Active</span>}
        //             </>
        //         )
        //     }
        // },
        {
            name: canAccess && userRoles[0] !== "MANAGER" ? (
                <span className="font-weight-bold fs-sm">Action</span>
            ) : null,
            // sortable: true,
            cell: (row: any) => {
                return (canAccess && userRoles[0] !== "MANAGER" &&
                    <>
                        <div style={{ display: 'flex', gap: '8px' }}>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="edit-tooltip">Edit</Tooltip>}>
                                <i
                                    onClick={() => editMaster(row.id)}
                                    className="ri-edit-2-line link-success"
                                    style={{ fontSize: '18px', cursor: 'pointer' }}
                                ></i>
                            </OverlayTrigger>

                            <OverlayTrigger placement="top" overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}>
                                <i
                                    onClick={() => handleArchiveCompliance(row.id)}
                                    className="ri-delete-bin-line link-danger"
                                    style={{ fontSize: '18px', cursor: 'pointer' }}
                                ></i>
                            </OverlayTrigger>
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={LeaveMasterDetail}
            pagination
            paginationServer
            paginationTotalRows={paginationTotalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            onSort={(column: any, sortDirection: SortOrder) => {
                if (column.sortField) {
                    handleSort(column.sortField, sortDirection);
                }
            }}
            sortServer
        />
    );
};

export default LeaveMasterTable