import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Col, Form, Nav, ProgressBar, Tab, Button, Image, Dropdown } from 'react-bootstrap';
import Frame1 from "assets/images/auth/Frame1.png"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getCompanyDetail, updateCompanyDetail } from 'helpers/saibackend_helper';
import SearchableAddress from "helpers/SearchableAddress";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useProfile } from 'Common/Hooks/UserHooks';

interface CompanyDetails {
    abn: string;
    account_holder_name: string;
    acn: string;
    address: string;
    bank_name: string;
    bsb_no: string;
    company_name: string;
    mobile: string,
    email: string,
    id: number;
}

interface AddressData {
    address: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
}

const Companyonboarding = () => {
    document.title = "Company Details | The Guard Duty";

    const [companyDetails, setCompanyDetails] = useState<CompanyDetails | null>(null);
    const [loading, setLoading] = useState(true);
    const companySettingId = 1;

    const { userProfile } = useProfile();
    const userRoles = userProfile.roles;
    const allowedRoles = ['DEVELOPER', 'SUPER_ADMIN', 'ADMIN', 'MANAGER'];
    const hasRoles = (roles: string[]) => roles.some(role => userRoles.includes(role));
    const canAccess = hasRoles(allowedRoles);

    useEffect(() => {

        const fetchCompanyDetails = async () => {
            try {
                const response = await getCompanyDetail(companySettingId);
                setCompanyDetails(response.data);
                setLoading(false)
            } catch (error) {
                return;

            }
        };

        fetchCompanyDetails();
    }, []);

    const formik = useFormik({
        initialValues: {
            company_name: companyDetails?.company_name || '',
            abn: companyDetails?.abn || '',
            acn: companyDetails?.acn || '',
            address: companyDetails?.address || '',
            bank_name: companyDetails?.bank_name || '',
            account_holder_name: companyDetails?.account_holder_name || '',
            bsb_no: companyDetails?.bsb_no || '',
            mobile: companyDetails?.mobile || '',
            email: companyDetails?.email || '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            company_name: Yup.string().required('Company name is required'),
            abn: Yup.string().required('ABN is required'),
            acn: Yup.string().required('ACN is required'),
            address: Yup.string().required('Address is required'),
            bank_name: Yup.string().required('Bank name is required'),
            account_holder_name: Yup.string().required('Account holder name is required'),
            bsb_no: Yup.string().required('BSB number is required'),
        }),
        onSubmit: async (values) => {
            try {
                const updatedData = { ...companyDetails, ...values, company_setting_id: companySettingId };
                const response = await updateCompanyDetail(updatedData);
                if (response.status === 1) {
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            } catch (error) {
                toast.error('Error updating company details. Please try again later.');
            }
        },
    });

    const [address, setAddress] = useState<string>('');

    const handleAddressChange = (data: AddressData | boolean) => {
        if (typeof data === 'boolean') return;
        setAddress(data.address);
        formik.setFieldValue("address", data.address);
    };

    return (
        <React.Fragment>
            <div className="page-content company-onboarding">
                <Container fluid>
                    {
                        loading ?
                            <Row className="placeholder-glow">
                                <Col lg={12} className="mt-4">
                                    <div className="placeholder bg-light table-placeholder col-12"></div>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col lg={12} className="organization-details">
                                    <Card>
                                        <Form onSubmit={formik.handleSubmit}>
                                            <Card.Body>
                                                <Row>
                                                    <Col lg={12}>

                                                        <Row>
                                                            <Col lg={3} className='side-view-onboarding'>
                                                                <Row>
                                                                    <Col lg={12} className='side-section'>
                                                                        <h4 className='text-center'>Company Details</h4>
                                                                        <p className='text-center'>"Stay informed with the latest trends, market insights, and valuable business data by signing up for our Business Information service today."</p>

                                                                    </Col>
                                                                    <Col lg={12} className='div-side'>
                                                                        <img className='side-image' src={Frame1} />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col lg={9} className='onboarding-form'>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>Company Name</Form.Label>
                                                                            <div className="position-relative form-icon">
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name="company_name"
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                    value={formik.values.company_name}
                                                                                    className={`form-control-lg form-control-icon name-input ${formik.touched.company_name && formik.errors.company_name ? 'is-invalid' : ''
                                                                                        }`}
                                                                                    placeholder="Enter Company Name"
                                                                                    disabled={!(canAccess && userRoles[0] !== "MANAGER")}
                                                                                />
                                                                                <i style={{ fontSize: '16px' }} className="ri-briefcase-4-line"></i>
                                                                                {formik.touched.company_name && formik.errors.company_name ? (
                                                                                    <div className="invalid-feedback">{formik.errors.company_name}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>ABN</Form.Label>
                                                                            <div className="position-relative form-icon">
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name="abn"
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                    value={formik.values.abn}
                                                                                    className={`form-control-lg form-control-icon name-input ${formik.touched.abn && formik.errors.abn ? 'is-invalid' : ''
                                                                                        }`}
                                                                                    placeholder="Enter your ABN"
                                                                                    disabled={!(canAccess && userRoles[0] !== "MANAGER")}
                                                                                />
                                                                                <i style={{ fontSize: '16px' }} className="bx bx-dialpad-alt"></i>
                                                                                {formik.touched.abn && formik.errors.abn ? (
                                                                                    <div className="invalid-feedback">{formik.errors.abn}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>ACN</Form.Label>
                                                                            <div className="position-relative form-icon">
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name="acn"
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                    value={formik.values.acn}
                                                                                    className={`form-control-lg form-control-icon name-input ${formik.touched.acn && formik.errors.acn ? 'is-invalid' : ''
                                                                                        }`}
                                                                                    placeholder="Enter your ACN"
                                                                                    disabled={!(canAccess && userRoles[0] !== "MANAGER")}
                                                                                />
                                                                                <i style={{ fontSize: '16px' }} className="bx bx-dialpad-alt"></i>
                                                                                {formik.touched.acn && formik.errors.acn ? (
                                                                                    <div className="invalid-feedback">{formik.errors.acn}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                    <Form.Group className="mb-3">
    <Form.Label>Address</Form.Label>
    <div
        style={{
            pointerEvents: !(canAccess && userRoles[0] !== "MANAGER") ? "none" : "auto",
            opacity: !(canAccess && userRoles[0] !== "MANAGER") ? 1 : 1, // Keep opacity 1 for better visibility
        }}
    >
        <SearchableAddress
            label="Address"
            name="address"
            placeholder="Enter Address"
            onChange={handleAddressChange}
            invalid={false}
            defaultValue={{
                latitude: "",
                longitude: "",
                address: formik.values.address,
            }}
            // style={{
            //     backgroundColor: !(canAccess && userRoles[0] !== "MANAGER") ? "#e9ecef" : "white",
            //     color: !(canAccess && userRoles[0] !== "MANAGER") ? "#6c757d" : "black",
            //     cursor: !(canAccess && userRoles[0] !== "MANAGER") ? "not-allowed" : "text",
            // }}
        />
    </div>
</Form.Group>

                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <Form.Group className="mb-4">
                                                                            <Form.Label>Bank Name</Form.Label>
                                                                            <div className="position-relative form-icon">
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name="bank_name"
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                    value={formik.values.bank_name}
                                                                                    className={`form-control-lg form-control-icon name-input ${formik.touched.bank_name && formik.errors.bank_name ? 'is-invalid' : ''
                                                                                        }`}
                                                                                    placeholder="Enter your bank Name"
                                                                                    disabled={!(canAccess && userRoles[0] !== "MANAGER")}
                                                                                />
                                                                                <i style={{ fontSize: '16px' }} className="ri-lock-password-line"></i>
                                                                                {formik.touched.bank_name && formik.errors.bank_name ? (
                                                                                    <div className="invalid-feedback">{formik.errors.bank_name}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <Form.Group className="mb-4">
                                                                            <Form.Label>Account Holder Name</Form.Label>
                                                                            <div className="position-relative form-icon">
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name="account_holder_name"
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                    value={formik.values.account_holder_name}
                                                                                    className={`form-control-lg form-control-icon name-input ${formik.touched.account_holder_name && formik.errors.account_holder_name ? 'is-invalid' : ''
                                                                                        }`}
                                                                                    placeholder="Enter Account Holder Name"
                                                                                    disabled={!(canAccess && userRoles[0] !== "MANAGER")}
                                                                                />
                                                                                <i style={{ fontSize: '16px' }} className="bx bx-face"></i>
                                                                                {formik.touched.account_holder_name && formik.errors.account_holder_name ? (
                                                                                    <div className="invalid-feedback">{formik.errors.account_holder_name}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <Form.Group className="mb-4">
                                                                            <Form.Label>BSB Number</Form.Label>
                                                                            <div className="position-relative form-icon">
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name="bsb_no"
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                    value={formik.values.bsb_no}
                                                                                    className={`form-control-lg form-control-icon name-input ${formik.touched.bsb_no && formik.errors.bsb_no ? 'is-invalid' : ''
                                                                                        }`}
                                                                                    placeholder="Enter your BSB"
                                                                                    disabled={!(canAccess && userRoles[0] !== "MANAGER")}
                                                                                />
                                                                                <i style={{ fontSize: '16px' }} className="bx bx-dialpad-alt"></i>
                                                                                {formik.touched.bsb_no && formik.errors.bsb_no ? (
                                                                                    <div className="invalid-feedback">{formik.errors.bsb_no}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <Form.Group className="mb-4">
                                                                            <Form.Label>Mobile Number</Form.Label>
                                                                            <div className="position-relative form-icon">
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name="mobile"
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                    value={formik.values.mobile}
                                                                                    className={`form-control-lg form-control-icon name-input ${formik.touched.mobile && formik.errors.mobile ? 'is-invalid' : ''
                                                                                        }`}
                                                                                    placeholder="Enter Mobile Number"
                                                                                    disabled={!(canAccess && userRoles[0] !== "MANAGER")}
                                                                                />
                                                                                <i style={{ fontSize: '16px' }} className="bx bx-dialpad-alt"></i>
                                                                                {formik.touched.mobile && formik.errors.mobile ? (
                                                                                    <div className="invalid-feedback">{formik.errors.mobile}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <Form.Group className="mb-4">
                                                                            <Form.Label>Email</Form.Label>
                                                                            <div className="position-relative form-icon">
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name="email"
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                    value={formik.values.email}
                                                                                    className={`form-control-lg form-control-icon name-input ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''
                                                                                        }`}
                                                                                    placeholder="Enter Email"
                                                                                    disabled={!(canAccess && userRoles[0] !== "MANAGER")}
                                                                                />
                                                                                <i style={{ fontSize: '16px' }} className=" ri-mail-line"></i>
                                                                                {formik.touched.email && formik.errors.email ? (
                                                                                    <div className="invalid-feedback">{formik.errors.email}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>

                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                            <Card.Footer>
                                                <Row>
                                                    <Col lg={12} className="next-page1">
                                                        <Button color="primary" type="submit" className="next-btn">Submit <i className="bi bi-arrow-right"></i></Button>
                                                    </Col>
                                                </Row>
                                            </Card.Footer>
                                        </Form>
                                    </Card>
                                </Col>
                            </Row>
                    }

                </Container>
            </div >
        </React.Fragment >
    );
};

export default Companyonboarding;
