import React, { useState, ChangeEvent, useEffect } from "react";
import {
    Container,
    Col,
    Row,
    Card,
    Button,
    Offcanvas,
    Form,
    Tab,
    Modal,
    Nav,
    Badge,
    Spinner
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import SubContractorTable from "./SubContractorTable";
import SimpleBar from "simplebar-react";
import { toast } from "react-toastify";
import { FormikErrors, useFormik } from "formik";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import SearchableAddress from "helpers/SearchableAddress";
import { getSubcontractorDetail, addSubcontractorDetail, exportSubcontractorDetail, addBulkSubcontractorDetail, archiveSubcontractorDetail, restoreSubDetail } from "helpers/saibackend_helper";
import { useProfile } from "Common/Hooks/UserHooks";

interface AddressData {
    address: string;
}

interface SubcontractorData {
    id: number;
    name: string;
    abbreviation: string;
    abn: string;
    contact_person: string;
    contact_number: string;
    email: string;
    address: string;
    total_staff: number;
    assigned_locations: number;
}

interface Subcontractor {
    name: string;
    abbreviation: string;
    abn: string;
    contact_person: string;
    contact_number: string;
    email: string;
    address: string;
}

interface FormValues {
    rows: Subcontractor[];
}


const SubContractorPage = () => {

    document.title = "Subcontractor | The Guard Duty";

    const { userProfile } = useProfile();
    const userRoles = userProfile.roles;
    const allowedRoles = ['DEVELOPER', 'SUPER_ADMIN', 'ADMIN', 'MANAGER'];
    const hasRoles = (roles: string[]) => roles.some(role => userRoles.includes(role));
    const canAccess = hasRoles(allowedRoles);

    const [isArchived, setIsArchived] = useState<boolean>(false);
    const [SubcontractorDetail, setSubcontractorDetail] = useState<SubcontractorData[]>([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [payloadValue, setPayloadValue] = useState({
        page: 1,
        page_size: "10",
        search_term: "",
        sort: {
            sort_order: "DESC",
            sort_by: ""
        },
        is_archive: 0,
    });


    const [archiveId, setArchiveId] = useState<number | undefined>(undefined);


    const handleArchiveSubcontractor = async (id: number): Promise<void> => {
        setArchiveId(id);
        setmodal_center(true);
    };


    const [modal_center, setmodal_center] = useState<boolean>(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }


    const fetchSubcontractorDetails = async () => {
        try {
            const response = await getSubcontractorDetail(
                payloadValue.page,
                payloadValue.page_size,
                payloadValue.search_term,
                payloadValue.sort.sort_order,
                payloadValue.sort.sort_by,
                Number(isArchived)
            );
            setSubcontractorDetail(response.data.data_list);
            setTotalPages(response.data.total_pages);
            setCurrentPage(payloadValue.page);
            setTotalRecords(response.data.total_records);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchSubcontractorDetails();
    }, [payloadValue, isArchived]);


    const handleSortChange = (column: string) => {
        setPayloadValue(prevState => ({
            ...prevState,
            sort: {
                sort_order: prevState.sort.sort_by === column && prevState.sort.sort_order === "ASC" ? "DESC" : "ASC",
                sort_by: column
            },
            page: 1
        }));
    };

    const handlePageChange = (page: number) => {
        setPayloadValue({ ...payloadValue, page: page });
    };

    // Subcontractor Search Integration //
    // =================================================================================================================================== \\

    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        const handler = setTimeout(() => {
            setPayloadValue(prevPayload => ({
                ...prevPayload,
                search_term: inputValue,
            }));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };


    const [isBottom, setIsBottom] = useState(false);
    const toggleBottomCanvas = () => {
        setIsBottom(!isBottom);
    };

    const handleAddressChange = (data: AddressData | boolean, index: number) => {
        if (typeof data === 'boolean') return;
        const updatedRows = formik.values.rows.map((row, i) =>
            i === index ? { ...row, address: data.address } : row
        );
        formik.setFieldValue('rows', updatedRows);
    };

    const validationSchema = Yup.object().shape({
        rows: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required("Name is required"),
                // abbreviation: Yup.string().required("Abbreviation is required"),
                email: Yup.string().email('Invalid email format').required('Email is required'),
                abn: Yup.string()
                    .matches(/^\d{11}$/, 'ABN must be an 11-digit number'),
                contact_number: Yup.string()
                    .matches(/^(\+61|0)[2-9]\d{8}$|^(\+61|0)4\d{8}$/, 'Invalid phone number'),
                // abn: Yup.string().required("ABN is required"),
                // contact_person: Yup.string().required("Contact Person Name is required"),
                // contact_number: Yup.string().required("Contact Person Number is required"),
                // address: Yup.string().required("Address is required"),
            })
        ),
    });

    const initialValues: FormValues = {
        rows: [
            {
                name: "",
                abbreviation: "",
                email: "",
                abn: "",
                contact_person: "",
                contact_number: "",
                address: "",
            },
        ],
    };

    const generateAbbreviation = (name: string) => {
        return name
            .split(' ')
            .map(word => word.charAt(0).toUpperCase())
            .join('');
    };

    const handleNameChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        formik.setFieldValue(`rows[${index}].name`, name);
        const abbreviation = generateAbbreviation(name);
        formik.setFieldValue(`rows[${index}].abbreviation`, abbreviation);
    };

    const formik = useFormik<FormValues>({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                console.log(values.rows);
                const response = await addSubcontractorDetail(values.rows);
                if (response.status === 1) {
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                    return;
                }
                setPayloadValue(prevState => ({
                    ...prevState,
                    sort: {
                        sort_order: "DESC",
                        sort_by: ""
                    },
                    page: 1
                }));
                fetchSubcontractorDetails();
                toggleBottomCanvas()
                formik.resetForm();
            } catch (error) {
                console.error("Error Adding/Updating Subcontractor:", error);
                toast.error("Error Adding Subcontractor. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        },
    });

    const addRow = () => {
        const newRow = {
            name: "",
            abbreviation: "",
            email: "",
            abn: "",
            contact_person: "",
            contact_number: "",
            address: "",
        };
        formik.setFieldValue("rows", [...formik.values.rows, newRow]);
    };

    const deleteRow = (id: number) => {
        if (id !== 0) {
            const updatedRows = formik.values.rows.filter((_, index) => index !== id);
            formik.setFieldValue("rows", updatedRows);
        }
    };

    const [file, setFile] = useState<File | null>(null);
    const [showUploadButton, setShowUploadButton] = useState(true);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files;
        if (selectedFiles && selectedFiles.length > 0) {
            setFile(selectedFiles[0]);
            setShowUploadButton(false);
            console.log(selectedFiles[0]);
            Csvformik.setFieldValue("csv_file", selectedFiles[0])
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
        setShowUploadButton(true);
    };

    const handleFilePreview = (file: File | null) => {
        if (file) {
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        } else {
          return;
        }
      }

    const bulkValidationSchema = Yup.object().shape({
        csv_file: Yup.mixed().required('CSV File is required'),
    });

    const Csvformik = useFormik({
        initialValues: {
            csv_file: '',
        },
        validationSchema: bulkValidationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                console.log(values.csv_file);
                const response = await addBulkSubcontractorDetail(values);
                if (response.status === 1) {
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                    return;
                }
                fetchSubcontractorDetails();
                toggleBottomCanvas()
                Csvformik.resetForm();
                setFile(null);
                setShowUploadButton(true);
            } catch (error) {
                console.error("Error Adding/Updating Bulk Subcontractor:", error);
                toast.error("Error Adding Bulk Subcontractor. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        },
    });

    const [archiveLoader, setArchiveLoader] = useState(false)

    const archiveSubcontractor = async (id: number) => {
        setArchiveLoader(true)
        try {
            const response = await archiveSubcontractorDetail(id);
            fetchSubcontractorDetails();
            toast.success(response.message);
            setmodal_center(false)
            setArchiveLoader(false)
        } catch (error) {
            console.error("Error Archive Subcontractor:", error);
            toast.error("Error Archive Please try again later.");
        }
    };


    const [restoreId, setRestoreId] = useState<number | undefined>(undefined);
    const [restoreLoader, setRestoreLoader] = useState(false)

    const handleRestoreSubcontractor = async (id: number): Promise<void> => {
        setRestoreId(id);
        setmodal_restore_center(true);
    };


    const [modal_restore_center, setmodal_restore_center] = useState<boolean>(false);
    function tog_restore_center() {
        setmodal_restore_center(!modal_restore_center);
    }

    const restoreClient = async (id: number) => {
        setRestoreLoader(true)
        try {
            const response = await restoreSubDetail(id);
            fetchSubcontractorDetails();
            toast.success(response.message);
            setmodal_restore_center(false);
            setRestoreLoader(false)
        } catch (error) {
            console.error("Error Restore Subcontractor:", error);
            toast.error("Error Restore Please try again later.");
        }
    };


    const [expLoader, setExpLoader] = useState(false)

    const exportSubcontractor = async () => {
        setExpLoader(true)
        try {
            await exportSubcontractorDetail(
                payloadValue.page,
                payloadValue.page_size,
                payloadValue.search_term,
                payloadValue.sort.sort_order,
                payloadValue.sort.sort_by,
                Number(isArchived),
            );
            setExpLoader(false)
        } catch (error) {
            console.error('There was an error exporting the data!', error);
        }
    };

    const handleDownload = () => {
        const csvContent = `name,abbreviation,abn,contact_person,contact_number,email,address\n` +
            `Company A,CA,123456789,Alice,1234567890,alice@companya.com,123 Street\n` +
            `Company B,CB,987654321,Bob,987654321,bob@companyb.com,456 Avenue\n` +
            `Company C,CC,111111111,Charlie,1111111111,charlie@companyc.com,789 Boulevard\n` +
            `Company D,CD,222222222,Dave,2222222222,dave1@companyd.com,1010 Lane\n` +
            `Company E,CE,333333333,Eve,3333333333,eve@companye.com,2020 Path\n` +
            `Company F,CF,444444444,Frank,4444444444,frank@companyf.com,3030 Way\n` +
            `Company G,CG,555555555,Grace,5555555555,grace@companyg.com,4040 Road\n` +
            `Company H,CH,666666666,Hank,6666666666,hank@companyh.com,5050 Street\n` +
            `Company I,CI,777777777,Ivy,7777777777,ivy@companyi.com,6060 Avenue\n` +
            `Company J,CJ,888888888,Jack,8888888888,jack@companyj.com,7070 Boulevard`;

        const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);

        const link = document.createElement('a');
        link.href = encodedUri;
        link.download = 'subcontractor.csv';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    };


    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Sub-Contractor" pageTitle="Dashboard" />
                        {
                            loading ?
                                <Row className="placeholder-glow">
                                    <Col lg={12} className="mt-4">
                                        <div className="placeholder bg-light table-placeholder col-12"></div>
                                    </Col>
                                </Row>
                                :
                                <Row className="workforce-staff">
                                    <Col lg={12}>
                                        <Card className="staff-tabel-card">
                                            <Card.Body>
                                                <Row className="align-items-center g-2"><Col lg={12}>
                                                    <div className="form-check mb-2" style={{ float: 'right' }}>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="Allstatus"
                                                            checked={isArchived}
                                                            onChange={() => setIsArchived(!isArchived)}
                                                        />
                                                        <label className="form-check-label font-weight-bold" htmlFor="Allstatus">
                                                            <b>Show Archive List</b>
                                                        </label>
                                                    </div>
                                                </Col>
                                                    <Col lg={5} className="btn-employee-section mb-3">
                                                        <h5>
                                                            Subcontractor List  <Badge>{totalRecords}</Badge>
                                                        </h5>
                                                    </Col>
                                                    <Col lg={7} className="btn-section mb-3">
                                                        <div className="app-search d-none d-md-inline-flex">
                                                            <div className="position-relative">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Search"
                                                                    id="search-options"
                                                                    value={inputValue}
                                                                    onChange={handleChange}
                                                                />
                                                                <span className="mdi mdi-magnify search-widget-icon"></span>
                                                                <span
                                                                    className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                                                    id="search-close-options"
                                                                ></span>
                                                            </div>
                                                        </div>
                                                        {canAccess && userRoles[0] !== "MANAGER" && (<Button className='next-btn' onClick={toggleBottomCanvas}>
                                                            <i className="ri-add-fill"></i>  Add New
                                                        </Button>)}
                                                        {expLoader ? (
                                                            <Button
                                                                color="primary"
                                                                className="export-btn"
                                                                disabled
                                                            >
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            </Button>
                                                        ) : (
                                                            <Button onClick={exportSubcontractor} className='export-btn'>
                                                                <i className="bx bx-export me-1 "></i>  Export
                                                            </Button>
                                                        )}
                                                    </Col>
                                                    <Col lg={12} className="leave-table mt-0">
                                                        <SubContractorTable data={SubcontractorDetail}
                                                            currentPage={currentPage}
                                                            totalPages={totalPages}
                                                            archiveMaster={archiveSubcontractor}
                                                            handleSortChange={handleSortChange}
                                                            handleArchiveSubcontractor={handleArchiveSubcontractor}
                                                            handleRestoreSubcontractor={handleRestoreSubcontractor}
                                                            onPageChange={handlePageChange} 
                                                            userRoles={userRoles}
                                                            canAccess={canAccess}
                                                            />
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                        }
                    </Container>
                </div>
                <Offcanvas className="add-leave" show={isBottom} onHide={toggleBottomCanvas} placement='bottom'>
                    <Offcanvas.Header className="add-header border-bottom" closeButton>
                        <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">Add Subcontractor</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Tab.Container defaultActiveKey="home">
                        <Offcanvas.Body>
                            <SimpleBar style={{ maxHeight: "320px" }} className="px-3">
                                <Nav as="ul" variant="tabs" className="mb-3">
                                    <Nav.Item as="li">
                                        <Nav.Link style={{ fontSize: "15px", fontWeight: "600" }} eventKey="home">
                                            Invite
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li">
                                        <Nav.Link style={{ fontSize: "15px", fontWeight: "600" }} eventKey="product1">
                                            Invite Bulk
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content className="text-muted">
                                    <Tab.Pane eventKey="home" id="home">
                                        <Form onSubmit={formik.handleSubmit}>
                                            {formik.values.rows.map((row, index) => (
                                                <Row key={index}>
                                                    <Col lg="12">
                                                        <Row>
                                                            <Col>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Name</Form.Label>
                                                                    <div className="position-relative form-icon">
                                                                        <Form.Control
                                                                            type="text"
                                                                            name={`rows[${index}].name`}
                                                                            className="form-control-lg form-control-icon name-input"
                                                                            placeholder="Subcontractor Name"
                                                                            value={row.name}
                                                                            onChange={(event) => handleNameChange(index, event as React.ChangeEvent<HTMLInputElement>)}
                                                                            onBlur={formik.handleBlur}
                                                                            isInvalid={!!(formik.touched.rows?.[index]?.name && typeof formik.errors.rows === 'object' && (formik.errors.rows as FormikErrors<Subcontractor>[])[index]?.name)}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {formik.touched.rows?.[index]?.name && typeof formik.errors.rows === 'object' && (formik.errors.rows as FormikErrors<Subcontractor>[])[index]?.name}
                                                                        </Form.Control.Feedback>
                                                                        <i style={{ fontSize: '16px' }} className="ri-building-2-line"></i>
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Abbreviation</Form.Label>
                                                                    <div className="position-relative form-icon">
                                                                        <Form.Control
                                                                            type="text"
                                                                            name={`rows[${index}].abbreviation`}
                                                                            className="form-control-lg form-control-icon name-input"
                                                                            placeholder="Enter Subcontractor Abbreviation"
                                                                            value={row.abbreviation}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                        />
                                                                        <i style={{ fontSize: '16px' }} className="ri-building-2-line"></i>
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>ABN</Form.Label>
                                                                    <div className="position-relative form-icon">
                                                                        <Form.Control type="text" name={`rows[${index}].abn`} className="form-control-lg form-control-icon name-input" placeholder="Enter ABN"
                                                                            value={row.abn}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            isInvalid={!!(formik.touched.rows?.[index]?.abn && typeof formik.errors.rows === 'object' && (formik.errors.rows as FormikErrors<Subcontractor>[])[index]?.abn)}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {formik.touched.rows?.[index]?.abn && typeof formik.errors.rows === 'object' && (formik.errors.rows as FormikErrors<Subcontractor>[])[index]?.abn}
                                                                        </Form.Control.Feedback>
                                                                        <i style={{ fontSize: '16px' }} className="ri-keyboard-line"></i>
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Address</Form.Label>
                                                                    <SearchableAddress
                                                                        label="Location Address"
                                                                        name={`rows[${index}].address`}
                                                                        placeholder="Enter Address"
                                                                        onChange={(data) => handleAddressChange(data, index)}
                                                                        invalid={false}
                                                                        defaultValue={{
                                                                            latitude: '',
                                                                            longitude: '',
                                                                            address: row.address,
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Contact Person Name</Form.Label>
                                                                    <div className="position-relative form-icon">
                                                                        <Form.Control type="text" name={`rows[${index}].contact_person`} className="form-control-lg form-control-icon name-input" placeholder="Enter Contact Person Name"
                                                                            value={row.contact_person}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                        />
                                                                        <i style={{ fontSize: '16px' }} className="bx bx-face"></i>
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Email</Form.Label>
                                                                    <div className="position-relative form-icon">
                                                                        <Form.Control type="email" name={`rows[${index}].email`} className="form-control-lg form-control-icon name-input" placeholder="Enter Email"
                                                                            value={row.email}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            isInvalid={!!(formik.touched.rows?.[index]?.email && typeof formik.errors.rows === 'object' && (formik.errors.rows as FormikErrors<Subcontractor>[])[index]?.email)}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {formik.touched.rows?.[index]?.email && typeof formik.errors.rows === 'object' && (formik.errors.rows as FormikErrors<Subcontractor>[])[index]?.email}
                                                                        </Form.Control.Feedback>
                                                                        <i style={{ fontSize: '16px' }} className=" ri-mail-line"></i>
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Contact Number</Form.Label>
                                                                    <div className="position-relative form-icon">
                                                                        <Form.Control type="text" name={`rows[${index}].contact_number`} className="form-control-lg form-control-icon name-input" placeholder="Enter Contact Person Mobile Number"
                                                                            value={row.contact_number}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            isInvalid={!!(formik.touched.rows?.[index]?.contact_number && typeof formik.errors.rows === 'object' && (formik.errors.rows as FormikErrors<Subcontractor>[])[index]?.contact_number)}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {formik.touched.rows?.[index]?.contact_number && typeof formik.errors.rows === 'object' && (formik.errors.rows as FormikErrors<Subcontractor>[])[index]?.contact_number}
                                                                        </Form.Control.Feedback>
                                                                        <i style={{ fontSize: '16px' }} className=" ri-keyboard-line"></i>
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col style={{ flex: '0.5' }}
                                                                className="document-adjust-div d-flex align-items-center gap-2 mt-3 p-0"
                                                            >
                                                                {index !== 0 && (
                                                                    <Button className="remove-btn mt-0" onClick={() => deleteRow(index)}>
                                                                        <i className="bx bx-trash" style={{ fontSize: '24px' }}></i>
                                                                    </Button>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Col lg={12} className="mb-3">
                                                <Button className="add-btn p-0" onClick={addRow}>
                                                    <i className="ri-add-fill"></i> Add More
                                                </Button>
                                            </Col>
                                            {/* <Col lg={12} className="text-center">
                                                <Button color="primary" disabled={formik.isSubmitting} type="submit" className="next-btn">Apply</Button>
                                            </Col> */}
                                            <Col
                                                lg="12"
                                                className="offcanvas-footer pb-0 text-center"
                                                style={{
                                                    position: "fixed",
                                                    bottom: "0",
                                                    width: "100%",
                                                    left: "0",
                                                }}
                                            >
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="off-bottom-staff justify-content-end">
                                                            <div className="off-confirm d-flex">
                                                                <Button
                                                                    onClick={toggleBottomCanvas}
                                                                    color="primary"
                                                                    className="clear-btn"
                                                                >
                                                                    Cancel
                                                                </Button>
                                                                {formik.isSubmitting ? (
                                                                    <Button
                                                                        color="primary"
                                                                        className="next-btn"
                                                                        disabled
                                                                    >
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="border"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        color="primary"
                                                                        type="submit"
                                                                        disabled={formik.isSubmitting}
                                                                        className="next-btn"
                                                                    >
                                                                        Confirm
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="product1" id="product1">
                                        <Form onSubmit={Csvformik.handleSubmit}>
                                            <Row className="add-doc">
                                                <Col lg="5" className="upload-section-col">
                                                    <Form.Group>
                                                        <div className="d-flex gap-2 align-items-center">
                                                                                <Form.Label className="doc-label">
                                                                                  Upload CSV File
                                                                                </Form.Label>
                                                                            
                                                                                  {/* Eye Button to Preview */}
                                                                                  {file && (
                                                                                   
                                                                                      <i
                                                                                        onClick={() => handleFilePreview(file)}
                                                                                        className="ri-eye-line link-info"
                                                                                        style={{ fontSize: "18px", cursor: "pointer", marginTop: "-7px"}}
                                                                                      ></i>
                                                                                  
                                                                                  )}
                                                                              
                                                                                </div>
                                                        <div className="uploadsection">
                                                            {showUploadButton ? (
                                                                <div className="d-flex align-item-center">
                                                                    <Form.Label htmlFor="file" className="uploadlabel">
                                                                        <Form.Control
                                                                            type="file"
                                                                            name="csv_file"
                                                                            className="form-control-lg"
                                                                            style={{ display: "none" }}
                                                                            id="file"
                                                                            onChange={handleFileChange}
                                                                        />
                                                                        <i className="bx bx-plus"></i> Choose File
                                                                    </Form.Label>
                                                                </div>
                                                            ) : (
                                                                <Col lg="12" className="closeuser py-0">
                                                                    <p>File Selected: {file?.name}</p>
                                                                    <Button onClick={handleRemoveFile} color="red">
                                                                        X
                                                                    </Button>
                                                                </Col>
                                                            )}
                                                            {Csvformik.touched.csv_file && Csvformik.errors.csv_file ? (
                                                                <div className="invalid-feedback d-block">{Csvformik.errors.csv_file}</div>
                                                            ) : null}
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg="12" className="d-flex align-items-center">
                                                    <Button
                                                        color="primary"
                                                        className="add-btn"
                                                        style={{ background: 'none', paddingLeft: '8px' }}
                                                        onClick={handleDownload}
                                                    >
                                                        Sample File
                                                    </Button>
                                                </Col>
                                                <Col
                                                    lg="12"
                                                    className="offcanvas-footer pb-0 text-center"
                                                    style={{
                                                        position: "fixed",
                                                        bottom: "0",
                                                        width: "100%",
                                                        left: "0",
                                                    }}
                                                >
                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="off-bottom-staff justify-content-end">
                                                                <div className="off-confirm d-flex">
                                                                    <Button
                                                                        onClick={toggleBottomCanvas}
                                                                        color="primary"
                                                                        className="clear-btn"
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                    {Csvformik.isSubmitting ? (
                                                                        <Button
                                                                            color="primary"
                                                                            className="next-btn"
                                                                            disabled
                                                                        >
                                                                            <Spinner
                                                                                as="span"
                                                                                animation="border"
                                                                                size="sm"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            color="primary"
                                                                            type="submit"
                                                                            disabled={Csvformik.isSubmitting}
                                                                            className="next-btn"
                                                                        >
                                                                            Confirm
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Tab.Pane>
                                </Tab.Content>
                            </SimpleBar>
                        </Offcanvas.Body>
                        {/* <Row>
                            <Col lg="12" style={{ position: 'absolute', bottom: '0' }}>
                                <div className="offcanvas-footer p-3 pb-0 text-center">
                                    <Row>
                                        <Col lg={12} className='off-bottom justify-content-end'>
                                            <div className='off-confirm'>
                                                <Button onClick={() => setIsBottom(false)} color="primary" className="clear-btn">Cancel</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row> */}
                    </Tab.Container>
                </Offcanvas>
                <Modal
                    show={modal_center}
                    onHide={() => {
                        tog_center();
                    }}
                    size="sm"
                    centered
                >

                    <Modal.Body className="text-center p-4">
                        <i className="ri-delete-bin-line text-danger display-5"></i>
                        <div className="mt-4">
                            <h4 className="mb-3">Are you sure?</h4>
                            <p className="text-muted mb-4">You want to archive this Subcontractor.</p>
                            <div className="hstack gap-2 justify-content-center">
                                <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
                                {archiveLoader ? (
                                    <Button
                                        color="primary"
                                        className="next-btn"
                                        disabled
                                    >
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>
                                ) : (
                                    <Button variant="danger" onClick={() => {
                                        if (archiveId !== undefined) {
                                            archiveSubcontractor(archiveId);
                                        } else {
                                            toast.error("No Archive ID provided.");
                                        }
                                    }}>Delete</Button>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={modal_restore_center}
                    onHide={() => {
                        tog_restore_center();
                    }}
                    size="sm"
                    centered
                >
                    <Modal.Body className="text-center p-4">
                        <i className=" ri-restart-line text-success display-5"></i>
                        <div className="mt-4">
                            <h4 className="mb-3">Are you sure?</h4>
                            <p className="text-muted mb-4">You want to restore this Subcontractor.</p>
                            <div className="hstack gap-2 justify-content-center">
                                <Button variant="light" onClick={() => setmodal_restore_center(false)}>Close</Button>
                                {restoreLoader ? (
                                    <Button
                                        variant="success"
                                        className="next-btn"
                                        disabled
                                    >
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>
                                ) : (
                                    <Button variant="success" onClick={() => {
                                        if (restoreId !== undefined) {
                                            restoreClient(restoreId);
                                        } else {
                                            toast.error("No Restore ID provided.");
                                        }
                                    }}>Restore</Button>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        </>
    );
};

export default SubContractorPage;
