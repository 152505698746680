import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getComplianceMasterDetail, addComplianceMasterDetail, archiveComplianceMasterDetail, getByComplianceMasterDetail, updateComplianceMasterDetail } from 'helpers/saibackend_helper';
import {
    Button,
    Col,
    Form,
    Row,
    Offcanvas,
    Modal,
    Spinner
} from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleBar from "simplebar-react";
import ComplianceTable from './ComplianceTable';
import { useProfile } from 'Common/Hooks/UserHooks';

interface ComplianceMasterData {
    id: number;
    compliance_name: string;
    description: string;
    reminder_days: number,
    is_critical: number,
    is_show_to_customer: number
}


const ComplianceMaster = (() => {
    const [loading, setLoading] = useState(true);
    const [isBottom, setIsBottom] = useState(false);
    const toggleBottomCanvas = () => {
        setIsBottom(!isBottom);
    };
    const [totalRows, setTotalRows] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState<number | null>(null);
    const [archiveId, setArchiveId] = useState<number | undefined>(undefined);

    const { userProfile } = useProfile();
  const userRoles = userProfile.roles;
  const allowedRoles = ['DEVELOPER', 'SUPER_ADMIN', 'ADMIN', 'MANAGER'];
  const hasRoles = (roles: string[]) => roles.some(role => userRoles.includes(role));
  const canAccess = hasRoles(allowedRoles);


    const handleArchiveCompliance = async (id: number): Promise<void> => {
        setArchiveId(id);
        setmodal_center(true);
    };


    const [modal_center, setmodal_center] = useState<boolean>(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }

    const [ComplianceMasterDetail, setComplianceMasterDetail] = useState<ComplianceMasterData[]>([])

    const [defaultValue, setDefaultValue] = useState({
        page: 1,
        page_size: "10",
        search_term: "",
        sort: {
            sort_order: "ASC",
            sort_by: ""
        },
        is_archive: 0
    });


    const handlePageChange = (page: number) => {
        setDefaultValue(prevPayload => ({
            ...prevPayload,
            page: page
        }));
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setDefaultValue(prevPayload => ({
            ...prevPayload,
            page_size: String(newPerPage),
            page: page
        }));
    };


    const handleSort = (column: any, sortDirection: 'asc' | 'desc') => {
        const columnSelector = typeof column === 'function' ? column.name : column;

        setDefaultValue(prevState => ({
            ...prevState,
            sort: {
                sort_by: columnSelector,
                sort_order: sortDirection.toUpperCase(),
            }
        }));
    };

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        const handler = setTimeout(() => {
            setDefaultValue((prevPayload) => ({
                ...prevPayload,
                search_term: inputValue,
            }));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const fetchComplianceDetails = async () => {
        try {
            const response = await getComplianceMasterDetail(
                defaultValue.page,
                defaultValue.page_size,
                defaultValue.search_term,
                defaultValue.sort.sort_order,
                defaultValue.sort.sort_by,
                defaultValue.is_archive
            );
            setComplianceMasterDetail(response.data.data_list);
            setTotalRows(response.data.total_records);
            setLoading(false);
        } catch (error) {
            return;
        }
    };

    useEffect(() => {
        fetchComplianceDetails();
    }, [defaultValue]);

    const validationSchema = Yup.object().shape({
        compliance_name: Yup.string().required('Compliance name is required'),
        reminder_days: Yup.number().typeError('Reminder days must be a number').positive('Reminder days must be a positive number')
            .integer('Reminder days must be an integer') // Changed from "minutes" to "days"
    });


    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        formik.setFieldValue(name, checked ? 1 : 0);
    };

    const formik = useFormik({
        initialValues: {
            compliance_name: '',
            reminder_days: 0,
            is_critical: 0,
            is_document_compulsory: 0
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (isEditing && editId !== null) {
                    const response = await updateComplianceMasterDetail(editId, values.compliance_name, values.reminder_days, values.is_critical, values.is_document_compulsory);
                    if (response.status === 1) {
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                        return;
                    }
                } else {
                    const response = await addComplianceMasterDetail(values);
                    if (response.status === 1) {
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                        return;
                    }
                }
                toggleBottomCanvas();
                fetchComplianceDetails();
                formik.resetForm();
            } catch (error) {
                toast.error("Error Compliance Master. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        }
    });

    const [archiveLoader, setArchiveLoader] = useState(false)

    const archiveMaster = async (id: number) => {
        setArchiveLoader(true)
        try {
            const response = await archiveComplianceMasterDetail(id);
            fetchComplianceDetails();
            toast.success(response.message);
            setmodal_center(false)
            setArchiveLoader(false)
        } catch (error) {
            toast.error("Error Archive Please try again later.");
        }
    };

    const editMaster = async (id: number) => {
        try {
            const response = await getByComplianceMasterDetail(id);
            const data = response.data;
            formik.setValues({
                compliance_name: data.compliance_name,
                reminder_days: data.reminder_days,
                // description: data.description,
                is_critical: data.is_critical,
                is_document_compulsory: data.is_document_compulsory
            });
            setIsEditing(true);
            setEditId(id);
            toggleBottomCanvas();
        } catch (error) {
            toast.error("Error fetching details. Please try again later.");
        }
    };

    const openAddForm = () => {
        setIsEditing(false);
        setEditId(null);
        formik.resetForm()
        setIsBottom(true);
    };



    return (
        <>
            {
                loading ?
                    <Row className="placeholder-glow">
                        <Col lg={6}>
                            <div className="placeholder bg-light card-placeholder col-12"></div>
                        </Col>
                        <Col lg={6}>
                            <div className="placeholder bg-light card-placeholder col-12"></div>
                        </Col>
                        <Col lg={12} className="mt-4">
                            <div className="placeholder bg-light table-placeholder col-12"></div>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col lg="12" className="d-flex align-items-center justify-content-between role-navbar">
                            <h4 className="card-title mb-2">Compliance Master</h4>
                            <div className="d-flex gap-2">
                                <div className="app-search d-none d-md-inline-flex">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                            id="search-options"
                                            value={inputValue}
                                            onChange={handleChange}
                                        />
                                        <span className="mdi mdi-magnify search-widget-icon"></span>
                                        <span
                                            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                            id="search-close-options"
                                        ></span>
                                    </div>
                                </div>
                                {canAccess && userRoles[0] !== "MANAGER" && (<Button
                                                                    variant="primary"
                                                                    className="add-company"
                                                                    onClick={openAddForm}
                                                                >
                                                                    <i className=" ri-add-fill"></i> Add
                                                                </Button>)}
                            </div>
                        </Col>
                        <Col lg="12">
                            <SimpleBar
                                style={{ maxHeight: "589px" }}
                            >
                                <ComplianceTable editMaster={editMaster} data={ComplianceMasterDetail} handlePageChange={handlePageChange} handlePerRowsChange={handlePerRowsChange} paginationTotalRows={totalRows} handleArchiveCompliance={handleArchiveCompliance} handleSort={handleSort} canAccess={canAccess} userRoles={userRoles}/>
                            </SimpleBar>
                        </Col>
                    </Row>
            }
            <Offcanvas
                className="add-role-canvas"
                show={isBottom}
                onHide={toggleBottomCanvas}
                placement="bottom"
                style={{ minHeight: "496px" }}
            >
                <Offcanvas.Header className="add-header border-bottom" closeButton>
                    <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">{isEditing ? "Edit" : "Add"} Compliance</Offcanvas.Title>
                </Offcanvas.Header>
                <Form onSubmit={formik.handleSubmit}>
                    <>
                        <SimpleBar
                            style={{ maxHeight: "310px" }}
                            className="px-3"
                        >
                            <Offcanvas.Body>
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Compliance name</Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="text"
                                                    name="compliance_name"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter Compliance name"
                                                    value={formik.values.compliance_name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!(formik.touched.compliance_name && formik.errors.compliance_name)}
                                                />
                                                <i style={{ fontSize: '16px' }} className="ri-book-2-fill"></i>
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.compliance_name}
                                                </Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
  <Form.Group className="mb-3">
    <Form.Label>Reminder (In Days)</Form.Label>
    <div className="position-relative form-icon">
      <Form.Control
        type="number"
        name="reminder_days"
        className="form-control-lg form-control-icon name-input"
        placeholder="Enter Reminder Days"
        value={formik.values.reminder_days || ""}
        onChange={(e) => {
          // Parse the input as a number
          let value = parseInt(e.target.value);
          // Ensure the value is not less than 0
          if (isNaN(value) || value < 0) value = 0; // Set to 0 if it's negative or invalid
          formik.setFieldValue("reminder_days", value);
        }}
        onBlur={formik.handleBlur}
        isInvalid={!!(formik.touched.reminder_days && formik.errors.reminder_days)}
      />
      <i style={{ fontSize: '16px' }} className="ri-time-line"></i>
      <Form.Control.Feedback type="invalid">
        {formik.errors.reminder_days}
      </Form.Control.Feedback>
    </div>
  </Form.Group>
</Col>


                                    {/* <Col lg={12}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Description</Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    className="form-control form-control-icon"
                                                    type='textarea'
                                                    name="description"
                                                    placeholder="Enter Description"
                                                    value={formik.values.description}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <i className="ri-edit-circle-line d-flex align-items-start" style={{ marginTop: '0.5rem' }}></i>
                                            </div>
                                        </Form.Group>
                                    </Col> */}
                                    <Col lg="12">
                                        {/* <Form.Group>
                                            <div className="form-check form-switch form-switch-custom mb-2">
                                                <Form.Check
                                                    type="checkbox"
                                                    name="is_critical"
                                                    id="Critical"
                                                    role="switch"
                                                    checked={formik.values.is_critical === 1}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <Form.Label>Critical</Form.Label>                                            
                                            </div>
                                        </Form.Group> */}
                                        <Form.Group>
                                            <div className="form-check form-switch form-switch-custom form-switch-lg mb-2">
                                                <Form.Check
                                                    type="checkbox"
                                                    name="is_document_compulsory"
                                                    id="is_document_compulsory"
                                                    role="switch"
                                                    checked={formik.values.is_document_compulsory === 1}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <Form.Label>Compulsory Document ?</Form.Label>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Offcanvas.Body>
                        </SimpleBar>
                        <Row>
                            <Col lg="12" style={{ position: 'absolute', bottom: '0' }}>
                                <div className="offcanvas-footer border-top-0 p-3 pb-0 text-center">
                                    <Row>
                                        <Col lg={12} className="off-bottom-staff justify-content-end">
                                            <div className="off-confirm">
                                                <Button
                                                    onClick={toggleBottomCanvas}
                                                    color="primary"
                                                    className="clear-btn"
                                                >
                                                    Cancel
                                                </Button>
                                                {formik.isSubmitting ? (
                                                    <Button
                                                        color="primary"
                                                        className="next-btn"
                                                        disabled
                                                    >
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    </Button>
                                                ) : (
                                                    <Button color="primary" className="next-btn" type="submit" disabled={formik.isSubmitting}>
                                                        {isEditing ? "Update" : "Add"}
                                                    </Button>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </>
                </Form>
            </Offcanvas>
            <Modal
                show={modal_center}
                onHide={() => {
                    tog_center();
                }}
                size="sm"
                centered
            >

                <Modal.Body className="text-center p-4">
                    <i className="ri-delete-bin-line text-danger display-5"></i>
                    <div className="mt-4">
                        <h4 className="mb-3">Are you sure?</h4>
                        <p className="text-muted mb-4">You want to delete this Compliance.</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
                            {archiveLoader ? (
                                <Button
                                    color="primary"
                                    className="next-btn"
                                    disabled
                                >
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                            ) : (
                                <Button variant="danger" onClick={() => {
                                    if (archiveId !== undefined) {
                                        archiveMaster(archiveId);
                                    } else {
                                        toast.error("No Archive ID provided.");
                                    }
                                }}>Delete</Button>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
})

export default ComplianceMaster;