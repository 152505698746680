import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

interface ComplianceMasterData {
  id: number;
  compliance_name: string;
  description: string;
  reminder_days: number;
  is_critical: number;
  is_show_to_customer: number;
}
type SortOrder = "asc" | "desc";

interface ComplianceTableProps {
  data: ComplianceMasterData[];
  editMaster: (id: number) => Promise<void>;
  handlePageChange: (page: number) => void;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  paginationTotalRows: number;
  handleArchiveCompliance: (id: number) => Promise<void>;
  handleSort: (column: string, sortDirection: "asc" | "desc") => void;
  canAccess: boolean;
  userRoles: any;
}

const ComplianceTable: React.FC<ComplianceTableProps> = ({
  data,
  editMaster,
  handlePageChange,
  handlePerRowsChange,
  paginationTotalRows,
  handleArchiveCompliance,
  handleSort,
  canAccess,
  userRoles,
}) => {
  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Compliance Name</span>,
      sortable: true, // Disable client-side sorting
      selector: (row: any) => row.compliance_name,
      sortField: "compliance_name",
    },
    {
      name: <span className="font-weight-bold fs-sm">Reminder</span>,
      cell: (row: any) => {
        return <>{row.reminder_days ? row.reminder_days : "-"}</>;
      },
      sortable: false, // Disable client-side sorting
      sortField: "reminder_days",
    },
    // {
    //     name: <span className='font-weight-bold fs-sm'>Critical</span>,
    //     cell: (row: any) => {
    //         return (
    //             <>
    //                 {row?.is_critical === 1 ? "Yes" : "No"}
    //             </>
    //         );
    //     },
    //     sortable: false, // Disable client-side sorting
    //     sortField: 'is_critical',
    // },
    {
      name: <span className="font-weight-bold fs-sm">Compulsory Document</span>,
      cell: (row: any) => {
        return <>{row?.is_document_compulsory === 1 ? "Yes" : "No"}</>;
      },
      sortable: false, // Disable client-side sorting
      sortField: "is_document_compulsory",
    },
    {
      name: canAccess && userRoles[0] !== "MANAGER" ? (
        <span className="font-weight-bold fs-sm">Action</span>
      ) : null,
      cell: (row: any) => {
        return (canAccess && userRoles[0] !== "MANAGER" &&
          <>
            <div style={{ display: "flex", gap: "8px" }}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="edit-tooltip">Edit</Tooltip>}
              >
                <i
                  onClick={() => editMaster(row.id)}
                  style={{ fontSize: "18px", cursor: "pointer" }}
                  className="ri-edit-2-line link-success"
                ></i>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}
              >
                <i
                  onClick={() => handleArchiveCompliance(row.id)}
                  style={{ fontSize: "18px", cursor: "pointer" }}
                  className="ri-delete-bin-line link-danger"
                ></i>
              </OverlayTrigger>
            </div>
          </>
        );
      },
      sortable: false, // Disable client-side sorting
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      pagination
      paginationServer
      paginationTotalRows={paginationTotalRows}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerRowsChange}
      onSort={(column: any, sortDirection: SortOrder) => {
        if (column.sortField) {
          handleSort(column.sortField, sortDirection);
        }
      }}
      sortServer // Indicate server-side sorting
    />
  );
};

export default ComplianceTable;
