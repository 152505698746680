import axios from "axios";
import { toast } from "react-toastify";
// import { api } from "../config";

interface ApiResponse {
  status: number;
  message: string;
  [key: string]: any; // This allows for additional properties if any
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.defaults.headers.post["Content-Type"] = "application/json";

const authUserString = localStorage.getItem("authUser");
const authUser = authUserString ? JSON.parse(authUserString) : null;
const token = authUser && authUser.data ? authUser.data.token : null;

if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 404:
          message = "Sorry! The data you are looking for could not be found";
          break;
        default:
          message = error.response.data.message || error.message || error;
      }
    } else {
      message = error.message || error;
    }
    return Promise.reject(message);
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: any) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  get = (url: string, params: any): Promise<ApiResponse> => {
    let response: Promise<ApiResponse>;

    let paramKeys: string[] = [];

    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };

  logout = (url: string): Promise<ApiResponse> => {
    const authUserString = localStorage.getItem("authUser");
    const authUser = authUserString ? JSON.parse(authUserString) : null;
    const token = authUser && authUser.data ? authUser.data.token : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return axios.post(url, {}, config);
  };

  create = (url: string, data: any, config: any = {}): Promise<ApiResponse> => {
    const defaultConfig = {
      headers: {
        "Content-Type":
          data instanceof FormData ? "multipart/form-data" : "application/json",
      },
    };
    const finalConfig = { ...defaultConfig, ...config };
    return axios.post(url, data, finalConfig);
  };

  update = (url: string, data: any): Promise<ApiResponse> => {
    return axios.patch(url, data);
  };

  put = (url: string, data: any): Promise<ApiResponse> => {
    return axios.put(url, data);
  };

  delete = (url: string, config: any): Promise<ApiResponse> => {
    return axios.delete(url, { ...config });
  };

  exportNewFile = (
    url: string,
    params: any,
    fileName: string
  ): Promise<void> => {
    console.log("url",url)
    const serializeParams = (params: any): string[] => {
      const paramKeys: string[] = [];
      for (const key of Object.keys(params)) {
        const value = params[key];
        if (Array.isArray(value)) {
          if (value.length > 0) {
            paramKeys.push(
              `${key}=[${value
                .map((item) => encodeURIComponent(item))
                .join(",")}]`
            );
          }
        } else if (value !== undefined && value !== null && value !== "") {
          paramKeys.push(`${key}=${encodeURIComponent(value)}`);
        }
      }
      return paramKeys;
    };

    const queryString = serializeParams(params).join("&");
    const requestUrl = queryString ? `${url}?${queryString}` : url;
    console.log("url")

    return this.exportFile(requestUrl, {}, fileName);
  };

  exportDutyReportFile = (
    url: string,
    params: any,
    fileName: string
  ): Promise<void> => {
    
  
    const serializeParams = (params: any): string[] => {
      const paramKeys: string[] = [];
      for (const key of Object.keys(params)) {
        const value = params[key];
        if (Array.isArray(value)) {
          if (value.length > 0) {
            // Handle location_id as an array in the form location_id[0]=1&location_id[1]=23
            if (key === 'location_id') {
              value.forEach((item, index) => {
                paramKeys.push(`${key}[${index}]=${encodeURIComponent(item)}`);
              });
            } else {
              value.forEach((item, index) => {
                paramKeys.push(`${key}[${index}]=${encodeURIComponent(item)}`);
              });
            }
          }
        } else if (value !== undefined && value !== null && value !== "") {
          paramKeys.push(`${key}=${encodeURIComponent(value)}`);
        }
      }
      return paramKeys;
    };
  
    const queryString = serializeParams(params).join("&");
    const requestUrl = queryString ? `${url}?${queryString}` : url;
  
    
  
    return this.exportFile(requestUrl, {}, fileName);
  };
  
  exportFile = (url: string, params: any, fileName: string): Promise<void> => {
    
    return axios
      .get(url, {
        responseType: "blob", // Important for binary data
      })
      .then((response: any) => {
        
        
        const contentType = response.type;
        
        let extension = "";
        let mimeType = "";

        if (contentType === "application/json") {
          toast.error("No finished shifts are available in this location for duty report.", { autoClose: 3000 });
          return;
        }

        // Determine file type and extension based on content type
        if (contentType === "application/pdf") {
          extension = ".pdf";
          mimeType = "application/pdf";
          
        } else if (contentType.includes("image")) {
          extension = "." + contentType.split("/")[1];
          mimeType = contentType;
          
        } else if ( 
          contentType.includes(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          )
        ) {
          extension = ".xlsx";
          mimeType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        }
        const fileData: any = response;
        
        const blob = new Blob([fileData], { type: mimeType });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${fileName}${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
        toast.success("Duty Report downloaded successfully!", { autoClose: 3000 });
      })
      .catch((error) => {
        console.error("Error exporting file:", error);
        toast.error("Error downloading file. Please try again!", { autoClose: 3000 });
      });
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    const user_res = JSON.parse(user);
    if (user_res && user_res.data && user_res.data.user_detail) {
      return user_res.data.user_detail;
    } else {
      return null;
    }
  }
};

export { APIClient, setAuthorization, getLoggedinUser };

