import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  Button,
  Offcanvas,
  Form,
  Dropdown,
  ButtonGroup,
  Tab,
  Nav,
  Spinner,
  Table,
  Modal,
  ListGroup,
  Accordion,
  InputGroup,
  FormCheck, OverlayTrigger, Tooltip
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import ScheduleWeekTableNew from "./ScheduleTable/ScheduleWeekTableNew";
import SimpleBar from "simplebar-react";
import Select from "react-select";
import { getScheduleClientList, getAllLocationScheduleList, createScheduleShift, pasteScheduleShift, getByStaffDetail, archiveShiftDetail, exportPatrolLocationInfoDetail, getShiftDetail, ShiftHistoryList, getStaffDocumentDetail, getScheduleStaffList, getSchedulePublishDetailWithLocation, publishShiftsWithLocation, ExportShift, submitShiftTemplate, loadShiftTemplate, copyWeekTemplate, saveShiftTemplate, ShiftTemplateArchiveById, updateScheduleShift, getShiftSummary, getLocationDropdownList, dutyReportTemplate, getSchedulePublishShiftList, finishedBulkShift } from "helpers/saibackend_helper";
import moment from 'moment';
import * as Yup from "yup";
import { FastField, useFormik } from "formik";
import Cleave from 'cleave.js/react';
import { toast } from 'react-toastify';
import noDataImg from "assets/images/no-data.png"
import { Link } from 'react-router-dom';
import { useProfile } from "Common/Hooks/UserHooks";
moment.updateLocale('en', { week: { dow: 1 } });

interface DropdownData {
  value: number;
  label: string;
}
interface PublishDateItem {
  date: string;
  dayName: string;
}
interface PublishStaffItem {
  staff_id: string;
  staff_name: string;
}

interface PublishLocationItem {
  location_id: string;
  name: string;
}

interface ClientLocation {
  clientName: string;
  clientValue: string | number; // Allow both string and number
  locations: any; // Modify according to your actual location type
}



const ScheduleNew = () => {
  document.title = "Dashboard | The Guard Duty";

  const { userProfile } = useProfile();
  const userRoles = userProfile.roles;
  const allowedRoles = ['DEVELOPER', 'SUPER_ADMIN', 'ADMIN', 'MANAGER'];
  const hasRoles = (roles: string[]) => roles.some(role => userRoles.includes(role));
  const canAccess = hasRoles(allowedRoles);

  const [paste_alert_standard, setpaster_alert_standard] = useState<boolean>(false);
  function paste_standard() {
    setpaster_alert_standard(!paste_alert_standard);
  }

  const [shiftFilters, setShiftFilters] = useState<boolean>(false);

  const FilterOpen = (() => {
    if (pasteMode) {
      paste_standard()
    } else {
      setShiftFilters(!shiftFilters)
    }
  })

  const handleTabChange = (key: string | null) => {
    if (key) {
      setActiveTab(key);
    }
  };

  const getInitials = (name: string): string => {
    const names = name.split(" ");
    const firstNameInitial = names[0].charAt(0).toUpperCase();
    const lastNameInitial =
      names.length > 1 ? names[names.length - 1].charAt(0).toUpperCase() : "";
    return `${firstNameInitial}${lastNameInitial}`;
  };

  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [isClear, setIsClear] = useState(false);

  const allStatusOptions = ['Un-assigned Shifts', 'Unpublished Shifts', 'Publish Shifts', 'Finish Shifts', 'Archive Shifts'];

  const handleAllStatusChange = () => {
    if (selectedStatus.length === allStatusOptions.length) {
      setSelectedStatus([]);
    } else {
      setSelectedStatus(allStatusOptions.slice());
    }
  };

  const handleStatusChange = (status: string) => {
    const updatedStatus = [...selectedStatus];

    if (updatedStatus.includes(status)) {
      updatedStatus.splice(updatedStatus.indexOf(status), 1);
    } else {
      updatedStatus.push(status);
    }

    setSelectedStatus(updatedStatus);
  };

  const handleClearAllStatus = () => {
    setSelectedStatus([]);
  };

  const ClearAllFilter = async () => {
    setSelectedFilterLocation([]);
    setIsClear(!isClear);
    setShiftFilters(false);
  };

  /*Location Wise Scheduling STart */
  const [clientList, setClientList] = useState<DropdownData[]>([]);
  const [clientSearch, setClientSearch] = useState<string>("");
  const [selectedClient, setSelectedClient] = useState<DropdownData | null>(null);
  const [selectedClients, setSelectedClients] = useState<DropdownData[]>([]);
  const [eventList, setEventList] = useState<any[]>([]);
  const [eventDates, setEventDates] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  // Starting week date
  const [startDate, setStartDate] = useState(moment().startOf('week'));

  const fetchClientList = async (searchValue: string) => {
    try {
      const response = await getScheduleClientList(searchValue);
      const clients = response.data.map((client: any) => ({
        value: client.value,
        label: client.label,
      }));
      setClientList(clients);


      const storedClient = localStorage.getItem('selectedClient');

      if (clients.length > 0) {
        if (storedClient) {
          setSelectedClient(JSON.parse(storedClient));
        } else {
          setSelectedClient(clients[0]);
          localStorage.setItem('selectedClient', JSON.stringify(clients[0]));
        }
      } else {
        setSelectedClient(null);
      }
    } catch (error) {
      console.log(error);
    }
    setSelectedFilterLocation([]);
  };

  const fetchEventsFromAPI = async () => {
    try {
      if (selectedClient) {
        const client: any = selectedClient?.value;
        const formattedStart = moment(startDate).format("YYYY-MM-DD");
        const formattedEnd = moment(endDate).format("YYYY-MM-DD");
        const locationIds = selectedFilterLocation.map((location: any) => location.value);
        // console.log("locations",locationIds)

        // Fetch schedule list
        const response = await getAllLocationScheduleList(client, formattedStart, formattedEnd, locationIds);
        // console.log("scheduel",response)

        // Update event-related states
        setEventList(response.data.data_list);
        setEventDates(response.data.dates);
        // setTotalLocation(response.data.data_list.length);

        // Fetch shift summary
        const summaryResponse = await getShiftSummary(client, formattedStart, formattedEnd);
        setTotalShifts(summaryResponse.data?.total_shift);
        setTotalPublishShifts(summaryResponse.data?.total_published_shift);
        setTotalUnpublishShifts(summaryResponse.data?.total_unpublished_shift);
        setTotalUnassignShifts(summaryResponse.data?.total_unassigned_shift);
        setTotalArchivedShifts(summaryResponse.data?.total_archived_shift);
        setTotalFinishedShifts(summaryResponse.data?.total_finished_shift);

        // Fetch location dropdown list
        const locationResp = await getLocationDropdownList(client, locationSearch);



        setFilterLocationList(locationResp?.data);
        // setEventList(locationResp?.data)

        // Update allSelectedLocations and save to ref
        const updatedAllSelectedLocations = [
          {
            clientName: selectedClient.label,
            clientValue: selectedClient.value,
            locations: locationResp?.data || [],
          },
        ];
        setAllSelectedLocations(updatedAllSelectedLocations);
        initialLocationsRef.current.allSelectedLocations = updatedAllSelectedLocations;

        // Update checkedLocations and save to ref
        const updatedCheckedLocations = [
          {
            clientName: selectedClient.label,
            clientValue: selectedClient.value,
            locations: locationResp?.data || [],
          },
        ];
        setCheckedLocations(updatedCheckedLocations);
        initialLocationsRef.current.checkedLocations = updatedCheckedLocations;

        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  /*Location Wise Scheduling STart */

  /* NEW */
  const [StaffViewDetail, setStaffViewDetail] = useState<any | null>(null);

  const doc_url = process.env.REACT_APP_IMAGE_URL
  const imageSrc = `${doc_url}/${StaffViewDetail?.avatar}`

  const [staffList, setStaffList] = useState<DropdownData[]>([]);
  const [isShiftCanvasOpen, setIsShiftCanvasOpen] = useState<boolean>(false);
  const [selectedStaffName, setSelectedStaffName] = useState<string>("");
  const [editId, setEditId] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState<string>("home1");
  const [isFinishShift, setIsFinishShift] = useState<boolean>(false);
  const [isPublished, setIsPublished] = useState<boolean>(false);
  const [shiftHours, setShiftHours] = useState<number>(9);
  const [selectedLocation, setSelectedLocation] = useState<DropdownData | null>(null);
  const [isAccept, setIsAccept] = useState<any>("")
  const [shiftClockIn, setShiftClockIn] = useState<any>("")
  const [shiftClockOut, setShiftClockOut] = useState<any>("");
  const [Created_by, setCreatedBy] = useState<string>("")
  const [totalShifts, setTotalShifts] = useState<number>(0);
  const [totalLocation, setTotalLocation] = useState<number>(0);
  const [totalPublishShifts, setTotalPublishShifts] = useState<number>(0);
  const [totalUnpublishShifts, setTotalUnpublishShifts] = useState<number>(0);
  const [totalUnassignShifts, setTotalUnassignShifts] = useState<number>(0);
  const [totalArchivedShifts, setTotalArchivedShifts] = useState<number>(0);
  const [totalFinishedShifts, setTotalFinishedShifts] = useState<number>(0);
  const [archiveModal, setArchiveModal] = useState<boolean>(false);
  const [archiveId, setArchiveId] = useState<number | undefined>(undefined);
  const [Is_petrol_site, setIs_petrol_site] = useState<number>(0);
  const [ShiftHistoryDetails, setShiftHistoryDetails] = useState<any[]>([]);
  const [locationSearch, setLocationSearch] = useState<string>("");
  const [staffSearch, setStaffSearch] = useState<string>("");
  const [filterLocationList, setFilterLocationList] = useState<DropdownData[]>([]);
  const [selectedFilterLocation, setSelectedFilterLocation] = useState<number[]>([]);
  const [allSelectedLocations, setAllSelectedLocations] = useState<ClientLocation[]>([]);
  const [checkedLocations, setCheckedLocations] = useState<ClientLocation[]>([]);
  const initialLocationsRef = useRef<{
    allSelectedLocations: ClientLocation[];
    checkedLocations: ClientLocation[];
  }>({
    allSelectedLocations: [],
    checkedLocations: [],
  });

  /*------------------------------Formik Start--------------------------------*/
  const [initialValues, setInitialValues] = useState({
    date: "",
    staff_id: 0,
    start_time: "09:00",
    end_time: "18:00",
    clock_in_time: "",
    clock_out_time: "",
    total_unpaid_break: "",
    clock_in_latitude: "",
    clock_in_longitude: "",
    clock_out_latitude: "",
    clock_out_longitude: "",
    total_paid_break: "",
    shift_notes: "",
    admin_note: "",
    is_finished: 0,
    client_id: selectedClient?.value,
    location_id: selectedLocation?.value,
    shift_id: 0,
    is_published: 0
  });

  const shiftValidationSchema = Yup.object().shape({
    date: Yup.string().required("Please select a date"),
    start_time: Yup.string()
      .required("Start time is required"),
    end_time: Yup.string()
      .required("Finish time is required"),
    total_unpaid_break: Yup.number()
      .min(0)
      .integer("Unpaid break must be an integer").nullable(),
    total_paid_break: Yup.number()
      .min(0)
      .integer("Paid break must be an integer").nullable(),
    shift_notes: Yup.string().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: shiftValidationSchema,
    onSubmit: async (values) => {
      const formattedStartTime = moment(values.start_time, "HH:mm").format("HH:mm");
      const formattedEndTime = moment(values.end_time, "HH:mm").format("HH:mm");
      const formattedClockinTime = values.clock_in_time ? moment(values.clock_in_time, "HH:mm").format("HH:mm") : "";
      const formattedClockoutTime = values.clock_out_time ? moment(values.clock_out_time, "HH:mm").format("HH:mm") : "";

      const postdata = {
        ...values,
        location_id: selectedLocation?.value,
        shift_id: editId,
        staff_id: values.staff_id ?? 0,
        start_time: formattedStartTime,
        end_time: formattedEndTime,
        clock_in_time: formattedClockinTime,
        clock_out_time: formattedClockoutTime,
        is_accept: editId ? isAccept : undefined
      };
      const response = await createScheduleShift(postdata);
      if (response.status === 1) {
        toast.success(response.message);
        fetchEventsFromAPI();
        formik.resetForm();
        setIsShiftCanvasOpen(false);
      } else {
        toast.error(response.message);
        return;
      }
    }
  });

  const calculateShiftHours = (startTime: string, endTime: string, unpaidBreak: number): number => {
    const start = moment(startTime, "HH:mm");
    const end = moment(endTime, "HH:mm");
    if (end.isBefore(start)) {
      end.add(1, 'day');
    }

    const duration = moment.duration(end.diff(start));
    const totalHours = parseFloat(duration.asHours().toFixed(2));

    // Deduct unpaid break time (convert minutes to hours)
    const unpaidBreakHours = unpaidBreak / 60;
    const netHours = totalHours - unpaidBreakHours;

    return parseFloat(netHours.toFixed(2));
  };

  const handleTimeChange = (time: string, field: string) => {
    formik.setFieldValue(field, time);
    if (field === "start_time" || field === "end_time") {
      const formattedTime = moment(time, "HH:mm").format("HH:mm");
      const startTime = (field === "start_time") ? formattedTime : formik.values.start_time;
      const endTime = (field === "end_time") ? formattedTime : formik.values.end_time;

      if (startTime && endTime) {
        const shiftHours = calculateShiftHours(startTime, endTime, Number(formik.values.total_unpaid_break));
        setShiftHours(shiftHours);
      }
    }
  };

  const handleEventDrop = async (event: any, newDate: any, locationData: any) => {
    if (event.class_name === "finished" || event.class_name === "archived" || event.status != 1 || event.status_name === "CLOCKED IN") {
      // arg.revert();
    } else {
      try {
        const postdata = {
          id: event.id,
          date: newDate?.value,
          location_id: locationData.location_id
        };
        const response = await updateScheduleShift(postdata);
        if (response.status === 1) {
          toast.success(response.message);
          fetchEventsFromAPI();
        } else {
          toast.error(response.message);
          // arg.revert();
          return;
        }
      } catch (error) {
        console.error("Error updating event:", error);
      }
    }
  };
  /*------------------------------Formik End----------------------------------*/




  const copyTemplateValidationSchema = Yup.object().shape({
    selected_weeks: Yup.array().of(Yup.string()).required("Select at least one week"),
    is_shift_notes: Yup.number().oneOf([0, 1]).required(),
    is_admin_notes: Yup.number().oneOf([0, 1]).required(),
    is_remove_staff: Yup.number().oneOf([0, 1]).required(),
    selected_days: Yup.array().of(Yup.string()).required("Select at least one day"),
  });

  // const [currentStep, setStep] = useState(1);
  // const [selectedWeeks, setSelectedWeeks] = useState<string[]>([]);
  // const [openCopyTemplateModal, setOpenCopyTemplateModal] = useState<boolean>(false);
  // const [isRemoveStaff, setIsRemoveStaff] = useState(false);
  // const [isShiftNotes, setIsShiftNotes] = useState(false);
  // const [isAdminNotes, setIsAdminNotes] = useState(false);
  // const [selectedDays, setSelectedDays] = useState<string[]>([]);
  // const addedClientRef = useRef<HTMLDivElement | null>(null);
  // const locationDropdownRef = useRef<HTMLDivElement | null>(null);

  // const copyTemplateFormik = useFormik({
  //   enableReinitialize: true,
  //   initialValues: {
  //     'selected_weeks': [],
  //     'is_shift_notes': 0,
  //     'is_admin_notes': 0,
  //     'is_remove_staff': 0,
  //     'selected_days' : []
  //   },
  //   validationSchema: copyTemplateValidationSchema,
  //   onSubmit: async (values) => {
  //     try {
  //       const client: any = selectedClient?.value;

  //       const formattedStart = moment(startDate).format("YYYY-MM-DD");
  //       const formattedEnd = moment(endDate).format("YYYY-MM-DD");

  //       const payload = {
  //         ...values,
  //         selected_client: checkedLocations,
  //         start_date: formattedStart,
  //         end_date: formattedEnd,
  //       }
  //       const response = await copyWeekTemplate(payload);
  //       console.log("response",response)
  //       if (response.status === 1) {
  //         toast.success(response.message);
  //         setOpenTemplateModal(false);
  //       } else {
  //         toast.error(response.message);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       toast.error("Something went wrong");
  //     }
  //   }
  // });

  const [currentStep, setStep] = useState(1);
  const [selectedWeeks, setSelectedWeeks] = useState<string[]>([]);
  const [openCopyTemplateModal, setOpenCopyTemplateModal] = useState<boolean>(false);
  const [isRemoveStaff, setIsRemoveStaff] = useState(false);
  const [isShiftNotes, setIsShiftNotes] = useState(false);
  const [isAdminNotes, setIsAdminNotes] = useState(false);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const addedClientRef = useRef<HTMLDivElement | null>(null);
  const locationDropdownRef = useRef<HTMLDivElement | null>(null);

  const copyTemplateFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      selected_weeks: [],
      is_shift_notes: 0,
      is_admin_notes: 0,
      is_remove_staff: 0,
      selected_days: [],
    },
    validationSchema: copyTemplateValidationSchema,
    onSubmit: async (values) => {
      try {
        // Validation for required fields
        if (selectedWeeks.length === 0) {
          toast.error("Please select at least one week to proceed.");
          return;
        }
        if (selectedDays.length === 0) {
          toast.error("Please select at least one day to proceed.");
          return;
        }

        const formattedStart = moment(startDate).format("YYYY-MM-DD");
        const formattedEnd = moment(endDate).format("YYYY-MM-DD");

        const parseWeekRange = (weekRange: string) => {
          const [startDateStr, endDateStr] = weekRange.split(" – ");
          const hasMonth = /\b[A-Za-z]{3}\b/.test(endDateStr);
          const startDate = moment(startDateStr + ", 2025", "MMM D, YYYY");
          const endDate = hasMonth
            ? moment(endDateStr + ", 2025", "D MMM, YYYY")
            : moment(startDate.format("MMM") + " " + endDateStr + ", 2025", "MMM D, YYYY");

          return startDate.isValid() && endDate.isValid()
            ? `${startDate.format("YYYY-MM-DD")} - ${endDate.format("YYYY-MM-DD")}`
            : "";
        };

        const formattedWeeks = selectedWeeks.map((week) => parseWeekRange(week));
        const transformedClients = checkedLocations.map((client: ClientLocation) => ({
          client_id: typeof client.clientValue === "number" ? client.clientValue : 0,
          location_ids: Array.isArray(client.locations)
            ? client.locations
              .filter((loc: any) => loc && loc.value !== undefined)
              .map((loc: any) => loc.value)
            : [],
        }));

        const payload = {
          ...values,
          selected_client: transformedClients,
          start_date: formattedStart,
          end_date: formattedEnd,
          selected_weeks: formattedWeeks,
          selected_days: selectedDays,
          is_shift_notes: isShiftNotes ? 1 : 0,
          is_admin_notes: isAdminNotes ? 1 : 0,
          is_remove_staff: isRemoveStaff ? 1 : 0,
        };

        // console.log("payload", payload);

        const response = await copyWeekTemplate(payload);
        // console.log("response", response);

        if (response.status === 1) {
          toast.success(response.message);
          setOpenCopyTemplateModal(false);
          resetForm();
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error(error);
        toast.error("Something went wrong");
      }
    },
  });

  // console.log("selected client",eventList)


  const steps = [
    {
      id: 1,
      title: "Select Weeks",
    },
    {
      id: 2,
      title: "Select Client & Location",
    },
  ];

  useEffect(() => {
    if (addedClientRef.current) {
      addedClientRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    if (locationDropdownRef.current) {
      locationDropdownRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [allSelectedLocations]);


  const handleNext = () => {
    setStep((prevStep) => Math.min(prevStep + 1, steps.length)); // Increment step but max is the number of steps
  };

  const handlePrevious = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 1)); // Decrement step but min is 1
  };

  const resetForm = () => {
    setSelectedWeeks([]);
    setIsRemoveStaff(false);
    setIsShiftNotes(false);
    setIsAdminNotes(false);
    setSelectedDays([]);
    setStep(1);
    clearSelectedClients();
  };

  const handleFinishOrCancel = () => {
    resetForm();
    setOpenCopyTemplateModal(false);
    setStep(1);
  };

  const generateNextWeeks = (numWeeks: number) => {
    const weeks = [];

    let nextMonday = startDate.clone().add(1, 'week');

    for (let i = 0; i < numWeeks; i++) {
      const weekStart = nextMonday.clone();
      const weekEnd = nextMonday.clone().add(6, 'days');

      const formattedWeek = `${weekStart.format("MMM D")} – ${weekEnd.format("D MMM, YYYY")}`;

      weeks.push(formattedWeek);

      nextMonday.add(1, 'week');
    }

    return weeks;
  };

  const totalWeeks = generateNextWeeks(15);

  const handleSelectAll = (isChecked: any) => {
    if (isChecked) {
      setSelectedWeeks(totalWeeks);
    } else {
      setSelectedWeeks([]);
    }
  };

  const handleClearAll = (isChecked: any) => {
    if (isChecked) {
      setSelectedWeeks([]);
    }
  };

  const handleWeekSelection = (week: string) => {
    setSelectedWeeks((prev) =>
      prev.includes(week) ? prev.filter((w) => w !== week) : [...prev, week]
    );
  };

  const isAllSelected = selectedWeeks.length === totalWeeks.length;
  const isClearAll = selectedWeeks.length === 0;



  const days = [
    { label: "Monday", value: "Mon" },
    { label: "Tuesday", value: "Tue" },
    { label: "Wednesday", value: "Wed" },
    { label: "Thursday", value: "Thu" },
    { label: "Friday", value: "Fri" },
    { label: "Saturday", value: "Sat" },
    { label: "Sunday", value: "Sun" },
  ];

  const toggleDay = (dayValue: string) => {
    setSelectedDays((prev) =>
      prev.includes(dayValue)
        ? prev.filter((day) => day !== dayValue)
        : [...prev, dayValue]
    );
  };

  const handleSelectAllDays = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedDays(days.map((day) => day.value));
    } else {
      setSelectedDays([]);
    }
  };

  const handleClearAllDays = () => {
    setSelectedDays([]);
  };


  // const handleClientSelection = (clientValue: string) => {
  //   setSelectedClients(prevState => 
  //     prevState.includes(clientValue)
  //       ? prevState.filter(client => client !== clientValue) // Unselect
  //       : [...prevState, clientValue] // Select
  //   );
  // };

  // // Handle Location Selection
  // const handleLocationChange = (location: string) => {
  //   setSelectedLocations(prevState =>
  //     prevState.includes(location)
  //       ? prevState.filter(loc => loc !== location) // Unselect
  //       : [...prevState, location] // Select
  //   );
  // };

  const fetchClientListCopy = async (searchValue: string) => {
    try {
      const response = await getScheduleClientList(searchValue);
      const clients = response.data.map((client: { value: any; label: any; }) => ({
        value: client.value,
        label: client.label,
      }));
      setClientList(clients);

      const storedClients = JSON.parse(localStorage.getItem("selectedClients") || "[]");
      setSelectedClients(storedClients);

      if (storedClients.length > 0) {
        await updateLocationList(storedClients); // Fetch locations for pre-selected clients
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateLocationList = async (clientValues: any[]) => {
    try {
      let allLocations: any[] | ((prevState: DropdownData[]) => DropdownData[]) = [];
      for (const client of clientValues) {
        const response = await getLocationDropdownList(client, locationSearch);
        allLocations = [...allLocations, ...response.data];
      }
      setFilterLocationList(allLocations);
    } catch (error) {
      console.error(error);
    }
  };

  // console.log("all locatiopns",filterLocationList)

  // const handleClientSelection = async (clientValue: string) => {
  //   const updatedClients = selectedClients.includes(clientValue)
  //     ? selectedClients.filter((val) => val !== clientValue)
  //     : [...selectedClients, clientValue];

  //   setSelectedClients(updatedClients);
  //   localStorage.setItem("selectedClients", JSON.stringify(updatedClients));
  //   await updateLocationList(updatedClients);
  // };

  // const handleLocationChange = (locationValue: number) => {
  //   setSelectedLocations((prev) => {
  //     if (prev.some((loc) => loc.value === locationValue)) {
  //       // If location is already selected, remove it
  //       return prev.filter((loc) => loc.value !== locationValue);
  //     } else {
  //       // Add location to the selected locations list
  //       const selectedLocation = filterLocationList.find((loc) => loc.value === locationValue);
  //       if (selectedLocation) {
  //         return [...prev, selectedLocation];
  //       }
  //       return prev;
  //     }
  //   });
  // };

  useEffect(() => {
    fetchClientListCopy(clientSearch);

  }, [clientSearch]);

  const clearSelectedClients = () => {
    setSelectedClients([]); // Clear all selected clients
    setAllSelectedLocations(initialLocationsRef.current.allSelectedLocations);
    setCheckedLocations(initialLocationsRef.current.checkedLocations);


    // Keep only the first client’s locations in allSelectedLocations
    setAllSelectedLocations((prev) => {
      if (prev.length > 0) {
        const firstClientLocations = prev[0]; // Get the locations for the first client
        return [firstClientLocations]; // Return only the first client’s locations
      }
      return []; // If no clients, return an empty array
    });
  };

  useEffect(() => {
    clearSelectedClients()
  }, [selectedClient]);


  useEffect(() => {
    if (
      selectedClient &&
      !selectedClients.some((client) => client.value === selectedClient.value)
    ) {
      setSelectedClients((prev) => [...prev, selectedClient]);
    }
  }, [selectedClient, openCopyTemplateModal]);

  useEffect(() => {
    // Pre-check the first client's locations when data is loaded
    if (allSelectedLocations.length > 0 && checkedLocations.length === 0) {
      setCheckedLocations([
        {
          ...allSelectedLocations[0],
          locations: [...allSelectedLocations[0].locations],
        },
      ]);
    }
  }, [allSelectedLocations, openCopyTemplateModal]);

  // const handleLocationChange = (client, location, isChecked) => {
  //   setCheckedLocations((prev) => {
  //     const updated = prev.map((locGroup) =>
  //       locGroup.clientValue === client.clientValue
  //         ? {
  //             ...locGroup,
  //             locations: isChecked
  //               ? [...locGroup.locations, location]
  //               : locGroup.locations.filter((loc) => loc.value !== location.value),
  //           }
  //         : locGroup
  //     );

  //     // Add client to checkedLocations if not already there
  //     if (isChecked && !updated.some((locGroup) => locGroup.clientValue === client.clientValue)) {
  //       updated.push({ ...client, locations: [location] });
  //     }

  //     // Filter out clients with no checked locations
  //     return updated.filter((locGroup) => locGroup.locations.length > 0);
  //   });
  // };



  // const fetchLocationsAPI = async () => {
  //   try {
  //     if (selectedClients && selectedClients.length > 0) {
  //       // Assuming client.value is a number
  //       const clientValues: number[] = selectedClients.map((client: any) => client.value); // Ensure client.value is a number

  //       // Call the API for each selected client
  //       const locations = await getLocationDropdownList(clientValues, locationSearch);

  //       // Assuming locations.data contains the list of locations
  //       setSelectedLocations(locations?.data);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchLocationsFromAPI = async (client: any) => {
    try {

      // Fetch new locations
      const locations = await getLocationDropdownList(client, locationSearch);

      // Concatenate the previous data with the new data
      setAllSelectedLocations((prev: any) => {
        // Check if the clientValue already exists in the state
        const isDuplicate = prev.some(
          (item: any) => item.clientValue === client.value
        );

        // If the clientValue is not a duplicate, add the new data
        if (!isDuplicate) {
          return [
            ...prev,
            {
              clientName: client.label,
              clientValue: client.value,
              locations: locations?.data || [],
            },
          ];
        }

        // If it's a duplicate, just return the previous state
        return prev;
      });

      setCheckedLocations((prev: any) => {
        // Check if the clientValue already exists in the state
        const isDuplicate = prev.some(
          (item: any) => item.clientValue === client.value
        );

        // If the clientValue is not a duplicate, add the new data
        if (!isDuplicate) {
          return [
            ...prev,
            {
              clientName: client.label,
              clientValue: client.value,
              locations: locations?.data || [],
            },
          ];
        }

        // If it's a duplicate, just return the previous state
        return prev;
      });

      // Set loading to false once the data is fetched
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  //   const fetchLocationsFromAPI = async (client: any) => {
  //   try {
  //     console.log("client before", client);

  //     // Fetch locations for the selected client
  //     const locations = await getLocationDropdownList(client, locationSearch);
  //     console.log("locations after", locations);

  //     // Update selectedLocations without removing the previous data
  //     setSelectedLocations((prev) => {
  //       // Check if the client already exists in selectedLocations
  //       const existingClient = prev.find((loc) => loc.value === client.value);

  //       if (existingClient) {
  //         // If the client already exists, update its location data
  //         return prev.map((loc) => {
  //           if (loc.value === client.value) {
  //             return {
  //               ...loc,
  //               locations: locations?.data || [], // Update with the new locations
  //             };
  //           }
  //           return loc; // Keep other clients' data unchanged
  //         });
  //       } else {
  //         // If the client doesn't exist, add the new client and locations
  //         return [
  //           ...prev,
  //           {
  //             value: client.value,
  //             label: client.label, // Use the client's label from the object
  //             locations: locations?.data || [], // Set the fetched locations
  //           },
  //         ];
  //       }
  //     });

  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };



  /*Shift Template Start */
  const templateValidationSchema = Yup.object().shape({
    template_name: Yup.string().required("Please enter a template name"),
  });

  const [template_id, setTemplateId] = useState<number>(0);
  const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false);
  const [openLoadTemplateModal, setOpenLoadTemplateModal] = useState<boolean>(false);
  const [savingTemplateShifts, setSavingTemplateShifts] = useState<boolean>(false);
  const [submitTemplateResponse, setSubmitTemplateResponse] = useState<any>(null);
  const [templateData, setTemplateData] = useState<any>([]);
  const templateFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      'template_name': '',
      'description': '',
      'is_shift_notes': 0,
      'is_admin_notes': 0,
      'is_remove_staff': 0
    },
    validationSchema: templateValidationSchema,
    onSubmit: async (values) => {
      try {
        const client: any = selectedClient?.value;

        const formattedStart = moment(startDate).format("YYYY-MM-DD");
        const formattedEnd = moment(endDate).format("YYYY-MM-DD");

        const payload = {
          ...values,
          client_id: client,
          start_date: formattedStart,
          end_date: formattedEnd,
        }
        const response = await saveShiftTemplate(payload);
        if (response.status === 1) {
          toast.success(response.message);
          setOpenTemplateModal(false);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
      }
    }
  });



  const loadShiftTemplateFunc = async () => {
    try {
      const client: any = selectedClient?.value;
      const payload = {
        client_id: client
      }
      const response = await loadShiftTemplate(payload);
      if (response.status === 1) {
        setTemplateData(response.data.data_list)
        if (response.data.data_list.length > 0 && response.data.data_list[0].id) {
          setTemplateId(response.data.data_list[0].id);
        }
        setOpenLoadTemplateModal(true);
        setSubmitTemplateResponse(null);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  }

  const submitShiftTemplateFunc = async () => {
    try {
      setSavingTemplateShifts(true);
      const formattedStart = moment(startDate).format("YYYY-MM-DD");
      const formattedEnd = moment(endDate).format("YYYY-MM-DD");

      const payload = {
        template_id,
        start_date: formattedStart,
        end_date: formattedEnd,
      }
      const response = await submitShiftTemplate(payload);
      if (response.status === 1) {
        toast.success(response.message);
        fetchEventsFromAPI();
        setSubmitTemplateResponse(response.data);
        // setOpenLoadTemplateModal(false);
        setSavingTemplateShifts(false);
      } else {
        setSavingTemplateShifts(false);
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
      setSavingTemplateShifts(false);
      toast.error("Something went wrong");
    }
  }

  const [archiveTemplateId, setArchiveTemplateId] = useState<number | undefined>(undefined);

  const handleArchiveShiftTemplate = async (id: number): Promise<void> => {
    setArchiveTemplateId(id);
    setmodal_center(true);
  };

  const [modal_center, setmodal_center] = useState<boolean>(false);
  function tog_center() {
    setmodal_center(!modal_center);
  }


  const [archiveLoader, setArchiveLoader] = useState(false)

  const archiveShiftTemplate = async (id: number) => {
    setArchiveLoader(true)
    try {
      const response = await ShiftTemplateArchiveById(id);
      toast.success(response.message);
      setArchiveLoader(false)
      setmodal_center(false)
      loadShiftTemplateFunc()
    } catch (error) {
      toast.error("Error Archive Please try again later.");
    }
  };
  /*Shift Template End */

  const [openDutyReportModal, setOpenDutyReportModal] = useState<boolean>(false);
  const [locations, setLocations] = useState<any[]>([]);

  const handleClientChange = async (client: number) => {

    try {
      // Call the API to fetch locations for the selected client
      const locationResp = await getLocationDropdownList(client, locationSearch); // Pass clientId and locationSearch
      setLocations(locationResp.data)
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const reportDutyTemplateFormik = useFormik({
    initialValues: {
      start_date: "",
      end_date: "",
      client_name: "",
      location_name: [],
    },
    enableReinitialize: true, // Ensure reinitialization
    validationSchema: Yup.object().shape({
      start_date: Yup.string().required("Start date is required"),
      end_date: Yup.string()
        .required("End date is required")
        .test("date-order", "End date must be after start date", function (value) {
          return new Date(value!) > new Date(this.parent.start_date);
        }),
      client_name: Yup.string().required("Client is required"),
      location_name: Yup.array()
        .min(1, "At least one location must be selected")
        .required("Locations are required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const payload = {
        start_date: values.start_date,
        end_date: values.end_date,
        client_id: parseInt(values.client_name, 10),
        location_id: values.location_name.map(id => `${id}`),
      };

      try {
        const response = await dutyReportTemplate(payload);

        reportDutyTemplateFormik.resetForm(); // Reset after successful download
        setOpenDutyReportModal(false); // Close modal
      } catch (error) {
        console.error(error);

      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleModalClose = () => {

    reportDutyTemplateFormik.resetForm(); // Reset values
    setOpenDutyReportModal(false); // Close modal
  };


  const createShiftShow = (date: any, location: DropdownData) => {
    formik.resetForm();
    setSelectedStaffName("");
    setEditId(null);
    setActiveTab('home1');
    setIsFinishShift(false);
    setIsPublished(false)
    setShiftHours(9);

    // Check if pasteMode is true or false
    if (pasteMode) {
      // Call paste_standard logic if pasteMode is true
      paste_standard();
    } else {
      // Standard logic when pasteMode is false
      formik.setFieldValue("date", date);
      formik.setFieldValue("client_id", selectedClient?.value);
      formik.setFieldValue("location_id", selectedLocation?.value);
      setIsShiftCanvasOpen(true);
      setShiftClockIn('');
      setShiftClockOut('');
      setSelectedLocation(location);
    }
  };
  ;

  const handleEventClick = async (event: any) => {
    try {
      // Check if it's a pasted event
      if (event.copy_from) {
        // Handle pasted shift locally
        console.log("Pasted event details:", event);

        if (event.location_id) {
          console.log("Fetching staff list for location:", event.location_id);
          await fetchStaffList(event.location_id, "");  // Pass the location_id and searchValue (empty string in this case)
        } else {
          console.log("Location ID is missing in the event details.");
        }

        // Assuming that pasted events share the same structure, update the state with the pasted event details
        // setSelectedStaffName(event.staff_name);
        setCreatedBy(event.created_by);
        setEditId(event.id);
        setShiftClockIn(event.start);  // Adjust if your pasted event has a different clock-in structure
        setShiftClockOut(event.end);   // Adjust if your pasted event has a different clock-out structure
        setIs_petrol_site(event.is_petrol_site);
        setSelectedLocation({ "label": event.location_name, "value": event.location_id });

        const formattedStartTime = moment(event.start).format("HH:mm");
        const formattedEndTime = moment(event.end).format("HH:mm");
        const formattedDate = moment(event.date).format("DD-MM-YYYY");

        // Set the form values for pasted event
        formik.setValues({
          ...formik.values,
          date: formattedDate,
          start_time: formattedStartTime,
          end_time: formattedEndTime,
          staff_id: event.staff_id,
          location_id: event.location_id,
          shift_notes: event.shift_notes,
          // Add other fields as necessary
        });

        setIsShiftCanvasOpen(true);
        setActiveTab('home1');
        setIsFinishShift(event.is_finished || event.is_archive);
        setIsPublished(event.is_published)
        return;  // Exit early to avoid the API call
      }

      // If not a pasted event, proceed with API call as usual
      const response = await getShiftDetail(event.id);
      const history = await ShiftHistoryList(event.id);
      const historyData = history?.data ? history.data : null;
      setShiftHistoryDetails(historyData);

      if (response.status === 1) {
        const event_data = response.data;
        setIs_petrol_site(event_data?.is_petrol_site);
        setSelectedStaffName(event.staff_name);
        setCreatedBy(event_data?.created_by);
        setEditId(event.id);
        editMaster(event_data.staff_id);
        setShiftClockIn(event_data.clock_in_time);
        setShiftClockOut(event_data.clock_out_time);
        fetchStaffDocumentDetails(event_data.staff_id);
        setIsAccept(event_data.is_accept);
        setActiveTab('home1');
        setIsShiftCanvasOpen(true);
        setSelectedLocation({ "label": event.location_name, "value": event.location_id });

        const formattedStartTime = moment(event_data.start_time).format("HH:mm");
        const formattedEndTime = moment(event_data.end_time).format("HH:mm");
        const formattedDate = moment(event_data.date).format("DD-MM-YYYY");
        const formattedClockinTime = event_data.clock_in_time
          ? moment(event_data.clock_in_time).format("HH:mm")
          : "";
        const formattedClockoutTime = event_data.clock_out_time
          ? moment(event_data.clock_out_time).format("HH:mm")
          : "";

        const startTime = moment(event_data.start_time, "YYYY-MM-DDTHH:mm:ss");
        const endTime = moment(event_data.end_time, "YYYY-MM-DDTHH:mm:ss");
        const duration = moment.duration(endTime.diff(startTime));
        const hours = parseFloat(duration.asHours().toFixed(2));

        setShiftHours(hours);
        formik.setValues({
          ...formik.values,
          date: formattedDate,
          staff_id: event_data.staff_id,
          start_time: formattedStartTime,
          end_time: formattedEndTime,
          clock_in_time: formattedClockinTime,
          clock_out_time: formattedClockoutTime,
          clock_in_latitude: event_data.clock_in_latitude,
          clock_in_longitude: event_data.clock_in_longitude,
          total_unpaid_break: event_data.total_unpaid_break,
          total_paid_break: event_data.total_paid_break,
          shift_notes: event_data.shift_notes,
          admin_note: event_data.admin_note,
          is_finished: event_data.is_finished,
          client_id: selectedClient?.value,
          location_id: selectedLocation?.value,
          is_published: event_data.is_published,
        });
        setIsFinishShift(event_data.is_finished || event_data.is_archive);
        setIsPublished(event_data.is_published)
      } else {
        toast.error(response.message);
        return;
      }
    } catch (error) {
      console.error("Error fetching event details:", error);
    }
  };


  const [StaffDocumentDetail, setStaffDocumentDetail] = useState<
    any[]
  >([]);

  const fetchStaffDocumentDetails = async (id: any) => {
    try {
      if (!id) return;
      const response = await getStaffDocumentDetail("", id);
      setStaffDocumentDetail(response.data.data_list);
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpenDocument = (path: string) => {
    const fullPath = `${doc_url}/${path}`;
    window.open(fullPath, '_blank');
  };

  // console.log("list of locations",allSelectedLocations)

  const editMaster = async (id: any) => {
    try {
      if (id) {
        const response = await getByStaffDetail(id);
        const data = response.data;
        setStaffViewDetail(data);
      }
    } catch (error) {
      return;
    }
  };

  const handleArchiveShift = async (id: number): Promise<void> => {
    setArchiveId(id);
    setArchiveModal(true);
  };

  const archiveShift = async (id: number) => {
    try {
      const response = await archiveShiftDetail(id);
      fetchEventsFromAPI();
      toast.success(response.message);
      setArchiveModal(false);
      setIsShiftCanvasOpen(false);
    } catch (error) {
      console.error("Error Archive Holday:", error);
      toast.error("Error Archive Please try again later.");
    }
  };

  const downloadPatrolInfo = async () => {
    try {
      await exportPatrolLocationInfoDetail(
        editId ? editId : 0
      );
    } catch (error) {
      console.error("Error downloading Patrol Info:", error);
      toast.error("Error downloading Patrol Info. Please try again later.");
    }
  }

  const fetchStaffList = async (location: any, searchValue: string) => {
    try {
      const response = await getScheduleStaffList(location?.value, searchValue);
      setStaffList(response.data);
    } catch (error) {
      console.log(error);

    }
  };
  useEffect(() => {
    if (selectedLocation) {
      fetchStaffList(selectedLocation, staffSearch);
    }
  }, [selectedLocation]);



  // Calculate end date of the week (startDate + 6 days)
  const endDate = moment(startDate).add(6, "days");

  // Function to go to the previous week
  const handlePrevWeek = () => {
    if (pasteMode) {
      paste_standard()
    } else {
      setStartDate(moment(startDate).subtract(7, "days"));
    }
  };

  // Function to go to the next week
  const handleNextWeek = () => {
    if (pasteMode) {
      paste_standard()
    } else {
      setStartDate(moment(startDate).add(7, "days"));
    }
  };

  const handleCurrentWeek = () => {
    if (pasteMode) {
      paste_standard()
    } else {
      setStartDate(moment().startOf('week'));
    }
  };

  useEffect(() => {
    fetchEventsFromAPI();
  }, [selectedClient, startDate, isClear]);

  const handleRefresh = async () => {
    setLoading(true);
    try {
      await fetchEventsFromAPI();
    } catch (error) {
      console.error("Error fetching events:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchClientList(clientSearch);
  }, [clientSearch]);

  /*---------------------------MODAL HANDLER START---------------------------------*/
  const [publishModal, setPublishModal] = useState<boolean>(false);
  const [expLoader, setExpLoader] = useState(false)
  const [publishData, setPublishData] = useState<{ dates: PublishDateItem[], staff_list: PublishStaffItem[], location_list: PublishLocationItem[] }>({ dates: [], staff_list: [], location_list: [] });
  const [isDateAllChecked, setIsDateAllChecked] = useState(false);
  const [checkedPublishDates, setCheckedPublishDates] = useState<string[]>([]);
  const [publishModalForFinished, setPublishModalForFinished] = useState<boolean>(false);

  // Update isChecked state based on the checkedItems array
  useEffect(() => {
    setIsDateAllChecked(checkedPublishDates.length === publishData.dates.length);
  }, [checkedPublishDates, publishData.dates.length]);

  // Handle "Select All" and "Deselect All"
  const handleDateCheckAll = () => {
    if (!isDateAllChecked) {
      setCheckedPublishDates(publishData.dates.map(dateItem => dateItem.date));
    } else {
      setCheckedPublishDates([]);
    }
    setIsDateAllChecked(!isDateAllChecked);
  };

  // Handle individual checkbox changes
  const handleDateCheckboxChange = (date: string) => {
    setCheckedPublishDates(prev =>
      prev.includes(date)
        ? prev.filter(item => item !== date)
        : [...prev, date]
    );
  };

  const [isStaffAllChecked, setIsStaffAllChecked] = useState(false);
  const [checkedPublishStaff, setCheckedPublishStaff] = useState<string[]>([]);

  // Update isChecked state based on the checkedItems array
  useEffect(() => {
    setIsStaffAllChecked(checkedPublishStaff.length === publishData.staff_list.length);
  }, [checkedPublishStaff, publishData.staff_list.length]);

  // Handle "Select All" and "Deselect All"
  const handleStaffCheckAll = () => {
    if (!isStaffAllChecked) {
      setCheckedPublishStaff(publishData.staff_list.map(staffItem => staffItem.staff_id));
    } else {
      setCheckedPublishStaff([]);
    }
    setIsStaffAllChecked(!isStaffAllChecked);
  };

  // Handle individual checkbox changes
  const handleStaffCheckboxChange = (staff_id: string) => {
    setCheckedPublishStaff(prev =>
      prev.includes(staff_id)
        ? prev.filter(item => item !== staff_id)
        : [...prev, staff_id]
    );
  };

  const [isLocationAllChecked, setIsLocationAllChecked] = useState(false);
  const [checkedPublishLocation, setCheckedPublishLocation] = useState<string[]>([]);

  // Update isChecked state based on the checkedItems array
  useEffect(() => {
    setIsLocationAllChecked(checkedPublishLocation.length === publishData.location_list.length);
  }, [checkedPublishLocation, publishData.location_list.length]);

  // Handle "Select All" and "Deselect All"
  const handleLocationCheckAll = () => {
    if (!isLocationAllChecked) {
      setCheckedPublishLocation(publishData.location_list.map(locationItem => locationItem.location_id));
    } else {
      setCheckedPublishLocation([]);
    }
    setIsLocationAllChecked(!isLocationAllChecked);
  };

  // Handle individual checkbox changes
  const handleLocationCheckboxChange = (location_id: string) => {
    setCheckedPublishLocation(prev =>
      prev.includes(location_id)
        ? prev.filter(item => item !== location_id)
        : [...prev, location_id]
    );
  };

  const getPublishDetail = async () => {
    setExpLoader(true);
    try {
      const client: any = selectedClient?.value;
      const formattedStart = moment(startDate).format("YYYY-MM-DD");
      const formattedEnd = moment(endDate).format("YYYY-MM-DD");

      const payload = {
        client_id: client,
        start_date: formattedStart,
        end_date: formattedEnd,
      };

      if (pasteMode) {
        paste_standard();
      } else {
        const response = await getSchedulePublishDetailWithLocation(payload);
        if (response.status === 1) {
          setPublishData(response.data);
          const res_date: PublishDateItem[] = response.data.dates;
          const res_staff: PublishStaffItem[] = response.data.staff_list;
          const res_location: PublishLocationItem[] = response.data.location_list;
          setCheckedPublishDates(res_date.map(dateItem => dateItem.date));
          setCheckedPublishStaff(res_staff.map(staff => staff.staff_id));
          setCheckedPublishLocation(res_location.map(location => location.location_id));
          setPublishModal(true);
          setPublishModalForFinished(false);
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    } finally {
      setExpLoader(false);
    }
  };


  const [publishLoader, setPublishLoader] = useState(false)

  const handlePublishShifts = async () => {
    setPublishLoader(true)
    try {
      const client: any = selectedClient?.value;

      const payload = {
        client_id: client,
        dates: checkedPublishDates,
        staff_ids: checkedPublishStaff,
        location_ids: checkedPublishLocation,
      }
      const response = publishModalForFinished ? await finishedBulkShift(payload) : await publishShiftsWithLocation(payload);
      if (response.status === 1) {
        toast.success(response.message);
        setPublishModal(false);
        fetchEventsFromAPI();
        setPublishLoader(false)
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    } finally {
      setPublishLoader(false)
    }
  };

  /*---------------------------MODAL HANDLER END-------------------------------- */

  const bulkShiftFinishAction = async () => {
    setExpLoader(true);
    try {
      const client: any = selectedClient?.value;
      const formattedStart = moment(startDate).format("YYYY-MM-DD");
      const formattedEnd = moment(endDate).format("YYYY-MM-DD");

      const payload = {
        client_id: client,
        start_date: formattedStart,
        end_date: formattedEnd,
      };

      if (pasteMode) {
        paste_standard();
      } else {
        const response = await getSchedulePublishShiftList(payload);
        if (response.status === 1) {
          setPublishData(response.data);
          const res_date: PublishDateItem[] = response.data.dates;
          const res_staff: PublishStaffItem[] = response.data.staff_list;
          const res_location: PublishLocationItem[] = response.data.location_list;
          setCheckedPublishDates(res_date.map(dateItem => dateItem.date));
          setCheckedPublishStaff(res_staff.map(staff => staff.staff_id));
          setCheckedPublishLocation(res_location.map(location => location.location_id));
          setPublishModal(true);
          setPublishModalForFinished(true);
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    } finally {
      setExpLoader(false);
    }
  };


  const exportExcel = async () => {
    try {
      const client: any = selectedClient?.value;
      const locationIds: any = selectedFilterLocation.map((location: any) => location.value);
      const locationIdsCSV = locationIds.join(',');
      const formattedStart = moment(startDate).format("YYYY-MM-DD");
      const formattedEnd = moment(endDate).format("YYYY-MM-DD");

      await ExportShift(client, locationIdsCSV, formattedStart, formattedEnd);
    } catch (error) {
      console.log(error);
    }
  };

  const checkpointlog = () => {
    window.location.href = '/checkpoint-log';
  };
  /* New End */

  const [pasteMode, setPasteMode] = useState(false);
  const childRef = useRef<any>(null);
  const callRemovePasteData = () => {
    if (childRef.current) {
      childRef.current.triggerRemovePasteData();
    }
  };

  const callSavePasteData = async () => {
    if (childRef.current) {
      const formattedEvents = childRef.current.triggerSavePasteData();
      try {
        const response = await pasteScheduleShift(formattedEvents);
        if (response.status === 1) {
          toast.success(response.message);
          fetchEventsFromAPI();
          callRemovePasteData()
        } else {
          toast.error(response.message);
          return;
        }
      } catch (error) {
        toast.error("Error sending data. Please try again later.");
        console.error("API Error:", error);
      }
    }
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Schedule" pageTitle="Dashboard" />
            {
              loading ?
                <Row className="placeholder-glow">
                  <Col md={3} lg={2}>
                    <div className="placeholder bg-light table-placeholder col-12"></div>
                    <div className="placeholder bg-light table-placeholder col-12"></div>
                  </Col>
                  <Col md={9} lg={10}>
                    <div className="placeholder bg-light table-placeholder col-12"></div>
                    <div className="placeholder bg-light table-placeholder col-12"></div>
                  </Col>
                </Row>
                :
                <Row className="schedule">
                  <Col lg={12}>
                    <Row>
                      {/* <Col md={3} lg={2}>
                      <Card className="schedule-card">
                        <Card.Body>
                          <Row>
                            <Col lg={12}>
                              <Dropdown className="mb-3 w-100">
                                <Dropdown.Toggle variant="light" className="w-100">
                                  Actions
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {totalShifts > 0 ?
                                    <Dropdown.Item href="#" onClick={() => setOpenTemplateModal(true)}> <i className="ri-download-2-line"></i>  Save Template</Dropdown.Item> : null
                                  }
                                  <Dropdown.Item href="#" onClick={loadShiftTemplateFunc}> <i className="ri-upload-2-line"></i> Load Template</Dropdown.Item>
                                  <Dropdown.Item href="#" onClick={exportExcel}> <i className="ri-file-excel-2-line"></i> Export Excel</Dropdown.Item>
                                  <Dropdown.Item href="#" onClick={checkpointlog}> <i className="ri-login-box-line"></i> Go To Checkpoint</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              <Dropdown className="mb-4">
                                <Dropdown as={ButtonGroup} size="lg" className="w-100">
                                  {expLoader ? (
                                    <Button
                                      color="primary"
                                      // className="clear-btn"
                                      style={{ height: '71.78px', width: '197px' }}
                                      disabled
                                    >
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    </Button>
                                  ) : (
                                    <Button variant="primary" onClick={() => getPublishDetail()}>
                                      <h5 className="text-white">Publish</h5>
                                      <small>{totalUnpublishShifts ?? 0} Shifts Unpublished</small>
                                    </Button>
                                  )}
                                  <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

                                  <Dropdown.Menu>
                                    <Dropdown.Item href="#" onClick={() => getPublishDetail()}>Publish & Notify</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Resend Notifications</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Dropdown>
                            </Col>
                          </Row>
                          <Col lg={12}>
                            <div className="mb-3">
                              <Form.Label
                                htmlFor="choices-multiple-remove-button"
                                className="text-muted"
                              >
                                Client
                              </Form.Label>
                              <Select
                                closeMenuOnSelect={true}
                                value={selectedClient}
                                options={clientList}
                                // styles={customStyles}
                                onChange={(newValue: any) => {
                                  setSelectedClient(newValue);
                                  setSelectedLocation(null)
                                  localStorage.setItem('selectedLocation', JSON.stringify(null));
                                  localStorage.setItem('selectedClient', JSON.stringify(newValue));
                                }}
                                onInputChange={(newValue: string) => setClientSearch(newValue)}
                              />
                            </div>
                          </Col>
                          <div className="mt-4 mb-3">
                            <Card.Title as="h6" className="mb-0">Weekly Report</Card.Title>
                          </div>
                          <ul className="vstack gap-2 list-unstyled">
                            <li>
                              <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="avatar-xs flex-shrink-0 ronded">
                                    <div className="avatar-title bg-body-secondary text-body">
                                      <i className="bi bi-pin-map"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="mb-0">Total Locations</h6>
                                  </div>
                                  <div className="flex-shrink-0 text-muted">
                                    {totalLocation}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="avatar-xs flex-shrink-0 ronded">
                                    <div className="avatar-title bg-body-secondary text-body">
                                      <i className="bi bi-clock"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="mb-0">Total Shifts</h6>
                                  </div>
                                  <div className="flex-shrink-0 text-muted">
                                    {totalShifts}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="avatar-xs flex-shrink-0 ronded">
                                    <div className="avatar-title bg-body-secondary text-body">
                                      <i className="bi bi-pin"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="mb-0">Un-Assigned Shifts</h6>
                                  </div>
                                  <div className="flex-shrink-0 text-muted">
                                    {totalUnassignShifts}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="avatar-xs flex-shrink-0 ronded">
                                    <div className="avatar-title bg-body-secondary text-body">
                                      <i className="bi bi-pin"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="mb-0">UnPublished Shifts</h6>
                                  </div>
                                  <div className="flex-shrink-0 text-muted">
                                    {totalUnpublishShifts ?? 0}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="avatar-xs flex-shrink-0 ronded">
                                    <div className="avatar-title bg-body-secondary text-body">
                                      <i className="bi bi-pin"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="mb-0">Published Shifts</h6>
                                  </div>
                                  <div className="flex-shrink-0 text-muted">
                                    {totalPublishShifts}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="avatar-xs flex-shrink-0 ronded">
                                    <div className="avatar-title bg-body-secondary text-body">
                                      <i className="bi bi-trash3"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="mb-0">Archived Shifts</h6>
                                  </div>
                                  <div className="flex-shrink-0 text-muted">
                                    {totalArchivedShifts ?? 0}
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <Row>
                            <Col md={12}>
                              <Button className="next-btn w-100 mb-2" onClick={() => exportExcel()}>
                                <i className="ri-file-excel-2-line"></i> Export
                              </Button>
                            </Col>
                            <Col md={12}>
                              <Button className="next-btn w-100" onClick={() => checkpointlog()}>
                                <i className="ri-file-excel-2-line"></i> Checkpoint
                              </Button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                      </Col> */}
                      <Col md={12} lg={12}>
                        <Card className="schedule-card mb-1">
                          <Card.Body>
                            <Row>
                              <Col lg={5}>
                                <div className="d-inline-flex">
                                  <Select
                                    closeMenuOnSelect={true}
                                    value={selectedClient}
                                    options={clientList}
                                    styles={{
                                      control: (provided: any, state: any) => ({
                                        ...provided,
                                        height: '40px',
                                        minHeight: '40px',
                                        minWidth: '250px',
                                        borderColor: state.isFocused ? '#ED1B24' : provided.borderColor,
                                        boxShadow: state.isFocused ? '0 0 0 0.2px #ED1B24' : provided.boxShadow,
                                        '&:hover': {
                                          borderColor: state.isFocused ? '#ED1B24' : provided.borderColor,
                                        },
                                      }),
                                      option: (provided: any, state: any) => ({
                                        ...provided,
                                        backgroundColor: state.isSelected ? '#ED1B24' : provided.backgroundColor,
                                        color: state.isSelected ? '#fff' : provided.color,
                                        '&:hover': {
                                          backgroundColor: state.isSelected ? '#404040' : '#ffe9e9',
                                        },
                                      }),
                                      menuList: (provided: any) => ({
                                        ...provided,
                                        '::-webkit-scrollbar': {
                                          width: '6px',
                                        },
                                        '::-webkit-scrollbar-thumb': {
                                          background: 'lightgray',
                                          borderRadius: '3px',
                                        },
                                        '::-webkit-scrollbar-thumb:hover': {
                                          background: 'rgba(0, 0, 0, 0.5)',
                                        },
                                        '::-webkit-scrollbar-track': {
                                          background: 'rgba(0, 0, 0, 0.1)',
                                        },
                                      }),
                                    }}
                                    onChange={async (newValue: any) => {
                                      if (pasteMode) {
                                        paste_standard(); // Handle paste logic if enabled
                                      } else {
                                        // Reset all client-dependent states
                                        setSelectedClient(newValue); // Update the selected client
                                        setSelectedFilterLocation([]); // Clear selected filter locations
                                        setCheckedLocations([]); // Clear checked locations
                                        setAllSelectedLocations([]); // Clear all selected locations
                                        setSelectedLocation(null); // Reset any selected location

                                        // Save the new client to localStorage
                                        localStorage.setItem('selectedClient', JSON.stringify(newValue));

                                        // Fetch locations for the new client
                                        try {
                                          const response = await getLocationDropdownList(newValue.value, "");
                                          setAllSelectedLocations([
                                            {
                                              clientName: newValue.label,
                                              clientValue: newValue.value,
                                              locations: response?.data || [],
                                            },
                                          ]);
                                          setCheckedLocations([
                                            {
                                              clientName: newValue.label,
                                              clientValue: newValue.value,
                                              locations: response?.data || [],
                                            },
                                          ]);
                                        } catch (error) {
                                          console.error("Error fetching locations for the new client:", error);
                                        }
                                      }
                                    }}
                                    onInputChange={(newValue: string) => setClientSearch(newValue)}
                                  />

                                  <div className="d-flex align-items-center ms-2">
                                    <Button variant="primary" onClick={handlePrevWeek} size="sm" className="p-1">
                                      <i className="ri-arrow-left-s-line" style={{ fontSize: "20px" }} />
                                    </Button>

                                    <div className="mx-2" style={{ fontSize: "1rem", fontWeight: "600" }}>
                                      {startDate.format("MMM D")} – {endDate.format("D MMM, YYYY")}
                                    </div>

                                    <Button variant="primary" onClick={handleNextWeek} size="sm" className="p-1">
                                      <i className="ri-arrow-right-s-line" style={{ fontSize: "20px" }} />
                                    </Button>

                                    <Button variant="light" onClick={handleCurrentWeek} className="p-2" style={{ marginLeft: '8px', marginRight: '8px' }}>
                                      Today
                                    </Button>

                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip id="refresh-tooltip">Refresh</Tooltip>}
                                    >
                                      <i
                                        className="ri-restart-line text-danger"
                                        style={{
                                          fontSize: '1.2rem', // Increase the size
                                          cursor: 'pointer', // Add pointer cursor
                                          paddingLeft: "5px"
                                        }}
                                        onClick={handleRefresh}
                                      ></i>
                                    </OverlayTrigger>
                                  </div>

                                </div>
                              </Col>
                              {/* <Col lg={7} className="d-flex justify-content-end">
                                {pasteMode &&
                                  <div className="btn-section">
                                    <Button
                                      variant="primary"
                                      className="p-2"
                                      onClick={callSavePasteData}
                                    >
                                      <i className="ri-check-line align-baseline me-1" style={{ fontWeight: 'bolder' }}></i>
                                    </Button>
                                    <Button
                                      variant="light"
                                      className="p-2"
                                      onClick={callRemovePasteData}
                                    >
                                      <i className="ri-close-line align-baseline me-1" style={{ fontWeight: 'bolder' }}></i>
                                    </Button>
                                  </div>
                                }
                              </Col>
                              <Col lg={5} className="d-flex justify-content-end">
  {!pasteMode ? (
    <div className="btn-section">
      <Button
        className="filter-btn"
        onClick={() => FilterOpen()}
      >
        <i className="ri-list-settings-line align-baseline me-1"></i>{" "}
        Filters
      </Button>
      <Button variant="primary" onClick={() => getPublishDetail()} className="p-2">
        Publish ({totalUnpublishShifts ?? 0})
      </Button>
      <Dropdown className="ms-2">
        <Dropdown.Toggle variant="light" className="w-100">
          Actions
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {totalShifts > 0 ? (
            <Dropdown.Item href="#" onClick={() => setOpenCopyTemplateModal(true)}>
              <i className="ri-file-copy-2-line"></i> Copy Weeks
            </Dropdown.Item>
          ) : null}
          {totalShifts > 0 ? (
            <Dropdown.Item href="#" onClick={() => setOpenTemplateModal(true)}>
              <i className="ri-download-2-line"></i> Save Template
            </Dropdown.Item>
          ) : null}
          <Dropdown.Item href="#" onClick={loadShiftTemplateFunc}>
            <i className="ri-upload-2-line"></i> Load Template
          </Dropdown.Item>
          <Dropdown.Item href="#" onClick={checkpointlog}>
            <i className="ri-login-box-line"></i> Checkpoint Log
          </Dropdown.Item>
          <Dropdown.Item href="#" onClick={() => setOpenDutyReportModal(true)}>
            <i className="ri-file-chart-line"></i> Duty Report
          </Dropdown.Item>
          <Dropdown.Item href="#" onClick={exportExcel}>
            <i className="ri-file-excel-2-line"></i> Export Excel
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  ) : null}
</Col> */}
                              {/* <Col lg={7} className="d-flex justify-content-end">
  <div className="btn-section d-flex gap-2">
    {pasteMode ? (
      <>
        <Button
          variant="primary"
          className="p-2"
          onClick={callSavePasteData}
        >
          <i
            className="ri-check-line align-baseline me-1"
            style={{ fontWeight: "bolder" }}
          ></i>
        </Button>
        <Button
          variant="light"
          className="p-2"
          onClick={callRemovePasteData}
        >
          <i
            className="ri-close-line align-baseline me-1"
            style={{ fontWeight: "bolder" }}
          ></i>
        </Button>
      </>
    ) : null}
  </div>
</Col> */}
                              <Col lg={7} className="d-flex justify-content-end">
                                <div className="btn-section d-flex gap-2">
                                  {pasteMode ? (
                                    <>
                                      <Button
                                        variant="primary"
                                        className="p-2"
                                        onClick={callSavePasteData}
                                      >
                                        <i
                                          className="ri-check-line align-baseline me-1"
                                          style={{ fontWeight: "bolder" }}
                                        ></i>
                                      </Button>
                                      <Button
                                        variant="light"
                                        className="p-2"
                                        onClick={callRemovePasteData}
                                      >
                                        <i
                                          className="ri-close-line align-baseline me-1"
                                          style={{ fontWeight: "bolder" }}
                                        ></i>
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        className="filter-btn"
                                        onClick={() => FilterOpen()}
                                      >
                                        <i className="ri-list-settings-line align-baseline me-1"></i> Filters
                                      </Button>
                                      <Button
                                        variant="primary"
                                        onClick={() => getPublishDetail()}
                                        className="p-2"
                                      >
                                        Publish ({totalUnpublishShifts ?? 0})
                                      </Button>
                                      <Dropdown className="ms-2">
                                        <Dropdown.Toggle variant="light" className="w-100">
                                          Actions
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {totalShifts > 0 && (
                                            <Dropdown.Item
                                              href="#"
                                              onClick={() => setOpenCopyTemplateModal(true)}
                                            >
                                              <i className="ri-file-copy-2-line"></i> Copy Weeks
                                            </Dropdown.Item>
                                          )}
                                          {totalShifts > 0 && (
                                            <Dropdown.Item
                                              href="#"
                                              onClick={() => setOpenTemplateModal(true)}
                                            >
                                              <i className="ri-download-2-line"></i> Save Template
                                            </Dropdown.Item>
                                          )}
                                          <Dropdown.Item href="#" onClick={loadShiftTemplateFunc}>
                                            <i className="ri-upload-2-line"></i> Load Template
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#" onClick={checkpointlog}>
                                            <i className="ri-login-box-line"></i> Checkpoint Log
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            href="#"
                                            onClick={() => setOpenDutyReportModal(true)}
                                          >
                                            <i className="ri-file-chart-line"></i> Duty Report
                                          </Dropdown.Item>
                                          {totalPublishShifts ?
                                            <Dropdown.Item href="#" onClick={bulkShiftFinishAction}> <i className="ri-stack-line"></i> Complete Shifts</Dropdown.Item>
                                            : ''
                                          }

                                          <Dropdown.Item href="#" onClick={exportExcel}>
                                            <i className="ri-file-excel-2-line"></i> Export Excel
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </>
                                  )}
                                </div>
                              </Col>


                              {/* <Col lg={12} className="line"></Col> */}
                              <Col lg={12} className="schedule-table mt-2" style={{ overflow: 'auto' }}>
                                <ScheduleWeekTableNew
                                  ref={childRef}
                                  openCreateShift={createShiftShow}
                                  openShiftDetail={handleEventClick}
                                  eventData={eventList}
                                  eventDates={eventDates}
                                  selectedClient={selectedClient}
                                  handleEventDrop={handleEventDrop}
                                  handlePasteMode={(e: any) => setPasteMode(e)}
                                  handleArchiveShift={handleArchiveShift}
                                />
                              </Col>
                              <Col lg={12}>
                                <Row>
                                  <Col lg={7} className="legends d-flex flex-wrap">
                                    <span className="p-2 rounded">
                                      <i className="ri-checkbox-blank-circle-fill text-secondary align-bottom me-1"></i>
                                      Unassigned ({totalUnassignShifts ?? 0})
                                    </span>
                                    <span className="p-2 rounded">
                                      <i
                                        className="ri-checkbox-blank-circle-fill align-bottom me-1"
                                        style={{ color: "#F0B414" }}
                                      ></i>
                                      Unpublished  ({totalUnpublishShifts ?? 0})
                                    </span>
                                    <span className="p-2 rounded">
                                      <i
                                        className="ri-checkbox-blank-circle-fill align-bottom me-1"
                                        style={{ color: "#3981EC" }}
                                      ></i>
                                      Published  ({totalPublishShifts ?? 0})
                                    </span>
                                    <span className="p-2 rounded">
                                      <i className="ri-checkbox-blank-circle-fill text-success align-bottom me-1"></i>
                                      Finished ({totalFinishedShifts ?? 0})
                                    </span>

                                    <span className="p-2 rounded">
                                      <i
                                        className="ri-checkbox-blank-circle-fill align-bottom me-1"
                                        style={{ color: "#FF6C6C" }}
                                      ></i>
                                      Archived ({totalArchivedShifts ?? 0})
                                    </span>
                                  </Col>
                                  <Col lg={5} className="legends d-flex flex-wrap justify-content-end">
                                    <span className="p-2 rounded">
                                      <i
                                        className="ri-timer-flash-line align-bottom me-1 text-success"
                                      ></i>
                                      Clocked In
                                    </span>
                                    <span className="p-2 rounded">
                                      <i
                                        className="ri-alarm-warning-line align-bottom me-1 text-primary"
                                      ></i>
                                      Missed Clock In
                                    </span>
                                    <span className="p-2 rounded">
                                      <i
                                        className="ri-thumb-down-line align-bottom me-1 text-primary"
                                      ></i>
                                      Rejected
                                    </span>
                                    <span className="p-2 rounded">
                                      <i
                                        className="ri-scan-line align-bottom me-1 text-warning"
                                      ></i>
                                      Pending
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
            }
          </Container>
        </div>
        <Offcanvas
          className="filter-canvas"
          show={shiftFilters}
          placement="end"
          onHide={() => setShiftFilters(false)}
        >
          <Offcanvas.Header
            id="shiftFilters"
            className="border-bottom filter-canvas"
            closeButton
          >
            <Offcanvas.Title id="courseFiltersLabel">
              {" "}
              <i className="ri-list-settings-line align-baseline me-1"></i>{" "}
              Filters
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form>
              <Row>
                <Col lg={12}>
                  {/* <Row className="filter-2">
                    <Col lg={12} className="d-flex justify-content-between">
                      <h5>Shifts</h5>
                      <p style={{ cursor: 'pointer' }} onClick={handleClearAllStatus}>Clear All</p>
                    </Col>
                    <Col lg={12}>
                      <div className="form-check mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="AllStatus"
                          checked={selectedStatus.length === allStatusOptions.length}
                          onChange={handleAllStatusChange}
                        />
                        <label className="form-check-label" htmlFor="AllStatus">
                          All
                        </label>
                      </div>
                    </Col>
                    {allStatusOptions.map((status) => (
                      <Col lg={12} key={status}>
                        <div className="form-check mb-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`statusCheckbox${status}`}
                            checked={selectedStatus.includes(status)}
                            onChange={() => handleStatusChange(status)}
                          />
                          <label className="form-check-label" htmlFor={`statusCheckbox${status}`}>
                            {status}
                          </label>
                        </div>
                      </Col>
                    ))}
                  </Row> */}
                  <Row className="filter-2">
                    <Col lg={12}>
                      <h5>Locations</h5>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-icon">
                        <div className={`react-select-container`}>
                          <Select
                            options={filterLocationList}
                            isMulti
                            value={selectedFilterLocation}
                            onChange={(newValue: any) => setSelectedFilterLocation(newValue || [])}
                            getOptionLabel={(option: any) => option.label}
                            getOptionValue={(option: any) => option.value}
                            placeholder="Select Locations..."
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Offcanvas.Body>
          <Row>
            <Col lg="12">
              <div className="offcanvas-footer border-top-0 p-3 text-center">
                <Row className="px-2 approve-reject-btn">
                  <Col lg={6} className="clear-section">
                    <Button onClick={ClearAllFilter} color="primary" className="clear-btn">
                      Clear Filters
                    </Button>
                  </Col>
                  <Col lg={6} className="Apply-section">
                    <Button color="primary" className="next-btn" onClick={() => { setLoading(true); fetchEventsFromAPI(); setShiftFilters(false) }}>
                      Apply Filters
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Offcanvas>
        <Offcanvas
          className="shift-option-canvas"
          show={isShiftCanvasOpen}
          placement="end"
          onHide={() => setIsShiftCanvasOpen(false)}
        >

          <Row className="m-0">
            <Col lg={3} className="shift-column">
              {selectedStaffName &&
                <>
                  <span>{getInitials(selectedStaffName)}</span>
                  <h4 className="title">{selectedStaffName}</h4>
                </>
              }
              <Row className="g-3 mt-3">
                <Col md={12}>
                  <Card className="border shadow-none mb-0">
                    <Card.Body className="p-2">
                      <div className="text-center">
                        <p className="text-muted text-truncate mb-2">Client</p>
                        <h6 className="fs-lg">{selectedClient?.label}</h6>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={12}>
                  <Card className="border shadow-none mb-0">
                    <Card.Body className="p-2">
                      <div className="text-center">
                        <p className="text-muted text-truncate mb-2">Location</p>
                        <h6 className="fs-lg">{selectedLocation?.label}</h6>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                {editId &&
                  <Col md={12}>
                    <Card className="border shadow-none mb-0">
                      <Card.Body className="p-2">
                        <div className="text-center">
                          <p className="text-muted text-truncate mb-2">Created By</p>
                          <h6 className="fs-lg">{Created_by}</h6>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                }
                <Col md={12}>
                  <Card className="border shadow-none mb-0">
                    <Card.Body className="p-2">
                      <div className="text-center">
                        <p className="text-muted text-truncate mb-2">Shift Length</p>
                        <h6 className="fs-lg">{shiftHours} Hrs</h6>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                {/* <Col md={12}>
                  <Card className="border shadow-none mb-0">
                    <Card.Body className="p-2">
                      <div className="text-center">
                        <p className="text-muted text-truncate mb-2">Shift Cost</p>
                        <h6 className="fs-lg">$0</h6>
                      </div>
                    </Card.Body>
                  </Card>
                </Col> */}
                {(editId != null && editId > 0 && !isFinishShift && !formik.values.clock_in_time) && (
                  <Col md={12}>
                    <Button
                      variant="outline-primary"
                      className="w-100"
                      onClick={() => handleArchiveShift(editId)}
                      style={{
                        pointerEvents: "auto", // Disables hover interaction
                        color: "red", // Ensures text color remains consistent
                        backgroundColor: "transparent", // Maintains the original background
                        borderColor: "currentColor", // Keeps border consistent
                      }}
                    >
                      <i className="ri-delete-bin-line"></i> Delete
                    </Button>
                  </Col>
                )}
                {/* <Col md={12}>
                {formik.isSubmitting ? (
                                  <Button
                                    color="primary"
                                    className="next-btn w-100"
                                    disabled
                                  >
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  </Button>
                                ) : (
                                  <Button
                                    color="primary"
                                    type="submit"
                                    className="next-btn w-100"
                                    disabled={formik.isSubmitting}
                                  >
                                    {editId ? "Update" : "Create"}
                                  </Button>
                                )}
</Col> */}

                {(Is_petrol_site === 1 && editId) &&
                  <Col md={12}>
                    <Button onClick={downloadPatrolInfo} variant="primary" className="sub-update-btn d-flex align-items-center gap-2">
                      <i className=" ri-download-line" style={{ fontSize: '18px' }}></i> Patrol Info
                    </Button>
                  </Col>
                }
              </Row>
            </Col>
            <Col lg={9} className="p-0 shift-form">
              <Form onSubmit={formik.handleSubmit}>
                <Offcanvas.Header
                  id="createShift"
                  className="border-bottom"
                  closeButton
                >
                  <Offcanvas.Title id="createShiftLabel">
                    {" "}
                    <i
                      className="bx bx-calendar-plus align-middle me-1"
                      style={{ color: "#999" }}
                    ></i>{" "}
                    {editId ? (isFinishShift) ? "Shift Detail" : "Edit Shift" : "New Shift"}   {(Is_petrol_site === 1 && editId) && <span className="badge bg-info-subtle text-info">Patrol Shift</span>}
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-0 px-4">
                  <Tab.Container
                    defaultActiveKey="home1"
                    activeKey={activeTab}
                    onSelect={handleTabChange}
                  >
                    {
                      (editId != null && editId > 0) &&
                      <Nav as="ul" variant='pills' className="nav-justified mt-3 mb-3">
                        <Nav.Item as="li">
                          <Nav.Link eventKey="home1">
                            Details
                          </Nav.Link>
                        </Nav.Item>
                        {canAccess && editId &&
                          <Nav.Item as="li">
                            <Nav.Link eventKey="profile1">
                              History
                            </Nav.Link>
                          </Nav.Item>
                        }
                        {(editId && StaffViewDetail) &&
                          <Nav.Item as="li">
                            <Nav.Link eventKey="profile2">
                              Staff Details
                            </Nav.Link>
                          </Nav.Item>
                        }
                      </Nav>
                    }

                    <Tab.Content className="text-muted">
                      <Tab.Pane eventKey="home1">
                        <SimpleBar
                          style={{ maxHeight: "80vh" }}
                          className="px-3"
                        >
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="mb-3 mt-3">
                                <Form.Label>Date</Form.Label>
                                <div className="position-relative form-icon">
                                  <Form.Control type="text" className="form-control form-control-icon" placeholder="Enter Date" name="shift_date" value={formik.values.date} readOnly />
                                  <i
                                    style={{ fontSize: "16px" }}
                                    className="ri-calendar-event-line"
                                  ></i>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg="12">
                              <Form.Group className="mb-3 form-icon">
                                <Form.Label className="text-muted">
                                  Select Staff
                                </Form.Label>
                                <Select
                                  inputId="staffSelect"
                                  name="staff_id"
                                  options={staffList}
                                  value={staffList.find(option => option.value === formik.values.staff_id)}
                                  onChange={(selectedOption: any) => {
                                    formik.setFieldValue('staff_id', selectedOption ? selectedOption.value : '');
                                    setSelectedStaffName(selectedOption ? selectedOption.label : '');
                                  }}
                                  onBlur={formik.handleBlur}
                                  isDisabled={shiftClockIn}
                                  classNamePrefix="react-select"
                                  className={formik.touched.staff_id && formik.errors.staff_id ? 'is-invalid' : ''}
                                />
                                <i
                                  style={{
                                    fontSize: "16px",
                                    marginTop: "1.6rem",
                                  }}
                                  className="ri-user-line"
                                ></i>
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.staff_id}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg="6">
                              <Form.Group className="mb-3">
                                <Form.Label>Start Time</Form.Label>
                                <div className="position-relative form-icon">
                                  <Cleave
                                    placeholder="hh:mm"
                                    options={{
                                      time: true,
                                      timePattern: ['h', 'm']
                                    }}
                                    value={formik.values.start_time}
                                    onChange={(e: any) => handleTimeChange(e.target.rawValue, "start_time")}
                                    className={`form-control form-control-lg form-control-icon ${formik.touched.start_time && formik.errors.start_time ? 'is-invalid' : ''}`}
                                    disabled={shiftClockIn}
                                  />
                                  {/* <Flatpickr
                                className={`form-control form-control-lg form-control-icon ${formik.touched.start_time && formik.errors.start_time ? 'is-invalid' : ''}`}
                                name="start_time"
                                value={formik.values.start_time}
                                onChange={(time: Date[]) => handleTimeChange(time, "start_time")}
                                style={{ height: "45px" }}
                                placeholder="Select Start Time"
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "H:i",
                                  time_24hr: true
                                }}
                              /> */}
                                  <i
                                    style={{ fontSize: "16px" }}
                                    className="ri-time-line"
                                  ></i>
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.start_time}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg="6">
                              <Form.Group className="mb-3">
                                <Form.Label>End Time</Form.Label>
                                <div className="position-relative form-icon">
                                  <Cleave
                                    placeholder="hh:mm"
                                    options={{
                                      time: true,
                                      timePattern: ['h', 'm']
                                    }}
                                    value={formik.values.end_time}
                                    disabled={shiftClockIn}
                                    onChange={(e: any) => handleTimeChange(e.target.rawValue, "end_time")}
                                    className={`form-control form-control-lg form-control-icon ${formik.touched.end_time && formik.errors.end_time ? 'is-invalid' : ''}`}
                                  />
                                  {/* <Flatpickr
                                className={`form-control form-control-lg form-control-icon ${formik.touched.end_time && formik.errors.end_time ? 'is-invalid' : ''}`}
                                name="end_time"
                                value={formik.values.end_time}
                                onChange={(time: Date[]) => handleTimeChange(time, "end_time")}
                                style={{ height: "45px" }}
                                placeholder="Select End Time"
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "H:i",
                                  time_24hr: true
                                }}
                              /> */}
                                  <i
                                    style={{ fontSize: "16px" }}
                                    className="ri-time-line"
                                  ></i>
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.end_time}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg="6">
                              <Form.Group className="mb-3 form-icon">
                                <Form.Label className="text-muted">
                                  Unpaid Break (in min)
                                </Form.Label>
                                <div className="position-relative form-icon">
                                  <Form.Control
                                    type="number"
                                    className="form-control form-control-icon"
                                    placeholder="Enter Time"
                                    name="total_unpaid_break"
                                    onChange={(e) => {
                                      let value = parseFloat(e.target.value);
                                      if (value < 0) value = 0; // Enforce minimum value of 1
                                      formik.setFieldValue("total_unpaid_break", value);
                                      const shiftHours = calculateShiftHours(formik.values.start_time, formik.values.end_time, value);
                                      setShiftHours(shiftHours); // Update shift hours
                                    }}
                                    disabled={shiftClockIn}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.total_unpaid_break}
                                    isInvalid={!!(formik.touched.total_unpaid_break && formik.errors.total_unpaid_break)}
                                  />
                                  <i style={{ fontSize: "16px" }} className="ri-calendar-2-line"></i>
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.total_unpaid_break}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>

                            <Col lg="6">
                              <Form.Group className="mb-3 form-icon">
                                <Form.Label className="text-muted">
                                  Paid Break (in min)
                                </Form.Label>
                                <div className="position-relative form-icon">
                                  <Form.Control
                                    type="number"
                                    className="form-control form-control-icon"
                                    placeholder="Enter Time"
                                    name="total_paid_break"
                                    onChange={(e) => {
                                      let value = parseFloat(e.target.value);
                                      if (value < 0) value = 0; // Enforce minimum value of 1
                                      formik.setFieldValue("total_paid_break", value);
                                    }}
                                    onBlur={formik.handleBlur}
                                    disabled={shiftClockIn}
                                    value={formik.values.total_paid_break}
                                    isInvalid={!!(formik.touched.total_paid_break && formik.errors.total_paid_break)}
                                  />
                                  <i style={{ fontSize: "16px" }} className="ri-calendar-2-line"></i>
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.total_paid_break}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>

                            {editId != null && editId > 0 ? (
                              isPublished ? (
                                <>
                                  <Col lg="6">
                                    <Form.Group className="mb-3">
                                      <Form.Label>Clocked In Time</Form.Label>
                                      <div className="position-relative form-icon">
                                        <Cleave
                                          placeholder="hh:mm"
                                          options={{
                                            time: true,
                                            timePattern: ['h', 'm']
                                          }}
                                          value={formik.values.clock_in_time}
                                          onChange={(e: any) => handleTimeChange(e.target.rawValue, "clock_in_time")}
                                          disabled={shiftClockIn || !canAccess}
                                          className={`form-control form-control-lg form-control-icon ${formik.touched.clock_in_time && formik.errors.clock_in_time ? 'is-invalid' : ''}`}
                                        />
                                        <i
                                          style={{ fontSize: "16px" }}
                                          className="ri-time-line"
                                        ></i>
                                        <Form.Control.Feedback type="invalid">
                                          {formik.errors.clock_in_time}
                                        </Form.Control.Feedback>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                  <Col lg="6">
                                    <Form.Group className="mb-3">
                                      <Form.Label>Clocked Out Time</Form.Label>
                                      <div className="position-relative form-icon">
                                        <Cleave
                                          placeholder="hh:mm"
                                          options={{
                                            time: true,
                                            timePattern: ['h', 'm'],
                                          }}
                                          value={formik.values.clock_out_time}
                                          disabled={shiftClockOut || !canAccess}
                                          onChange={(e: any) => {
                                            const time = e.target.rawValue;
                                            handleTimeChange(time, "clock_out_time");

                                            // Automatically set 'is_finished' based on clock-out time
                                            if (time) {
                                              formik.setFieldValue("is_finished", 1); // Set to "Yes" if time is entered
                                            } else {
                                              formik.setFieldValue("is_finished", 0); // Reset to "No" if time is cleared
                                            }
                                          }}
                                          className={`form-control form-control-lg form-control-icon ${formik.touched.clock_out_time && formik.errors.clock_out_time ? 'is-invalid' : ''
                                            }`}
                                        />
                                        <i style={{ fontSize: "16px" }} className="ri-time-line"></i>
                                        <Form.Control.Feedback type="invalid">
                                          {formik.errors.clock_out_time}
                                        </Form.Control.Feedback>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                  <Col lg="6">
                                    <Form.Group className="mb-3">
                                      <Form.Label>Clock in Latitude/Longitude</Form.Label>
                                      <div className="position-relative form-icon">
                                        <Form.Control
                                          type="text"
                                          name="clock_in_latitude"
                                          className="form-control-lg form-control-icon name-input"
                                          value={`${formik.values.clock_in_latitude ? formik.values.clock_in_latitude : "-"}/${formik.values.clock_in_longitude ? formik.values.clock_in_longitude : "-"}`}
                                          disabled
                                        />
                                        <i style={{ fontSize: '16px' }} className=" ri-map-pin-line"></i>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                  <Col lg="6">
                                    <Form.Group className="mb-3">
                                      <Form.Label>Clock out Latitude/Longitude</Form.Label>
                                      <div className="position-relative form-icon">
                                        <Form.Control
                                          type="text"
                                          name="clock_in_longitude"
                                          className="form-control-lg form-control-icon name-input"
                                          value={`${formik.values.clock_out_latitude ? formik.values.clock_out_latitude : "-"}/${formik.values.clock_out_longitude ? formik.values.clock_out_longitude : "-"}`}
                                          disabled
                                        />
                                        <i style={{ fontSize: '16px' }} className=" ri-map-pin-line"></i>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                  <Col lg="12">
                                    <Form.Group className="mb-3 form-icon">
                                      <Form.Label className="text-muted">
                                        Is Finished
                                      </Form.Label>
                                      <Form.Select
                                        className="form-select-lg form-control-icon"
                                        id="is_finished"
                                        onChange={(e) => formik.handleChange(e)}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.is_finished}
                                        disabled={shiftClockOut}
                                      >
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                      </Form.Select>
                                      <i
                                        style={{
                                          fontSize: "16px",
                                          marginTop: "1.6rem",
                                        }}
                                        className="ri-user-line"
                                      ></i>
                                    </Form.Group>
                                  </Col>
                                </>
                              )
                                : (
                                  <div></div> // Empty div when isPublished is 0
                                )
                            ) : null}

                            <Col lg="12">
                              <Form.Group className="mb-3">
                                <Form.Label>Note</Form.Label>
                                <div className="position-relative form-icon">
                                  <Form.Control
                                    as="textarea"
                                    className="form-control-lg form-control-icon name-input"
                                    placeholder="Enter Notes"
                                    rows={2}
                                    name="shift_notes"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.shift_notes}
                                    isInvalid={!!(formik.touched.shift_notes && formik.errors.shift_notes)}
                                    disabled={shiftClockOut}
                                  />
                                  <i
                                    style={{
                                      fontSize: "16px",
                                      alignItems: "baseline",
                                      marginTop: "10px",
                                    }}
                                    className="ri-book-open-line"
                                  ></i>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.shift_notes}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            {canAccess && (<Col lg="12">
                              <Form.Group className="mb-3">
                                <Form.Label>Admin Note</Form.Label>
                                <div className="position-relative form-icon">
                                  <Form.Control
                                    as="textarea"
                                    className="form-control-lg form-control-icon name-input"
                                    placeholder="Enter Admin Notes"
                                    rows={2}
                                    name="admin_note"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.admin_note}
                                    isInvalid={!!(formik.touched.admin_note && formik.errors.admin_note)}
                                    disabled={shiftClockOut}
                                  />
                                  <i
                                    style={{
                                      fontSize: "16px",
                                      alignItems: "baseline",
                                      marginTop: "10px",
                                    }}
                                    className="ri-book-open-line"
                                  ></i>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.admin_note}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>)}
                          </Row>
                          {!isFinishShift &&
                            <Row>
                              <div className="line"></div>
                              <Col lg={12} className="d-flex justify-content-between">
                                {/* <div className="col-sm-auto">
                                        <Form.Label
                                          className="visually-hidden"
                                          htmlFor="autoSizingSelect"
                                        >
                                          Preference
                                        </Form.Label>
                                        <Form.Select
                                          data-choices
                                          data-choices-sorting="true"
                                          id="autoSizingSelect"
                                        >
                                          <option value="1">Repeat Tomorrow</option>
                                          <option value="2">Repeat for next week</option>
                                          <option value="3">Repeat for Specific days</option>
                                          <option value="3">Publish shift</option>
                                          {editId && (
                                            <option value="5">Terminate Shift</option>
                                          )}
                                        </Form.Select>
                                      </div> */}
                                {formik.isSubmitting ? (
                                  <Button
                                    color="primary"
                                    className="next-btn"
                                    disabled
                                  >
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  </Button>
                                ) : (
                                  <Button
                                    color="primary"
                                    type="submit"
                                    className="next-btn"
                                    disabled={formik.isSubmitting}
                                  >
                                    {editId ? "Update" : "Create"}
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          }
                        </SimpleBar>
                      </Tab.Pane>
                      {editId &&
                        <Tab.Pane eventKey="profile1">
                          <SimpleBar
                            style={{ maxHeight: "85vh" }}
                            className="px-3"
                          >
                            <Row>
                              {ShiftHistoryDetails === null ?
                                <Col lg={12} className="text-center mt-5 pt-5">
                                  <img src={noDataImg} style={{ width: '100px' }} />
                                  <div className='d-flex justify-content-center'>No history available</div>
                                </Col>
                                :
                                <Col lg="12" className="status-table">
                                  <Table className="align-middle table-wrap mb-0" responsive>
                                    <thead>
                                      <tr>
                                        <th scope="col User">User</th>
                                        <th scope="col User">Field</th>
                                        <th scope="col Previous">Previous Value</th>
                                        <th scope="col Previous">Updated Value</th>
                                        <th scope="col Previous">Date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ShiftHistoryDetails?.map((time: any, index: number) => {
                                        return (
                                          <tr key={index}>
                                            <td>{time?.user_name}</td>
                                            <td>{time?.field}</td>
                                            <td>{time?.prev_val}</td>
                                            <td>{time?.updated_val}</td>
                                            <td>{time?.created_date}</td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </Table>
                                </Col>
                              }
                            </Row>
                          </SimpleBar>
                        </Tab.Pane>
                      }
                      {editId &&
                        <Tab.Pane eventKey="profile2">
                          {!StaffViewDetail && (
                            <Col lg={12} className="text-center mt-5 pt-5">
                              <img src={noDataImg} style={{ width: '100px' }} />
                              <div className='d-flex justify-content-center'>Shift not assigned yet</div>
                            </Col>
                          )}
                          {StaffViewDetail && (
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 position-relative">
                                {StaffViewDetail?.avatar ? (
                                  <img src={imageSrc} alt="" className="avatar-sm rounded" />
                                ) : (
                                  <div className="avatar-sm">
                                    <div className="avatar-title rounded bg-danger-subtle text-danger">
                                      {getInitials(StaffViewDetail?.employee_name || "-")}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="flex-grow-1 ms-2">
                                <h5 className="fs-md mb-0">
                                  <Link to="/pages-profile" className="text-dark">
                                    {StaffViewDetail?.first_name} {StaffViewDetail?.last_name}
                                  </Link>
                                </h5>
                                <p className="text-muted mb-0">
                                  {StaffViewDetail?.access_level || "-"}
                                </p>
                                <p className="text-muted mb-0">
                                  {StaffViewDetail?.employee_status || "-"}
                                </p>
                              </div>
                            </div>
                          )}

                          {/* Contact Details */}
                          {StaffViewDetail && (
                            <div className="mt-4">
                              <p className="text-muted mb-2">
                                <i className="bi bi-telephone align-baseline me-1"></i>
                                {StaffViewDetail?.mobile_no ? (
                                  <Link
                                    to={`tel:${StaffViewDetail.mobile_no}`}
                                    className="text-muted"
                                    style={{ paddingLeft: "4px" }}
                                  >
                                    {StaffViewDetail.mobile_no}
                                  </Link>
                                ) : (
                                  "-"
                                )}
                              </p>
                              <p className="text-muted mb-2">
                                <i className="bi bi-envelope align-baseline me-1"></i>
                                {StaffViewDetail?.email ? (
                                  <Link
                                    to={`mailto:${StaffViewDetail.email}`}
                                    className="text-muted"
                                    style={{ paddingLeft: "4px" }}
                                  >
                                    {StaffViewDetail.email}
                                  </Link>
                                ) : (
                                  "-"
                                )}
                              </p>
                              <p className="text-muted mb-2">
                                <i className="bi bi-geo-alt align-baseline me-1"></i>
                                {StaffViewDetail?.address || "-"}
                              </p>
                              <p className="text-muted mb-2">
                                <i className="mdi mdi-gender-transgender align-baseline me-1"></i>
                                {StaffViewDetail?.gender || "-"}
                              </p>
                              <p className="text-muted mb-0">
                                <i className="ri-cake-line align-baseline me-1"></i>
                                {StaffViewDetail?.dob || "-"}
                              </p>
                            </div>
                          )}

                          {/* License Section */}
                          {StaffDocumentDetail?.filter((doc) => doc.document_type === "Licenses").length > 0 && (
                            <div className="border-bottom border-top border-dashed py-4 mt-4">
                              <h5 className="card-title mb-3">License</h5>
                              <div className="vstack gap-3">
                                {StaffDocumentDetail
                                  .filter((doc) => doc.document_type === "Licenses")
                                  .map((doc, index) => (
                                    <div key={index} className="d-flex gap-2 align-items-center position-relative">
                                      <div className="flex-shrink-0">
                                        <div className="avatar-sm border border rounded">
                                          <div className="avatar-title bg-body-secondary text-secondary rounded fs-lg">
                                            <i className="bi bi-file-pdf"></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex-grow-1">
                                        <h6>License No: {doc.ref_no}</h6>
                                        <p className="text-muted mb-0">Expiry Date: {doc.expiry_date || "-"}</p>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <Link
                                          to={`#`}
                                          onClick={() => handleOpenDocument(doc.document_path)}
                                          className="icon-link text-reset stretched-link fs-xl"
                                          style={{ transform: "translate3d(0, -.125rem, 0)" }}
                                        >
                                          <i className="ri-eye-line link-info"></i>
                                        </Link>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}
                        </Tab.Pane>
                      }
                    </Tab.Content>
                  </Tab.Container>
                </Offcanvas.Body>
              </Form>
            </Col>
          </Row>
        </Offcanvas>
        {/* copy modal start  */}
        <Modal
          show={openCopyTemplateModal}
          onHide={() => {
            setOpenCopyTemplateModal(false);
            resetForm();
          }}
          size="lg"
          centered
        >
          <Modal.Header className="modal-title bg-danger-subtle text-center" id="myModalLabel" style={{ display: 'block' }}>
            <h4>Copy Weeks for {startDate.format("MMM D")} – {endDate.format("D MMM, YYYY")}</h4>
            <i
              className="ri-close-fill"
              onClick={handleFinishOrCancel}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
                fontSize: '30px',
              }}
            />
          </Modal.Header>
          <Modal.Body className="p-4">
            {/* Step Indicators */}
            <div className="p-3">
              <div className="d-flex align-items-center justify-content-between position-relative">
                {steps.map((step, index) => (
                  <div
                    key={index}
                    className="text-center"
                    style={{
                      flex: 1,
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {/* Step Circle */}
                    <div
                      className={`step-circle ${currentStep === index + 1 ? "bg-danger text-white" : "bg-white text-dark"
                        }`}
                      style={{
                        width: "40px",
                        height: "40px",
                        lineHeight: "40px",
                        borderRadius: "50%",
                        border: "2px solid",
                        borderColor: currentStep === index + 1 ? "red" : "gray",
                        zIndex: 2,
                        textAlign: "center",
                      }}
                      onClick={() => {
                        if (index + 1 === currentStep - 1) {
                          handlePrevious();
                        }
                        else if (index + 1 === currentStep + 1) {
                          handleNext();
                        }
                      }}
                    >
                      {index + 1}
                    </div>

                    {/* Step Title */}
                    <div style={{ marginTop: "5px", fontSize: "12px", fontWeight: "bold" }}>
                      {step.title}
                    </div>

                    {/* Line Between Steps */}
                    {index < steps.length - 1 && (
                      <div
                        className="position-absolute"
                        style={{
                          width: "100%",
                          height: "2px",
                          background: `linear-gradient(
                to right,
                ${currentStep === 1 ? "red" : "gray"} 50%,
                ${currentStep >= 2 ? "red" : "gray"} 50%
              )`,
                          top: "30%",
                          left: "100%",
                          transform: "translateX(-50%)",
                          zIndex: 1,
                        }}
                      ></div>
                    )}
                  </div>
                ))}
              </div>
            </div>


            <Form onSubmit={templateFormik.handleSubmit}>
              {currentStep === 1 && (
                <Row>
                  <Col md="12">
                    <Form.Group className="mb-3">
                      <Form.Label>Choose the week's where you want to copy the schedule:</Form.Label>
                      {/* Select All & Clear All */}
                      <div className="d-flex justify-content-end mb-3">
                        {/* Select All Checkbox */}
                        <Form.Check
                          inline
                          type="checkbox"
                          id="select-all"
                          label="Select All"
                          checked={isAllSelected}
                          onChange={(e) => handleSelectAll(e.target.checked)}
                        />

                        {/* Clear All Checkbox */}
                        <Form.Check
                          inline
                          type="checkbox"
                          id="clear-all"
                          label="Clear All"
                          checked={isClearAll}
                          onChange={(e) => handleClearAll(e.target.checked)}
                        />
                      </div>

                      {/* Week Checkboxes */}
                      <div className="d-flex flex-wrap">
                        {totalWeeks.map((week, index) => (
                          <div className="col-3 mb-3" key={index}>
                            <Form.Check
                              type="checkbox"
                              className="week-checkbox"
                              label={week}
                              id={`week-${index}`}
                              checked={selectedWeeks.includes(week)}
                              onChange={() => handleWeekSelection(week)}
                            />
                          </div>
                        ))}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-3">
                      <div className="form-check form-switch form-switch-custom form-switch-lg mb-2">
                        <Form.Check
                          type="checkbox"
                          role="switch"
                          id="is_remove_staff"
                          checked={isRemoveStaff}
                          onChange={(e) => setIsRemoveStaff(e.target.checked)}

                        />
                        <Form.Label>
                          Remove Staff From Shifts
                        </Form.Label>
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <div className="form-check form-switch form-switch-custom form-switch-lg mb-2">
                        <Form.Check
                          type="checkbox"
                          role="switch"
                          id="is_shift_notes"
                          checked={isShiftNotes}
                          onChange={(e) => setIsShiftNotes(e.target.checked)}
                        />
                        <Form.Label>
                          Include Shift Notes
                        </Form.Label>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-3">
                      <div className="form-check form-switch form-switch-custom form-switch-lg mb-2">
                        <Form.Check
                          type="checkbox"
                          role="switch"
                          id="is_admin_notes"
                          checked={isAdminNotes}
                          onChange={(e) => setIsAdminNotes(e.target.checked)}
                        />
                        <Form.Label>
                          Include Admin Notes
                        </Form.Label>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              {currentStep === 2 && (
                <div>
                  <div
                    className="d-flex flex-column align-items-center"
                  >
                    <Form.Group className="mb-3 text-center">
                      <Form.Label className="mb-4">
                        <h5>Select Days:</h5>
                      </Form.Label>
                      <Col>
                        {/* Selected Days */}
                        {selectedDays.length > 0 && (
                          <div className="mb-3">
                            <strong>Selected Days:</strong> {selectedDays.join(", ")}
                          </div>
                        )}

                        {/* Days Selector */}
                        <div className="position-relative form-icon">
                          <ButtonGroup
                            aria-label="Basic checkbox toggle button group"
                            className="week_chk_group"
                          >
                            {days.map((day) => (
                              <React.Fragment key={day.value}>
                                <input
                                  type="checkbox"
                                  className="btn-check week_chk"
                                  id={`btn-check-${day.value}`}
                                  checked={selectedDays.includes(day.value)}
                                  onChange={() => toggleDay(day.value)}
                                />
                                <Button
                                  as="label"
                                  variant={
                                    selectedDays.includes(day.value)
                                      ? "primary"
                                      : "outline-primary"
                                  }
                                  htmlFor={`btn-check-${day.value}`}
                                  className="mb-0"
                                >
                                  {day.label.slice(0, 3)}
                                </Button>
                              </React.Fragment>
                            ))}
                          </ButtonGroup>
                        </div>

                        {/* Select All */}
                        <div className="mt-3 d-flex justify-content-between align-items-center">
                          <Form.Check
                            type="checkbox"
                            id="select-all"
                            label="Select All"
                            onChange={handleSelectAllDays}
                            checked={selectedDays.length === days.length}
                          />
                          <Form.Check
                            type="checkbox"
                            id="clear-all"
                            label="Clear All"
                            onChange={handleClearAllDays}
                            checked={selectedDays.length === 0}
                          />
                        </div>
                      </Col>
                    </Form.Group>
                  </div>

                  {/* <Col md={6}>
    <Form.Group>
      <Form.Label>Select Clients:</Form.Label>
      <Form.Select
        multiple
        value={selectedClients}
        onChange={(e) => {
          const options = Array.from(e.target.selectedOptions, (option) => option.value);
          setSelectedClients(options);
        }}
      >
        {clientList.map((client) => (
          <option key={client.value} value={client.value}>
            {client.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  </Col>

  <Col md={6}>
    <Form.Group>
      <Form.Label>Select Locations:</Form.Label>
      <Form.Select
        multiple
        value={selectedLocations}
        onChange={(e) => {
          const options = Array.from(e.target.selectedOptions, (option) => option.value);
          setSelectedLocations(options);
        }}
      >
        {filterLocationList.map((location) => (
          <option key={location.value} value={location.value}>
            {location.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  </Col> */}
                  <Row
                    className="mb-3"
                    style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}
                  >
                    {/* Client Dropdown */}
                    <Col md={6} xs={12}>
                      <Form.Group>
                        <Form.Label>Select Clients:</Form.Label>
                        <div
                          className="dropdown-container"
                          style={{
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            padding: "10px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "#fff",
                            height: "250px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <InputGroup className="btn-section">
                            <div className="position-relative" style={{ width: "100%" }}>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search Clients"
                                value={clientSearch}
                                onChange={(e) => setClientSearch(e.target.value)}
                                style={{
                                  border: "1px solid #ccc",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  marginBottom: "10px",
                                }}
                              />
                              <span
                                className="mdi mdi-magnify search-widget-icon"
                                style={{
                                  position: "absolute",
                                  top: "45%",
                                  right: "10px",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                }}
                              ></span>
                              {clientSearch && (
                                <span
                                  className="mdi mdi-close-circle search-widget-icon-close"
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "35px",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setClientSearch("")}
                                ></span>
                              )}
                            </div>
                          </InputGroup>

                          <div
                            className="custom-dropdown"
                            style={{
                              overflowY: "auto",
                              flexGrow: 1,
                              maxHeight: "calc(100% - 40px)",
                            }}
                          >
                            {clientList.map((client) => (
                              <div
                                key={client.value}
                                className="client-group"
                                style={{
                                  marginBottom: "10px",
                                  marginTop: "5px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={selectedClients.some((c) => c.value === client.value)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedClients((prev) => [...prev, client]);
                                      fetchLocationsFromAPI(client);
                                    } else {
                                      setSelectedClients((prev) =>
                                        prev.filter((c) => c.value !== client.value)
                                      );
                                      setCheckedLocations((prev) =>
                                        prev.filter(
                                          (locGroup: any) => locGroup.clientValue !== client.value
                                        )
                                      );
                                      setAllSelectedLocations((prev) =>
                                        prev.filter(
                                          (locGroup: any) => locGroup.clientValue !== client.value
                                        )
                                      );
                                    }
                                  }}
                                  style={{
                                    marginRight: "10px",
                                    accentColor: "red",
                                  }}
                                />
                                <strong>{client.label}</strong>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Form.Group>
                    </Col>

                    {/* Location Dropdown */}
                    <Col md={6} xs={12}>
                      <Form.Group>
                        <Form.Label>Select Locations:</Form.Label>
                        <div
                          className="dropdown-container"
                          style={{
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            padding: "10px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "#fff",
                            height: "250px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <InputGroup>
                            <div className="position-relative" style={{ width: "100%" }}>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search Locations"
                                value={locationSearch}
                                onChange={(e) => setLocationSearch(e.target.value)}
                                style={{
                                  border: "1px solid #ccc",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  marginBottom: "10px",
                                }}
                              />
                              <span
                                className="mdi mdi-magnify search-widget-icon"
                                style={{
                                  position: "absolute",
                                  top: "45%",
                                  right: "10px",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                }}
                              ></span>
                              {locationSearch && (
                                <span
                                  className="mdi mdi-close-circle search-widget-icon-close"
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "35px",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setLocationSearch("")}
                                ></span>
                              )}
                            </div>
                          </InputGroup>
                          <div
                            className="custom-dropdown"
                            style={{
                              overflowY: "auto",
                              flexGrow: 1,
                              maxHeight: "calc(100% - 40px)",
                            }}
                            ref={locationDropdownRef}
                          >
                            {allSelectedLocations.map((client, clientIndex) => {
                              const filteredLocations = client.locations.filter((location: any) =>
                                location.label.toLowerCase().includes(locationSearch.toLowerCase())
                              );

                              return (
                                <div
                                  key={client.clientValue}
                                  className={`client-group client-group-${clientIndex}`}
                                  style={{
                                    marginBottom: "10px",
                                  }}
                                >
                                  <strong>{client.clientName}</strong>
                                  {filteredLocations.map((location: any) => (
                                    <div
                                      key={location.value}
                                      className="location-item"
                                      style={{
                                        marginBottom: "5px",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Form.Check
                                        type="checkbox"
                                        label={location.label}
                                        checked={checkedLocations
                                          .find(
                                            (group) => group.clientValue === client.clientValue
                                          )
                                          ?.locations.some((loc: any) => loc.value === location.value)}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setCheckedLocations((prev) =>
                                              prev.map((group) =>
                                                group.clientValue === client.clientValue
                                                  ? {
                                                    ...group,
                                                    locations: [
                                                      ...group.locations,
                                                      location,
                                                    ].filter(
                                                      (loc, index, self) =>
                                                        index ===
                                                        self.findIndex(
                                                          (t) => t.value === loc.value
                                                        )
                                                    ),
                                                  }
                                                  : group
                                              )
                                            );
                                          } else {
                                            setCheckedLocations((prev) =>
                                              prev.map((group) =>
                                                group.clientValue === client.clientValue
                                                  ? {
                                                    ...group,
                                                    locations: group.locations.filter(
                                                      (loc: any) => loc.value !== location.value
                                                    ),
                                                  }
                                                  : group
                                              )
                                            );
                                          }
                                        }}
                                        style={{
                                          marginRight: "10px",
                                        }}
                                      />
                                    </div>
                                  ))}
                                  {clientIndex < allSelectedLocations.length - 1 && (
                                    <div
                                      style={{
                                        borderTop: "1px solid #ccc",
                                        margin: "10px 0",
                                      }}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>








                </div>
              )}
            </Form>
          </Modal.Body>
          {/* <Modal.Footer>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setOpenCopyTemplateModal(false)}>Cancel</Button>
              <Button variant="danger" onClick={() => templateFormik.handleSubmit()} disabled={templateFormik.isSubmitting}>
                {templateFormik.isSubmitting ?
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  ><span className="visually-hidden">Submitting...</span></Spinner> :
                  <><i className="ri-upload-2-fill"></i> Submit</>
                }
              </Button>
            </div>
          </Modal.Footer> */}
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleFinishOrCancel}>
          Cancel
        </Button> */}
            <Button
              variant="light"
              onClick={handlePrevious}
              disabled={currentStep === 1}
            >
              Previous
            </Button>
            {currentStep === steps.length ? (

              <Button variant="danger" onClick={() => copyTemplateFormik.handleSubmit()} disabled={copyTemplateFormik.isSubmitting}>
                {templateFormik.isSubmitting ?
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  ><span className="visually-hidden">Submitting...</span></Spinner> :
                  <><i className="ri-file-copy-2-line"></i> Copy</>
                }
              </Button>
            ) : (
              <Button variant="primary" onClick={handleNext} disabled={currentStep === steps.length}>
                Next
              </Button>
            )}

          </Modal.Footer>
        </Modal>
        {/* copy modal end  */}
        <Modal
          show={openTemplateModal}
          onHide={() => {
            setOpenTemplateModal(false);
          }}
          size="lg"
          centered
        >
          <Modal.Header className="modal-title bg-danger-subtle text-center" id="myModalLabel" style={{ display: 'block' }}>
            <h4>Save Template for {selectedClient?.label}</h4>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form onSubmit={templateFormik.handleSubmit}>
              <Row>
                <Col md="12">
                  <Form.Group className="mb-3">
                    <Form.Label>Template Name</Form.Label>
                    <div className="position-relative form-icon">
                      <Form.Control
                        name="template_name"
                        className="form-control-lg form-control-icon name-input"
                        placeholder="Enter Template Name"
                        type="text"
                        onChange={templateFormik.handleChange}
                        onBlur={templateFormik.handleBlur}
                        value={templateFormik.values.template_name}
                        isInvalid={!!(templateFormik.touched.template_name && templateFormik.errors.template_name)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {templateFormik.errors.template_name}
                      </Form.Control.Feedback>
                      <i style={{ fontSize: "16px" }} className="bx bx-note"></i>
                    </div>
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <div className="position-relative form-icon">
                      <Form.Control
                        name="description"
                        as="textarea"
                        className="form-control-lg form-control-icon name-input"
                        placeholder="Enter Description"
                        type="text"
                        onChange={templateFormik.handleChange}
                        onBlur={templateFormik.handleBlur}
                        value={templateFormik.values.description}
                        rows={5}
                      />
                      <i style={{ fontSize: "16px" }} className="bx bx-file"></i>
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-3">
                    <div className="form-check form-switch form-switch-custom form-switch-lg mb-2">
                      <Form.Check
                        type="checkbox"
                        role="switch"
                        name="is_remove_staff"
                        id="is_remove_staff"
                        onChange={(e) => {
                          templateFormik.setFieldValue("is_remove_staff", e.target.checked ? 1 : 0)
                        }}
                        checked={Boolean(templateFormik.values.is_remove_staff)}
                      />
                      <Form.Label>
                        Remove Staff From Shifts
                      </Form.Label>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div className="form-check form-switch form-switch-custom form-switch-lg mb-2">
                      <Form.Check
                        type="checkbox"
                        role="switch"
                        name="is_shift_notes"
                        id="is_shift_notes"
                        onChange={(e) => {
                          templateFormik.setFieldValue("is_shift_notes", e.target.checked ? 1 : 0)
                        }}
                        checked={Boolean(templateFormik.values.is_shift_notes)}
                      />
                      <Form.Label>
                        Include Shift Notes
                      </Form.Label>
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-3">
                    <div className="form-check form-switch form-switch-custom form-switch-lg mb-2">
                      <Form.Check
                        type="checkbox"
                        role="switch"
                        name="is_admin_notes"
                        id="is_admin_notes"
                        onChange={(e) => {
                          templateFormik.setFieldValue("is_admin_notes", e.target.checked ? 1 : 0)
                        }}
                        checked={Boolean(templateFormik.values.is_admin_notes)}
                      />
                      <Form.Label>
                        Include Admin Notes
                      </Form.Label>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setOpenTemplateModal(false)}>Cancel</Button>
              <Button variant="danger" onClick={() => templateFormik.handleSubmit()} disabled={templateFormik.isSubmitting}>
                {templateFormik.isSubmitting ?
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  ><span className="visually-hidden">Submitting...</span></Spinner> :
                  <><i className="ri-upload-2-fill"></i> Submit</>
                }
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={openLoadTemplateModal}
          onHide={() => {
            setOpenLoadTemplateModal(false);
          }}
          size="lg"
          centered
        >
          {
            submitTemplateResponse ?
              <>
                <Modal.Body className="p-4">
                  <Row className="g-4">
                    <Col md={12}>
                      <h3 className="mb-4">{submitTemplateResponse?.total_shifts} Shifts in Template</h3>
                      <span className="flex-grow-1">
                        <h6 className="text-muted mb-0 mt-2">
                          <i className="ri-check-line text-success align-middle me-1" style={{ fontSize: "1.2rem" }}></i> {submitTemplateResponse?.successful_shifts}
                        </h6>
                      </span>
                      {
                        (submitTemplateResponse?.issues != "") &&
                        <>
                          <span className="flex-grow-1">
                            <h6 className="text-muted mb-0 mt-2">
                              <i className="ri-alert-line text-danger align-middle me-1" style={{ fontSize: "1.2rem" }}></i> {submitTemplateResponse?.issues}
                            </h6>
                          </span>
                          {
                            (submitTemplateResponse.overlaps.length > 0) &&
                            <Accordion defaultActiveKey='0' className="custom-accordion-border accordion-border-box accordion-danger mt-3">
                              <Accordion.Item eventKey='0'>
                                <Accordion.Header>
                                  {submitTemplateResponse.overlaps.length} Shift has overlapped with existing shift.
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ul>
                                    {
                                      submitTemplateResponse.overlaps.map((overlapItem: any, index: number) => (
                                        <li key={index}>
                                          {overlapItem}
                                        </li>
                                      ))
                                    }
                                  </ul>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          }
                        </>
                      }
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <div className="hstack gap-2 justify-content-center">
                    <Button variant="primary" onClick={() => setOpenLoadTemplateModal(false)}>Okay, Done</Button>
                  </div>
                </Modal.Footer>
              </>
              : templateData.length > 0 ?
                <>
                  <Modal.Header className="modal-title bg-danger-subtle text-center" id="myModalLabel" style={{ display: 'block' }}>
                    <h4>Load Template for {selectedClient?.label}</h4>
                  </Modal.Header>
                  <Modal.Body className="p-4">
                    <SimpleBar
                      style={{ maxHeight: "65vh" }}
                      className="px-3"
                    >
                      <Row className="g-4">
                        {templateData.map((dataItem: any, index: number) => (
                          <Col lg={12} key={index}>
                            <Form.Check className="card-radio">
                              <Form.Check.Input id={`template_chk${index}`} name="shippingMethod" type="radio" checked={dataItem.id === template_id} onChange={() => setTemplateId(dataItem.id)} />
                              <Form.Check.Label className="d-flex gap-2 align-items-center" htmlFor={`template_chk${index}`}>
                                <span className="flex-grow-1">
                                  <span className="fs-md fw-medium mb-1 text-wrap d-block">{dataItem.template_name}</span>
                                  <p className="text-muted mb-0 mt-2">
                                    <i className="bi bi-calendar-event align-baseline me-1"></i> {dataItem.total_shift}
                                    <i className="bi bi-people-fill align-baseline ms-4 me-1"></i> {dataItem.total_assign_staff}
                                    <i className="bi bi-calendar-range align-baseline ms-4 me-1"></i> {dataItem.start_date + "-" + dataItem.end_date}
                                  </p>
                                  <span className="text-muted fw-normal text-wrap d-block mt-3">{dataItem.description}</span>
                                </span>
                                <Link to="#" onClick={() => handleArchiveShiftTemplate(dataItem.id)} className="btn btn-primary btn-icon btn-sm float-end"><i className="bi bi-trash-fill"></i></Link>
                              </Form.Check.Label>
                            </Form.Check>
                          </Col>
                        ))}
                      </Row>
                    </SimpleBar>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="hstack gap-2 justify-content-center">
                      <Button variant="light" onClick={() => setOpenLoadTemplateModal(false)}>Cancel</Button>
                      <Button variant="danger" onClick={() => submitShiftTemplateFunc()} disabled={savingTemplateShifts}>
                        {savingTemplateShifts ?
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          ><span className="visually-hidden">Submitting...</span></Spinner> :
                          <><i className="ri-upload-2-fill"></i> Submit</>
                        }
                      </Button>
                    </div>
                  </Modal.Footer>
                </>
                :
                <Modal.Body className="p-4 m-4">
                  <Row>
                    <Col lg={12}>
                      <p className="text-center text-muted">No templates available.</p>
                    </Col>
                  </Row>
                </Modal.Body>
          }
        </Modal>
        {/* Duty Report Modal Start */}
        <Modal
          show={openDutyReportModal}
          onHide={() => {
            handleModalClose();
          }}
          size="lg"
          centered
        >
          <Modal.Header
            className="modal-title bg-danger-subtle text-center"
            id="myModalLabel"
            style={{ display: "block" }}
          >
            <h4>Duty Report</h4>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form onSubmit={reportDutyTemplateFormik.handleSubmit}>
              <Row>
                {/* Start Date */}
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Start Date</Form.Label>
                    <div className="position-relative form-icon">
                      <Form.Control
                        name="start_date"
                        type="date"
                        className="form-control-lg form-control-icon"
                        onChange={reportDutyTemplateFormik.handleChange}
                        onBlur={reportDutyTemplateFormik.handleBlur}
                      />
                      <i className="bx bx-calendar" style={{ fontSize: "16px" }}></i>
                      {reportDutyTemplateFormik.touched.start_date && reportDutyTemplateFormik.errors.start_date && (
                        <div className="text-danger">{reportDutyTemplateFormik.errors.start_date}</div>
                      )}
                    </div>
                  </Form.Group>
                </Col>

                {/* End Date */}
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>End Date</Form.Label>
                    <div className="position-relative form-icon">
                      <Form.Control
                        name="end_date"
                        type="date"
                        className="form-control-lg form-control-icon"
                        onChange={reportDutyTemplateFormik.handleChange}
                        onBlur={reportDutyTemplateFormik.handleBlur}
                      />
                      <i className="bx bx-calendar" style={{ fontSize: "16px" }}></i>
                      {reportDutyTemplateFormik.touched.end_date && reportDutyTemplateFormik.errors.end_date && (
                        <div className="text-danger">{reportDutyTemplateFormik.errors.end_date}</div>
                      )}
                    </div>
                  </Form.Group>
                </Col>

                {/* Client Name */}
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Client Name</Form.Label>
                    <div className="position-relative">
                      <Form.Select
                        name="client_name"
                        className="form-control-lg"
                        onChange={(e) => {
                          const selectedClientId = parseInt(e.target.value, 10);
                          reportDutyTemplateFormik.handleChange(e);
                          handleClientChange(selectedClientId); // Fetch locations for selected client
                        }}
                        onBlur={reportDutyTemplateFormik.handleBlur}
                      >
                        <option value="" hidden>Select Client</option>
                        {clientList.map((client: any) => (
                          <option key={client.value} value={client.value}>
                            {client.label}
                          </option>
                        ))}
                      </Form.Select>
                      {reportDutyTemplateFormik.touched.client_name && reportDutyTemplateFormik.errors.client_name && (
                        <div className="text-danger">{reportDutyTemplateFormik.errors.client_name}</div>
                      )}
                    </div>
                  </Form.Group>
                </Col>

                {/* Location Name */}
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Location</Form.Label>
                    <div className="position-relative">
                      <Select
                        options={locations} // Available options for locations
                        isMulti // Enable multi-selection
                        onChange={(selectedOptions: any) => {
                          const selectedIds = selectedOptions.map((option: any) => option.value);
                          reportDutyTemplateFormik.setFieldValue("location_name", selectedIds); // Correct field name
                        }}
                        onBlur={() => reportDutyTemplateFormik.setFieldTouched("location_name", true)} // Correct field name
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                        placeholder="Select Locations"
                        classNamePrefix="react-select"
                      />

                      {reportDutyTemplateFormik.touched.location_name && reportDutyTemplateFormik.errors.location_name && (
                        <div className="text-danger">
                          {reportDutyTemplateFormik.errors.location_name}
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </Col>



              </Row>
            </Form>


          </Modal.Body>
          <Modal.Footer>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={handleModalClose}>
                Cancel
              </Button>
              <Button
                variant="danger"
                type="submit"
                onClick={() => {
                  reportDutyTemplateFormik.handleSubmit();
                }}
                disabled={reportDutyTemplateFormik.isSubmitting}
              >
                {reportDutyTemplateFormik.isSubmitting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  >
                    <span className="visually-hidden">Downloading...</span>
                  </Spinner>
                ) : (
                  <>
                    <i className="ri-file-download-line"></i> Download
                  </>
                )}
              </Button>
            </div>
          </Modal.Footer>

        </Modal>


        {/* Duty Report Modal end */}
        <Modal
          show={publishModal}
          onHide={() => {
            setPublishModal(false);
          }}
          size="xl"
          centered
        >
          <Modal.Header className="modal-title bg-danger-subtle text-center" id="myModalLabel" style={{ display: 'block' }}>
            <h4>{publishModalForFinished ? 'Complete Shifts' : 'Publish'} For {selectedClient?.label}</h4>
          </Modal.Header>
          <Modal.Body className="p-4">
            <div className="mt-4">
              <Row>
                <Col lg={4}>
                  <Row>
                    <Col className="align-self-center">
                      <h5>Select Days</h5>
                    </Col>
                    <Col className="align-self-end mb-1">
                      <div className="form-check" style={{ float: 'right' }}>
                        <input className="form-check-input me-2" type="checkbox" checked={isDateAllChecked} onChange={handleDateCheckAll} />
                        Select All
                      </div>
                    </Col>
                  </Row>
                  <ListGroup>
                    {publishData.dates.map((dateItem: any, index) => (
                      <ListGroup.Item key={index}>
                        <input className="form-check-input me-2" type="checkbox" checked={checkedPublishDates.includes(dateItem.date)}
                          onChange={() => handleDateCheckboxChange(dateItem.date)} />
                        {dateItem.dayName}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Col>
                <Col lg={4}>
                  <Row>
                    <Col className="align-self-center">
                      <h5>Select Staff</h5>
                    </Col>
                    <Col className="align-self-end mb-1">
                      <div className="form-check" style={{ float: 'right' }}>
                        <input className="form-check-input me-2" type="checkbox" checked={isStaffAllChecked} onChange={handleStaffCheckAll} />
                        Select All
                      </div>
                    </Col>
                  </Row>
                  <SimpleBar style={{ maxHeight: "300px" }} data-simplebar-direction="right" data-simplebar-track="primary">
                    <ListGroup>
                      {publishData.staff_list.map((staff: any, index) => (
                        <ListGroup.Item key={index}>
                          <input className="form-check-input me-2" type="checkbox" checked={checkedPublishStaff.includes(staff.staff_id)}
                            onChange={() => handleStaffCheckboxChange(staff.staff_id)} />
                          {staff.staff_name}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </SimpleBar>
                </Col>
                <Col lg={4}>
                  <Row>
                    <Col className="align-self-center">
                      <h5>Select Location</h5>
                    </Col>
                    <Col className="align-self-end mb-1">
                      <div className="form-check" style={{ float: 'right' }}>
                        <input className="form-check-input me-2" type="checkbox" checked={isLocationAllChecked} onChange={handleLocationCheckAll} />
                        Select All
                      </div>
                    </Col>
                  </Row>
                  <SimpleBar style={{ maxHeight: "300px" }} data-simplebar-direction="right" data-simplebar-track="primary">
                    <ListGroup>
                      {publishData.location_list.map((location: any, index) => (
                        <ListGroup.Item key={index}>
                          <input className="form-check-input me-2" type="checkbox" checked={checkedPublishLocation.includes(location.location_id)}
                            onChange={() => handleLocationCheckboxChange(location.location_id)} />
                          {location.name}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </SimpleBar>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setPublishModal(false)}>Cancel</Button>
              {publishLoader ? (
                <Button
                  color="primary"
                  className="next-btn"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button variant="danger" onClick={handlePublishShifts}><i className="ri-upload-2-fill"></i> {publishModalForFinished ? 'Save' : 'Publish'}</Button>
              )}
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={archiveModal}
          onHide={() => setArchiveModal(false)}
          size="sm"
          centered
        >

          <Modal.Body className="text-center p-4">
            <i className="ri-delete-bin-line text-danger display-5"></i>
            <div className="mt-4">
              <h4 className="mb-3">Are you sure?</h4>
              <p className="text-muted mb-4">You want to pemenantly delete this Shift.</p>
              <div className="hstack gap-2 justify-content-center">
                <Button variant="light" onClick={() => setArchiveModal(false)}>Close</Button>
                <Button variant="danger" onClick={() => {
                  if (archiveId !== undefined) {
                    archiveShift(archiveId);
                  } else {
                    toast.error("No Archive ID provided.");
                  }
                }}>Delete</Button>
              </div>

            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={modal_center}
          onHide={() => {
            tog_center();
          }}
          size="sm"
          centered
        >

          <Modal.Body className="text-center p-4">
            <i className="ri-delete-bin-line text-danger display-5"></i>
            <div className="mt-4">
              <h4 className="mb-3">Are you sure?</h4>
              <p className="text-muted mb-4">You want to delete this template.</p>
              <div className="hstack gap-2 justify-content-center">
                <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
                {archiveLoader ? (
                  <Button
                    color="primary"
                    className="next-btn"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Button>
                ) : (
                  <Button variant="danger" onClick={() => {
                    if (archiveTemplateId !== undefined) {
                      archiveShiftTemplate(archiveTemplateId);
                    } else {
                      toast.error("No Archive ID provided.");
                    }
                  }}>Delete</Button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal id="myModal" show={paste_alert_standard} onHide={() => { paste_standard(); }} >
          <Modal.Header className="modal-title fs-xl" id="myModalLabel">
            Discard Changes ?
          </Modal.Header>
          <Modal.Body>
            <p className="text-muted">You have changes that have not been saved. Discard to discard the changes, or Changes to remain where you are.</p>
          </Modal.Body>
          <div className="modal-footer">
            <Button variant="light" onClick={() => paste_standard()}>Close</Button>
            <Button
              color="primary"
              onClick={() => {
                paste_standard();
                callRemovePasteData();
              }}
            >
              Discard changes
            </Button>
          </div>
        </Modal>
      </React.Fragment >
    </>
  );
};

export default ScheduleNew;
