
import React, { useState, useEffect, useMemo } from 'react';
import { Dropdown, Form, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';


interface CompanyDocumentMasterData {
    id: number;
    document_name: string;
    document_path: string;
    date_added: string,
    allow_satff_to_view: number,
    requires_acknowledgement: number,
    requires_signature: number
}

type SortOrder = 'asc' | 'desc';

interface CompanyDocumentTableProps {
    data: CompanyDocumentMasterData[];
    editMaster: (id: number) => Promise<void>;
    handlePageChange: (page: number) => void;
    handlePerRowsChange: (newPerPage: number, page: number) => void;
    paginationTotalRows: number;
    handleArchiveCompliance: (id: number) => Promise<void>;
    handleSort: (column: string, sortDirection: 'asc' | 'desc') => void;
    canAccess: boolean;
    userRoles: any;
}


const CompanyDocumentTable: React.FC<CompanyDocumentTableProps> = ({ data, editMaster, handlePageChange, handlePerRowsChange, paginationTotalRows, handleArchiveCompliance, handleSort, canAccess, userRoles }) => {

    const baseurl = process.env.REACT_APP_IMAGE_URL

    const handleOpenDocument = (path: string) => {
        const fullPath = `${baseurl}/${path}`;
        window.open(fullPath, '_blank');
    };



    const columns = [
        {
            name: <span className='font-weight-bold fs-sm'>Document name</span>,
            selector: (row: any) => row.document_name,
            sortField: 'document_name',
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-sm'>Date</span>,
            selector: (row: any) => row.date_added,
            sortField: 'date_added',
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-sm'>Visible to Staff</span>,
            cell: (row: any) => {
                return (
                    <>
                        {row?.allow_satff_to_view === 1 ? "Yes" : "No"}
                    </>
                )
            },
            sortField: 'allow_satff_to_view',
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-sm'>Requires Signature</span>,
            cell: (row: any) => {
                return (
                    <>
                        {row?.requires_signature === 1 ? "Yes" : "No"}
                    </>
                )
            },
            sortField: 'requires_signature',
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-sm'>Require Acknowledgement</span>,
            cell: (row: any) => {
                return (
                    <>
                        {row?.requires_acknowledgement === 1 ? "Yes" : "No"}
                    </>
                )
            },
            sortField: 'requires_acknowledgement',
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-sm'>Action</span>,
            // sortable: true,

            cell: (row: any) => {
                const documentPath = row.document_path;
                return (
                    <>
                        <div style={{ display: 'flex', gap: '8px' }}>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="view-tooltip">View </Tooltip>}>
                                <i
                                    onClick={() => handleOpenDocument(documentPath)}
                                    className="ri-eye-line link-info"
                                    style={{ fontSize: '18px', cursor: 'pointer' }}
                                ></i>
                            </OverlayTrigger>
                            {canAccess && userRoles[0] !== "MANAGER" && (
                                <OverlayTrigger placement="top" overlay={<Tooltip id="edit-tooltip">Edit</Tooltip>}>
                                    <i
                                        onClick={() => editMaster(row.id)}
                                        className="ri-edit-2-line link-success"
                                        style={{ fontSize: '18px', cursor: 'pointer' }}
                                    ></i>
                                </OverlayTrigger>
                            )}

                            {canAccess && userRoles[0] !== "MANAGER" && (
                                <OverlayTrigger placement="top" overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}>
                                    <i
                                        onClick={() => handleArchiveCompliance(row.id)}
                                        className="ri-delete-bin-line link-danger"
                                        style={{ fontSize: '18px', cursor: 'pointer' }}
                                    ></i>
                                </OverlayTrigger>
                            )}
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={paginationTotalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            onSort={(column: any, sortDirection: SortOrder) => {
                if (column.sortField) {
                    handleSort(column.sortField, sortDirection);
                }
            }}
            sortServer
        />
    );
}


export default CompanyDocumentTable