import { useProfile } from "Common/Hooks/UserHooks";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
type SortOrder = "asc" | "desc";

interface PayCalenderMasterData {
  id: number;
  name: string;
}

interface PaycalenderTableProps {
  data: PayCalenderMasterData[];
  editMaster: (id: number) => Promise<void>;
  handlePageChange: (page: number) => void;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  paginationTotalRows: number;
  handleArchiveCategories: (id: number) => Promise<void>;
  handleSort: (column: string, sortDirection: "asc" | "desc") => void;
}

const PayCategoriesTable: React.FC<PaycalenderTableProps> = ({
  data,
  handlePageChange,
  handlePerRowsChange,
  paginationTotalRows,
  handleSort,
  editMaster,
  handleArchiveCategories,
}) => {
  const { userProfile } = useProfile();
  const userRoles = userProfile.roles;
  const allowedRoles = ['DEVELOPER', 'SUPER_ADMIN', 'ADMIN', 'MANAGER'];
  const hasRoles = (roles: string[]) => roles.some(role => userRoles.includes(role));
  const canAccess = hasRoles(allowedRoles);
  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Name</span>,
      sortable: true,
      selector: (row: any) => row.name,
      sortField: "name",
    },
    // {
    //   name: <span className="font-weight-bold fs-sm">Type</span>,
    //   // sortable: true,
    //   selector: (row: any) => (row.for_patrol === 0 ? "Static" : "Patrol"),
    //   // sortField: "type",
    // },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      right: true,
      cell: (row: any) => {
        return (
          <>
            <div style={{ display: "flex", gap: "8px" }}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="edit-master-tooltip">Edit</Tooltip>}
              >
                <i
                  onClick={() => editMaster(row.id)}
                  style={{ fontSize: "18px", cursor: "pointer" }}
                  className="ri-edit-2-line link-success"
                ></i>
              </OverlayTrigger>

              {canAccess && userRoles[0] !== "MANAGER" && (<OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="archive-categories-tooltip">Delete</Tooltip>
                }
              >
                <i
                  onClick={() => handleArchiveCategories(row.id)}
                  style={{ fontSize: "18px", cursor: "pointer" }}
                  className="ri-delete-bin-line link-danger"
                ></i>
              </OverlayTrigger>)}
            </div>
          </>
        );
      },
      sortable: false,
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      pagination
      paginationServer
      paginationTotalRows={paginationTotalRows}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerRowsChange}
      onSort={(column: any, sortDirection: SortOrder) => {
        if (column.sortField) {
          handleSort(column.sortField, sortDirection);
        }
      }}
      sortServer // Indicate server-side sorting
    />
  );
};

export default PayCategoriesTable;
