import React, { useMemo, useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  Button,
  Offcanvas,
  Form,
  Badge,
  Table,
  Modal,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import TableContainer from "Common/TableContainer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import {
  GetByInvoicePaymentList,
  AddInvoicePaymentList,
  RemoveInvoicePaymentList,
  ExportInvoicePaymentList,
} from "helpers/saibackend_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "Common/Hooks/UserHooks";

interface InvoiceListTableProps {
  Data: any;
  onSelectedItemsChange: (selectedItems: any[]) => void;
  handleSortChange: (column: string, direction: string) => void;
  viewInvoiceBtn: () => void;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  editMaster: (id: number) => Promise<void>;
  handlePaid: (id: number) => Promise<void>;
  handleArchiveInvoice: (id: number) => Promise<void>;
  getInvoiceListDetail: (id: number) => Promise<void>;
  downloadInvoice: (id: number) => Promise<void>;
  fetchInvoiceDetails: any;
  loadingInvoices: { [id: number]: boolean };
}

const InvoiceListTable: React.FC<InvoiceListTableProps> = ({
  Data,
  onSelectedItemsChange,
  viewInvoiceBtn,
  currentPage,
  totalPages,
  onPageChange,
  handleSortChange,
  editMaster,
  handlePaid,
  handleArchiveInvoice,
  getInvoiceListDetail,
  downloadInvoice,
  fetchInvoiceDetails,
  loadingInvoices,
}) => {
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const handleSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      setSelectedItems(Data);
    } else {
      setSelectedItems([]);
    }
  };

  const handleCheckboxChange = (item: any) => {
    if (selectedItems.includes(item)) {
      setSelectedItems((prevSelected) =>
        prevSelected.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      setSelectedItems((prevSelected) => [...prevSelected, item]);
    }
  };

  useEffect(() => {
    onSelectedItemsChange(selectedItems);
  }, [selectedItems]);

  const { userProfile } = useProfile();
    const userRoles = userProfile.roles;
    const allowedRoles = ['DEVELOPER', 'SUPER_ADMIN', 'ADMIN', 'MANAGER'];
    const hasRoles = (roles: string[]) => roles.some(role => userRoles.includes(role));
    const canAccess = hasRoles(allowedRoles);

  const [viewInvoice, setViewInvoice] = useState<boolean>(false);
  const viewInvoiceShow = () => setViewInvoice(!viewInvoice);
  const [viewInvoiceData, setViewInvoiceData] = useState<any[]>([]);
  const [invoiceId, setInvoiceId] = useState<any>(0);

  const handleViewInvoice = async (id: number) => {
    try {
      const response = await GetByInvoicePaymentList(id);
      setInvoiceId(id);
      setViewInvoiceData(response.data);
      if (invoiceId) {
        setViewInvoice(true);
      } else {
        setViewInvoice(true);
      }
    } catch (error) {
      console.error("Error Getting Invoice Payment:", error);
      toast.error("Error Getting Invoice Payment. Please try again later.");
    }
  };

  const [modal_center, setmodal_center] = useState<boolean>(false);
  function tog_center() {
    setmodal_center(!modal_center);
  }

  const validationSchema = Yup.object({
    amount: Yup.number()
      .required("Amount is required")
      .typeError("Amount must be a number")
      .positive("Amount must be greater than zero"),
  });

  const formik = useFormik({
    initialValues: {
      invoice_id: invoiceId,
      amount: "",
      remark: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any, { setSubmitting }: any) => {
      try {
        const updatedvalues = {
          ...values,
          invoice_id: invoiceId,
        };
        const response = await AddInvoicePaymentList(updatedvalues);
        if (response.status === 1) {
          toast.success(response.message);
          handleViewInvoice(invoiceId);
          setmodal_center(false);
          formik.resetForm();
          fetchInvoiceDetails();
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error Adding/Updating Payment:", error);
        toast.error("Error with Payment. Please try again later.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const [archiveId, setArchiveId] = useState<number | undefined>(undefined);
  const [archiveLoader, setArchiveLoader] = useState(false);

  const handleArchiveStaff = async (id: number): Promise<void> => {
    setArchiveId(id);
    setmodal_archive_center(true);
  };

  const [modal_archive_center, setmodal_archive_center] =
    useState<boolean>(false);
  function tog_archive_center() {
    setmodal_archive_center(!modal_archive_center);
  }

  const archiveInvoice = async (id: number) => {
    setArchiveLoader(true);
    try {
      const response = await RemoveInvoicePaymentList(id);
      fetchInvoiceDetails();
      handleViewInvoice(invoiceId);
      toast.success(response.message);
      setmodal_archive_center(false);
      setArchiveLoader(false);
    } catch (error) {
      console.error("Error Archive Invoice Payment:", error);
      toast.error("Error Archive Please try again later.");
    }
  };

  const [expLoader, setExpLoader] = useState(false);

  const exportnvoice = async () => {
    setExpLoader(true);
    try {
      await ExportInvoicePaymentList(invoiceId);
      setExpLoader(false);
    } catch (error) {
      console.error("Error downloading Invoice:", error);
      toast.error("Error downloading Invoice. Please try again later.");
    }
  };

  const columns = useMemo(
    () => [
      // {
      //   header: (
      //     <Form.Check>
      //       <Form.Check.Input
      //         type="checkbox"
      //         id="checkAll"
      //         checked={selectedItems.length === Data.length}
      //         onChange={(e) => handleSelectAll(e.target.checked)}
      //       />
      //     </Form.Check>
      //   ),
      //   cell: (cellProps: any) => {
      //     return (
      //       <Form.Check>
      //         <Form.Check.Input
      //           type="checkbox"
      //           name="chk_child"
      //           checked={selectedItems.includes(cellProps.row.original)}
      //           onChange={() => handleCheckboxChange(cellProps.row.original)}
      //         />
      //       </Form.Check>
      //     );
      //   },
      //   id: "#",
      //   enableColumnFilter: false,
      //   enableSorting: false,
      // },
      {
        header: "Client Name",
        accessorKey: "client_name",
        disableFilters: true,
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Invoice Number",
        accessorKey: "invoice_number",
        disableFilters: false,
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => {
          return (
            <span style={{ color: "#F0484F" , cursor: "pointer"}}  onClick={() =>
              getInvoiceListDetail(cellProps.row.original.id)
            }
            >
              {cellProps.row.original.invoice_number}
            </span>
          );
        },
      },
      {
        header: "Invoice Date",
        accessorKey: "invoice_date",
        disableFilters: false,
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Amounts",
        accessorKey: "total_amt",
        disableFilters: false,
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => {
          return (
            <span>
              {cellProps.row.original.total_amt
                ? cellProps.row.original.total_amt
                : "0"}
            </span>
          );
        },
      },
      {
        header: "Paid Amounts",
        accessorKey: "total_paid_amt",
        disableFilters: false,
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => {
          return (
            <span>
              {cellProps.row.original.total_paid_amt
                ? cellProps.row.original.total_paid_amt
                : "0"}
            </span>
          );
        },
      },
      {
        header: "Outstanding Amounts",
        accessorKey: "total_due_amt",
        disableFilters: false,
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => {
          return (
            <span>
              {cellProps.row.original.total_due_amt
                ? cellProps.row.original.total_due_amt
                : "0"}
            </span>
          );
        },
      },
      {
        header: "Status",
        accessorKey: "is_draft",
        disableFilters: false,
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          const isDraft = row.original.is_draft;
          return isDraft === 1 ? "Draft" : "Approved";
        },
      },
      {
        header: "Paid",
        accessorKey: "is_paid",
        disableFilters: false,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          return (
            <span>
              <span
                style={{ fontSize: "12px" }}
                className={`badge bg-${
                  cellProps.row.original.is_paid === "Yes"
                    ? "success"
                    : "danger"
                }`}
              >
                {cellProps.row.original.is_paid}
              </span>
            </span>
          );
        },
      },
      {
        header: "Action",
        disableFilters: false,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          return (
            <div className="d-flex gap-2 list-unstyled mb-0">
              <div className="edit">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="view-invoice-tooltip">
                       Payment Entry
                    </Tooltip>
                  }
                >
                  <i
                    onClick={() => handleViewInvoice(cellProps.row.original.id)}
                    style={{ fontSize: "18px", cursor: "pointer" }}
                    className="ri-money-dollar-circle-line link-success"
                  ></i>
                </OverlayTrigger>
              </div>

              <div className="edit">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="invoice-details-tooltip">View</Tooltip>}
                >
                  <i
                    onClick={() =>
                      getInvoiceListDetail(cellProps.row.original.id)
                    }
                    style={{ fontSize: "18px", cursor: "pointer" }}
                    className="ri-eye-line link-info"
                  ></i>
                </OverlayTrigger>
              </div>

              <div className="edit">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="edit-tooltip">Edit</Tooltip>}
                >
                  <i
                    onClick={() => editMaster(cellProps.row.original.id)}
                    style={{
                      fontSize: "18px",
                      cursor: "pointer",
                      color: "#2272EA",
                    }}
                    className="ri-edit-2-line"
                  ></i>
                </OverlayTrigger>
              </div>

              {canAccess && userRoles[0] !== "MANAGER" && (<div className="edit">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}
                >
                  <i
                    onClick={() =>
                      handleArchiveInvoice(cellProps.row.original.id)
                    }
                    style={{ fontSize: "18px", cursor: "pointer" }}
                    className="ri-delete-bin-line link-danger"
                  ></i>
                </OverlayTrigger>
              </div>)}
              <div className="edit">
                {loadingInvoices[cellProps.row.original.id] ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="download-tooltip">Download</Tooltip>}
                  >
                    <i
                      onClick={() => downloadInvoice(cellProps.row.original.id)}
                      style={{
                        fontSize: "18px",
                        cursor: "pointer",
                        color: "#F0B414",
                      }}
                      className="ri-download-2-line"
                    ></i>
                  </OverlayTrigger>
                )}
              </div>
              {/* <div className="edit">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="share-tooltip">Share</Tooltip>}
                >
                  <i
                    onClick={() =>
                      getInvoiceListDetail(cellProps.row.original.id)
                    }
                    style={{ fontSize: "18px", cursor: "pointer" }}
                    className="ri-share-line"
                  ></i>
                </OverlayTrigger>
              </div> */}
            </div>
          );
        },
      },
    ],
    [selectedItems, Data]
  );

  return (
    <React.Fragment>
      {Data.length > 0 ? (
        <TableContainer
          isPagination={true}
          columns={columns || []}
          data={Data}
          isBordered={false}
          customPageSize={10}
          PaginationClassName="align-items-center mt-4 pt-2"
          divClassName="table-responsive table-card mt-0"
          tableClass="table-centered align-middle table-custom-effect table-nowrap mb-0"
          theadClass="text-muted table-light"
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
          onSortChange={handleSortChange}
        />
      ) : (
        <div className="noresult">
          <div className="text-center py-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light text-primary rounded-circle fs-4xl">
                <i className="bi bi-search"></i>
              </div>
            </div>
            <h5 className="mt-2">No Invoice Available</h5>
          </div>
        </div>
      )}
      <Offcanvas
        className="view-invoice"
        show={viewInvoice}
        onHide={viewInvoiceShow}
        placement="bottom"
      >
        <Offcanvas.Header className="add-header border-bottom">
          <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
            Invoice Payment Details
          </Offcanvas.Title>
          <div className="d-flex gap-2">
            <Button className="edit-btn" onClick={() => tog_center()}>
              Add
            </Button>
            {expLoader ? (
              <Button color="primary" className="export-btn" disabled>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            ) : (
              <Button onClick={exportnvoice} className="export-btn">
                <i className="bx bx-export me-1 "></i> Export
              </Button>
            )}
          </div>
          <div>
            <Button
              className="close-btn"
              onClick={() => {
                setViewInvoice(false);
              }}
            >
              <i className="ri-close-line"></i>
            </Button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col lg="12">
              {viewInvoiceData.length > 0 ? (
                <div className="table-card p-3">
                  <table className="table align-middle table-bordered">
                    <thead className="table-light">
                      <tr style={{ textAlign: "center" }}>
                        <th scope="col" style={{ width: "8%" }}>
                          Date
                        </th>
                        <th scope="col" style={{ width: "8%" }}>
                          Invoice Number
                        </th>
                        <th scope="col" style={{ width: "8%" }}>
                          Amount
                        </th>
                        <th scope="col" style={{ width: "8%" }}>
                          Remarks
                        </th>
                        <th scope="col" style={{ width: "8%" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {viewInvoiceData.map((timesheet: any) => {
                        return (
                          <tr
                            key={timesheet.id}
                            style={{ textAlign: "center" }}
                          >
                            <td>{timesheet.date}</td>
                            <td>{timesheet.invoice_number}</td>
                            <td>{timesheet.amount}</td>
                            <td>{timesheet.remark ? timesheet.remark : "-"}</td>
                            <td>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="delete-tooltip">Delete</Tooltip>
                                }
                              >
                                <i
                                  onClick={() =>
                                    handleArchiveStaff(timesheet.id)
                                  }
                                  style={{
                                    fontSize: "18px",
                                    cursor: "pointer",
                                  }}
                                  className="ri-delete-bin-line link-danger"
                                ></i>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center gap-1 p-4">
                  <div className="flex-grow-1 d-flex justify-content-center">
                    <h6 className="fs-md mb-1">No Invoice Payment Available</h6>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={modal_center}
        onHide={() => {
          tog_center();
        }}
        size="lg"
      >
        <Modal.Header className="modal-title fs-xl" id="myModalLabel">
          Add Payment
        </Modal.Header>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <Row>
              <Col lg="6">
                <Form.Group className="mb-3">
                  <Form.Label>Amount</Form.Label>
                  <div className="position-relative form-icon">
                    <Form.Control
                      type="text"
                      name="amount"
                      className={`form-control-lg form-control-icon name-input ${
                        formik.errors.amount && formik.touched.amount
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter Custom Amount"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <i
                      style={{ fontSize: "16px" }}
                      className="ri-refund-2-line"
                    ></i>
                    {formik.touched.amount && formik.errors.amount ? (
                      <div className="invalid-feedback d-block">
                        {formik.errors.amount}
                      </div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="mb-3">
                  <Form.Label>Remarks</Form.Label>
                  <div className="position-relative form-icon">
                    <Form.Control
                      type="text"
                      name="remark"
                      className="form-control-lg form-control-icon name-input"
                      placeholder="Enter Remarks.."
                      value={formik.values.remark}
                      onChange={formik.handleChange}
                    />
                    <i
                      style={{ fontSize: "16px" }}
                      className=" ri-message-3-line"
                    ></i>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <div className="modal-footer">
            <Button variant="light" onClick={() => tog_center()}>
              Close
            </Button>
            {formik.isSubmitting ? (
              <Button color="primary" className="next-btn" disabled>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            ) : (
              <Button type="submit" className="add-company" color="primary">
                Add
              </Button>
            )}
          </div>
        </Form>
      </Modal>
      <Modal
        show={modal_archive_center}
        onHide={() => {
          tog_archive_center();
        }}
        size="sm"
        centered
      >
        <Modal.Body className="text-center p-4">
          <i className="ri-delete-bin-line text-danger display-5"></i>
          <div className="mt-4">
            <h4 className="mb-3">Are you sure?</h4>
            <p className="text-muted mb-4">You want to delete this Payment.</p>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setmodal_center(false)}>
                Close
              </Button>
              {archiveLoader ? (
                <Button color="primary" className="next-btn" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button
                  variant="danger"
                  onClick={() => {
                    if (archiveId !== undefined) {
                      archiveInvoice(archiveId);
                    } else {
                      toast.error("No Archive ID provided.");
                    }
                  }}
                >
                  Delete
                </Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default InvoiceListTable;
