import React, { useState, useEffect, useRef } from "react";
import { Label } from "reactstrap";
import { Form } from 'react-bootstrap';
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";

interface GoogleAutocompleteNoDefaultValueProps {
  label: string;
  name: string;
  placeholder: string;
  onChange: (addressData: AddressData | boolean) => void;
  invalid: boolean;
  defaultValue: {
    latitude: string;
    longitude: string;
    address: string;
  };
}

interface AddressData {
  latitude: number | string;
  longitude: number | string;
  address: string;
  country: string;
  state: string;
  city: string;
  streetAddress: string;
  postalCode: string;
}

const SearchableAddress: React.FC<GoogleAutocompleteNoDefaultValueProps> = ({
  label,
  name,
  placeholder,
  onChange,
  invalid,
  defaultValue}) => {
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: "AIzaSyA35lst1bnWNgD14Eyx3ZFqhXe4qU44xts", // Use environment variable
  });

  const [value, setValue] = useState<string>(defaultValue.address);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setValue(defaultValue.address);
  }, [defaultValue.address]);

  const geocodeAddress = (geocoder: google.maps.Geocoder, address: string): Promise<google.maps.GeocoderResult | null> => {
    return new Promise((resolve, reject) => {
      geocoder.geocode({ address }, (results, status) => {
        if (status === "OK" && results && results[0]) {
          resolve(results[0]);
        } else {
          reject(status);
        }
      });
    });
  };

  const handleAddressSelect = async (selectedItem: google.maps.places.AutocompletePrediction) => {
    setValue(selectedItem.description);
    setShowDropdown(false);

    try {
      const geocoder = new window.google.maps.Geocoder();
      const results = await geocodeAddress(geocoder, selectedItem.description);

      if (results) {
        const { lat, lng } = results.geometry.location;
        const addressComponents = results.address_components;
        const addressData: AddressData = {
          latitude: lat(),
          longitude: lng(),
          address: selectedItem.description,
          country: '',
          state: '',
          city: '',
          streetAddress: '',
          postalCode: '',
        };

        addressComponents.forEach((component) => {
          component.types.forEach((type) => {
            switch (type) {
              case "country":
                addressData.country = component.long_name;
                break;
              case "administrative_area_level_1":
                addressData.state = component.long_name;
                break;
              case "locality":
              case "administrative_area_level_2":
                addressData.city = component.long_name;
                break;
              case "street_number":
              case "route":
              case "sublocality":
                if (addressData.streetAddress !== "") {
                  addressData.streetAddress += ", ";
                }
                addressData.streetAddress += component.long_name;
                break;
              case "postal_code":
                addressData.postalCode = component.long_name;
                break;
              default:
                break;
            }
          });
        });

        console.log(addressData);
        onChange(addressData);
      } else {
        console.error("Geocoding failed for the selected address.");
      }
    } catch (error) {
      console.error("An error occurred while geocoding the address:", error);
    }
  };

  const handleAddressSelectForEmptyTextBox = () => {
    const addressData: AddressData = {
      latitude: '',
      longitude: '',
      address: '',
      country: '',
      state: '',
      city: '',
      streetAddress: '',
      postalCode: '',
    };

    onChange(addressData);
  };

  return (
    // <div className={`google-autocomplete form-control ${invalid ? 'is-invalid' : ''}`} ref={dropdownRef}>
    <>
      <Label htmlFor={`${name}_id`} className="google-autocomplete-label d-none">
        {label}
      </Label>
      <Form.Control
        className="google-autocomplete-input"
        value={value}
        name={name}
        id={`${name}_id`}
        placeholder={placeholder}
        autoComplete="off"
        style={{padding:'0.7rem 1.2rem',borderRadius:'0.5rem'}}
        onChange={(evt) => {
          getPlacePredictions({ input: evt.target.value,componentRestrictions: { country: ['au','in'] } });
          setValue(evt.target.value);
          setShowDropdown(true);

          if (!evt.target.value) {
            handleAddressSelectForEmptyTextBox();
          }

          onChange(false);
        }}
        onClick={() => setShowDropdown(true)}
      />
      {showDropdown && (
        <ul className="autocomplete-dropdown" style={{background:'#fff',position:'absolute',zIndex:'1000',padding:'12px',width:'100%',listStyle:'none',boxShadow:'0 0 16px 0 rgba(0,0,0,.12)',borderRadius:'8px'}}>
        <>
          {placePredictions.map((item) => (
            <li key={item.place_id} style={{marginBottom:'8px',cursor:'pointer'}} onClick={() => handleAddressSelect(item)}>{item.description}</li>
          ))}
        </>
      </ul>
      )}
    </>
    // </div>
  );
};

export default SearchableAddress;
