import { useProfile } from "Common/Hooks/UserHooks";
import React from "react";

const Navdata = () => {
    const { userProfile } = useProfile();
  const userRoles = userProfile.roles;
  const allowedRoles = ['DEVELOPER', 'SUPER_ADMIN', 'ADMIN', 'MANAGER'];
  const hasRoles = (roles: string[]) => roles.some(role => userRoles.includes(role));
  const canAccess = hasRoles(allowedRoles);
    const menuItems: any = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "dashboard",
            label: "Admin Dashboard",
            icon: "ri-dashboard-line",
            link: "/dashboard",
            roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER','SUB_CONTRACTOR'],
        },
        {
            id: "staff-page",
            label: "Staff List",
            icon: "ri-team-line",
            link: "/staff-page",
            roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER', 'SUB_CONTRACTOR'],
        },
        {
            id: "time-off",
            label: "Time-Off",
            icon: "ri-time-line",
            link: "/time-off",
            roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'],
        },
        {
            id: "location",
            label: "Location",
            icon: "ri-map-pin-line",
            link: "/location",
            roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER', 'SUB_CONTRACTOR'],
        },
        {
            id: "subcontractor",
            label: !canAccess && userRoles[0] === "SUB_CONTRACTOR" ? "Edit-subcontractor" : "Subcontractor",
            icon: "ri-user-settings-line",
            link:
                !canAccess && userRoles[0] === "SUB_CONTRACTOR"
                    ? "/edit-subcontractor"
                    : "/subcontractor",
            roles: ['SUPER_ADMIN', 'DEVELOPER', 'ADMIN', 'MANAGER', 'SUB_CONTRACTOR'],
        },
        {
            id: "schedule",
            label: "Schedule",
            icon: "ri-layout-masonry-line",
            link: "/schedule",
            roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER', 'SUB_CONTRACTOR'],
        },
        {
            id: "timesheet",
            label: "Timesheet",
            icon: "mdi mdi-calendar-clock",
            link: "/timesheet",
            roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'],
        },
        {
            id: "client-list",
            label: "Client List",
            icon: "ri-user-line",
            link: "/client-list",
            roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'],
        },
        {
            id: "payroll",
            label: "Payroll",
            icon: "ri-refund-2-line",
            link: "/payroll",
            roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'],
        },
        {
            id: "invoice-list",
            label: "Invoice List",
            icon: "ri-file-text-line",
            link: "/invoice-list",
            roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'],
        },
        {
            id: "patrol-location",
            label: "Patrol Location",
            icon: "ri-road-map-line",
            link: "/patrol-location",
            roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'],
        },
        // {
        //     id: "company-setting",
        //     label: "Company Setting",
        //     icon: "ri-settings-2-line",
        //     link: "/company-setting",
        //     className: "setting",
        //     roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'],
        // },
        {
            id: "organization-setting",
            label: "Organization Setting",
            icon: "ri-folder-settings-line",
            link: "/organization-setting",
            roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'],
        },
        {
            id: "incident-master",
            label: "Incident",
            icon: "ri-spam-line",
            link: "/incident-master",
            roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER','SUB_CONTRACTOR'],
        }
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};

export default Navdata;