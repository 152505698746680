import React, { ChangeEvent, useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  Button,
  Offcanvas,
  Form,
  Tab,
  Nav,
  Modal,
  Badge,
  Spinner
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import {
  getStaffDetailsDetail,
  addStaffDetails,
  getAccessLevelDetail,
  getSubcontractorFilterList,
  addBulkStaffDetail,
  archiveStaffDetail,
  restoreStaffDetail,
  getEmpTypeListDetail,
  getExportStaffDetailsDetail
} from "helpers/saibackend_helper";
import SimpleBar from "simplebar-react";
import StaffTable from "./workforcetables/StaffTable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import usflag from "assets/images/flags/us.svg";
import { FormikErrors, useFormik } from "formik";
import * as Yup from "yup";
import CountUp from 'react-countup';
import { useProfile } from "Common/Hooks/UserHooks";
import Select from 'react-select';

interface StaffData {
  id: number;
  employee_name: string;
  email: string;
  mobile_no: string;
  gender: string;
  access_level: string;
  has_subcontractor: number;
  subcontractor_id: number;
  invited: number;
  employee_status: string;
}

interface StaffSummary {
  active_count: number,
  invited_count: number,
  onboarding_pending_count: number,
  today_leave_staff: number
}

interface AccessLevelData {
  id: number;
  name: string;
}

interface EmptypeData {
  id: number;
  type: string;
}

interface RowValues {
  avatar: string;
  first_name: string;
  last_name: string;
  email: string;
  mobile_no: string;
  employee_status: string;
  access_level: string;
  has_subcontractor: number;
  subcontractor_id: number;
  employee_pin: string;
  invited: number;
}

interface FormValues {
  rows: RowValues[];
}

interface PayloadValue {
  page: number;
  page_size: string;
  search_term: string;
  sort: {
    sort_order: string;
    sort_by: string;
  };
  is_archive: number;
  employee_type: number | number[];
  access_level: number | number[];
  status: number | number[]
}

const StaffPage = () => {
  document.title = "Staff | The Guard Duty";

  const { userProfile } = useProfile();
  const userRoles = userProfile.roles;
  const allowedRoles = ['DEVELOPER', 'SUPER_ADMIN', 'ADMIN', 'MANAGER'];
  const hasRoles = (roles: string[]) => roles.some(role => userRoles.includes(role));
  const canAccess = hasRoles(allowedRoles);

  const [StaffDetail, setStaffDetail] = useState<StaffData[]>([]);
  const [staffSummary, setStaffSummary] = useState<StaffSummary>({
    active_count: 0,
    invited_count: 0,
    onboarding_pending_count: 0,
    today_leave_staff: 0
  });

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [payloadValue, setPayloadValue] = useState<PayloadValue>({
    page: 1,
    page_size: "10",
    search_term: "",
    sort: {
      sort_order: "ASC",
      sort_by: ""
    },
    is_archive: 0,
    employee_type: 0,
    access_level: 0,
    status: 0
  });

  const fetchStaffDetails = async () => {
    try {
      const response = await getStaffDetailsDetail(
        payloadValue.page,
        payloadValue.page_size,
        payloadValue.search_term,
        payloadValue.sort.sort_order,
        payloadValue.sort.sort_by,
        payloadValue.is_archive,
        payloadValue.employee_type,
        payloadValue.access_level,
        payloadValue.status,
      );
      setStaffDetail(response.data.data_list);
      setTotalPages(response.data.total_pages);
      setStaffSummary(response.data.summary)
      setCurrentPage(payloadValue.page);
      setTotalRecords(response.data.total_records);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchStaffDetails();
  }, [payloadValue]);

  const handleCardClick = (statusId: number) => {
    setPayloadValue((prev) => ({
      ...prev,
      page: 1,
      status: [statusId],
    }));
    setLoading(true);
  };



  const handleSortChange = (column: string) => {
    setPayloadValue(prevState => ({
      ...prevState,
      sort: {
        sort_order: prevState.sort.sort_by === column && prevState.sort.sort_order === "ASC" ? "DESC" : "ASC",
        sort_by: column
      },
      page: 1
    }));
  };


  const handlePageChange = (page: number) => {
    setPayloadValue({ ...payloadValue, page: page });
  };

  const generateUniquePin = () => {
    return Math.floor(1000 + Math.random() * 9000).toString();
  };

  useEffect(() => {
    const randomPin = generateUniquePin();
    formik.setFieldValue("rows[0].employee_pin", randomPin);
  }, []);

  const validationSchema = Yup.object().shape({
    rows: Yup.array().of(
      Yup.object().shape({
        first_name: Yup.string().required("First name is required").matches(/^[A-Za-z0-9]+$/, "First name can only contain letters and digits"),
        last_name: Yup.string().matches(/^[A-Za-z0-9 ]+$/, "Last name can only contain letters, digits, and spaces"),
        mobile_no: Yup.number()
          .positive("Mobile Number must be a positive number")
          .integer("Mobile Number must be an integer"),
        email: Yup.string()
          .email("Invalid email format"),
        // employement_type: Yup.string().nullable().required("Staff Type is required"),
        // access_level: Yup.string().nullable().required("Access Level is required"),
        // subcontractor_id: Yup.string().when('employement_type', {
        //   is: '4',
        //   then: Yup.string().required("Subcontractor ID is required"),
        //   otherwise: Yup.string().nullable()
        // }),
      })
    ),
  });

  const initialValues: FormValues = {
    rows: [
      {
        avatar: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile_no: "",
        employee_status: "1",
        access_level: "EMPLOYEE",
        has_subcontractor: 0,
        subcontractor_id: 0,
        employee_pin: generateUniquePin(),
        invited: 0,
      },
    ],
  };

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setPayloadValue((prevPayload) => ({
        ...prevPayload,
        search_term: inputValue,
        page: 1
      }));
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const formik = useFormik<FormValues>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const response = await addStaffDetails(values.rows);
        if (response.status === 0) {
          toast.error(response.message);
          setSubmitting(false);
          return;
        }
        toast.success(response.message);
        toggleBottomCanvas();
        fetchStaffDetails();
        formik.resetForm();
      } catch (error) {
        console.error("Error Adding/Updating Staff:", error);
        toast.error("Error Adding Staff. Please try again later.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const [archiveId, setArchiveId] = useState<number | undefined>(undefined);
  const [archiveLoader, setArchiveLoader] = useState(false)

  const handleArchiveStaff = async (id: number): Promise<void> => {
    setArchiveId(id);
    setmodal_center(true);
  };

  const [modal_center, setmodal_center] = useState<boolean>(false);
  function tog_center() {
    setmodal_center(!modal_center);
  }


  const archiveMaster = async (id: number) => {
    setArchiveLoader(true)
    try {
      const response = await archiveStaffDetail(id);
      fetchStaffDetails()
      toast.success(response.message);
      setmodal_center(false)
      setArchiveLoader(false);
    } catch (error) {
      console.error("Error Archive Staff :", error);
      toast.error("Error Archive Please try again later.");
    }
  };

  const [restoreId, setRestoreId] = useState<number | undefined>(undefined);
  const [restoreLoader, setRestoreLoader] = useState(false)

  const handleRestoreStaff = async (id: number): Promise<void> => {
    setRestoreId(id);
    setmodal_restore_center(true);
  };


  const [modal_restore_center, setmodal_restore_center] = useState<boolean>(false);
  function tog_restore_center() {
    setmodal_restore_center(!modal_restore_center);
  }

  const restoreStaff = async (id: number) => {
    setRestoreLoader(true)
    try {
      const response = await restoreStaffDetail(id);
      fetchStaffDetails();
      toast.success(response.message);
      setmodal_restore_center(false);
      setRestoreLoader(false)
    } catch (error) {
      console.error("Error Restore Staff:", error);
      toast.error("Error Restore Please try again later.");
    }
  };

  const [isBottom, setIsBottom] = useState(false);
  const toggleBottomCanvas = () => {
    formik.resetForm();
    setIsBottom(!isBottom);
  };

  /*Filters Dropdown Start*/
  const [staffFilters, setStaffFilters] = useState<boolean>(false);
  const [SubcontractorList, SetSubcontractorList] = useState<any[]>([]);
  const [AccessLevelDetail, setAccessLevelDetail] = useState<AccessLevelData[]>([]);
  const [EmpTypeDetail, setEmpTypeDetail] = useState<EmptypeData[]>([]);

  const fetchSubcontractorDetails = async () => {
    try {
      const searchTerm = "";
      const response = await getSubcontractorFilterList(searchTerm);
      SetSubcontractorList(response.data.data_list);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAccessLevelDetails = async () => {
    try {
      const searchTerm = "";
      const response = await getAccessLevelDetail(searchTerm);
      setAccessLevelDetail(response.data.data_list);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEmpTypeDetails = async () => {
    try {
      const searchTerm = "";
      const response = await getEmpTypeListDetail(searchTerm);
      setEmpTypeDetail(response.data.data_list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (staffFilters || isBottom) {
      fetchAccessLevelDetails();
      fetchEmpTypeDetails();
      fetchSubcontractorDetails();
    }
  }, [staffFilters, isBottom]);
  /*Filters Dropdown Finished */

  const [selectedCountries, setSelectedCountries] = useState<any[]>([
    {
      id: 1,
      flagImg: usflag,
      countryName: "United States of America",
      countryCode: "+1",
    },
  ]);

  const addRow = () => {
    const newRow = {
      avatar: "",
      first_name: "",
      last_name: "",
      email: "",
      mobile_no: "",
      employee_status: "1",
      access_level: "EMPLOYEE",
      has_subcontractor: 0,
      subcontractor_id: 0,
      employee_pin: generateUniquePin(),
      invited: 0,
    };
    formik.setFieldValue("rows", [...formik.values.rows, newRow]);
    setSelectedCountries((prevSelectedCountries) => [
      ...prevSelectedCountries,
      {
        id: prevSelectedCountries.length + 1,
        flagImg: usflag,
        countryName: "United States of America",
        countryCode: "+1",
      },
    ]);
  };

  const deleteRow = (id: number) => {
    if (id !== 0) {
      const updatedRows = formik.values.rows.filter((_, index) => index !== id);
      formik.setFieldValue("rows", updatedRows);
      const updatedCountries = selectedCountries.filter(
        (_, index) => index !== id
      );
      setSelectedCountries(updatedCountries);
    }
  };

  const handleInviteCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = e.target;
    const invitedValue = checked ? 1 : 0;

    formik.setFieldValue(
      "rows",
      formik.values.rows.map((row) => ({
        ...row,
        invited: invitedValue,
      }))
    );
  };

  // Staff Filters //
  // ========================================================================= //

  const status = [
    {
      name: 'Invited Pending',
      id: 0
    },
    {
      name: 'Invited',
      id: 1
    },
    {
      name: 'Onboarding Pending',
      id: 2
    },
    {
      name: 'Active',
      id: 3
    }

  ]


  const [selectedEmploymentTypes, setSelectedEmploymentTypes] = useState<number[]>([]);
  const [selectedAccessLevels, setSelectedAccessLevels] = useState<number[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<number[]>([]);
  const [allEmploymentTypesChecked, setAllEmploymentTypesChecked] = useState(false);
  const [allAccessLevelsChecked, setAllAccessLevelsChecked] = useState(false);
  const [allStatusChecked, setAllStatusChecked] = useState(false);
  const [isArchived, setIsArchived] = useState(false);

  const handleEmploymentTypeChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (e.target.checked) {
      const newSelected = [...selectedEmploymentTypes, id];
      setSelectedEmploymentTypes(newSelected);
      if (newSelected.length === EmpTypeDetail.length) {
        setAllEmploymentTypesChecked(true);
      }
    } else {
      const newSelected = selectedEmploymentTypes.filter(typeId => typeId !== id);
      setSelectedEmploymentTypes(newSelected);
      setAllEmploymentTypesChecked(false);
    }
  };

  const handleAccessLevelChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (e.target.checked) {
      const newSelected = [...selectedAccessLevels, id];
      setSelectedAccessLevels(newSelected);
      if (newSelected.length === AccessLevelDetail.length) {
        setAllAccessLevelsChecked(true);
      }
    } else {
      const newSelected = selectedAccessLevels.filter(levelId => levelId !== id);
      setSelectedAccessLevels(newSelected);
      setAllAccessLevelsChecked(false);
    }
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (e.target.checked) {
      const newSelected = [...selectedStatus, id];
      setSelectedStatus(newSelected);
      if (newSelected.length === status.length) {
        setAllStatusChecked(true);
      }
    } else {
      const newSelected = selectedStatus.filter(levelId => levelId !== id);
      setSelectedStatus(newSelected);
      setAllStatusChecked(false);
    }
  };


  const handleAllEmploymentTypesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setAllEmploymentTypesChecked(true);
      setSelectedEmploymentTypes(EmpTypeDetail.map(type => type.id));
    } else {
      setAllEmploymentTypesChecked(false);
      setSelectedEmploymentTypes([]);
    }
  };

  const handleAllAccessLevelsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setAllAccessLevelsChecked(true);
      setSelectedAccessLevels(AccessLevelDetail.map(level => level.id));
    } else {
      setAllAccessLevelsChecked(false);
      setSelectedAccessLevels([]);
    }
  };

  const handleAllStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setAllStatusChecked(true);
      setSelectedStatus(status.map(level => level.id));
    } else {
      setAllStatusChecked(false);
      setSelectedStatus([]);
    }
  };

  const handleApplyFilters = () => {
    setPayloadValue(prevState => ({
      ...prevState,
      employee_type: allEmploymentTypesChecked ? [] : selectedEmploymentTypes,
      access_level: allAccessLevelsChecked ? [] : selectedAccessLevels,
      status: allStatusChecked ? [] : selectedStatus,
      is_archive: Number(isArchived),
      page: 1
    }));
    setStaffFilters(false)
  };

  const clearEmpType = (() => {
    setSelectedEmploymentTypes([]);
    setAllEmploymentTypesChecked(false);
    setPayloadValue(prevState => ({
      ...prevState,
      employee_type: [],
      page: 1
    }));
  })

  const clearAccessLevel = (() => {
    setSelectedAccessLevels([]);
    setAllAccessLevelsChecked(false);
    setPayloadValue(prevState => ({
      ...prevState,
      access_level: []
    }));
  })

  const clearStatus = (() => {
    setSelectedStatus([]);
    setAllStatusChecked(false);
    setPayloadValue(prevState => ({
      ...prevState,
      status: [],
      page: 1
    }));
  })

  const clearAllFilters = (() => {
    setSelectedEmploymentTypes([]);
    setAllEmploymentTypesChecked(false);
    setSelectedAccessLevels([]);
    setAllAccessLevelsChecked(false);
    setSelectedStatus([]);
    setIsArchived(false)
    setAllStatusChecked(false);
    setPayloadValue(prevState => ({
      ...prevState,
      employee_type: [],
      access_level: [],
      status: [],
      is_archive: 0,
      page: 1
    }));
    setStaffFilters(false)
  })


  // CSV File ADD Staff API Integration //
  // ========================================================================================//

  const [file, setFile] = useState<File | null>(null);
  const [showUploadButton, setShowUploadButton] = useState(true);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      setFile(selectedFiles[0]);
      setShowUploadButton(false);
      Csvformik.setFieldValue("csv_file", selectedFiles[0]);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setShowUploadButton(true);
    Csvformik.setFieldValue("csv_file", null);
  };


  const handleFilePreview = (file: File | null) => {
    if (file) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    } else {
      return;
    }
  }

  const bulkValidationSchema = Yup.object().shape({
    csv_file: Yup.mixed().required("CSV File is required"),
  });

  const Csvformik = useFormik({
    initialValues: {
      csv_file: "",
    },
    validationSchema: bulkValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const response = await addBulkStaffDetail(values);
        if (response.status === 1) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
          return;
        }
        fetchStaffDetails();
        toggleBottomCanvas();
        Csvformik.resetForm();
        setFile(null);
        setShowUploadButton(true);
      } catch (error) {
        console.error("Error Adding/Updating Bulk Staff:", error);
        toast.error("Error Adding Bulk Staff. Please try again later.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const [expLoader, setExpLoader] = useState(false)

  const downloadStaff = async () => {
    setExpLoader(true)
    try {
      await getExportStaffDetailsDetail(payloadValue.page,
        payloadValue.page_size,
        payloadValue.search_term,
        payloadValue.sort.sort_order,
        payloadValue.sort.sort_by,
        payloadValue.is_archive,
        payloadValue.employee_type,
        payloadValue.access_level,
        payloadValue.status);
      setExpLoader(false)
    } catch (error) {
      console.error("Error downloading Staff:", error);
      toast.error("Error downloading Staff. Please try again later.");
    }
  }

  const handleDownload = () => {
    const csvContent =
      `First Name,Last Name,Email,Contact No.,Employment Type,Access Level,Subcontractor Name\n` +
      `John,Doe,john@gmail.com,7845784578,Full-time,EMPLOYEE,\n` +
      `Melinda,Chapman,melinda@gmail.com,4578475475,Full-time,EMPLOYEE,\n` +
      `Sheila,Ray,sheila@gmail.com,4578475476,part-time,ADMIN,\n` +
      `Cairo,Cole,cairo123@gmail.com,4578475476,part-time,MANAGER,\n` +
      `Vaughan,Rosario,vaughan@yahoo.com,4578475477,Contract Worker,EMPLOYEE,Rhea Moses\n` +
      `Chloe,Solomon,chole32@yahoo.com,4578475478,Contract Worker,EMPLOYEE,Ralph Franks`;


    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);

    const link = document.createElement('a');
    link.href = encodedUri;
    link.download = 'staff_members.csv';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Staff" pageTitle="Dashboard" />
          {
            loading ?
              <Row className="placeholder-glow">
                <Col lg={3}>
                  <div className="placeholder bg-light card-placeholder col-12"></div>
                </Col>
                <Col lg={3}>
                  <div className="placeholder bg-light card-placeholder col-12"></div>
                </Col>
                <Col lg={3}>
                  <div className="placeholder bg-light card-placeholder col-12"></div>
                </Col>
                <Col lg={3}>
                  <div className="placeholder bg-light card-placeholder col-12"></div>
                </Col>
                <Col lg={12} className="mt-4">
                  <div className="placeholder bg-light table-placeholder col-12"></div>
                </Col>
              </Row>
              :
              <Row className="workforce-staff">
                <Col lg={3}>
                  <Card className="border-bottom border-3 card-animate border-primary">
                    <Card.Body onClick={() => handleCardClick(3)}>
                      <div className="avatar-sm float-end">
                        <div className="avatar-title bg-primary-subtle text-primary fs-3xl rounded">
                          <i className="bi bi-people"></i>
                        </div>
                      </div>
                      <h4 className="mb-4"><span className="counter-value" data-target="40"><CountUp end={staffSummary?.active_count ? staffSummary.active_count : "-"} separator=',' /></span></h4>

                      <p className="text-muted fw-medium text-uppercase mb-0">Active Staff</p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card
                    className="border-bottom border-3 card-animate border-warning"
                  >
                    <Card.Body onClick={() => handleCardClick(1)}>
                      <div className="avatar-sm float-end">
                        <div className="avatar-title bg-warning-subtle text-warning fs-3xl rounded">
                          <i className="bi bi-people"></i>
                        </div>
                      </div>
                      <h4 className="mb-4">
                        <span className="counter-value" data-target="40">
                          <CountUp end={staffSummary?.invited_count ? staffSummary.invited_count : "-"} separator="," />
                        </span>
                      </h4>
                      <p className="text-muted fw-medium text-uppercase mb-0">Invited Staff</p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card className="border-bottom border-3 card-animate border-success">
                    <Card.Body onClick={() => handleCardClick(2)}>
                      <div className="avatar-sm float-end">
                        <div className="avatar-title bg-success-subtle text-success fs-3xl rounded">
                          <i className="bi bi-people"></i>
                        </div>
                      </div>
                      <h4 className="mb-4"><span className="counter-value" data-target="40"><CountUp end={staffSummary?.onboarding_pending_count ? staffSummary.onboarding_pending_count : "-"} separator=',' /></span></h4>

                      <p className="text-muted fw-medium text-uppercase mb-0">OnBoarding Pending</p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card className="border-bottom border-3 card-animate border-info">
                    <Card.Body>
                      <div className="avatar-sm float-end">
                        <div className="avatar-title bg-info-subtle text-info fs-3xl rounded">
                          <i className="bi bi-people"></i>
                        </div>
                      </div>
                      <h4 className="mb-4"><span className="counter-value" data-target="40"><CountUp end={staffSummary?.today_leave_staff ? staffSummary.today_leave_staff : "-"} separator=',' /></span></h4>

                      <p className="text-muted fw-medium text-uppercase mb-0">Staff On Leave</p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={12}>
                  <Card className="staff-tabel-card">
                    <Card.Body>
                      <Row className="align-items-center g-2">
                        <Col lg={5} className="btn-employee-section mb-3">
                          <h5>Employees List <Badge><i className="ri-group-line"></i> {totalRecords}</Badge></h5>
                        </Col>
                        <Col lg={7} className="btn-section mb-3">
                          <div className="app-search d-none d-md-inline-flex">
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                                id="search-options"
                                value={inputValue}
                                onChange={handleChange}
                              />
                              <span className="mdi mdi-magnify search-widget-icon"></span>
                              <span
                                className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                id="search-close-options"
                              ></span>
                            </div>
                          </div>
                          <Button
                            className="filter-btn"
                            onClick={() => setStaffFilters(true)}
                          >
                            <i className="ri-list-settings-line align-baseline me-1"></i>{" "}
                            Filters
                          </Button>
                          <Button
                            onClick={toggleBottomCanvas}
                            className="next-btn"
                          >
                            <i className="ri-add-fill"></i> Add New
                          </Button>
                          {expLoader ? (
                            <Button
                              color="primary"
                              className="export-btn"
                              disabled
                            >
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </Button>
                          ) : (
                            <Button onClick={downloadStaff} className='export-btn'>
                              <i className="bx bx-export me-1 "></i>  Export
                            </Button>
                          )}
                        </Col>
                        <Col lg={12} className="leave-table mt-0">
                          <StaffTable
                            StaffDetail={StaffDetail}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            handleArchiveStaff={handleArchiveStaff}
                            handleRestoreStaff={handleRestoreStaff}
                            handleSortChange={handleSortChange}
                            canAccess={canAccess}
                            userRoles={userRoles}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
          }

        </Container>
      </div>
      <Offcanvas
        className="filter-canvas"
        show={staffFilters}
        placement="end"
        onHide={() => setStaffFilters(false)}
      >
        <Offcanvas.Header
          id="staffFilters"
          className="border-bottom filter-canvas"
          closeButton
        >
          <Offcanvas.Title id="courseFiltersLabel">
            <i className="ri-list-settings-line align-baseline me-1"></i>
            Filters
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            <Row>
              <Col lg={12}>
                {canAccess &&
                  <>
                    <Row className="filter-1">
                      <Col lg={12} className="d-flex justify-content-between">
                        <h5>Employment Type</h5>
                        <p onClick={clearEmpType} style={{ cursor: "pointer" }}>Clear All</p>
                      </Col>
                      <Col lg={12}>
                        <div className="form-check mb-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="AllType"
                            checked={allEmploymentTypesChecked}
                            onChange={handleAllEmploymentTypesChange}
                          />
                          <label className="form-check-label" htmlFor="AllType">
                            All Employment Type
                          </label>
                        </div>
                      </Col>
                      {EmpTypeDetail.map(type => (
                        <Col lg={6} className="filter-radio" key={type.id}>
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`employmentTypeCheckbox${type.id}`}
                              checked={selectedEmploymentTypes.includes(type.id)}
                              onChange={(e) => handleEmploymentTypeChange(e, type.id)}
                            />
                            <label className="form-check-label" htmlFor={`employmentTypeCheckbox${type.id}`}>
                              {type.type}
                            </label>
                          </div>
                        </Col>
                      ))}
                    </Row>
                    <Row className="filter-2">
                      <Col lg={12} className="d-flex justify-content-between">
                        <h5>Access Level</h5>
                        <p onClick={clearAccessLevel} style={{ cursor: "pointer" }}>Clear All</p>
                      </Col>
                      <Col lg={12}>
                        <div className="form-check mb-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="AllLevel"
                            checked={allAccessLevelsChecked}
                            onChange={handleAllAccessLevelsChange}
                          />
                          <label className="form-check-label" htmlFor="AllLevel">
                            All Access Level
                          </label>
                        </div>
                      </Col>
                      {AccessLevelDetail.map(accessLevel => (
                        <Col lg={6} key={accessLevel.id}>
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`accessLevelCheckbox${accessLevel.id}`}
                              checked={selectedAccessLevels.includes(accessLevel.id)}
                              onChange={(e) => handleAccessLevelChange(e, accessLevel.id)}
                            />
                            <label className="form-check-label" htmlFor={`accessLevelCheckbox${accessLevel.id}`}>
                              {accessLevel.name}
                            </label>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </>
                }
                <Row className="filter-2">
                  <Col lg={12} className="d-flex justify-content-between">
                    <h5>Status</h5>
                    <p onClick={clearStatus} style={{ cursor: "pointer" }}>Clear All</p>
                  </Col>
                  <Col lg={12}>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="Allstatus"
                        checked={allStatusChecked}
                        onChange={handleAllStatusChange}
                      />
                      <label className="form-check-label" htmlFor="Allstatus">
                        All Status
                      </label>
                    </div>
                  </Col>
                  {status.map(status => (
                    <Col lg={6} key={status.id}>
                      <div className="form-check mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`statusCheckbox${status.id}`}
                          checked={selectedStatus.includes(status.id)}
                          onChange={(e) => handleStatusChange(e, status.id)}
                        />
                        <label className="form-check-label" htmlFor={`statusCheckbox${status.id}`}>
                          {status.name}
                        </label>
                      </div>
                    </Col>
                  ))}
                </Row>

                <Row className="filter-2">
                  <Col lg={12}>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="archive"
                        checked={isArchived}
                        onChange={() => setIsArchived(!isArchived)}
                      />
                      <label className="form-check-label font-weight-bold" htmlFor="archive">
                        <b>Show Archive List</b>
                      </label>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
        <Row>
          <Col lg="12">
            <div className="offcanvas-footer border-top-0 p-3 text-center">
              <Row className="px-2 approve-reject-btn">
                <Col lg={6} className="clear-section">
                  <Button
                    color="primary"
                    className="clear-btn"
                    onClick={clearAllFilters}
                  >
                    Clear Filters
                  </Button>
                </Col>
                <Col lg={6} className="Apply-section">
                  <Button color="primary" className="next-btn" onClick={handleApplyFilters}>
                    Apply Filters
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Offcanvas>
      <Offcanvas
        className="add-new"
        show={isBottom}
        onHide={toggleBottomCanvas}
        placement="bottom"
        style={{ minHeight: "581px" }}
      >
        <Offcanvas.Header className="add-header border-bottom" closeButton>
          <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
            Add New Guard
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SimpleBar style={{ maxHeight: "350px" }} className="px-3">
            <Tab.Container defaultActiveKey="home">
              <Nav as="ul" variant="tabs" className="mb-3">
                <Nav.Item as="li">
                  {" "}
                  <Nav.Link
                    style={{ fontSize: "15px", fontWeight: "600" }}
                    eventKey="home"
                  >
                    {" "}
                    Invite{" "}
                  </Nav.Link>{" "}
                </Nav.Item>
                <Nav.Item as="li">
                  {" "}
                  <Nav.Link
                    style={{ fontSize: "15px", fontWeight: "600" }}
                    eventKey="product1"
                  >
                    {" "}
                    Invite Bulk{" "}
                  </Nav.Link>{" "}
                </Nav.Item>
              </Nav>

              <Tab.Content className="text-muted">
                <Tab.Pane eventKey="home" id="home">
                  <Form onSubmit={formik.handleSubmit}>
                    {formik.values.rows.map((row, index) => (
                      <React.Fragment key={index}>
                        <Row>
                          <Col md={11}>
                            <Row>
                              <Col>
                                <Form.Group className="mb-3">
                                  <Form.Label>First name*</Form.Label>
                                  <div className="position-relative form-icon">
                                    <Form.Control
                                      type="text"
                                      name={`rows[${index}].first_name`}
                                      value={row.first_name}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      placeholder="Enter First Name"
                                      className="form-control-lg form-control-icon name-input"
                                      isInvalid={
                                        !!(
                                          formik.touched.rows?.[index]
                                            ?.first_name &&
                                          typeof formik.errors.rows === "object" &&
                                          (
                                            formik.errors
                                              .rows as FormikErrors<RowValues>[]
                                          )[index]?.first_name
                                        )
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formik.touched.rows?.[index]?.first_name &&
                                        typeof formik.errors.rows === "object" &&
                                        (
                                          formik.errors
                                            .rows as FormikErrors<RowValues>[]
                                        )[index]?.first_name}
                                    </Form.Control.Feedback>
                                    <i
                                      style={{ fontSize: "16px" }}
                                      className="bx bx-face"
                                    ></i>
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group className="mb-3">
                                  <Form.Label>Last name*</Form.Label>
                                  <div className="position-relative form-icon">
                                    <Form.Control
                                      type="text"
                                      name={`rows[${index}].last_name`}
                                      className="form-control-lg form-control-icon name-input"
                                      placeholder="Enter Last Name"
                                      value={formik.values.rows[index].last_name}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      isInvalid={
                                        !!(
                                          formik.touched.rows?.[index]?.last_name &&
                                          typeof formik.errors.rows === "object" &&
                                          (
                                            formik.errors
                                              .rows as FormikErrors<RowValues>[]
                                          )[index]?.last_name
                                        )
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formik.touched.rows?.[index]?.last_name &&
                                        typeof formik.errors.rows === "object" &&
                                        (
                                          formik.errors
                                            .rows as FormikErrors<RowValues>[]
                                        )[index]?.last_name}
                                    </Form.Control.Feedback>
                                    <i
                                      style={{ fontSize: "16px" }}
                                      className="bx bx-face"
                                    ></i>
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group className="mb-3">
                                  <Form.Label>Contact Number*</Form.Label>
                                  <div className="position-relative form-icon">
                                    <Form.Control
                                      type="text"
                                      name={`rows[${index}].mobile_no`}
                                      className="form-control-lg form-control-icon name-input"
                                      placeholder="Enter number"
                                      value={formik.values.rows[index].mobile_no}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      isInvalid={
                                        !!(
                                          formik.touched.rows?.[index]?.mobile_no &&
                                          typeof formik.errors.rows === "object" &&
                                          (
                                            formik.errors
                                              .rows as FormikErrors<RowValues>[]
                                          )[index]?.mobile_no
                                        )
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formik.touched.rows?.[index]?.mobile_no &&
                                        typeof formik.errors.rows === "object" &&
                                        (
                                          formik.errors
                                            .rows as FormikErrors<RowValues>[]
                                        )[index]?.mobile_no}
                                    </Form.Control.Feedback>
                                    <i
                                      style={{ fontSize: "16px" }}
                                      className="ri-keyboard-line"
                                    ></i>
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group className="mb-3">
                                  <Form.Label>Email address*</Form.Label>
                                  <div className="position-relative form-icon">
                                    <Form.Control
                                      type="email"
                                      name={`rows[${index}].email`}
                                      className="form-control-lg form-control-icon name-input"
                                      placeholder="Enter Email address"
                                      value={formik.values.rows[index].email}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      isInvalid={
                                        !!(
                                          formik.touched.rows?.[index]?.email &&
                                          typeof formik.errors.rows === "object" &&
                                          (
                                            formik.errors
                                              .rows as FormikErrors<RowValues>[]
                                          )[index]?.email
                                        )
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formik.touched.rows?.[index]?.email &&
                                        typeof formik.errors.rows === "object" &&
                                        (
                                          formik.errors
                                            .rows as FormikErrors<RowValues>[]
                                        )[index]?.email}
                                    </Form.Control.Feedback>
                                    <i
                                      style={{ fontSize: "16px" }}
                                      className="ri-mail-line"
                                    ></i>
                                  </div>
                                </Form.Group>
                              </Col>
                              {canAccess &&
                                <>

                                  <Col>
                                    <Form.Group className="mb-3 form-icon">
                                      <Form.Label>Employment Type*</Form.Label>
                                      <Form.Select
                                        name={`rows[${index}].employee_status`}
                                        className="form-select-lg form-control-icon"
                                        value={
                                          formik.values.rows[index].employee_status ||
                                          ""
                                        }
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          formik.setFieldTouched(
                                            `rows[${index}].employee_status`,
                                            true,
                                            true
                                          );
                                        }}
                                        onBlur={formik.handleBlur}
                                        isInvalid={
                                          !!(
                                            formik.touched.rows?.[index]
                                              ?.employee_status &&
                                            typeof formik.errors.rows === "object" &&
                                            (
                                              formik.errors
                                                .rows as FormikErrors<RowValues>[]
                                            )[index]?.employee_status
                                          )
                                        }
                                      >
                                        {EmpTypeDetail.map((empTypeDetail) => {
                                          return (
                                            <option key={empTypeDetail.id} value={empTypeDetail.id}>{empTypeDetail.type}</option>
                                          )
                                        })}
                                      </Form.Select>
                                      {/* <Form.Control.Feedback type="invalid">
                                    {formik.touched.rows?.[index]?.employement_type && typeof formik.errors.rows === 'object' && (formik.errors.rows as FormikErrors<RowValues>[])[index]?.employement_type}
                                  </Form.Control.Feedback> */}
                                      <i
                                        style={{ fontSize: "16px" }}
                                        className="ri-account-circle-line mt-4"
                                      ></i>
                                    </Form.Group>
                                  </Col>
                                  <Col>
                                    <Form.Group className="mb-3 form-icon">
                                      <Form.Label>Access level*</Form.Label>
                                      <Form.Select
                                        name={`rows[${index}].access_level`}
                                        className="form-select-lg form-control-icon"
                                        value={
                                          formik.values.rows[index].access_level || ""
                                        }
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          formik.setFieldTouched(
                                            `rows[${index}].access_level`,
                                            true,
                                            true
                                          );
                                        }}
                                        onBlur={formik.handleBlur}
                                        isInvalid={
                                          !!(
                                            formik.touched.rows?.[index]
                                              ?.access_level &&
                                            typeof formik.errors.rows === "object" &&
                                            (
                                              formik.errors
                                                .rows as FormikErrors<RowValues>[]
                                            )[index]?.access_level
                                          )
                                        }
                                      >
                                        {AccessLevelDetail.map((level) => {
                                          return (
                                            <option key={level.id} value={level.name}>
                                              {level.name}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                      {/* <Form.Control.Feedback type="invalid">
                                    {formik.touched.rows?.[index]?.access_level && typeof formik.errors.rows === 'object' && (formik.errors.rows as FormikErrors<RowValues>[])[index]?.access_level}
                                  </Form.Control.Feedback> */}
                                      <i
                                        style={{ fontSize: "16px" }}
                                        className="ri-shield-user-line mt-4"
                                      ></i>
                                    </Form.Group>
                                  </Col>
                                  <Col>
                                    {formik.values.rows[index].employee_status === "4" && (
                                      <Col>
                                        <Form.Group className="mb-3 form-icon">
                                          <Form.Label>Subcontractor</Form.Label>
                                          <Select
                                            inputId={`subcontractorSelect${index}`}
                                            name={`rows[${index}].subcontractor_id`}
                                            options={SubcontractorList}
                                            value={SubcontractorList.find(option => option.value === formik.values.rows[index].subcontractor_id)}
                                            onChange={(selectedOption: any) => {
                                              formik.setFieldValue(
                                                `rows[${index}].subcontractor_id`,
                                                selectedOption ? selectedOption.value : ''
                                              );
                                            }}
                                            onBlur={formik.handleBlur}
                                            classNamePrefix="react-select"
                                            className={formik.touched.rows?.[index]?.subcontractor_id && typeof formik.errors.rows === 'object' && (formik.errors.rows as FormikErrors<RowValues>[])[index]?.subcontractor_id ? 'is-invalid' : ''}
                                          />
                                          <i
                                            style={{ fontSize: "16px" }}
                                            className="ri-user-2-line mt-4"
                                          ></i>
                                        </Form.Group>
                                      </Col>
                                    )}
                                  </Col>
                                </>
                              }
                            </Row>
                          </Col>
                          <Col
                            style={{ flex: "0.5" }}
                            className="d-flex remove-btn-div align-items-center mt-0"
                          >
                            {index !== 0 && (
                              <Button
                                className="remove-btn mt-0"
                                onClick={() => deleteRow(index)}
                              >
                                <i
                                  className="bx bx-trash"
                                  style={{ fontSize: "24px" }}
                                ></i>
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </React.Fragment>
                    ))}
                    <Col lg={12}>
                      <Button className="add-btn" onClick={addRow}>
                        <i className="ri-add-fill"></i> Add More
                      </Button>
                    </Col>
                    <Col
                      lg="12"
                      className="offcanvas-footer pb-0 text-center"
                      style={{
                        position: "fixed",
                        bottom: "0",
                        width: "100%",
                        left: "0",
                      }}
                    >
                      <Row>
                        <Col lg={12}>
                          <div className="off-bottom-staff">
                            <div className="form-check mb-2 pt-2">
                              {/* <input
                                className="form-check-input"
                                type="checkbox"
                                id="invite"
                                onChange={handleInviteCheckboxChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="invite"
                              >
                                Send an Invite
                              </label> */}
                            </div>
                            <div className="off-confirm">
                              <Button
                                onClick={toggleBottomCanvas}
                                color="primary"
                                className="clear-btn"
                              >
                                Cancel
                              </Button>
                              {formik.isSubmitting ? (
                                <Button
                                  color="primary"
                                  className="next-btn"
                                  disabled
                                >
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  type="submit"
                                  disabled={formik.isSubmitting}
                                  className="next-btn"
                                >
                                  Confirm
                                </Button>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Form>
                </Tab.Pane>
                <Tab.Pane eventKey="product1" id="product1">
                  <Form onSubmit={Csvformik.handleSubmit}>
                    <Row className="add-doc">
                      <Col lg="5" className="upload-section-col">
                        <Form.Group>
                          <div className="d-flex gap-2 align-items-center">
                          <Form.Label className="doc-label">
                            Upload CSV File
                          </Form.Label>
                      
                            {/* Eye Button to Preview */}
                            {file && (
                             
                                <i
                                  onClick={() => handleFilePreview(file)}
                                  className="ri-eye-line link-info"
                                  style={{ fontSize: "18px", cursor: "pointer", marginTop: "-7px"}}
                                ></i>
                            
                            )}
                        
                          </div>
                          <div className="uploadsection">
                            {showUploadButton ? (
                              <div className="d-flex align-item-center">
                                <Form.Label
                                  htmlFor="file"
                                  className="uploadlabel"
                                >
                                  <Form.Control
                                    type="file"
                                    name="csv_file"
                                    className="form-control-lg"
                                    style={{ display: "none" }}
                                    id="file"
                                    onChange={handleFileChange}
                                  />
                                  <i className="bx bx-plus"></i> Choose File
                                </Form.Label>
                              </div>
                            ) : (
                              <Col lg="12" className="closeuser py-0">
                                <p>File Selected: {file?.name}</p>
                                <Button onClick={handleRemoveFile} color="red">
                                  X
                                </Button>
                              </Col>
                            )}
                            {Csvformik.touched.csv_file &&
                              Csvformik.errors.csv_file ? (
                              <div className="invalid-feedback d-block">
                                {Csvformik.errors.csv_file}
                              </div>
                            ) : null}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg="12" className="d-flex align-items-center">
                        <Button
                          color="primary"
                          className="download-btn "
                          onClick={handleDownload}
                        >
                          Sample File
                        </Button>
                      </Col>
                      <Col
                        lg="12"
                        className="offcanvas-footer pb-0 text-center"
                        style={{
                          position: "fixed",
                          bottom: "0",
                          width: "100%",
                          left: "0",
                        }}
                      >
                        <Row>
                          <Col lg={12}>
                            <div className="off-bottom-staff justify-content-end">
                              <div className="off-confirm">
                                <Button
                                  onClick={toggleBottomCanvas}
                                  color="primary"
                                  className="clear-btn"
                                >
                                  Cancel
                                </Button>
                                {Csvformik.isSubmitting ? (
                                  <Button
                                    color="primary"
                                    className="next-btn"
                                    disabled
                                  >
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  </Button>
                                ) : (
                                  <Button
                                    color="primary"
                                    type="submit"
                                    className="next-btn"
                                  >
                                    Confirm
                                  </Button>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </SimpleBar>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={modal_center}
        onHide={() => {
          tog_center();
        }}
        size="sm"
        centered
      >
        <Modal.Body className="text-center p-4">
          <i className="ri-delete-bin-line text-danger display-5"></i>
          <div className="mt-4">
            <h4 className="mb-3">Are you sure?</h4>
            <p className="text-muted mb-4">You want to delete this Staff.</p>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
              {archiveLoader ? (
                <Button
                  color="primary"
                  className="next-btn"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button variant="danger" onClick={() => {
                  if (archiveId !== undefined) {
                    archiveMaster(archiveId);
                  } else {
                    toast.error("No Archive ID provided.");
                  }
                }}>Delete</Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modal_restore_center}
        onHide={() => {
          tog_restore_center();
        }}
        size="sm"
        centered
      >
        <Modal.Body className="text-center p-4">
          <i className=" ri-restart-line text-success display-5"></i>
          <div className="mt-4">
            <h4 className="mb-3">Are you sure?</h4>
            <p className="text-muted mb-4">You want to restore this Staff.</p>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setmodal_restore_center(false)}>Close</Button>
              {restoreLoader ? (
                <Button
                  variant="success"
                  className="next-btn"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button variant="success" onClick={() => {
                  if (restoreId !== undefined) {
                    restoreStaff(restoreId);
                  } else {
                    toast.error("No Restore ID provided.");
                  }
                }}>Restore</Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default StaffPage;
