import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Form,
    Nav,
    ProgressBar,
    Tab,
    Button,
    Offcanvas,
    Table,
    Spinner,
    Modal,
} from "react-bootstrap";
import sideimage from "assets/images/auth/extimg.png";
import Cleave from 'cleave.js/react';
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from 'yup';
import SimpleBar from "simplebar-react";
import { useNavigate } from 'react-router-dom';
import logo from "assets/images/auth/sailogo.png";
import LocationSelectMap from "./LocationSelectMap";
import { addClientDetail, getClientDropdownList, getStaffDropdownList, getByBillingGroupDetail, getLocationIncidentDetail, getBillingGroupDropdownDetail, addLocationDetail, getByLocationDetail, updateLocationDetail, getSubContractorDropdownList, getCheckpointQrImage, getByStaffDetail } from "helpers/saibackend_helper";
import SearchableAddress from "helpers/SearchableAddress";
import successfullyImage from "assets/images/auth/donelocation.png";
import Flatpickr from "react-flatpickr";
import BreadCrumb from 'Common/BreadCrumb';
import Select from 'react-select';
import { Link } from "react-router-dom";
import { useProfile } from "Common/Hooks/UserHooks";

interface RowData {
    id: number;
    document_path: File | string | null,
    showUploadButton: boolean;
    document_name: string;
    is_viewable_staff: number;
    require_sign: number;
    document_id: number
}

interface AddressData {
    latitude: number | string;
    longitude: number | string;
    address: string;
    country: string;
    state: string;
    city: string;
    streetAddress: string;
    postalCode: string;
}

interface StaffData {
    id: number,
    staff_name: string,
    start_date: string,
    employee_status: string,
    employee_name: String,
    access_level: string,
    role_id: number,
    name: string,
    mobile_no?: number;
    email?: string;
    address?: string;
    dob?: number;
    gender?: string;
}

interface ClientData {
    value: number;
    label: string;
}

interface IncidentData {
    id: number;
    name: string;
}

interface Checkpoint {
    name: string;
    address: string;
    mobile_check_in_max_distance: string;
    latitude: string;
    longitude: string;
    tag: string;
    qr_id: string;
}

interface LocationData {
    id: number;
    name: string;
    address: string;
    city: string;
    client_name: string;
    state: string;
    PostalCode: string;
    country: string;
    is_billable: number;
    is_geofancing: number;
    geofancing_radius: number | string;
    client_id: number;
    subcontractor_id: number;
    short_notes: string;
    notes: string;
    longitude: string;
    latitude: string;
    weekday_rate: string;
    saturday_rate: string;
    sunday_rate: string;
    holiday_rate: string;
    start_time: string;
    end_time: string;
    working_days: [];
    checkpoints: [
        {
            latitude: string,
            longitude: string,
            tag: string,
            qr_id: string,
            name: string,
            address: string,
            mobile_check_in_max_distance: string
        }
    ];
    contacts: [
        {
            contact_person: string,
            contact_phone: string,
            contact_email: string,
            contact_type: string,
            visible_on_mobile: number
        }
    ];
    documents: [
        {
            document_name: string,
            document_path: File | string | null,
            is_viewable_staff: number,
            require_sign: number
            document_id: number
        }
    ];
    pay_templates: [];
    staff: [
        {
            staff_id: number,
            first_name: string,
            last_name: string
        }
    ];
    incident_report_template: [
        {
            incident_report_template_id: number,
            name: string
        }
    ];
    billing_group_id: number,
    billing_group_rates: []
}

const AddLocation = () => {
    const [activeTab, setactiveTab] = useState<number>(0);
    const [isBilaibleClient, setIsBilaibleClient] = useState(false);
    const [LocationDetail, setLocationDetail] = useState<LocationData | null>(null);
    const [isGeofancing, setIsGeofancing] = useState(false);
    const navigate = useNavigate();

    const [isDepartment, setIsDepartment] = useState(false);
    const toggleDepartmentCanvas = () => {
        setIsDepartment(!isDepartment);
    };

    const [clientCanvas, setClientCanvas] = useState<boolean>(false);
    const handleAddClient = () => setClientCanvas(!clientCanvas);

    const [latitude, setLatitude] = useState<number | string>('');
    const [longitude, setLongitude] = useState<number | string>('');
    const [marker, setMarker] = useState<{ lat: number, lng: number } | null>(null);
    const [address, setAddress] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [geofencingRadius, setGeofencingRadius] = useState<number | string>(0);

    const { userProfile } = useProfile();
    const userRoles = userProfile.roles;
    const allowedRoles = ['DEVELOPER', 'SUPER_ADMIN', 'ADMIN', 'MANAGER'];
    const hasRoles = (roles: string[]) => roles.some(role => userRoles.includes(role));
    const canAccess = hasRoles(allowedRoles);



    const handleQrDownload = async (qr_id: string) => {
        try {
            await getCheckpointQrImage(qr_id);
            // setClientDetail(response.data.data_list);
        } catch (error) {
            console.log(error);

        }
    }
    const handleAddMarker = (lat: number, lng: number) => {
        setMarker({ lat, lng });
        setLatitude(lat);
        setLongitude(lng);
        updateAddressFromCoordinates(lat, lng);
    };

    const handleLatitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const lat = e.target.value;
        setLatitude(lat);
        if (lat === '' || longitude === '') {
            setMarker(null);
            return;
        }
        const parsedLat = parseFloat(lat);
        const parsedLng = parseFloat(longitude.toString());
        if (!isNaN(parsedLat) && !isNaN(parsedLng)) {
            handleAddMarker(parsedLat, parsedLng);
        }
    };

    const handleLongitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const lng = e.target.value;
        setLongitude(lng);
        if (latitude === '' || lng === '') {
            setMarker(null);
            return;
        }
        const parsedLat = parseFloat(latitude.toString());
        const parsedLng = parseFloat(lng);
        if (!isNaN(parsedLat) && !isNaN(parsedLng)) {
            handleAddMarker(parsedLat, parsedLng);
        }
    };

    const handleAddressChange = (data: AddressData | boolean) => {
        if (typeof data === 'boolean') return;
        setLatitude(data.latitude);
        setLongitude(data.longitude);
        setAddress(data.address);
        setCity(data.city);
        setState(data.state);
        setCountry(data.country);
        setPostalCode(data.postalCode);
        setMarker({ lat: parseFloat(data.latitude.toString()), lng: parseFloat(data.longitude.toString()) });
        Locationformik.setFieldValue("address", data.address);
        Locationformik.setFieldValue("city", data.city);
        Locationformik.setFieldValue("state", data.state);
        Locationformik.setFieldValue("country", data.country);
        Locationformik.setFieldValue("postal_code", data.postalCode);
        Locationformik.setFieldValue("latitude", data.latitude.toString());
        Locationformik.setFieldValue("longitude", data.longitude.toString());
    };

    const updateAddressFromCoordinates = async (lat: number, lng: number) => {
        const geocoder = new window.google.maps.Geocoder();
        const latLng = { lat, lng };
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === "OK" && results && results[0]) {
                const result = results[0];
                setAddress(result.formatted_address);
                const addressData: AddressData = {
                    latitude: lat,
                    longitude: lng,
                    address: result.formatted_address,
                    country: '',
                    state: '',
                    city: '',
                    streetAddress: '',
                    postalCode: '',
                };
                results[0].address_components.forEach((component) => {
                    component.types.forEach((type) => {
                        switch (type) {
                            case "country":
                                addressData.country = component.long_name;
                                break;
                            case "administrative_area_level_1":
                                addressData.state = component.long_name;
                                break;
                            case "locality":
                            case "administrative_area_level_2":
                                addressData.city = component.long_name;
                                break;
                            case "street_number":
                            case "route":
                            case "sublocality":
                                if (addressData.streetAddress !== "") {
                                    addressData.streetAddress += ", ";
                                }
                                addressData.streetAddress += component.long_name;
                                break;
                            case "postal_code":
                                addressData.postalCode = component.long_name;
                                break;
                            default:
                                break;
                        }
                    });
                });
                handleAddressChange(addressData);
            } else {
                console.error("Geocoding failed: " + status);
            }
        });
    };


    // Client List API Integration //
    // =========================================================================================================================

    const [ClientDetail, setClientDetail] = useState<ClientData[]>([]);
    const [subcontractorDetail, setSubcontractorDetail] = useState<ClientData[]>([]);
    const [clientSearch, setClientSearch] = useState("");
    const [subcontractorSearch, setSubcontractorSearch] = useState("");

    const fetchClientDetails = async () => {
        try {
            const response = await getClientDropdownList(clientSearch);
            setClientDetail(response.data.data_list);
        } catch (error) {
            console.log(error);

        }
    };

    useEffect(() => {
        if (activeTab === 0) {
            fetchClientDetails();
        }
    }, [clientSearch, activeTab === 0]);

    const fetchSubContractorDetails = async () => {
        try {
            const response = await getSubContractorDropdownList(clientSearch);
            setSubcontractorDetail(response.data.data_list);
        } catch (error) {
            console.log(error);

        }
    };

    useEffect(() => {
        if (activeTab === 0) {
            fetchSubContractorDetails();
        }
    }, [subcontractorSearch, activeTab === 0]);

    // Client ADD API Integration //
    // =========================================================================================================================

    const [alladdress, setAllAddress] = useState<AddressData | null>(null);
    const [clientaddress, setClientAddress] = useState<string>('');

    const handleClientAddressChange = (data: AddressData | boolean) => {
        if (typeof data === 'boolean') return;
        setClientAddress(data.address);
        formik.setFieldValue("address", data.address);
        formik.setFieldValue("city", data.city);
        formik.setFieldValue("state", data.state);
        formik.setFieldValue("postal_code", data.postalCode);
        formik.setFieldValue("country", data.country);
        setAllAddress(data);
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Client name is required'),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            address: '',
            city: '',
            state: '',
            postal_code: '',
            country: '',
            invoice_note: '',
            abn_no: '',
            contact_person: "",
            contact_phone: "",
            contact_email: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {

                const response = await addClientDetail(values);
                if (response.status === 1) {
                    toast.success(response.message);
                    Locationformik.setFieldValue('client_id', response.data.id);
                } else {
                    toast.error(response.message);
                }
                handleAddClient();
                formik.resetForm();
                fetchClientDetails()
                setAllAddress(null)
                setAddress('')
            } catch (error) {
                console.error("Error Adding/Updating Client Master:", error);
                toast.error("Error Client Master. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        }
    });

    // Location Staff List API Integration //
    // =========================================================================================================================

    const [locationStaff, setLocationStaff] = useState<StaffData[]>([]);

    const [defaultStaffValue, setDefaultStaffValue] = useState({
        search_term: "",
    })

    const fetchLocationStaffDetails = async () => {
        try {
            const response = await getStaffDropdownList(defaultStaffValue.search_term);
            setLocationStaff(response.data.data_list);
        } catch (error) {
            console.log(error);

        }
    };

    useEffect(() => {
        if (activeTab == 2) {
            fetchLocationStaffDetails();
        }
    }, [defaultStaffValue, activeTab == 2]);

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        const handler = setTimeout(() => {
            setDefaultStaffValue((prevPayload) => ({
                ...prevPayload,
                search_term: inputValue,
            }));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    // Location Incident List API Integration //
    // =========================================================================================================================

    const [locationIncident, setLocationIncident] = useState<IncidentData[]>([]);

    const [defaultIncidentValue, setDefaultIncidentValue] = useState({
        search_term: "",
    })

    const fetchLocationIncidentDetails = async () => {
        try {
            const response = await getLocationIncidentDetail(defaultIncidentValue.search_term);
            setLocationIncident(response.data.data_list);
        } catch (error) {
            console.log(error);

        }
    };

    useEffect(() => {
        if (activeTab === 3) {
            fetchLocationIncidentDetails();
        }
    }, [defaultIncidentValue, activeTab === 3]);

    const [inputIncidentValue, setInputIncidentValue] = useState("");

    useEffect(() => {
        const handler = setTimeout(() => {
            setDefaultIncidentValue((prevPayload) => ({
                ...prevPayload,
                search_term: inputIncidentValue,
            }));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [inputIncidentValue]);

    const handleIncidentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputIncidentValue(e.target.value);
    };

    // Location CheckPoint //
    // =========================================================================================================================  

    const [checkpointAddress, setCheckpointAddress] = useState<string>('');
    const [allCheckpointAddress, setAllCheckpointAddress] = useState<AddressData | null>(null);
    // const [isCheckpointDepartment, setIsCheckpointDepartment] = useState(false);
    const [checkpoints, setCheckpoints] = useState<Checkpoint[]>([]);
    const [checkpointName, setCheckpointName] = useState('');
    const [checkpointTag, setCheckpointTag] = useState('QR');
    const [checkpointDistance, setCheckpointDistance] = useState('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filteredCheckpoints, setFilteredCheckpoints] = useState<Checkpoint[]>([]);

    useEffect(() => {
        const filtered = checkpoints.filter(checkpoint =>
            checkpoint.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCheckpoints(filtered);
    }, [searchTerm, checkpoints]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleCheckpointAddressChange = (data: AddressData | boolean) => {
        if (typeof data === 'boolean') return;
        setCheckpointAddress(data.address);
        setAllCheckpointAddress(data);
    };

    const validationCheckpointSchema = Yup.object({
        checkpointName: Yup.string().required('Checkpoint Name is required'),
        checkpointDistance: Yup.number()
            .required('Distance Threshold is required')
            .positive('Distance must be positive')
            .integer('Distance must be an integer'),
    });

    const handleDeleteCheckpoint = (index: string) => {
        const newCheckpoints = [...filteredCheckpoints];
        newCheckpoints.splice(parseInt(index), 1);
        setFilteredCheckpoints(newCheckpoints);
        setCheckpoints(newCheckpoints)
        Locationformik.setFieldValue("checkpoints", newCheckpoints)
    };

    const checkpointformik = useFormik({
        initialValues: {
            checkpointName: '',
            checkpointTag: 'QR',
            checkpointDistance: '',
            checkpointAddress: '',
        },
        validationSchema: validationCheckpointSchema,
        onSubmit: async (values, { setSubmitting }) => {

            if (!checkpointAddress) {
                toast.error("Location Geofencing is required.");
                return;
            }

            try {
                const newCheckpoint: Checkpoint = {
                    name: values.checkpointName,
                    address: checkpointAddress,
                    mobile_check_in_max_distance: values.checkpointDistance,
                    latitude: allCheckpointAddress?.latitude.toString() || '',
                    longitude: allCheckpointAddress?.longitude.toString() || '',
                    tag: values.checkpointTag,
                    qr_id: ''
                };
                setCheckpoints([...checkpoints, newCheckpoint]);
                Locationformik.setFieldValue("checkpoints", [...checkpoints, newCheckpoint])
                setIsDepartment(false);
                setCheckpointName('');
                checkpointformik.resetForm()
                setCheckpointTag('QR');
                setCheckpointDistance('');
                setCheckpointAddress('');
                setAllCheckpointAddress(null);
                setSubmitting(false);
            } catch (error) {
                console.error("Error Adding/Updating CheckPoint:", error);
                toast.error("Error Location. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        }
    });

    // Location Add and Edit API Integration //
    // =========================================================================================================================

    const initialRowData: RowData[] = [{ id: 1, document_path: null, showUploadButton: true, document_name: '', is_viewable_staff: 0, require_sign: 0, document_id: 0 }];

    const [rows, setRows] = useState<RowData[]>(initialRowData);

    const validationLocationSchema = Yup.object().shape({
        name: Yup.string().required('Location name is required'),
        client_id: Yup.number()
            .nullable()
            .test(
                'is-required',
                'Client is required when location is billable',
                function (value) {
                    const { is_billable } = this.parent;
                    if (is_billable === 1) {
                        return value !== null && value !== undefined && value !== 0;
                    }
                    return true;
                }
            ),
        billing_group_id: Yup.number()
            .nullable()
            .test(
                'is-required',
                'Billing Group is required when location is billable',
                function (value) {
                    const { is_billable } = this.parent;
                    if (is_billable === 1) {
                        return value !== null && value !== undefined && value !== 0;
                    }
                    return true;
                }
            ),
        geofancing_radius: Yup.number()
            .nullable()
            .test(
                'is-required',
                'Meter is required when you want location geofencing',
                function (value) {
                    const isGeoFancing = this.parent?.is_geoFancing; // Ensure safe access
                    if (isGeoFancing === 'Yes') {
                        return !!value && value > 0; // Ensures value is not null, undefined, or 0
                    }
                    return true;
                }
            )

    });

    useEffect(() => {
        if (LocationDetail !== null) {
            const initialValues = {
                name: LocationDetail?.name || "",
                address: LocationDetail?.address || "",
                city: LocationDetail?.city || "",
                state: LocationDetail?.state || "",
                postal_code: LocationDetail?.PostalCode || "",
                country: LocationDetail?.country || "",
                latitude: LocationDetail?.latitude || "",
                longitude: LocationDetail?.longitude || "",
                is_billable: LocationDetail?.is_billable || 0,
                is_geofancing: LocationDetail?.is_geofancing || 0,
                geofancing_radius: Number(LocationDetail?.geofancing_radius || 0),
                client_id: LocationDetail?.client_id || 0,
                subcontractor_id: LocationDetail?.subcontractor_id || 0,
                working_days: LocationDetail?.working_days || [],
                start_time: LocationDetail?.start_time || '',
                end_time: LocationDetail?.end_time || '',
                contacts: LocationDetail?.contacts.map((contact, index) => ({
                    id: index + 1,
                    contact_person: contact.contact_person || '',
                    contact_phone: contact.contact_phone || '',
                    contact_email: contact.contact_email || '',
                    contact_type: contact.contact_type || '',
                    visible_on_mobile: contact.visible_on_mobile || 0,
                })),
                notes: LocationDetail?.notes || "",
                staff: LocationDetail?.staff?.map(staffMember => ({ staff_id: staffMember.staff_id })) || [],
                incident_report_template: LocationDetail?.incident_report_template?.map(template => ({ id: template.incident_report_template_id })) || [],
                documents: LocationDetail?.documents?.map(doc => ({
                    id: Math.random(),
                    document_name: doc.document_name || "",
                    is_viewable_staff: doc.is_viewable_staff || 0,
                    require_sign: doc.require_sign || 0,
                    document_path: doc.document_path || null,
                    showUploadButton: !doc.document_path,
                    document_id: doc.document_id || 0,
                })),
                checkpoints: LocationDetail?.checkpoints?.map(checkpoint => ({
                    latitude: checkpoint.latitude || "",
                    longitude: checkpoint.longitude || "",
                    tag: checkpoint.tag || "",
                    qr_id: checkpoint.qr_id || "",
                    name: checkpoint.name || "",
                    address: checkpoint.address || "",
                    mobile_check_in_max_distance: checkpoint.mobile_check_in_max_distance || ""
                })),
                billing_group_id: LocationDetail?.billing_group_id || 0,
                // billing_group_rates : LocationDetail.location_billing_rates || [],
                billing_group_rates: LocationDetail?.
                    billing_group_rates
                    ?.map((rate: { location_id: any; billing_rate_id: any; billing_category_id: any; default_rate: any; assign_rate: any; category_name: any }) => ({
                        billing_rate_id: rate.billing_rate_id,
                        location_id: rate.location_id,
                        billing_category_id: rate.billing_category_id,
                        default_rate: rate.default_rate,
                        assign_rate: rate.assign_rate,
                        category_name: rate.category_name
                    })) || [],
            };
            setLatitude(LocationDetail?.latitude ? LocationDetail?.latitude : "");
            setLongitude(LocationDetail?.longitude ? LocationDetail?.longitude : "");
            setAddress(LocationDetail?.address ? LocationDetail?.address : "");
            setCity(LocationDetail?.city ? LocationDetail?.city : "");
            setState(LocationDetail?.state ? LocationDetail?.state : "");
            setCountry(LocationDetail?.country ? LocationDetail?.country : "");
            setPostalCode(LocationDetail?.PostalCode ? LocationDetail?.PostalCode : "");
            setMarker({ lat: parseFloat(LocationDetail?.latitude?.toString() ? LocationDetail?.latitude?.toString() : ""), lng: parseFloat(LocationDetail?.longitude?.toString() ? LocationDetail?.longitude?.toString() : "") });
            setIsBilaibleClient(LocationDetail?.is_billable === 1 ? true : false);
            setIsGeofancing(LocationDetail?.is_geofancing === 1 ? true : false);
            setGeofencingRadius(LocationDetail?.geofancing_radius ? LocationDetail?.geofancing_radius : "")
            setSelectedDays(LocationDetail?.working_days ? LocationDetail?.working_days : []);
            Locationformik.setValues(initialValues);
            setRowsContact(initialValues.contacts);
            setCheckpoints(initialValues.checkpoints);
            setRows(initialValues.documents)
            const staffIds = LocationDetail.staff.map(staffMember => staffMember.staff_id);
            setSelectedStaff(staffIds);
            setIsCheckAll(staffIds.length === locationStaff.length);
            const incidentID = LocationDetail.incident_report_template.map(IncidentID => IncidentID.incident_report_template_id);
            setSelectedIncidents(incidentID)
            setIsCheckAllIncidents(incidentID.length === locationIncident.length);
            SetBillingGroupList(initialValues.billing_group_rates)
        }
    }, [LocationDetail, locationStaff.length, locationIncident.length]);


    const [billingGroupList, SetBillingGroupList] = useState<any[]>([]);


    const locID = localStorage.getItem("LocationID");

    const Locationformik = useFormik({
        initialValues: {
            name: "",
            address: '',
            city: '',
            state: '',
            postal_code: '',
            country: '',
            latitude: '',
            longitude: '',
            is_billable: isBilaibleClient ? 1 : 0,
            is_geofancing: isGeofancing ? 1 : 0,
            geofancing_radius: 0,
            client_id: 0,
            subcontractor_id: 0,
            working_days: [] as string[],
            start_time: '',
            end_time: '',
            contacts: [
                {
                    contact_person: '',
                    contact_phone: '',
                    contact_email: '',
                    contact_type: '',
                    visible_on_mobile: 0,
                },
            ],
            notes: "",
            staff: [] as { staff_id: number }[],
            incident_report_template: [] as { id: number }[],
            documents: rows.map((row) => ({
                document_name: row.document_name,
                is_viewable_staff: row.is_viewable_staff,
                require_sign: row.require_sign,
                document_path: row.document_path,
                document_id: row.document_id
            })),
            checkpoints: checkpoints,
            billing_group_id: 0,
            billing_group_rates: billingGroupList
        },
        validationSchema: validationLocationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {

                if (values.client_id && !values.billing_group_id) {
                    toast.error("Please Select Billing Group")
                    return;
                }
                const formData = new FormData();
                formData.append('name', values.name);
                formData.append('address', values.address);
                formData.append('city', values.city);
                formData.append('state', values.state);
                formData.append('postal_code', values.postal_code);
                formData.append('country', values.country);
                formData.append('latitude', values.latitude);
                formData.append('longitude', values.longitude);
                formData.append('is_billable', values.is_billable.toString());
                formData.append('is_geofancing', values.is_geofancing.toString());
                formData.append('geofancing_radius', values.geofancing_radius.toString())
                formData.append('client_id', values.client_id.toString());
                formData.append('subcontractor_id', values.subcontractor_id.toString());
                formData.append('notes', values.notes);
                formData.append('start_time', values.start_time.toString());
                formData.append('end_time', values.end_time.toString());
                formData.append('billing_group_id', values.billing_group_id.toString());
                // formData.append('billing_group_rates', billingGroupList);

                billingGroupList.forEach((rate, index) => {
                    formData.append(`billing_group_rates[${index}][billing_rate_id]`, rate.billing_rate_id.toString());
                    formData.append(`billing_group_rates[${index}][billing_category_id]`, rate.billing_category_id.toString());
                    // formData.append(`billing_group_rates[${index}][location_billing_rate_id]`, rate.location_billing_rate_id);
                    formData.append(`billing_group_rates[${index}][default_rate]`, rate.default_rate.toString());
                    formData.append(`billing_group_rates[${index}][assign_rate]`, rate.assign_rate.toString());
                });


                values.working_days.forEach((day, index) => {
                    formData.append(`working_days[${index}]`, day);
                });

                values.contacts.forEach((contact, index) => {
                    if (contact.contact_person) {
                        formData.append(`contacts[${index}][contact_person]`, contact.contact_person);
                        formData.append(`contacts[${index}][contact_phone]`, contact.contact_phone);
                        formData.append(`contacts[${index}][contact_email]`, contact.contact_email);
                        formData.append(`contacts[${index}][contact_type]`, contact.contact_type);
                        const visibleOnMobile = rowsContact[index].visible_on_mobile !== undefined ? rowsContact[index].visible_on_mobile : 0;
                        formData.append(`contacts[${index}][visible_on_mobile]`, visibleOnMobile.toString());
                    }
                });

                values.documents.forEach((document, index) => {
                    if (document.document_name) {
                        formData.append(`documents[${index}][document_name]`, document.document_name);
                        formData.append(`documents[${index}][is_viewable_staff]`, document.is_viewable_staff.toString());
                        formData.append(`documents[${index}][require_sign]`, document.require_sign.toString());
                        formData.append(`documents[${index}][document_id]`, document.document_id.toString());
                        if (document.document_path) {
                            if (document.document_path instanceof File) {
                                formData.append(`documents[${index}][document_path]`, document.document_path);
                            } else {
                                formData.append(`documents[${index}][document_path_string]`, document.document_path);
                            }
                        }
                    }
                });

                values.checkpoints.forEach((checkpoint, index) => {
                    formData.append(`checkpoints[${index}][name]`, checkpoint.name);
                    formData.append(`checkpoints[${index}][address]`, checkpoint.address);
                    formData.append(`checkpoints[${index}][latitude]`, checkpoint.latitude);
                    formData.append(`checkpoints[${index}][longitude]`, checkpoint.longitude);
                    formData.append(`checkpoints[${index}][mobile_check_in_max_distance]`, checkpoint.mobile_check_in_max_distance);
                    formData.append(`checkpoints[${index}][tag]`, checkpoint.tag);
                    formData.append(`checkpoints[${index}][qr_id]`, checkpoint.qr_id);
                });

                values.incident_report_template.forEach((incident, index) => {
                    formData.append(`incident_report_template[${index}][id]`, incident.id.toString());
                });

                values.staff.forEach((staff, index) => {
                    formData.append(`staff[${index}][staff_id]`, staff.staff_id.toString());
                });

                if (locID !== null) {

                    formData.append('location_id', locID);

                    const response = await updateLocationDetail(formData)
                    if (response.status === 1) {
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                        return;
                    }



                } else {

                    const response = await addLocationDetail(formData);

                    if (response.status === 1) {
                        toast.success(response.message);
                        setTimeout(() => {
                            navigate('/location');
                        }, 1000);
                    } else {
                        toast.error(response.message);
                        return;
                    }
                }

                Locationformik.resetForm();
                setLatitude('');
                setLongitude('');
                setAddress('');
                setCity('');
                setState('');
                setCountry('');
                setPostalCode('');
                setMarker(null);
                setSelectedDays([]);
                setIsBilaibleClient(false);
                setIsGeofancing(false);
                setRowsContact([{ id: 1, visible_on_mobile: 0 }]);
                setSelectedStaff([]);
                setIsCheckAll(false);
                setSelectedIncidents([]);
                setIsCheckAllIncidents(false);
                setCheckpoints([]);
                setRows([{ id: 1, document_path: null, showUploadButton: true, document_name: '', is_viewable_staff: 0, require_sign: 0, document_id: 0 }])
                editMaster()
                // setactiveTab(7)
            } catch (error) {
                console.error("Error Adding/Updating Location:", error);
                toast.error("Error Location. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        }
    });

    const hasFirstTabError =
        (!!Locationformik.touched.name && !!Locationformik.errors.name) ||
        (!!Locationformik.touched.client_id && !!Locationformik.errors.client_id) ||
        (!!Locationformik.touched.billing_group_id && !!Locationformik.errors.billing_group_id);
    // Location Is Billable Function //
    // =========================================================================================================================

    const handleRadioChange = (value: string) => {
        setIsBilaibleClient(value === 'Payable');
        Locationformik.setFieldValue('is_billable', value === 'Payable' ? 1 : 0);
        if (value !== 'Payable') {
            Locationformik.setFieldValue('client_id', 0);
            Locationformik.setFieldValue('billing_group_id', 0);
            SetBillingGroupList([])
        }
    };

    const handleGeofancingChange = (value: string) => {
        setIsGeofancing(value === 'Yes');
        Locationformik.setFieldValue('is_geofancing', value === 'Yes' ? 1 : 0);
        if (value !== 'Yes') {
            Locationformik.setFieldValue('geofancing_radius', 0);
        }
    };

    const [selectedDays, setSelectedDays] = useState<string[]>([]);

    const handleDayToggle = (day: string) => {
        const updatedDays = [...selectedDays];
        if (updatedDays.includes(day)) {
            updatedDays.splice(updatedDays.indexOf(day), 1);
        } else {
            updatedDays.push(day);
        }
        setSelectedDays(updatedDays);
        Locationformik.setFieldValue('working_days', updatedDays);
    };

    // Location Start Time and End Time Function //
    // =========================================================================================================================


    const handleTimeChange = (time: string, field: string) => {
        const formattedTime = time.replace(/^(\d{2})(\d{2})$/, '$1:$2');
        Locationformik.setFieldValue(field, formattedTime);
    };


    // Location Contact Function //
    // =========================================================================================================================

    const [rowsContact, setRowsContact] = useState([{ id: 1, visible_on_mobile: 0 }]);

    const addRowContact = () => {
        const newId = rowsContact.length ? Math.max(...rowsContact.map(row => row.id)) + 1 : 1;
        setRowsContact([...rowsContact, { id: newId, visible_on_mobile: 0 }]);
    };

    const deleteRowContact = (id: number) => {
        if (id !== 1) {
            const updatedRows = rowsContact.filter(row => row.id !== id);
            setRowsContact(updatedRows);
            // formik.setFieldValue('contacts', updatedRows);
        }
    };

    const handleVisibleOnMobileChange = (index: number, isChecked: boolean) => {
        const updatedContacts = [...rowsContact];
        if (updatedContacts[index]) {
            updatedContacts[index] = {
                ...updatedContacts[index],
                visible_on_mobile: Number(isChecked)
            };
            setRowsContact(updatedContacts);
        }
    };

    // Location Staff Checkbox Function //
    // =========================================================================================================================

    const [selectedStaff, setSelectedStaff] = useState<number[]>([]);
    const [isCheckAll, setIsCheckAll] = useState<boolean>(false);

    const handleCheckboxChange = (staffId: number) => {
        setSelectedStaff((prevSelected) => {
            const newSelected = prevSelected.includes(staffId)
                ? prevSelected.filter((id) => id !== staffId)
                : [...prevSelected, staffId];
            setIsCheckAll(newSelected.length === locationStaff.length);
            return newSelected;
        });
    };

    const handleCheckAllChange = () => {
        if (isCheckAll) {
            setSelectedStaff([]);
        } else {
            setSelectedStaff(locationStaff.map((staff) => staff.id));
        }
        setIsCheckAll(!isCheckAll);
    };

    useEffect(() => {
        Locationformik.setFieldValue('staff', selectedStaff.map((id) => ({ staff_id: id })));
    }, [selectedStaff]);


    //profile pop-up function
    const [staffViewDetail, setstaffViewDetail] = useState<StaffData | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleInfoClick = async (staff: any) => {
        try {
            const response = await getByStaffDetail(staff.id);
            if (response && response.data) {
                setstaffViewDetail(response.data);
                setIsModalOpen(true);
            } else {
                console.error("Invalid API response:", response);
            }
        } catch (error) {
            console.error("Error fetching staff details:", error);
        }
    };



    const closeModal = () => {
        setIsModalOpen(false);
        setstaffViewDetail(null);
    };

    // Location Incident Report Checkbox Function //
    // =========================================================================================================================

    const [selectedIncidents, setSelectedIncidents] = useState<number[]>([]);
    const [isCheckAllIncidents, setIsCheckAllIncidents] = useState<boolean>(false);

    const handleIncidentCheckboxChange = (incidentId: number) => {
        setSelectedIncidents((prevSelected) => {
            const newSelected = prevSelected.includes(incidentId)
                ? prevSelected.filter((id) => id !== incidentId)
                : [...prevSelected, incidentId];
            setIsCheckAllIncidents(newSelected.length === locationIncident.length);
            return newSelected;
        });
    };

    const handleCheckAllIncidentsChange = () => {
        if (isCheckAllIncidents) {
            setSelectedIncidents([]);
        } else {
            setSelectedIncidents(locationIncident.map((incident) => incident.id));
        }
        setIsCheckAllIncidents(!isCheckAllIncidents);
    };

    useEffect(() => {
        Locationformik.setFieldValue('incident_report_template', selectedIncidents.map((id) => ({ id })));
    }, [selectedIncidents]);


    // Location Document Function //
    // =========================================================================================================================


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, rowId: number) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            setRows(rows.map(row =>
                row.id === rowId ? { ...row, document_path: selectedFile, showUploadButton: false } : row
            ));
        }
    };

    const handleRemoveFile = (rowId: number) => {
        setRows(rows.map(row =>
            row.id === rowId ? { ...row, document_path: null, showUploadButton: true } : row
        ));
    };

    const handleFilePreview = (documentPath: File | string | null) => {
        if (!documentPath) return;

        if (typeof documentPath === "string") {
            window.open(documentPath, "_blank"); // Open URL if document_path is a string
        } else {
            const fileURL = URL.createObjectURL(documentPath);
            window.open(fileURL, "_blank"); // Open file preview if it's a File object
        }
    };


    const addRow = () => {
        setRows([...rows, { id: rows.length + 1, document_path: null, showUploadButton: true, document_name: '', is_viewable_staff: 0, require_sign: 0, document_id: 0 }]);
    };

    const deleteRow = (id: number) => {
        if (id !== 1) {
            const updatedRows = rows.filter((row) => row.id !== id);
            setRows(updatedRows);
        }
    };

    const handleDocumentNameChange = (value: string, rowId: number) => {
        setRows(rows.map(row =>
            row.id === rowId ? { ...row, document_name: value } : row
        ));
    };

    const handleViewableChange = (value: number, rowId: number) => {
        setRows(rows.map(row =>
            row.id === rowId ? { ...row, is_viewable_staff: value } : row
        ));
    };

    const handleRequireSignChange = (value: number, rowId: number) => {
        setRows(rows.map(row =>
            row.id === rowId ? { ...row, require_sign: value } : row
        ));
    };

    useEffect(() => {
        Locationformik.setFieldValue('documents', rows);
    }, [rows]);


    const baseurl = process.env.REACT_APP_IMAGE_URL

    const handleOpenDocument = (path: string) => {
        const fullPath = `${baseurl}/${path}`;
        window.open(fullPath, '_blank');
    };


    // Location GETBY API Integration //
    // =========================================================================================================================

    const editMaster = async () => {
        try {
            const locID = localStorage.getItem("LocationID");
            if (locID !== null) {
                const response = await getByLocationDetail(parseInt(locID));
                const data = response.data;
                setLocationDetail(data);
            } else {
                return;
                // console.error("Location ID is null.");
                // toast.error("Error fetching details. Please try again later.");
            }
        } catch (error) {
            console.error("Error fetching Location details:", error);
            // toast.error("Error fetching details. Please try again later.");
        }
    };


    useEffect(() => {
        editMaster()
    }, [])

    // Billing Group Dropdown //

    const [billingGroup, setBillingGroup] = useState<any[]>([]);


    const fetchBillingGroupDetails = async () => {
        try {
            const search_term = ""
            const response = await getBillingGroupDropdownDetail(
                search_term
            );
            setBillingGroup(response.data.data_list);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (activeTab === 0) {
            fetchBillingGroupDetails();
        }
    }, [activeTab === 0]);

    const fetchScheduleDropdownDetails = async (id: any) => {
        try {
            const response = await getByBillingGroupDetail(id);
            SetBillingGroupList(response.data.billing_group_rates);
        } catch (error) {
            console.log(error);
        }
    };

    const handleAssignRateChange = (billing_rate_id: number, newRate: number) => {
        SetBillingGroupList((prevBillingGroupList) =>
            prevBillingGroupList.map((rate) =>
                rate.billing_rate_id === billing_rate_id
                    ? { ...rate, assign_rate: newRate }
                    : rate
            )
        );
    };

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title={`${(locID) ? 'Edit' : 'Add'} Location ${LocationDetail?.name ? '(' + LocationDetail?.name + ')' : ''}`} pageTitle="Locations" pageTitleLink="/location" />
                        <Row>
                            <Form onSubmit={Locationformik.handleSubmit}>
                                <Col lg="12" className="add-location mb-4">
                                    <Tab.Container activeKey={activeTab}>
                                        <Row>
                                            <Col
                                                lg={12}
                                                id="custom-progress-bar"
                                                className="progress-nav mb-4"
                                            >
                                                <div className="col-md d-flex justify-content-between  order-1">
                                                    <Nav
                                                        as="ul"
                                                        variant="pills"
                                                        className="progress-bar-tab custom-nav d-flex gap-4"
                                                    >
                                                        <Nav.Item
                                                            as="li"
                                                            className="d-flex align-items-baseline"
                                                        >
                                                            <Nav.Link
                                                                as="button"
                                                                eventKey="0"
                                                                onClick={() => setactiveTab(0)}
                                                                className="rounded-pill"
                                                            >
                                                                1
                                                            </Nav.Link>
                                                            <Nav.Link
                                                                eventKey="0"
                                                                className="nav-name"
                                                                style={{ width: "fit-content", background: "none" }}
                                                                onClick={() => setactiveTab(0)}
                                                            >
                                                                Location Details
                                                                {hasFirstTabError && (
                                                                    <i
                                                                        className={`ms-2 text-danger bi bi-exclamation-circle-fill`}
                                                                        title="Validation Error"
                                                                        style={{ fontSize: "20px" }}
                                                                    ></i>
                                                                )}
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item as="li">
                                                            <Nav.Link
                                                                as="button"
                                                                eventKey="1"
                                                                onClick={() => setactiveTab(1)}
                                                                className="rounded-pill"
                                                            >
                                                                2
                                                            </Nav.Link>
                                                            <Nav.Link
                                                                eventKey="1"
                                                                className="nav-name"
                                                                style={{ width: "fit-content", background: "none" }}
                                                                onClick={() => setactiveTab(1)}
                                                            >
                                                                Location Contacts
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item as="li">
                                                            <Nav.Link
                                                                as="button"
                                                                eventKey="2"
                                                                onClick={() => setactiveTab(2)}
                                                                className="rounded-pill"
                                                            >
                                                                3
                                                            </Nav.Link>
                                                            <Nav.Link
                                                                eventKey="2"
                                                                className="nav-name"
                                                                style={{ width: "fit-content", background: "none" }}
                                                                onClick={() => setactiveTab(2)}
                                                            >
                                                                Preferred Staff
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item as="li">
                                                            <Nav.Link
                                                                as="button"
                                                                eventKey="3"
                                                                onClick={() => setactiveTab(3)}
                                                                className="rounded-pill"
                                                            >
                                                                4
                                                            </Nav.Link>
                                                            <Nav.Link
                                                                eventKey="3"
                                                                className="nav-name"
                                                                style={{ width: "fit-content", background: "none" }}
                                                                onClick={() => setactiveTab(3)}
                                                            >
                                                                Incident Report
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        {canAccess && userRoles[0] !== "MANAGER" ? (
                                                            <Nav.Item as="li">
                                                                <Nav.Link
                                                                    as="button"
                                                                    eventKey="4"
                                                                    onClick={() => setactiveTab(4)}
                                                                    className="rounded-pill"
                                                                >
                                                                    5
                                                                </Nav.Link>
                                                                <Nav.Link
                                                                    eventKey="4"
                                                                    className="nav-name"
                                                                    style={{ width: "fit-content", background: "none" }}
                                                                    onClick={() => setactiveTab(4)}
                                                                >
                                                                    Location Documents
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        ) : null}

                                                        <Nav.Item as="li">
                                                            <Nav.Link
                                                                as="button"
                                                                eventKey="5"
                                                                onClick={() => setactiveTab(5)}
                                                                className="rounded-pill"
                                                            >
                                                                {canAccess && userRoles[0] !== "MANAGER" ? "6" : "5"}
                                                            </Nav.Link>
                                                            <Nav.Link
                                                                eventKey="5"
                                                                className="nav-name"
                                                                style={{ width: "fit-content", background: "none" }}
                                                                onClick={() => setactiveTab(5)}
                                                            >
                                                                Checkpoints
                                                            </Nav.Link>
                                                        </Nav.Item>

                                                    </Nav>
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <Tab.Content>
                                                    <Row>
                                                        {activeTab === 0 && (
                                                            <Tab.Pane
                                                                eventKey="0"
                                                                // id="pills-gen-info"
                                                                role="tabpanel"
                                                                className="d-flex"
                                                            // aria-labelledby="pills-gen-info-tab"
                                                            >
                                                                <Col lg={12} style={{ width: '100%' }} className="location-create-tabs">
                                                                    <Card className="add-location-form-card">
                                                                        <Card.Header>
                                                                            <Row className="location-row flex-row flex-lg-row">
                                                                                <Col
                                                                                    lg={6}
                                                                                    className="location-title-details mb-0 d-flex align-items-center mb-2"
                                                                                >
                                                                                    <h4>Location Details</h4>
                                                                                </Col>
                                                                                <Col
                                                                                    lg={6}
                                                                                    className="mb-2 location-navigation"
                                                                                >
                                                                                    <span className="location-nav d-flex justify-content-end gap-3">
                                                                                        <Button
                                                                                            color="primary"
                                                                                            className="back-bnt-btn"
                                                                                            href="/location"
                                                                                        >
                                                                                            Back
                                                                                        </Button>
                                                                                        {Locationformik.isSubmitting ? (
                                                                                            <Button
                                                                                                color="primary"
                                                                                                className="sub-update-btn"
                                                                                                disabled
                                                                                            >
                                                                                                <Spinner
                                                                                                    as="span"
                                                                                                    animation="border"
                                                                                                    size="sm"
                                                                                                    role="status"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            </Button>
                                                                                        ) : (
                                                                                            <Button type="submit" className="sub-update-btn">
                                                                                                {locID ? 'Update' : 'Add'}
                                                                                            </Button>
                                                                                        )}
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Header>
                                                                        <Card.Body style={{ minHeight: '50vh' }}>
                                                                            <SimpleBar
                                                                                style={{
                                                                                    maxHeight: "auto",
                                                                                    overflowX: "hidden",
                                                                                }}
                                                                                className="pl-0 pr-3"
                                                                            >
                                                                                <Row>
                                                                                    <Col lg="6">
                                                                                        <Form.Group className="mb-3">
                                                                                            <Form.Label>
                                                                                                Location Name*
                                                                                            </Form.Label>
                                                                                            <div className="position-relative form-icon">
                                                                                                <Form.Control
                                                                                                    type="name"
                                                                                                    name="name"
                                                                                                    className="form-control-lg form-control-icon name-input"
                                                                                                    placeholder="Enter Location Name"
                                                                                                    value={Locationformik.values.name}
                                                                                                    onChange={Locationformik.handleChange}
                                                                                                    onBlur={Locationformik.handleBlur}
                                                                                                    isInvalid={!!(Locationformik.touched.name && Locationformik.errors.name)}
                                                                                                />
                                                                                                <i
                                                                                                    style={{ fontSize: "16px" }}
                                                                                                    className="ri-map-pin-line"
                                                                                                ></i>
                                                                                                <Form.Control.Feedback type="invalid">
                                                                                                    {Locationformik.errors.name}
                                                                                                </Form.Control.Feedback>
                                                                                            </div>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col lg="6">
                                                                                        <Form.Group className="mb-3 form-icon">
                                                                                            <Form.Label>Subcontractor</Form.Label>
                                                                                            <Select
                                                                                                className={`${Locationformik.touched.subcontractor_id && Locationformik.errors.subcontractor_id ? 'is-invalid' : ''
                                                                                                    }`}
                                                                                                id="Subcontractor"
                                                                                                name="subcontractor_id"
                                                                                                options={subcontractorDetail}
                                                                                                onChange={(selectedOption: any) => {
                                                                                                    Locationformik.setFieldValue('subcontractor_id', selectedOption ? selectedOption.value : '');
                                                                                                }}
                                                                                                onBlur={() => Locationformik.setFieldTouched('subcontractor_id', true)}
                                                                                                value={subcontractorDetail.find((option: any) => option.value === Locationformik.values.subcontractor_id) || null}
                                                                                                classNamePrefix="react-select"
                                                                                            />
                                                                                            <i style={{ fontSize: '16px' }} className="ri-user-line mt-4"></i>
                                                                                            {Locationformik.touched.subcontractor_id && Locationformik.errors.subcontractor_id ? (
                                                                                                <div className="invalid-feedback">{Locationformik.errors.subcontractor_id}</div>
                                                                                            ) : null}
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col lg="6">
                                                                                        <LocationSelectMap marker={marker}
                                                                                            onAddMarker={handleAddMarker}
                                                                                            setLatitude={setLatitude}
                                                                                            setLongitude={setLongitude}
                                                                                            setAddress={setAddress}
                                                                                            latitude={latitude}
                                                                                            longitude={longitude}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col lg="6" className="d-flex align-items-center">
                                                                                        <Row>
                                                                                            <Col lg="12">
                                                                                                <Form.Group className="mb-3">
                                                                                                    <Form.Label>Location Address</Form.Label>
                                                                                                    <SearchableAddress
                                                                                                        label="Location Address"
                                                                                                        name="location-name"
                                                                                                        placeholder="Location Address"
                                                                                                        onChange={handleAddressChange}
                                                                                                        invalid={false}
                                                                                                        defaultValue={{
                                                                                                            latitude: latitude.toString(),
                                                                                                            longitude: longitude.toString(),
                                                                                                            address: address,
                                                                                                        }}
                                                                                                    />
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                            <Col lg="6">
                                                                                                <Form.Group className="mb-3">
                                                                                                    <Form.Label>Latitude</Form.Label>
                                                                                                    <div className="position-relative form-icon">
                                                                                                        <Form.Control
                                                                                                            type="number"
                                                                                                            value={latitude}
                                                                                                            onChange={handleLatitudeChange}
                                                                                                            className="form-control-lg form-control-icon name-input"
                                                                                                            placeholder="Latitude"
                                                                                                        />
                                                                                                        <i style={{ fontSize: '16px' }} className="ri-map-pin-line"></i>
                                                                                                    </div>
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                            <Col lg="6">
                                                                                                <Form.Group className="mb-3">
                                                                                                    <Form.Label>Longitude</Form.Label>
                                                                                                    <div className="position-relative form-icon">
                                                                                                        <Form.Control
                                                                                                            type="text"
                                                                                                            value={longitude}
                                                                                                            onChange={handleLongitudeChange}
                                                                                                            className="form-control-lg form-control-icon name-input"
                                                                                                            placeholder="Longitude"
                                                                                                        />
                                                                                                        <i style={{ fontSize: '16px' }} className="ri-map-pin-line"></i>
                                                                                                    </div>
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                            <Col lg="6">
                                                                                                <Form.Group className="mb-3">
                                                                                                    <Form.Label>City</Form.Label>
                                                                                                    <div className="position-relative form-icon">
                                                                                                        <Form.Control
                                                                                                            type="text"
                                                                                                            onChange={(e) => setCity(e.target.value)}
                                                                                                            value={city}
                                                                                                            className="form-control-lg form-control-icon name-input"
                                                                                                            placeholder="City"
                                                                                                        />
                                                                                                        <i style={{ fontSize: '16px' }} className="ri-map-pin-line"></i>
                                                                                                    </div>
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                            <Col lg="6">
                                                                                                <Form.Group className="mb-3">
                                                                                                    <Form.Label>State</Form.Label>
                                                                                                    <div className="position-relative form-icon">
                                                                                                        <Form.Control
                                                                                                            type="text"
                                                                                                            onChange={(e) => setState(e.target.value)}
                                                                                                            value={state}
                                                                                                            className="form-control-lg form-control-icon name-input"
                                                                                                            placeholder="State"
                                                                                                        />
                                                                                                        <i style={{ fontSize: '16px' }} className="ri-map-pin-line"></i>
                                                                                                    </div>
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                            <Col lg="6">
                                                                                                <Form.Group className="mb-3">
                                                                                                    <Form.Label>Country</Form.Label>
                                                                                                    <div className="position-relative form-icon">
                                                                                                        <Form.Control
                                                                                                            type="text"
                                                                                                            onChange={(e) => setCountry(e.target.value)}
                                                                                                            value={country}
                                                                                                            className="form-control-lg form-control-icon name-input"
                                                                                                            placeholder="Country"
                                                                                                        />
                                                                                                        <i style={{ fontSize: '16px' }} className="ri-map-pin-line"></i>
                                                                                                    </div>
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                            <Col lg="6">
                                                                                                <Form.Group className="mb-3">
                                                                                                    <Form.Label>Pincode</Form.Label>
                                                                                                    <div className="position-relative form-icon">
                                                                                                        <Form.Control
                                                                                                            type="text"
                                                                                                            onChange={(e) => setPostalCode(e.target.value)}
                                                                                                            value={postalCode}
                                                                                                            className="form-control-lg form-control-icon name-input"
                                                                                                            placeholder="Pincode"
                                                                                                        />
                                                                                                        <i style={{ fontSize: '16px' }} className="ri-map-pin-line"></i>
                                                                                                    </div>
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                            {canAccess && userRoles[0] !== "MANAGER" && (
                                                                                                <Col lg="6" className="mt-3">
                                                                                                    <Form.Label>Please select location type.</Form.Label>
                                                                                                    <div className="d-flex gap-3">
                                                                                                        <span className="form-check mb-2">
                                                                                                            <input
                                                                                                                className="form-check-input"
                                                                                                                type="radio"
                                                                                                                name="is_billable"
                                                                                                                id="Yes2"
                                                                                                                value="Payable"
                                                                                                                checked={isBilaibleClient}
                                                                                                                onChange={() => handleRadioChange('Payable')}
                                                                                                            />
                                                                                                            <label className="form-check-label" htmlFor="Yes2">Payable</label>
                                                                                                        </span>
                                                                                                        <span className="form-check">
                                                                                                            <input
                                                                                                                className="form-check-input"
                                                                                                                type="radio"
                                                                                                                name="is_billable"
                                                                                                                id="No2"
                                                                                                                value="Internal"
                                                                                                                checked={!isBilaibleClient}
                                                                                                                onChange={() => handleRadioChange('Internal')}
                                                                                                            />
                                                                                                            <label className="form-check-label" htmlFor="No2">Internal</label>
                                                                                                        </span>
                                                                                                    </div>

                                                                                                    {/* Geofencing question */}

                                                                                                </Col>
                                                                                            )}
                                                                                            <Col lg={6} className="mt-3">
                                                                                                <Form.Label>Location Geofencing</Form.Label>
                                                                                                <div className="d-flex gap-3">
                                                                                                    <span className="form-check mb-2">
                                                                                                        <input
                                                                                                            className="form-check-input"
                                                                                                            type="radio"
                                                                                                            name="is_geofancing"
                                                                                                            id="YesGeofencing"
                                                                                                            value="Yes"
                                                                                                            checked={isGeofancing}
                                                                                                            onChange={() => handleGeofancingChange('Yes')}
                                                                                                        />
                                                                                                        <label className="form-check-label" htmlFor="YesGeofencing">Yes</label>
                                                                                                    </span>
                                                                                                    <span className="form-check">
                                                                                                        <input
                                                                                                            className="form-check-input"
                                                                                                            type="radio"
                                                                                                            name="is_geofancing"
                                                                                                            id="NoGeofencing"
                                                                                                            value="No"
                                                                                                            checked={!isGeofancing}
                                                                                                            onChange={() => handleGeofancingChange('No')}
                                                                                                        />
                                                                                                        <label className="form-check-label" htmlFor="NoGeofencing">No</label>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </Col>


                                                                                        </Row>
                                                                                    </Col>
                                                                                    {/* {canAccess && userRoles[0] !== "MANAGER" && (<Col lg="12" className="my-3">
                                                                                        <Form.Label>Please select location type.</Form.Label>
                                                                                        <div className="d-flex gap-3">
                                                                                            <span className="form-check mb-2">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="radio"
                                                                                                    name="is_billable"
                                                                                                    id="Yes2"
                                                                                                    value="Payable"
                                                                                                    checked={isBilaibleClient}
                                                                                                    onChange={() => handleRadioChange('Payable')}
                                                                                                />
                                                                                                <label className="form-check-label" htmlFor="Yes2">Payable</label>
                                                                                            </span>
                                                                                            <span className="form-check">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="radio"
                                                                                                    name="is_billable"
                                                                                                    id="No2"
                                                                                                    value="Internal"
                                                                                                    checked={!isBilaibleClient}
                                                                                                    onChange={() => handleRadioChange('Internal')}
                                                                                                />
                                                                                                <label className="form-check-label" htmlFor="No2">Internal</label>
                                                                                            </span>
                                                                                        </div>
                                                                                    </Col>)} */}
                                                                                    <Col lg={12}>
                                                                                        {isGeofancing && (
                                                                                            <div className="mt-3 mb-4">
                                                                                                <Form.Label>Enter geofencing distance (in meters):</Form.Label>
                                                                                                <input
                                                                                                    type="number"
                                                                                                    className="form-control"
                                                                                                    placeholder="e.g., 100, 200"
                                                                                                    value={geofencingRadius || ''}
                                                                                                    onChange={(e) => {
                                                                                                        let value = parseFloat(e.target.value) || 0;
                                                                                                        if (value < 0) value = 0;
                                                                                                        setGeofencingRadius(value);
                                                                                                        Locationformik.setFieldValue('geofancing_radius', value);
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                    </Col>
                                                                                    {isBilaibleClient && (
                                                                                        <>
                                                                                            <Col lg="6" className="mt-3">
                                                                                                <Form.Group className="mb-3 position-relative form-icon">
                                                                                                    <Form.Label>Client</Form.Label>
                                                                                                    <Select
                                                                                                        className={`${Locationformik.touched.client_id && Locationformik.errors.client_id ? 'is-invalid' : ''
                                                                                                            }`}
                                                                                                        id="Client"
                                                                                                        name="client_id"
                                                                                                        options={ClientDetail}
                                                                                                        onChange={(selectedOption: any) => {
                                                                                                            Locationformik.setFieldValue('client_id', selectedOption ? selectedOption.value : '');
                                                                                                        }}
                                                                                                        onBlur={() => Locationformik.setFieldTouched('client_id', true)}
                                                                                                        value={ClientDetail.find((option: any) => option.value === Locationformik.values.client_id) || null}
                                                                                                        classNamePrefix="react-select"
                                                                                                        menuPlacement="top"
                                                                                                    />
                                                                                                    <i style={{ fontSize: '16px' }} className="ri-user-line mt-4"></i>
                                                                                                    {Locationformik.touched.client_id && Locationformik.errors.client_id ? (
                                                                                                        <div className="invalid-feedback">{Locationformik.errors.client_id}</div>
                                                                                                    ) : null}
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                            <Col lg="6" className="mt-3">
                                                                                                <Form.Group className="mb-3 form-icon">
                                                                                                    <Form.Label>Billing Group</Form.Label>
                                                                                                    <Select
                                                                                                        className={`${Locationformik.touched.client_id && Locationformik.errors.client_id ? 'is-invalid' : ''
                                                                                                            }`}
                                                                                                        id="billing_group"
                                                                                                        name="billing_group_id"
                                                                                                        options={billingGroup}
                                                                                                        onChange={(selectedOption: any) => {
                                                                                                            Locationformik.setFieldValue('billing_group_id', selectedOption ? selectedOption.value : '');
                                                                                                            fetchScheduleDropdownDetails(selectedOption.value)
                                                                                                        }}
                                                                                                        onBlur={() => Locationformik.setFieldTouched('billing_group_id', true)}
                                                                                                        value={billingGroup.find(option => option.value === Locationformik.values.billing_group_id)}
                                                                                                        classNamePrefix="react-select"
                                                                                                        menuPlacement="top"
                                                                                                    />
                                                                                                    <i
                                                                                                        style={{ fontSize: '16px' }}
                                                                                                        className=" ri-bank-card-line mt-4"
                                                                                                    ></i>
                                                                                                    {Locationformik.touched.billing_group_id && Locationformik.errors.billing_group_id ? (
                                                                                                        <div className="invalid-feedback">{Locationformik.errors.billing_group_id}</div>
                                                                                                    ) : null}
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                            {!Locationformik.values.client_id &&
                                                                                                <Col lg="12">
                                                                                                    <Button className="add-btn mb-3" onClick={handleAddClient}>
                                                                                                        <i className="ri-add-fill"></i> Add New Client
                                                                                                    </Button>
                                                                                                </Col>
                                                                                            }
                                                                                            {billingGroupList.length > 0 ?
                                                                                                <Col lg="12" className="mb-3">
                                                                                                    <Table className="align-middle table-nowrap mb-0">
                                                                                                        <thead className="table-light">
                                                                                                            <tr>
                                                                                                                <th scope="col">Category Name</th>
                                                                                                                <th scope="col">Default Rate</th>
                                                                                                                <th scope="col">Assign Rate</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {billingGroupList?.map(rate => (
                                                                                                                <tr key={rate.billing_rate_id}>
                                                                                                                    <td>{rate.category_name}</td>
                                                                                                                    <td>{rate.default_rate}</td>
                                                                                                                    <td>
                                                                                                                        <Form.Control
                                                                                                                            type="number"
                                                                                                                            className="form-control-lg form-control-icon name-input"
                                                                                                                            value={rate.assign_rate ? rate.assign_rate : ""}
                                                                                                                            onWheel={(e) => e.currentTarget.blur()} 
                                                                                                                            // onChange={(e) =>
                                                                                                                            //     handleAssignRateChange(rate.billing_rate_id, Number(e.target.value))
                                                                                                                            // }
                                                                                                                            // onChange={(e) => {
                                                                                                                            //     const newRate = parseFloat(e.target.value);
                                                                                                                            //     // Check if the new rate is less than the default rate
                                                                                                                            //     if (newRate < rate.default_rate) {
                                                                                                                            //         // Set to default rate if it's less
                                                                                                                            //         handleAssignRateChange(rate.billing_rate_id, rate.default_rate);
                                                                                                                            //     } else {
                                                                                                                            //         // Otherwise, update with the entered rate
                                                                                                                            //         handleAssignRateChange(rate.billing_rate_id, newRate);
                                                                                                                            //     }
                                                                                                                            // }}
                                                                                                                            onChange={(e) =>
                                                                                                                                handleAssignRateChange(rate.billing_rate_id, Number(e.target.value))}
                                                                                                                        />
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            ))}
                                                                                                        </tbody>
                                                                                                    </Table>
                                                                                                </Col>
                                                                                                :
                                                                                                <div></div>
                                                                                            }
                                                                                        </>
                                                                                    )}
                                                                                </Row>
                                                                            </SimpleBar>
                                                                        </Card.Body>

                                                                        <Card.Footer className="border-top-0 pt-0">
                                                                            <Row>
                                                                                <Col lg={12} className="next-page1">
                                                                                    <Button
                                                                                        color="primary"
                                                                                        onClick={() => setactiveTab(1)}
                                                                                        className="next-btn"
                                                                                    >
                                                                                        NEXT{" "}
                                                                                        <i className="bi bi-arrow-right"></i>
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Footer>
                                                                    </Card>
                                                                </Col>
                                                            </Tab.Pane>
                                                        )}
                                                        {activeTab === 1 && (
                                                            <Tab.Pane
                                                                eventKey="1"
                                                                // id="pills-gen-info"
                                                                role="tabpanel"
                                                                className="d-flex"
                                                            // aria-labelledby="pills-gen-info-tab"
                                                            >
                                                                <Col lg={12} style={{ width: '100%' }}>
                                                                    <Card className="add-location-form-card">
                                                                        <Card.Header>
                                                                            <Row className="location-row flex-row flex-lg-row">
                                                                                <Col
                                                                                    lg={6}
                                                                                    className="location-title-details mb-0 d-flex align-items-center mb-2"
                                                                                >
                                                                                    <h4>Location Contacts</h4>
                                                                                </Col>
                                                                                <Col
                                                                                    lg={6}
                                                                                    className="mb-2 location-navigation"
                                                                                >
                                                                                    <span className=" location-nav d-flex justify-content-end gap-3">
                                                                                        <Button
                                                                                            color="primary"
                                                                                            className="back-bnt-btn"
                                                                                            href="/location"
                                                                                        >
                                                                                            Back
                                                                                        </Button>
                                                                                        {Locationformik.isSubmitting ? (
                                                                                            <Button
                                                                                                color="primary"
                                                                                                className="sub-update-btn"
                                                                                                disabled
                                                                                            >
                                                                                                <Spinner
                                                                                                    as="span"
                                                                                                    animation="border"
                                                                                                    size="sm"
                                                                                                    role="status"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            </Button>
                                                                                        ) : (
                                                                                            <Button type="submit" className="sub-update-btn">
                                                                                                {locID ? 'Update' : 'Add'}
                                                                                            </Button>
                                                                                        )}
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Header>
                                                                        <Card.Body style={{ minHeight: '50vh' }}>
                                                                            <SimpleBar
                                                                                style={{
                                                                                    maxHeight: "auto",
                                                                                    overflowX: "hidden",
                                                                                }}
                                                                                className="pl-0 pr-3"
                                                                            >
                                                                                {rowsContact.map((row, index) => (
                                                                                    <Row key={row.id} className="contact-row">
                                                                                        <Col lg="12">
                                                                                            <h5>Contact {index + 1}</h5>
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Form.Group className="mb-3">
                                                                                                <Form.Label>Contact Person Name</Form.Label>
                                                                                                <div className="position-relative form-icon">
                                                                                                    <Form.Control
                                                                                                        type="text"
                                                                                                        name={`contacts[${row.id - 1}].contact_person`}
                                                                                                        className="form-control-lg form-control-icon name-input"
                                                                                                        placeholder="Enter Contact Person Name"
                                                                                                        onChange={Locationformik.handleChange}
                                                                                                        value={Locationformik.values.contacts[row.id - 1]?.contact_person || ''}
                                                                                                    />
                                                                                                    <i style={{ fontSize: "16px" }} className="bx bx-face"></i>
                                                                                                </div>
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Form.Group className="mb-3">
                                                                                                <Form.Label>Contact Phone</Form.Label>
                                                                                                <div className="position-relative form-icon">
                                                                                                    <Form.Control
                                                                                                        type="text"
                                                                                                        name={`contacts[${row.id - 1}].contact_phone`}
                                                                                                        className="form-control-lg form-control-icon name-input"
                                                                                                        placeholder="Enter Contact Phone"
                                                                                                        onChange={Locationformik.handleChange}
                                                                                                        value={Locationformik.values.contacts[row.id - 1]?.contact_phone || ''}
                                                                                                    />
                                                                                                    <i style={{ fontSize: "16px" }} className="ri-phone-line"></i>
                                                                                                </div>
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Form.Group className="mb-3">
                                                                                                <Form.Label>Contact Email</Form.Label>
                                                                                                <div className="position-relative form-icon">
                                                                                                    <Form.Control
                                                                                                        type="email"
                                                                                                        name={`contacts[${row.id - 1}].contact_email`}
                                                                                                        className="form-control-lg form-control-icon name-input"
                                                                                                        placeholder="Enter Contact Email"
                                                                                                        onChange={Locationformik.handleChange}
                                                                                                        value={Locationformik.values.contacts[row.id - 1]?.contact_email || ''}
                                                                                                    />
                                                                                                    <i style={{ fontSize: "16px" }} className="ri-mail-line"></i>
                                                                                                </div>
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Form.Group className="mb-3">
                                                                                                <Form.Label>Contact Type</Form.Label>
                                                                                                <div className="position-relative form-icon">
                                                                                                    <Form.Control
                                                                                                        type="text"
                                                                                                        name={`contacts[${row.id - 1}].contact_type`}
                                                                                                        className="form-control-lg form-control-icon name-input"
                                                                                                        placeholder="Enter Contact Email"
                                                                                                        onChange={Locationformik.handleChange}
                                                                                                        value={Locationformik.values.contacts[row.id - 1]?.contact_type || ''}
                                                                                                    />
                                                                                                    <i style={{ fontSize: "16px" }} className="ri-account-circle-line"></i>
                                                                                                </div>
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                        {/* <Col>
                                                                                        <div className="form-check form-check-inline">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                id={`visibleOnMobile${index}`}
                                                                                                name={`contacts[${index}].visible_on_mobile`}
                                                                                                className="form-check-input"
                                                                                                onChange={(e) => handleVisibleOnMobileChange(index, e.target.checked)}
                                                                                                checked={Locationformik.values.contacts[index]?.visible_on_mobile === 1}
                                                                                            />
                                                                                            <Form.Label htmlFor={`visibleOnMobile${index}`} className="form-check-label">
                                                                                                Is Visible On Mobile
                                                                                            </Form.Label>
                                                                                        </div>
                                                                                    </Col> */}
                                                                                        <Col>
                                                                                            <Form.Group>
                                                                                                <Form.Label htmlFor={`visibleOnMobile${index}`}>Is Visible On Mobile</Form.Label>
                                                                                                <div className="form-check form-switch form-switch-custom form-switch-md mb-2">
                                                                                                    <Form.Check
                                                                                                        type="checkbox"
                                                                                                        id={`visibleOnMobile${row.id - 1}`}
                                                                                                        name={`contacts[${row.id - 1}].visible_on_mobile`}
                                                                                                        onChange={(e) => handleVisibleOnMobileChange(row.id - 1, e.target.checked)}
                                                                                                        checked={rowsContact[row.id - 1]?.visible_on_mobile === 1}
                                                                                                    />
                                                                                                </div>
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                        <Col className="document-adjust-div d-flex align-items-center gap-2 mt-3 p-0">
                                                                                            <Button
                                                                                                onClick={() => deleteRowContact(row.id)}
                                                                                                className="btn btn-subtle-danger btn-icon btn-md remove-item-btn"
                                                                                            >
                                                                                                <i className="ph-trash" style={{ fontSize: "15px" }}></i>
                                                                                            </Button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                ))}
                                                                                <Row>
                                                                                    <Col lg={12} className="mb-3">
                                                                                        <Button className="add-btn p-0" onClick={addRowContact}>
                                                                                            <i className="ri-add-fill"></i> Add More
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col lg="12" className="mt-4">
                                                                                        <h5 style={{ color: '#333' }}>Location notes</h5>
                                                                                    </Col>
                                                                                    <Col lg="10">
                                                                                        <Form.Group className="mb-3">
                                                                                            <div className="position-relative form-icon">
                                                                                                <Form.Control
                                                                                                    type="text"
                                                                                                    name="notes"
                                                                                                    className="form-control-lg form-control-icon name-input"
                                                                                                    placeholder="Enter Location notes..."
                                                                                                    value={Locationformik.values.notes}
                                                                                                    onChange={Locationformik.handleChange}
                                                                                                    onBlur={Locationformik.handleBlur}
                                                                                                />
                                                                                                <i
                                                                                                    style={{ fontSize: "16px" }}
                                                                                                    className=" ri-chat-1-line"
                                                                                                ></i>
                                                                                            </div>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                </Row>
                                                                            </SimpleBar>
                                                                        </Card.Body>
                                                                        <Card.Footer className="border-top-0">
                                                                            <Row>
                                                                                <Col lg={12} className="previous-page">
                                                                                    <Button
                                                                                        color="primary"
                                                                                        onClick={() => setactiveTab(0)}
                                                                                        className="previous-btn"
                                                                                    >
                                                                                        <i className="bi bi-arrow-left"></i>{" "}
                                                                                        Previous
                                                                                    </Button>
                                                                                    <Button
                                                                                        color="primary"
                                                                                        onClick={() => setactiveTab(2)}
                                                                                        className="next-btn"
                                                                                    >
                                                                                        NEXT{" "}
                                                                                        <i className="bi bi-arrow-right"></i>
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Footer>
                                                                    </Card>
                                                                </Col>
                                                            </Tab.Pane>
                                                        )}
                                                        {activeTab === 2 && (
                                                            <Tab.Pane
                                                                eventKey="2"
                                                                // id="pills-gen-info"
                                                                role="tabpanel"
                                                                className="d-flex"
                                                            // aria-labelledby="pills-gen-info-tab"
                                                            >
                                                                <Col lg={12} style={{ width: '100%' }} className="location-create-tabs">
                                                                    <Card className="add-location-form-card">
                                                                        <Card.Header>
                                                                            <Row className="location-row flex-row flex-lg-row">
                                                                                <Col
                                                                                    lg={6}
                                                                                    className="location-title-details mb-0 d-flex align-items-center mb-2"
                                                                                >
                                                                                    <h4>Preferred Staff</h4>
                                                                                </Col>
                                                                                <Col
                                                                                    lg={6}
                                                                                    className="mb-2 location-navigation"
                                                                                >
                                                                                    <span className=" location-nav d-flex justify-content-end gap-3">
                                                                                        <Button
                                                                                            color="primary"
                                                                                            className="back-bnt-btn"
                                                                                            href="/location"
                                                                                        >
                                                                                            Back
                                                                                        </Button>
                                                                                        {Locationformik.isSubmitting ? (
                                                                                            <Button
                                                                                                color="primary"
                                                                                                className="sub-update-btn"
                                                                                                disabled
                                                                                            >
                                                                                                <Spinner
                                                                                                    as="span"
                                                                                                    animation="border"
                                                                                                    size="sm"
                                                                                                    role="status"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            </Button>
                                                                                        ) : (
                                                                                            <Button type="submit" className="sub-update-btn">
                                                                                                {locID ? 'Update' : 'Add'}
                                                                                            </Button>
                                                                                        )}
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Header>
                                                                        <Card.Body style={{ minHeight: '50vh' }}>
                                                                            <Row>
                                                                                <Col lg="6">
                                                                                    <Form.Group className="mb-3">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <div className="position-relative form-icon me-2" style={{ width: "300px" }}>
                                                                                                <Form.Control
                                                                                                    type="text"
                                                                                                    name="name"
                                                                                                    className="form-control form-control-icon name-input"
                                                                                                    placeholder="Search Staff"
                                                                                                    value={inputValue}
                                                                                                    onChange={handleChange}
                                                                                                />
                                                                                                <i
                                                                                                    className="ri-search-line position-absolute"
                                                                                                    style={{
                                                                                                        fontSize: "16px",
                                                                                                        right: "10px",
                                                                                                        top: "50%",
                                                                                                        transform: "translateY(-50%)",
                                                                                                        pointerEvents: "none",
                                                                                                    }}
                                                                                                ></i>
                                                                                            </div>
                                                                                            <div className="form-check ms-2">
                                                                                                <Form.Check>
                                                                                                    <Form.Check.Input
                                                                                                        type="checkbox"
                                                                                                        name="checkAll"
                                                                                                        id="checkAll"
                                                                                                        checked={isCheckAll}
                                                                                                        onChange={handleCheckAllChange}
                                                                                                    />
                                                                                                    <Form.Check.Label className="ms-1">Select All</Form.Check.Label>
                                                                                                </Form.Check>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col lg="12">
                                                                                    <div className="card-container" style={{ height: '300px' }}>
                                                                                        {locationStaff.length > 0 ? (
                                                                                            <>
                                                                                                <SimpleBar style={{ maxHeight: "calc(400px - 50px)" }}>
                                                                                                    <div className="d-flex flex-wrap gap-4 pb-3" style={{ justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                                        {/* Selected staff members */}
                                                                                                        {locationStaff
                                                                                                            .sort((a, b) => b.id - a.id)
                                                                                                            .filter(staff => selectedStaff.includes(staff.id))
                                                                                                            .map((staff) => (
                                                                                                                <div
                                                                                                                    key={staff.id}
                                                                                                                    className="card"
                                                                                                                    style={{
                                                                                                                        width: '300px',
                                                                                                                        minWidth: '300px',
                                                                                                                        backgroundColor: '#fff',
                                                                                                                        borderRadius: '8px',
                                                                                                                        boxShadow: '0 1px 3px rgba(0,0,0,0.12)'
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <div className="card-body p-3">
                                                                                                                        <div className="d-flex align-items-start justify-content-between">
                                                                                                                            <div className="d-flex align-items-center">
                                                                                                                                {/* Profile Badge */}
                                                                                                                                <div className="profile-badge flex items-center justify-center text-center rounded-full" style={{
                                                                                                                                    width: '36px',
                                                                                                                                    height: '36px',
                                                                                                                                    fontSize: '14px',
                                                                                                                                    marginRight: '8px',
                                                                                                                                    // width: '40px',
                                                                                                                                    // height: '40px',
                                                                                                                                    borderRadius: '50%',
                                                                                                                                    backgroundColor: '#ffe9e9',
                                                                                                                                    color: '#ff6c6c',
                                                                                                                                    display: 'flex',
                                                                                                                                    alignItems: 'center',
                                                                                                                                    justifyContent: 'center',
                                                                                                                                    // fontSize: '16px',
                                                                                                                                    fontWeight: 'bold',
                                                                                                                                    // marginRight: '10px'
                                                                                                                                }}>
                                                                                                                                    {staff.staff_name && staff.staff_name.split(' ').map(word => word[0]).join('').toUpperCase()}
                                                                                                                                </div>
                                                                                                                                <h6 className="mb-1 flex flex-col">
                                                                                                                                    <div className="d-flex items-center">
                                                                                                                                        <label className="form-check-label mb-0 text-sm font-semibold" htmlFor={`cardtableCheck${staff.id}`}>
                                                                                                                                            {staff.staff_name}
                                                                                                                                        </label>
                                                                                                                                        <i
                                                                                                                                            className="ri-information-line link-info ms-1 cursor-pointer text-sm"
                                                                                                                                            onClick={() => handleInfoClick(staff)}
                                                                                                                                        ></i>
                                                                                                                                    </div>
                                                                                                                                    <div className="text-muted small mt-1 text-xs font-light">
                                                                                                                                        <div style={{ fontWeight: 'normal' }}>Type: {staff.employee_status ? staff.employee_status : "-"}</div>
                                                                                                                                        <div style={{ fontWeight: 'normal' }}>Access: {staff.name}</div>
                                                                                                                                    </div>
                                                                                                                                </h6>
                                                                                                                            </div>
                                                                                                                            <div className="form-check">
                                                                                                                                <Form.Check>
                                                                                                                                    <Form.Check.Input
                                                                                                                                        type="checkbox"
                                                                                                                                        name="preferred"
                                                                                                                                        id={`cardtableCheck${staff.id}`}
                                                                                                                                        checked={selectedStaff.includes(staff.id)}
                                                                                                                                        onChange={() => handleCheckboxChange(staff.id)}
                                                                                                                                        className="form-check-input transform scale-90"
                                                                                                                                    />
                                                                                                                                </Form.Check>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ))}

                                                                                                        {/* Unselected staff members */}
                                                                                                        {locationStaff
                                                                                                            .filter(staff => !selectedStaff.includes(staff.id))
                                                                                                            .map((staff) => (
                                                                                                                <div
                                                                                                                    key={staff.id}
                                                                                                                    className="card"
                                                                                                                    style={{
                                                                                                                        width: '300px',
                                                                                                                        minWidth: '300px',
                                                                                                                        backgroundColor: '#fff',
                                                                                                                        borderRadius: '8px',
                                                                                                                        boxShadow: '0 1px 3px rgba(0,0,0,0.12)'
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <div className="card-body p-3">
                                                                                                                        <div className="d-flex align-items-start justify-content-between">
                                                                                                                            <div className="d-flex align-items-center">
                                                                                                                                {/* Profile Badge */}
                                                                                                                                <div className="profile-badge flex items-center justify-center text-center rounded-full" style={{
                                                                                                                                    width: '36px',
                                                                                                                                    height: '36px',
                                                                                                                                    fontSize: '14px',
                                                                                                                                    marginRight: '8px',
                                                                                                                                    // width: '40px',
                                                                                                                                    // height: '40px',
                                                                                                                                    borderRadius: '50%',
                                                                                                                                    backgroundColor: '#ffe9e9',
                                                                                                                                    color: '#ff6c6c',
                                                                                                                                    display: 'flex',
                                                                                                                                    alignItems: 'center',
                                                                                                                                    justifyContent: 'center',
                                                                                                                                    // fontSize: '16px',
                                                                                                                                    fontWeight: 'bold',
                                                                                                                                    // marginRight: '10px'
                                                                                                                                }}>
                                                                                                                                    {staff.staff_name && staff.staff_name.split(' ').map(word => word[0]).join('').toUpperCase()}
                                                                                                                                </div>
                                                                                                                                <h6 className="mb-1 flex flex-col">
                                                                                                                                    <div className="d-flex items-center">
                                                                                                                                        <label className="form-check-label mb-0 text-sm font-semibold" htmlFor={`cardtableCheck${staff.id}`}>
                                                                                                                                            {staff.staff_name}
                                                                                                                                        </label>
                                                                                                                                        <i
                                                                                                                                            className="ri-information-line link-info ms-1 cursor-pointer text-sm"
                                                                                                                                            onClick={() => handleInfoClick(staff)}
                                                                                                                                        ></i>
                                                                                                                                    </div>
                                                                                                                                    <div className="text-muted small mt-1 text-xs font-light">
                                                                                                                                        <div style={{ fontWeight: 'normal' }}>Type: {staff.employee_status ? staff.employee_status : "-"}</div>
                                                                                                                                        <div style={{ fontWeight: 'normal' }}>Access: {staff.name}</div>
                                                                                                                                    </div>
                                                                                                                                </h6>
                                                                                                                            </div>
                                                                                                                            <div className="form-check">
                                                                                                                                <Form.Check>
                                                                                                                                    <Form.Check.Input
                                                                                                                                        type="checkbox"
                                                                                                                                        name="preferred"
                                                                                                                                        id={`cardtableCheck${staff.id}`}
                                                                                                                                        checked={selectedStaff.includes(staff.id)}
                                                                                                                                        onChange={() => handleCheckboxChange(staff.id)}
                                                                                                                                        className="form-check-input transform scale-90"
                                                                                                                                    />
                                                                                                                                </Form.Check>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ))}
                                                                                                    </div>
                                                                                                </SimpleBar>
                                                                                            </>
                                                                                        ) : (
                                                                                            <div className="text-center mt-4">
                                                                                                No Result Found.
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </Col>




                                                                            </Row>
                                                                        </Card.Body>
                                                                        <Card.Footer className="border-top-0">
                                                                            <Row>
                                                                                <Col lg={12} className="previous-page">
                                                                                    <Button
                                                                                        color="primary"
                                                                                        onClick={() => setactiveTab(1)}
                                                                                        className="previous-btn"
                                                                                    >
                                                                                        <i className="bi bi-arrow-left"></i>{" "}
                                                                                        Previous
                                                                                    </Button>
                                                                                    <div>
                                                                                        <Button
                                                                                            color="primary"
                                                                                            onClick={() => setactiveTab(3)}
                                                                                            className="next-btn"
                                                                                        >
                                                                                            NEXT{" "}
                                                                                            <i className="bi bi-arrow-right"></i>
                                                                                        </Button>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Footer>
                                                                    </Card>
                                                                </Col>
                                                            </Tab.Pane>
                                                        )}{activeTab === 3 && (
                                                            <Tab.Pane
                                                                eventKey="3"
                                                                // id="pills-gen-info"
                                                                role="tabpanel"
                                                                className="d-flex"
                                                            // aria-labelledby="pills-gen-info-tab"
                                                            >
                                                                <Col lg={12} style={{ width: '100%' }}>
                                                                    <Card className="add-location-form-card">
                                                                        <Card.Header>
                                                                            <Row className="location-row flex-row flex-lg-row">
                                                                                <Col
                                                                                    lg={6}
                                                                                    className="location-title-details mb-0 d-flex align-items-center mb-2"
                                                                                >
                                                                                    <h4>Incident Report</h4>
                                                                                </Col>
                                                                                <Col
                                                                                    lg={6}
                                                                                    className="mb-2 location-navigation"
                                                                                >
                                                                                    <span className="location-nav d-flex justify-content-end gap-3">
                                                                                        <Button
                                                                                            color="primary"
                                                                                            className="back-bnt-btn"
                                                                                            href="/location"
                                                                                        >
                                                                                            Back
                                                                                        </Button>
                                                                                        {Locationformik.isSubmitting ? (
                                                                                            <Button
                                                                                                color="primary"
                                                                                                className="sub-update-btn"
                                                                                                disabled
                                                                                            >
                                                                                                <Spinner
                                                                                                    as="span"
                                                                                                    animation="border"
                                                                                                    size="sm"
                                                                                                    role="status"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            </Button>
                                                                                        ) : (
                                                                                            <Button type="submit" className="sub-update-btn">
                                                                                                {locID ? 'Update' : 'Add'}
                                                                                            </Button>
                                                                                        )}
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Header>
                                                                        <Card.Body style={{ minHeight: '50vh' }}>
                                                                            <Row>
                                                                                <Col lg="6">
                                                                                    <Form.Group className="mb-3">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <div className="position-relative form-icon me-2" style={{ width: "300px" }}>
                                                                                                <Form.Control
                                                                                                    type="text"
                                                                                                    name="name"
                                                                                                    className="form-control form-control-lg form-control-icon name-input"
                                                                                                    placeholder="Search..."
                                                                                                    value={inputIncidentValue}
                                                                                                    onChange={handleIncidentChange}
                                                                                                />
                                                                                                <i
                                                                                                    className="ri-search-line position-absolute"
                                                                                                    style={{
                                                                                                        fontSize: "16px",
                                                                                                        right: "10px",
                                                                                                        top: "50%",
                                                                                                        transform: "translateY(-50%)",
                                                                                                        pointerEvents: "none",
                                                                                                    }}
                                                                                                ></i>
                                                                                            </div>
                                                                                            <div className="form-check ms-2">
                                                                                                <Form.Check>
                                                                                                    <Form.Check.Input
                                                                                                        type="checkbox"
                                                                                                        name="checkAllIncidents"
                                                                                                        id="checkAllIncidents"
                                                                                                        checked={isCheckAllIncidents}
                                                                                                        onChange={handleCheckAllIncidentsChange}
                                                                                                    />
                                                                                                    <Form.Check.Label className="ms-1">Select All</Form.Check.Label>
                                                                                                </Form.Check>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Col>

                                                                                <Col lg="12">
                                                                                    <div className="table-responsive">
                                                                                        {locationIncident.length > 0 ?
                                                                                            <SimpleBar
                                                                                                style={{
                                                                                                    maxHeight: "auto",
                                                                                                    overflow: "auto",
                                                                                                }}
                                                                                            >
                                                                                                <div className="card-container" style={{ height: '400px' }}>
                                                                                                    <div className="mb-3 position-sticky top-0 bg-white py-2" style={{ zIndex: 1 }}>

                                                                                                    </div>

                                                                                                    <SimpleBar style={{ maxHeight: "calc(400px - 50px)" }}>
                                                                                                        <div className="d-flex flex-wrap gap-3 pb-3">
                                                                                                            {/* Show selected incidents first */}
                                                                                                            {locationIncident
                                                                                                                .filter(item => selectedIncidents.includes(item.id))
                                                                                                                .map((item) => (
                                                                                                                    <div
                                                                                                                        key={item.id}
                                                                                                                        className="card"
                                                                                                                        style={{
                                                                                                                            width: '250px',
                                                                                                                            minWidth: '250px',
                                                                                                                            backgroundColor: '#fff',
                                                                                                                            borderRadius: '8px',
                                                                                                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                                                                                            border: '1px solid rgba(240, 72, 79, 0.3)'
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <div className="card-body p-3">
                                                                                                                            <div className="d-flex align-items-center">
                                                                                                                                <div>
                                                                                                                                    <h6 className="mb-1">
                                                                                                                                        <label className="form-check-label mt-1" htmlFor={`SwitchCheck${item.id}`}>
                                                                                                                                            {item.name}
                                                                                                                                        </label>
                                                                                                                                    </h6>
                                                                                                                                </div>
                                                                                                                                <div className="ms-auto">
                                                                                                                                    <Form.Check>
                                                                                                                                        <Form.Check.Input
                                                                                                                                            className="form-check-input"
                                                                                                                                            type="checkbox"
                                                                                                                                            id={`SwitchCheck${item.id}`}
                                                                                                                                            checked={selectedIncidents.includes(item.id)}
                                                                                                                                            onChange={() => handleIncidentCheckboxChange(item.id)}
                                                                                                                                        />
                                                                                                                                    </Form.Check>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ))}

                                                                                                            {/* Show unselected incidents next */}
                                                                                                            {locationIncident
                                                                                                                .filter(item => !selectedIncidents.includes(item.id))
                                                                                                                .map((item) => (
                                                                                                                    <div
                                                                                                                        key={item.id}
                                                                                                                        className="card"
                                                                                                                        style={{
                                                                                                                            width: '250px',
                                                                                                                            minWidth: '250px',
                                                                                                                            backgroundColor: '#fff',
                                                                                                                            borderRadius: '8px',
                                                                                                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                                                                                            border: '1px solid rgba(240, 72, 79, 0.3)'
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <div className="card-body p-3">
                                                                                                                            <div className="d-flex align-items-center">
                                                                                                                                <div>
                                                                                                                                    <h6 className="mb-1">
                                                                                                                                        <label className="form-check-label mt-1" htmlFor={`SwitchCheck${item.id}`}>
                                                                                                                                            {item.name}
                                                                                                                                        </label>
                                                                                                                                    </h6>
                                                                                                                                </div>
                                                                                                                                <div className="ms-auto">
                                                                                                                                    <Form.Check>
                                                                                                                                        <Form.Check.Input
                                                                                                                                            className="form-check-input"
                                                                                                                                            type="checkbox"
                                                                                                                                            id={`SwitchCheck${item.id}`}
                                                                                                                                            checked={selectedIncidents.includes(item.id)}
                                                                                                                                            onChange={() => handleIncidentCheckboxChange(item.id)}
                                                                                                                                        />
                                                                                                                                    </Form.Check>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ))}
                                                                                                        </div>
                                                                                                    </SimpleBar>
                                                                                                </div>
                                                                                            </SimpleBar>
                                                                                            : (
                                                                                                <div className="text-center mt-4">
                                                                                                    No Result Found.
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Body>
                                                                        <Card.Footer className="border-top-0">
                                                                            <Row>
                                                                                <Col lg={12} className="previous-page">
                                                                                    <Button
                                                                                        color="primary"
                                                                                        onClick={() => setactiveTab(2)}
                                                                                        className="previous-btn"
                                                                                    >
                                                                                        <i className="bi bi-arrow-left"></i>{" "}
                                                                                        Previous
                                                                                    </Button>
                                                                                    <Button
                                                                                        color="primary"
                                                                                        onClick={() => setactiveTab(4)}
                                                                                        className="next-btn"
                                                                                    >
                                                                                        NEXT{" "}
                                                                                        <i className="bi bi-arrow-right"></i>
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Footer>
                                                                    </Card>
                                                                </Col>
                                                            </Tab.Pane>
                                                        )}{activeTab === 4 && (
                                                            <Tab.Pane
                                                                eventKey="4"
                                                                // id="pills-gen-info"
                                                                role="tabpanel"
                                                                className="d-flex"
                                                            // aria-labelledby="pills-gen-info-tab"
                                                            >
                                                                <Col lg={12} style={{ width: '100%' }}>
                                                                    <Card className="add-location-form-card">
                                                                        <Card.Header>
                                                                            <Row className="location-row flex-row flex-lg-row">
                                                                                <Col
                                                                                    lg={6}
                                                                                    className="location-title-details mb-0 d-flex align-items-center mb-2"
                                                                                >
                                                                                    <h4>Location Documents</h4>
                                                                                </Col>
                                                                                <Col
                                                                                    lg={6}
                                                                                    className="mb-2 location-navigation"
                                                                                >
                                                                                    <span className=" location-nav d-flex justify-content-end gap-3">
                                                                                        <Button
                                                                                            color="primary"
                                                                                            className="back-bnt-btn"
                                                                                            href="/location"
                                                                                        >
                                                                                            Back
                                                                                        </Button>
                                                                                        {Locationformik.isSubmitting ? (
                                                                                            <Button
                                                                                                color="primary"
                                                                                                className="sub-update-btn"
                                                                                                disabled
                                                                                            >
                                                                                                <Spinner
                                                                                                    as="span"
                                                                                                    animation="border"
                                                                                                    size="sm"
                                                                                                    role="status"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            </Button>
                                                                                        ) : (
                                                                                            <Button type="submit" className="sub-update-btn">
                                                                                                {locID ? 'Update' : 'Add'}
                                                                                            </Button>
                                                                                        )}
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Header>
                                                                        <Card.Body style={{ minHeight: '50vh' }}>
                                                                            <Row>
                                                                                <Col lg="12">
                                                                                    <SimpleBar
                                                                                        autoHide={false}
                                                                                        // style={{ maxHeight: "61vh", overflowX: "hidden" }}
                                                                                        style={{ maxHeight: "auto", overflowX: "hidden" }}
                                                                                    >
                                                                                        {rows.map((row, index) => (
                                                                                            <Row className="add-doc" key={row.id}>
                                                                                                <Col lg="12">
                                                                                                    <h5>Documents {index + 1}</h5>
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Form.Group className="mb-3">
                                                                                                        <Form.Label>Document name</Form.Label>
                                                                                                        <div className="position-relative form-icon">
                                                                                                            <Form.Control
                                                                                                                type="text"
                                                                                                                name="document_name"
                                                                                                                className="form-control-lg form-control-icon name-input"
                                                                                                                placeholder="Enter Document Name"
                                                                                                                value={row.document_name}
                                                                                                                onChange={(e) => handleDocumentNameChange(e.target.value, row.id)}
                                                                                                            />
                                                                                                            <i
                                                                                                                style={{ fontSize: "16px" }}
                                                                                                                className="ri-file-text-line"
                                                                                                            ></i>
                                                                                                        </div>
                                                                                                    </Form.Group>
                                                                                                </Col>
                                                                                                <Col className="upload-section-col mb-2">
                                                                                                    <Form.Group>
                                                                                                        <div className="d-flex gap-2 align-items-center">
                                                                                                        <Form.Label className="doc-label">
                                                                                                            Document {index + 1}
                                                                                                        </Form.Label>
                                                                                                        {row.document_path && (
                                                                                                            <>
                                                                                                                {/* <p>{typeof row.document_path === "string" ? row.document_path : row.document_path.name}</p> */}
                                                                                                                {/* Eye icon for preview */}
                                                                                                                <i
                                                                                                                    className="ri-eye-line link-info"
                                                                                                                    style={{ fontSize: "18px", cursor: "pointer", marginTop: "-8px" }}
                                                                                                                    onClick={() => handleFilePreview(row.document_path)}
                                                                                                                ></i>
                                                                                                            </>
                                                                                                        )}
                                                                                                        </div>
                                                                                                        <div className="uploadsection">
                                                                                                            {row.showUploadButton ? (
                                                                                                                <div className="d-flex align-item-center">
                                                                                                                    <Form.Label
                                                                                                                        htmlFor={`document_path-${row.id}`}
                                                                                                                        className="uploadlabel"
                                                                                                                    >
                                                                                                                        <Form.Control
                                                                                                                            type="file"
                                                                                                                            className="form-control-lg"
                                                                                                                            style={{ display: "none" }}
                                                                                                                            id={`document_path-${row.id}`}
                                                                                                                            onChange={(e) => handleFileChange(e as React.ChangeEvent<HTMLInputElement>, row.id)}
                                                                                                                        />
                                                                                                                        <i className="bx bx-plus"></i> Choose File
                                                                                                                    </Form.Label>
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                <Col lg="12" className="closeuser py-0">
                                                                                                                    {row.document_path && (
                                                                                                                        <p>{typeof row.document_path === "string" ? row.document_path : row.document_path.name}</p>
                                                                                                                    )}
                                                                                                                    <Button
                                                                                                                        onClick={() => handleRemoveFile(row.id)}
                                                                                                                        color="red"
                                                                                                                    >
                                                                                                                        X
                                                                                                                    </Button>
                                                                                                                </Col>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </Form.Group>
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Form.Group className="mb-3 form-icon">
                                                                                                        <Form.Label>Visible to Staff</Form.Label>
                                                                                                        <Form.Select
                                                                                                            className="form-select-lg form-control-icon"
                                                                                                            id="floatingSelect"
                                                                                                            value={row.is_viewable_staff}
                                                                                                            onChange={(e) => handleViewableChange(parseInt(e.target.value), row.id)}
                                                                                                        >
                                                                                                            <option value={1}>Yes</option>
                                                                                                            <option value={0}>No</option>
                                                                                                        </Form.Select>
                                                                                                        <i
                                                                                                            style={{ fontSize: "16px" }}
                                                                                                            className="ri-eye-line text-muted mt-4"
                                                                                                        ></i>
                                                                                                    </Form.Group>
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Form.Group className="mb-3 form-icon">
                                                                                                        <Form.Label>Requires Signature</Form.Label>
                                                                                                        <Form.Select
                                                                                                            className="form-select-lg form-control-icon"
                                                                                                            id="floatingSelect"
                                                                                                            value={row.require_sign}
                                                                                                            onChange={(e) => handleRequireSignChange(parseInt(e.target.value), row.id)}
                                                                                                        >
                                                                                                            <option value={1}>Yes</option>
                                                                                                            <option value={0}>No</option>
                                                                                                        </Form.Select>
                                                                                                        <i
                                                                                                            style={{ fontSize: "16px" }}
                                                                                                            className="ri-edit-box-line text-muted mt-4"
                                                                                                        ></i>
                                                                                                    </Form.Group>
                                                                                                </Col>
                                                                                                <Col className="document-adjust-div d-flex align-items-center gap-2 mt-3 p-0">
                                                                                                    {LocationDetail &&
                                                                                                        <Button className="btn btn-icon btn-md bg-secondary-subtle btn-icon border-0">
                                                                                                            <i
                                                                                                                onClick={() => {
                                                                                                                    if (typeof row.document_path === "string" && row.document_path) {
                                                                                                                        handleOpenDocument(row.document_path);
                                                                                                                    }
                                                                                                                }}
                                                                                                                className="ri-eye-line text-secondary"
                                                                                                                style={{ fontSize: "15px" }}
                                                                                                            ></i>
                                                                                                        </Button>
                                                                                                    }
                                                                                                    <Button
                                                                                                        onClick={() => deleteRow(row.id)}
                                                                                                        className="btn btn-subtle-danger btn-icon btn-md remove-item-btn"
                                                                                                    >
                                                                                                        <i
                                                                                                            className="ph-trash"
                                                                                                            style={{ fontSize: "15px" }}
                                                                                                        ></i>
                                                                                                    </Button>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        ))}
                                                                                        <Row>
                                                                                            <Col lg={12}>
                                                                                                <Button className="add-btn p-0" onClick={addRow}>
                                                                                                    <i className="ri-add-fill"></i> Add More
                                                                                                </Button>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </SimpleBar>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Body>
                                                                        <Card.Footer className="border-top-0">
                                                                            <Row>
                                                                                <Col lg={12} className="previous-page">
                                                                                    <Button
                                                                                        color="primary"
                                                                                        onClick={() => setactiveTab(3)}
                                                                                        className="previous-btn"
                                                                                    >
                                                                                        <i className="bi bi-arrow-left"></i>{" "}
                                                                                        Previous
                                                                                    </Button>

                                                                                    <Button
                                                                                        color="primary"
                                                                                        onClick={() => setactiveTab(5)}
                                                                                        className="next-btn"
                                                                                    >
                                                                                        NEXT{" "}
                                                                                        <i className="bi bi-arrow-right"></i>
                                                                                    </Button>

                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Footer>
                                                                    </Card>
                                                                </Col>
                                                            </Tab.Pane>
                                                        )}
                                                        {activeTab === 5 && (
                                                            <Tab.Pane
                                                                eventKey="5"
                                                                // id="pills-gen-info"
                                                                role="tabpanel"
                                                                className="d-flex"
                                                            // aria-labelledby="pills-gen-info-tab"
                                                            >
                                                                <Col lg={12} style={{ width: '100%' }}>
                                                                    <Card className="add-location-form-card">
                                                                        <Card.Header>
                                                                            <Row className="location-row flex-row flex-lg-row">
                                                                                <Col
                                                                                    lg={6}
                                                                                    className="location-title-details mb-0 d-flex align-items-center mb-2"
                                                                                >
                                                                                    <h4>Checkpoints</h4>
                                                                                </Col>
                                                                                <Col
                                                                                    lg={6}
                                                                                    className="mb-2 location-navigation"
                                                                                >
                                                                                    <span className=" location-nav d-flex justify-content-end gap-3">
                                                                                        <Button
                                                                                            color="primary"
                                                                                            className="back-bnt-btn"
                                                                                            href="/location"
                                                                                        >
                                                                                            Back
                                                                                        </Button>
                                                                                        {Locationformik.isSubmitting ? (
                                                                                            <Button
                                                                                                color="primary"
                                                                                                className="sub-update-btn"
                                                                                                disabled
                                                                                            >
                                                                                                <Spinner
                                                                                                    as="span"
                                                                                                    animation="border"
                                                                                                    size="sm"
                                                                                                    role="status"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            </Button>
                                                                                        ) : (
                                                                                            <Button type="submit" className="sub-update-btn">
                                                                                               {locID ? 'Update' : 'Add'}
                                                                                            </Button>
                                                                                        )}
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Header>
                                                                        <Card.Body style={{ minHeight: '50vh' }}>
                                                                            <Row>
                                                                                <Col lg="12">
                                                                                    <Button
                                                                                        color="primary"
                                                                                        className="dashboard-btn mb-2"
                                                                                        onClick={toggleDepartmentCanvas}
                                                                                        style={{ float: 'right', width: '100px' }}
                                                                                    >
                                                                                        Add
                                                                                    </Button>
                                                                                    <div className="mb-3">
                                                                                        <Form.Group style={{ float: 'left' }}>
                                                                                            <div className="position-relative form-icon">
                                                                                                <Form.Control
                                                                                                    type="name"
                                                                                                    name="name"
                                                                                                    className="form-control-lg form-control-icon name-input"
                                                                                                    placeholder="Search Checkpoint"
                                                                                                    value={searchTerm}
                                                                                                    onChange={handleSearchChange}
                                                                                                    style={{ width: '250px' }}
                                                                                                />
                                                                                                <i
                                                                                                    style={{ fontSize: "16px" }}
                                                                                                    className="ri-search-line"
                                                                                                ></i>
                                                                                            </div>
                                                                                        </Form.Group>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col lg="12">
                                                                                    <SimpleBar style={{ maxHeight: "calc(100vh - 250px)" }}>
                                                                                        <div className="d-flex flex-wrap gap-3 pb-3">
                                                                                            {filteredCheckpoints.length > 0 ? (
                                                                                                filteredCheckpoints.map((check, index) => (
                                                                                                    <div
                                                                                                        key={index}
                                                                                                        className="card"
                                                                                                        style={{
                                                                                                            width: '300px',
                                                                                                            minWidth: '300px',
                                                                                                            backgroundColor: '#fff',
                                                                                                            borderRadius: '8px',
                                                                                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                                                                            border: '1px solid rgba(240, 72, 79, 0.3)',
                                                                                                        }}
                                                                                                    >
                                                                                                        <div className="card-body p-3">
                                                                                                            <div className="d-flex justify-content-between align-items-start mb-2">
                                                                                                                <h6 className="mb-0">{check.name}</h6>
                                                                                                                <div className="d-flex gap-2">
                                                                                                                    {(
                                                                                                                        <i
                                                                                                                            style={{ fontSize: '18px', cursor: 'pointer' }}
                                                                                                                            className="ri-download-2-fill link-info"
                                                                                                                            onClick={() => handleQrDownload(check.qr_id)}
                                                                                                                        ></i>
                                                                                                                    )}
                                                                                                                    <i
                                                                                                                        className="ri-delete-bin-line"
                                                                                                                        style={{ fontSize: '18px', cursor: 'pointer', color: '#dc3545' }}
                                                                                                                        onClick={() => handleDeleteCheckpoint(check.qr_id)}
                                                                                                                    ></i>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="text-muted small mb-1">
                                                                                                                <i className="ri-map-pin-line me-1"></i>
                                                                                                                {check.address}
                                                                                                            </div>
                                                                                                            <div className="text-muted small mb-1">
                                                                                                                <i className="ri-global-line me-1"></i>
                                                                                                                {check.latitude}, {check.longitude}
                                                                                                            </div>
                                                                                                            <div className="d-flex justify-content-between mt-2">
                                                                                                                <div className="text-muted small">
                                                                                                                    <i className="ri-radar-line me-1"></i>
                                                                                                                    {check.mobile_check_in_max_distance}m
                                                                                                                </div>
                                                                                                                <div className="badge bg-light text-dark">
                                                                                                                    {check.tag}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))
                                                                                            ) : (
                                                                                                <div className="text-center w-100 mt-4">
                                                                                                    <div className="text-muted">No Checkpoints Found</div>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </SimpleBar>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Body>
                                                                        <Card.Footer className="border-top-0">
                                                                            <Row>
                                                                                <Col lg={12} className="previous-page">
                                                                                    <Button
                                                                                        color="primary"
                                                                                        onClick={() => setactiveTab(4)}
                                                                                        className="previous-btn"
                                                                                    >
                                                                                        <i className="bi bi-arrow-left"></i>{" "}
                                                                                        Previous
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Footer>
                                                                    </Card>
                                                                </Col>
                                                            </Tab.Pane>
                                                        )}
                                                        {activeTab === 7 && (
                                                            <Col lg="12">
                                                                <Card
                                                                    className="add-location-form-card"
                                                                    style={{ minHeight: "704px" }}
                                                                >
                                                                    <Card.Body>
                                                                        <div className="registered">
                                                                            <img
                                                                                src={successfullyImage}
                                                                                alt="employee-registered"
                                                                            />
                                                                            <div className="content">
                                                                                <h3>Location Added Successfully</h3>
                                                                                <p>
                                                                                    Your Location Has Added Successfully
                                                                                    Please Go back to Location
                                                                                </p>
                                                                            </div>
                                                                            <Button
                                                                                color="primary"
                                                                                className="dashboard-btn"
                                                                                href="/location"
                                                                            >
                                                                                <i className="bi bi-arrow-left"></i> Go to
                                                                                Location
                                                                            </Button>
                                                                        </div>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </Col>
                            </Form>
                        </Row>
                    </Container>
                </div>
                <Offcanvas
                    className="add-client-offcanvas"
                    show={clientCanvas}
                    onHide={handleAddClient}
                    placement="bottom"
                    style={{ minHeight: "90vh" }}
                >
                    <Offcanvas.Header className="add-header border-bottom" closeButton>
                        <Offcanvas.Title id="offcanvasBottomLabel">
                            Add Client
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Form onSubmit={formik.handleSubmit}>
                        <Offcanvas.Body>
                            <SimpleBar
                                style={{
                                    maxHeight: "auto",
                                    overflowX: "hidden"
                                }}
                            >
                                <Row>
                                    <Col lg="12">
                                        <Row>
                                            <Col lg="12" className="mb-2">
                                                <h5 style={{ color: '#ccc' }}>General Details</h5>
                                            </Col>
                                            <Col lg="3">
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Client Name</Form.Label>
                                                    <div className="position-relative form-icon">
                                                        <Form.Control
                                                            type="name"
                                                            name="name"
                                                            className="form-control-lg form-control-icon name-input"
                                                            placeholder="Enter Client Name"
                                                            value={formik.values.name}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={!!(formik.touched.name && formik.errors.name)}
                                                        />
                                                        <i
                                                            style={{ fontSize: "16px" }}
                                                            className="bx bx-face"
                                                        ></i>
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.name}
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg="3">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Address</Form.Label>
                                                    <SearchableAddress
                                                        label="Location Address"
                                                        name="address"
                                                        placeholder="Enter Address"
                                                        onChange={handleClientAddressChange}
                                                        invalid={false}
                                                        defaultValue={{
                                                            latitude: "",
                                                            longitude: "",
                                                            address: clientaddress,
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg="3">
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Invoice Note</Form.Label>
                                                    <div className="position-relative form-icon">
                                                        <Form.Control
                                                            type="name"
                                                            name="invoice_note"
                                                            className="form-control-lg form-control-icon name-input"
                                                            placeholder="Enter Invoice Note"
                                                            value={formik.values.invoice_note}
                                                            onChange={formik.handleChange}
                                                        />
                                                        <i
                                                            style={{ fontSize: "16px" }}
                                                            className=" ri-file-list-2-line"
                                                        ></i>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg="3">
                                                <Form.Group className="mb-4">
                                                    <Form.Label>ABN Number</Form.Label>
                                                    <div className="position-relative form-icon">
                                                        <Form.Control
                                                            type="name"
                                                            name="abn_no"
                                                            className="form-control-lg form-control-icon name-input"
                                                            placeholder="Enter ABN Number"
                                                            value={formik.values.abn_no}
                                                            onChange={formik.handleChange}
                                                        />
                                                        <i
                                                            style={{ fontSize: "16px" }}
                                                            className=" ri-file-list-2-line"
                                                        ></i>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg="3">
                                                <Form.Group className="mb-4">
                                                    <Form.Label>City</Form.Label>
                                                    <div className="position-relative form-icon">
                                                        <Form.Control
                                                            type="name"
                                                            name="city"
                                                            value={alladdress?.city ? alladdress?.city : (formik.values.city || "-")}
                                                            onChange={formik.handleBlur}
                                                            className="form-control-lg form-control-icon name-input"
                                                            disabled
                                                        />
                                                        <i
                                                            style={{ fontSize: "16px" }}
                                                            className="  ri-map-pin-line"
                                                        ></i>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg="3">
                                                <Form.Group className="mb-4">
                                                    <Form.Label>State</Form.Label>
                                                    <div className="position-relative form-icon">
                                                        <Form.Control
                                                            type="name"
                                                            name="state"
                                                            value={alladdress?.state ? alladdress?.state : (formik.values.state || "-")}
                                                            onChange={formik.handleBlur}
                                                            className="form-control-lg form-control-icon name-input"
                                                            disabled
                                                        />
                                                        <i
                                                            style={{ fontSize: "16px" }}
                                                            className=" ri-map-pin-line"
                                                        ></i>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg="3">
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Postal Code</Form.Label>
                                                    <div className="position-relative form-icon">
                                                        <Form.Control
                                                            type="name"
                                                            name="postal_code"
                                                            value={alladdress?.postalCode ? alladdress?.postalCode : (formik.values.postal_code || "-")}
                                                            onChange={formik.handleBlur}
                                                            className="form-control-lg form-control-icon name-input"
                                                            disabled
                                                        />
                                                        <i
                                                            style={{ fontSize: "16px" }}
                                                            className=" ri-map-pin-line"
                                                        ></i>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg="3">
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Country</Form.Label>
                                                    <div className="position-relative form-icon">
                                                        <Form.Control
                                                            type="name"
                                                            name="country"
                                                            value={alladdress?.country ? alladdress?.country : (formik.values.country || "-")}
                                                            onChange={formik.handleBlur}
                                                            className="form-control-lg form-control-icon name-input"
                                                            disabled
                                                        />
                                                        <i
                                                            style={{ fontSize: "16px" }}
                                                            className=" ri-map-pin-line"
                                                        ></i>
                                                    </div>
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                    </Col>
                                    <Col lg="12" className="mb-2">
                                        <h5 style={{ color: '#ccc' }}>Contact Details</h5>
                                    </Col>
                                    <Col lg="3">
                                        <Form.Group className="mb-4">
                                            <Form.Label>Contact Person Name</Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="name"
                                                    name="contact_person"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter Contact Person Name"
                                                    value={formik.values.contact_person}
                                                    onChange={formik.handleChange}
                                                />
                                                <i
                                                    style={{ fontSize: "16px" }}
                                                    className="bx bx-face"
                                                ></i>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3">
                                        <Form.Group className="mb-4">
                                            <Form.Label>Contact Person Number</Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="name"
                                                    name="contact_phone"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter Contact Person Number"
                                                    value={formik.values.contact_phone}
                                                    onChange={formik.handleChange}
                                                />
                                                <i
                                                    style={{ fontSize: "16px" }}
                                                    className=" ri-keyboard-line"
                                                ></i>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3">
                                        <Form.Group className="mb-4">
                                            <Form.Label>Contact Person Email</Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="name"
                                                    name="contact_email"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter Contact Person Email"
                                                    value={formik.values.contact_email}
                                                    onChange={formik.handleChange}
                                                />
                                                <i
                                                    style={{ fontSize: "16px" }}
                                                    className="  ri-mail-open-line"
                                                ></i>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </SimpleBar>
                        </Offcanvas.Body>
                        <Row>
                            <Col lg="12" style={{ position: 'absolute', bottom: '0' }}>
                                <div className="offcanvas-footer border-top-0 p-3 pb-0 text-center">
                                    <Row>
                                        <Col lg={12} className="off-bottom-staff justify-content-end">
                                            <div className="off-confirm">
                                                <Button color="primary" className="next-btn" type="submit" disabled={formik.isSubmitting}>
                                                    Add
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Offcanvas>
                <Offcanvas
                    className="add-location-client-offcanvas"
                    show={isDepartment}
                    onHide={toggleDepartmentCanvas}
                    placement="bottom"
                    style={{ minHeight: '75vh' }}
                >
                    <Offcanvas.Header className="add-header-client border-bottom" closeButton>
                        <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
                            Add Checkpoint
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Form onSubmit={checkpointformik.handleSubmit}>
                        <Offcanvas.Body>
                            <SimpleBar
                                style={{
                                    maxHeight: "auto",
                                    overflowX: "hidden"
                                }}
                            >
                                <Row>
                                    <Col lg="6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Checkpoint Name</Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="text"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Checkpoint Name"
                                                    name="checkpointName"
                                                    value={checkpointformik.values.checkpointName}
                                                    onChange={checkpointformik.handleChange}
                                                    isInvalid={!!(checkpointformik.touched.checkpointName && checkpointformik.errors.checkpointName)}
                                                />
                                                <i style={{ fontSize: '16px' }} className="ri-map-pin-line"></i>
                                                <Form.Control.Feedback type="invalid">
                                                    {checkpointformik.errors.checkpointName}
                                                </Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="6">
                                        <Form.Group className="mb-3 form-icon">
                                            <Form.Label>Checkpoint Tag Type</Form.Label>
                                            <Form.Select
                                                className="form-select-lg form-control-icon"
                                                id="floatingSelect"
                                                value={checkpointTag}
                                                onChange={(e) => setCheckpointTag(e.target.value)}
                                            >
                                                <option value="QR">QR</option>
                                                {/* <option value="NFC">NFC</option> */}
                                            </Form.Select>
                                            <i style={{ fontSize: '16px' }} className="ri-price-tag-3-fill mt-4"></i>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="12">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Location Geofencing</Form.Label>
                                            <SearchableAddress
                                                label="Location Address"
                                                name="checkpointAddress"
                                                placeholder="Enter Location Geofencing"
                                                onChange={handleCheckpointAddressChange}
                                                invalid={!!(checkpointformik.touched.checkpointAddress && checkpointformik.errors.checkpointAddress)}
                                                defaultValue={{
                                                    latitude: '',
                                                    longitude: '',
                                                    address: checkpointAddress,
                                                }}
                                            />
                                            {checkpointAddress === "" &&
                                                <Form.Control.Feedback type="invalid">
                                                    {checkpointformik.errors.checkpointAddress}
                                                </Form.Control.Feedback>
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col lg="6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Latitude</Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="text"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Latitude"
                                                    value={allCheckpointAddress?.latitude || ''}
                                                    onChange={formik.handleBlur}
                                                    disabled
                                                />
                                                <i style={{ fontSize: '16px' }} className="ri-map-pin-line"></i>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Longitude</Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="text"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Longitude"
                                                    value={allCheckpointAddress?.longitude || ''}
                                                    onChange={formik.handleBlur}
                                                    disabled
                                                />
                                                <i style={{ fontSize: '16px' }} className="ri-map-pin-line"></i>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Distance Threshold</Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="text"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter Distance Threshold (in meters)"
                                                    name="checkpointDistance"
                                                    value={checkpointformik.values.checkpointDistance}
                                                    onChange={checkpointformik.handleChange}
                                                    isInvalid={!!(checkpointformik.touched.checkpointDistance && checkpointformik.errors.checkpointDistance)}
                                                />
                                                <i style={{ fontSize: '16px' }} className="ri-map-pin-line"></i>
                                                <Form.Control.Feedback type="invalid">
                                                    {checkpointformik.errors.checkpointDistance}
                                                </Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </SimpleBar>
                        </Offcanvas.Body>
                        <Row>
                            <Col lg="12" style={{ position: 'absolute', bottom: '0' }}>
                                <div className="offcanvas-footer border-top-0 p-3 pb-0 text-center">
                                    <Row>
                                        <Col lg={12} className="off-bottom mt-0">
                                            <div className="offcanvas-footer off-confirm border-top-0 text-center">
                                                <Button color="primary" className="next-btn" type="submit">
                                                    Add
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Offcanvas>
                {isModalOpen && staffViewDetail && (
                    <Modal show={isModalOpen} onHide={closeModal} centered>
                        <Modal.Body>
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 position-relative">
                                    <div className="avatar-sm">
                                        <div className="avatar-title rounded bg-primary-subtle text-primary">
                                            {staffViewDetail?.employee_name?.charAt(0)?.toUpperCase()}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                    <h5 className="fs-md mb-0">{staffViewDetail.employee_name}</h5>
                                    <p className="text-muted mb-0">{staffViewDetail.employee_status || "-"}</p>
                                    <p className="text-muted mb-0">{staffViewDetail.access_level || "-"}</p>
                                </div>
                            </div>
                            <div className="mt-4">
                                <p className="text-muted mb-2">
                                    <i className="bi bi-telephone align-baseline me-1"></i>
                                    {staffViewDetail?.mobile_no ? (
                                        <Link to={`tel:${staffViewDetail.mobile_no}`} className="text-muted" style={{ paddingLeft: '4px' }}>
                                            {staffViewDetail.mobile_no}
                                        </Link>
                                    ) : (
                                        "-"
                                    )}
                                </p>
                                <p className="text-muted mb-2">
                                    <i className="bi bi-envelope align-baseline me-1"></i>
                                    {staffViewDetail?.email ? (
                                        <Link to={`mailto:${staffViewDetail.email}`} className="text-muted" style={{ paddingLeft: '4px' }}>{staffViewDetail.email} </Link>
                                    ) : (
                                        "-"
                                    )}
                                </p>
                                <p className="text-muted mb-2"><i className="bi bi-geo-alt align-baseline me-1"></i> {staffViewDetail?.address ? staffViewDetail?.address : "-"} </p>
                                <p className="text-muted mb-2"><i className="mdi mdi-gender-transgender align-baseline me-1"></i> {staffViewDetail?.gender ? staffViewDetail?.gender : "-"} </p>
                                <p className="text-muted mb-0"><i className="ri-cake-line align-baseline me-1"></i> {staffViewDetail?.dob ? staffViewDetail?.dob : "-"} </p>
                            </div>
                            <div className="text-end mt-3">
                                <button className="btn btn-primary" onClick={closeModal}>Close</button>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
            </React.Fragment >
        </>
    );
};

export default AddLocation;
