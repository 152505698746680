import { useProfile } from 'Common/Hooks/UserHooks';
import React from 'react';
import DataTable from 'react-data-table-component';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface InvoiceMasterData {
    id: number;
    company_name: string;
    bank_name: string;
    account_holder_name: number,
    bsb_no: number,
}

type SortOrder = 'asc' | 'desc';

interface ComplianceTableProps {
    data: InvoiceMasterData[];
    editMaster: (id: number) => Promise<void>;
    handlePageChange: (page: number) => void;
    handlePerRowsChange: (newPerPage: number, page: number) => void;
    paginationTotalRows: number;
    handleArchiveCompliance: (id: number) => Promise<void>;
    handleSort: (column: string, sortDirection: 'asc' | 'desc') => void;
}

const InvoiceTemplateTable: React.FC<ComplianceTableProps> = ({ data, editMaster, handlePageChange, handlePerRowsChange, paginationTotalRows, handleArchiveCompliance, handleSort }) => {

    const { userProfile } = useProfile();
    const userRoles = userProfile.roles;
    const allowedRoles = ['DEVELOPER', 'SUPER_ADMIN', 'ADMIN', 'MANAGER'];
    const hasRoles = (roles: string[]) => roles.some(role => userRoles.includes(role));
    const canAccess = hasRoles(allowedRoles);


    const columns = [
        {
            name: <span className='font-weight-bold fs-sm'>Invoice Template</span>,
            selector: (row: any) => row.name,
            sortField: 'name',
            sortable: true,
            left: true,
            cell: (row: any) => <div style={{ textAlign: 'left' }}>{row.template_name}</div>
        },
        {
            name: <span className='font-weight-bold fs-sm text-right'>Action</span>,
            // sortable: true,
            right: true,
            cell: (row: any) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                        <OverlayTrigger placement="top" overlay={<Tooltip id="edit-tooltip">Edit</Tooltip>}>
                            <i
                                onClick={() => editMaster(row.id)}
                                className="ri-edit-2-line link-success"
                                style={{ fontSize: '18px', cursor: 'pointer' }}
                            ></i>
                        </OverlayTrigger>

                        {canAccess && userRoles[0] !== "MANAGER" && (
                            <OverlayTrigger placement="top" overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}>
                                <i
                                    onClick={() => handleArchiveCompliance(row.id)}
                                    className="ri-delete-bin-line link-danger"
                                    style={{ fontSize: '18px', cursor: 'pointer' }}
                                ></i>
                            </OverlayTrigger>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={paginationTotalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            onSort={(column: any, sortDirection: SortOrder) => {
                if (column.sortField) {
                    handleSort(column.sortField, sortDirection);
                }
            }}
            sortServer
        />
    );
};

export default InvoiceTemplateTable;
