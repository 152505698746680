import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Nav,
  Tab,
  Table,
  Dropdown,
  Image,
  Offcanvas,
  Modal,
  Spinner,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import logo from "assets/images/auth/cropprofile.png";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { FormikErrors, useFormik } from "formik";
import * as Yup from "yup";
import background from "assets/images/auth/background.png";
import blank from "assets/images/users/blanklogo.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getByStaffDetail,
  getLocationListDetail,
  getAccessLevelDetail,
  SendOnboardingLink,
  getPayScheduleDropdownDetail,
  getPayGroupDetail,
  PayGroupByIdDetail,
  getStaffLocationDetail,
  getDropdownComplianceDetail,
  updateStaffDocumentDetail,
  getStaffDocumentDetail,
  archiveStaffDocumentDetail,
  getLeaveListDetail,
  getByStaffDocumentDetail,
  getEmpTypeListDetail,
  addStaffComplianceDetail,
  updateStaffDetail,
  getSubContractorDropdownList,
  getStateMasterDetail,
} from "helpers/saibackend_helper";
import SearchableAddress from "helpers/SearchableAddress";
import SimpleBar from "simplebar-react";
import usflag from "assets/images/flags/us.svg";
import country from "Common/country";
import profile from "assets/images/auth/userimg.png";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import logo1 from "assets/images/auth/logo1.png";
import logo2 from "assets/images/auth/logo2.png";
import logo3 from "assets/images/auth/logo3.png";
import logo4 from "assets/images/auth/logo4.png";
import WorkingCalendar from "./WorkingCalendar";
import userpf from "assets/images/auth/userpf.png";
import StaffDocumentTable from "./workforcetables/StaffDocumentTable";
import { useProfile } from "Common/Hooks/UserHooks";

interface RowData {
  id: number;
}

interface LeaveSection {
  id: number;
}

interface RowData {
  id: number;
  file: File | null;
  showUploadButton: boolean;
}

interface LocationListData {
  location_id: number;
  name: string;
}

interface ComplianceData {
  id: number;
  compliance_name: string;
  reminder_days: number;
  is_critical: number;
}

interface LocationData {
  name: string;
  value: number;
  is_petrol_site: number;
}

interface PayGroupData {
  value: number;
  label: string;
}

interface AccessLevelData {
  id: number;
  name: string;
}

interface Location {
  staff_location_id: number;
  location_id: number;
  location_name: string;
  pay_group_id: number;
  pay_group_rates: PayRate[];
}

interface StaffData {
  avatar: null;
  first_name: string;
  last_name: string;
  email: string;
  mobile_no: string;
  employee_status_id: number;
  access_level: string;
  subcontractor_id: number;
  pay_schedule_id: number;
  preferred_name: string;
  gender: string;
  address: string;
  dob: string;
  start_date: string;
  emergency_name: string;
  emergency_number: string;
  relation: string;
  emergency_email: string;
  bank_name: string;
  bank_holder_name: string;
  acc_no: string;
  bsb_no: string;
  superannuation_fund_name: string;
  superannuation_account_no: string;
  tfn_no: string;
  staff_locations_count: number;
  weekly_working_hours: number;
  staff_leaves: [
    {
      leave_type_id: number;
      default_hours: number;
      used_leave_hours: number;
      available_hours: number;
    }
  ];
  staff_locations: Location[];
  daily_working_hours: number;
  subcontractor_name: string;
  tfn_file_path: null;
  is_invite: number;
  invited: number;
}

interface AddressData {
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

interface StaffLocation {
  location_id: number;
}

interface Location {
  location_id: number;
  name: string;
  is_petrol_site: number;
}

interface LeaveData {
  id: number;
  leave_name: string;
  default_hours: number;
  available_hours: number;
}

interface LeaveSection {
  id: number;
  selectedLeaveId?: number;
  defaultHours?: number;
  usedHours?: number;
  available_hours?: number;
  used_leave_hours?: number;
}

interface EmptypeData {
  id: number;
  type: string;
}

interface SubcontractorData {
  value: number;
  label: string;
}

interface StateMasterData {
  id: number;
  state_name: string;
}

interface SubComplianceMasterData {
  id: number;
  document_name: string;
  document_path: string;
  document_type: string;
  ref_no: number;
  expiry_date: string;
  reminder: string;
  is_critical: number;
}
interface PayRate {
  pay_category_id: number;
  default_rate: number;
  assign_rate: number;
  category_name?: string;
  pay_rate_id: number;
}

interface RowNewData {
  location_id: number;
  is_patrol_site: number;
  pay_group_id: number;
  pay_group_rates: PayRate[];
  isOpen?: boolean;
}

const EditStaff = () => {
  document.title = "Edit Staff | The Guard Duty";

  const { userProfile } = useProfile();
  const userRoles = userProfile.roles;
  const allowedRoles = ["DEVELOPER", "SUPER_ADMIN", "ADMIN", "MANAGER"];
  const hasRoles = (roles: string[]) =>
    roles.some((role) => userRoles.includes(role));
  const canAccess = hasRoles(allowedRoles);

  const [modal_standard, setmodal_standard] = useState<boolean>(false);
  function tog_standard() {
    setmodal_standard(!modal_standard);
  }
  const [key, setKey] = useState("first");

  const [StaffDetail, setStaffDetail] = useState<StaffData | null>(null);

  // const [LocationDetail, setLocationDetail] = useState<LocationListData[]>(
  //     []
  // );

  const [AccessLevelDetail, setAccessLevelDetail] = useState<AccessLevelData[]>(
    []
  );

  const [EmpTypeDetail, setEmpTypeDetail] = useState<EmptypeData[]>([]);

  const fetchAccessLevelDetails = async () => {
    try {
      const searchTerm = "";
      const response = await getAccessLevelDetail(searchTerm);
      setAccessLevelDetail(response.data.data_list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (key === "second") {
      fetchAccessLevelDetails();
    }
  }, [key === "second"]);

  const [SubcontractorList, setSubcontractorList] = useState<
    SubcontractorData[]
  >([]);

  const fetchSubcontractorDetails = async () => {
    try {
      const searchTerm = "";
      const response = await getSubContractorDropdownList(searchTerm);
      const dataList = response.data.data_list;
      const updatedList = [{ value: "", label: "Select" }, ...dataList];
      setSubcontractorList(updatedList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (key === "second") {
      fetchSubcontractorDetails();
    }
  }, [key === "second"]);

  const fetchEmpTypeDetails = async () => {
    try {
      const searchTerm = "";
      const response = await getEmpTypeListDetail(searchTerm);
      setEmpTypeDetail(response.data.data_list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (key === "second") {
      fetchEmpTypeDetails();
    }
  }, [key === "second"]);

  // Leave List API Integration
  // =============================================================================================================================//

  const [leaveSections, setLeaveSections] = useState<LeaveSection[]>([
    { id: 1 },
  ]);
  const [payloadLeaveValue, setPayloadLeaveValue] = useState({
    search_term: "",
  });
  const [leaveDetail, setLeaveDetail] = useState<LeaveData[]>([]);

  const handleAddLeave = () => {
    const newLeaveSections = [
      ...leaveSections,
      { id: leaveSections.length + 1 },
    ];
    setLeaveSections(newLeaveSections);
  };

  const fetchLeaveDetails = async () => {
    try {
      const searchTerm = "";
      const response = await getLeaveListDetail(searchTerm);
      setLeaveDetail(response.data.data_list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (key === "second") {
      fetchLeaveDetails();
    }
  }, [key === "second"]);

  const handleLeaveChange = (sectionId: number, leaveId: number) => {
    const selectedLeave = leaveDetail.find((leave) => leave.id === leaveId);
    if (selectedLeave) {
      setLeaveSections((prevSections) =>
        prevSections.map((section) =>
          section.id === sectionId
            ? {
                ...section,
                selectedLeaveId: leaveId,
                defaultHours: selectedLeave.default_hours,
                usedHours: 0,
                available_hours: selectedLeave.available_hours,
              }
            : section
        )
      );
    }
  };

  useEffect(() => {
    if (key === "second") {
      fetchLeaveDetails();
    }
  }, [key === "second"]);

  // =============================================================================================================================//

  // const fetchLocationDetails = async () => {
  //     try {
  //         const searchTerm = ""
  //         const response = await getLocationListDetail(
  //             searchTerm
  //         );
  //         setLocationDetail(response.data.data_list);
  //     } catch (error) {
  //         console.log(error);
  //     }
  // };

  // useEffect(() => {
  //     fetchLocationDetails();
  // }, []);

  // const allLocations = LocationDetail || [];
  // const preferredLocations = StaffDetail?.staff_locations || [];

  // const isPreferredLocation = (locationId: number): boolean => {
  //     return preferredLocations.some((loc: Location) => loc.location_id === locationId);
  // };

  // const [selectedLocations, setSelectedLocations] = useState<number[]>(
  //     allLocations
  //         .filter((location: Location) => isPreferredLocation(location.location_id))
  //         .map((location: Location) => location.location_id)
  // );

  // const [selectAll, setSelectAll] = useState<boolean>(false);
  // const [searchQuery, setSearchQuery] = useState<string>("");

  // useEffect(() => {
  //     setSelectedLocations(
  //         allLocations
  //             .filter((location: Location) => isPreferredLocation(location.location_id))
  //             .map((location: Location) => location.location_id)
  //     );
  // }, [allLocations, preferredLocations]);

  // useEffect(() => {
  //     if (selectedLocations.length === allLocations.length) {
  //         setSelectAll(true);
  //     } else {
  //         setSelectAll(false);
  //     }
  // }, [selectedLocations, allLocations]);

  // const handleLocationChange = (locationId: number) => {
  //     setSelectedLocations((prev) =>
  //         prev.includes(locationId) ? prev.filter((id) => id !== locationId) : [...prev, locationId]
  //     );
  // };

  // const handleSelectAllChange = () => {
  //     if (selectAll) {
  //         setSelectedLocations([]);
  //     } else {
  //         setSelectedLocations(allLocations.map((location: Location) => location.location_id));
  //     }
  //     setSelectAll(!selectAll);
  // };

  // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setSearchQuery(event.target.value);
  // };

  // const filteredLocations = allLocations.filter((location: Location) =>
  //     location.name.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  //================================================================================================================================//

  const [address, setAddress] = useState<string>("");

  const handleAddressChange = (data: AddressData | boolean) => {
    if (typeof data === "boolean") return;
    setAddress(data.address);
    formik.setFieldValue("address", data.address);
  };

  const [selectedImage, setSelectedImage] = useState<
    string | ArrayBuffer | null
  >(null);
  const [selectedAvatar, setSelectedAvatar] = useState<File | null>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      formik.setFieldValue("avatar", file);
      setSelectedAvatar(file);
    }
  };

  const removeLogo = () => {
    setSelectedImage(null);
    formik.setFieldValue("avatar", null);
  };

  const editMaster = async () => {
    try {
      const staffid = localStorage.getItem("clickedItemId");
      if (staffid !== null) {
        const response = await getByStaffDetail(parseInt(staffid));
        const data = response.data;
        setStaffDetail(data);
      } else {
        console.error("Staff ID is null.");
        toast.error("Error fetching details. Please try again later.");
      }
    } catch (error) {
      console.error("Error fetching Compliance Master details:", error);
      toast.error("Error fetching details. Please try again later.");
    }
  };

  useEffect(() => {
    editMaster();
  }, []);

  const [avatarlogo, setavatarlogo] = useState("");

  useEffect(() => {
    if (StaffDetail !== null) {
      const initialValues = {
        avatar: StaffDetail.avatar || null,
        first_name: StaffDetail.first_name || "",
        last_name: StaffDetail.last_name || "",
        email: StaffDetail.email || "",
        mobile_no: StaffDetail.mobile_no || "",
        employee_status_id: StaffDetail.employee_status_id || 0,
        access_level: StaffDetail.access_level || "",
        subcontractor_id: StaffDetail.subcontractor_id || 0,
        pay_schedule_id: StaffDetail.pay_schedule_id || 0,
        preferred_name: StaffDetail.preferred_name || "",
        gender: StaffDetail.gender || "",
        address: StaffDetail.address || "",
        relation: StaffDetail.relation || "",
        emergency_name: StaffDetail.emergency_name || "",
        emergency_email: StaffDetail.emergency_email || "",
        emergency_number: StaffDetail.emergency_number || "",
        dob: StaffDetail.dob || "",
        staff_locations:
          StaffDetail?.staff_locations?.map((location) => ({
            location_id: location.location_id,
            pay_group_id: location.pay_group_id,
            is_patrol_site: location.is_petrol_site,
            pay_group_rates: location?.pay_group_rates?.map((rate) => ({
              pay_category_id: rate.pay_category_id,
              default_rate: rate.default_rate,
              assign_rate: rate.assign_rate,
              category_name: rate.category_name,
              pay_rate_id: rate.pay_rate_id,
            })),
          })) || [],
        staff_leaves: StaffDetail.staff_leaves.map((leave, index) => ({
          id: index + 1,
          leave_type_id: leave.leave_type_id || 0,
          default_hours: leave.default_hours || 0,
          used_leave_hours: leave.used_leave_hours || 0,
          available_hours: leave.available_hours || 0,
          selectedLeaveId: leave.leave_type_id || 0,
          defaultHours: leave.default_hours || 0,
          usedHours: leave.used_leave_hours || 0,
        })),
        weekly_working_hours: StaffDetail.weekly_working_hours || 0,
        daily_working_hours: StaffDetail.daily_working_hours || 0,
        subcontractor_name: StaffDetail.subcontractor_name || "",
        start_date: StaffDetail.start_date || "",
        bank_name: StaffDetail.bank_name || "",
        bank_holder_name: StaffDetail.bank_holder_name || "",
        acc_no: StaffDetail.acc_no || "",
        bsb_no: StaffDetail.bsb_no || "",
        superannuation_fund_name: StaffDetail.superannuation_fund_name || "",
        superannuation_account_no: StaffDetail.superannuation_account_no || "",
        tfn_no: StaffDetail.tfn_no || "",
        tfn_file_path: StaffDetail.tfn_file_path || null,
        is_invite: StaffDetail.is_invite || 0,
      };
      setavatarlogo(StaffDetail.avatar || "");
      formik.setValues(initialValues);
      setRows(initialValues.staff_locations);
      setLeaveSections(initialValues.staff_leaves);
      setShowUploadButtonTFN(!StaffDetail.tfn_file_path);
    }
  }, [StaffDetail]);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First name is required")
      .matches(
        /^[A-Za-z0-9]+$/,
        "First name can only contain letters and digits"
      ),
    last_name: Yup.string()
      .required("Last name is required")
      .matches(
        /^[A-Za-z0-9]+$/,
        "First name can only contain letters and digits"
      ),
    email: Yup.string()
      .required("email is required")
      .email("Invalid email format"),
    gender: Yup.string()
      .required("Gender is required")
      .oneOf(["Male", "Female"], "Gender must be Male, Female"),
    access_level: Yup.string()
      .required("Access Level is Required")
      .oneOf(
        ["ADMIN", "EMPLOYEE", "MANAGER"],
        "Access Level must be ADMIN OR EMPLOYEE OR MANAGER"
      ),
    pay_schedule_id: Yup.lazy((value, context) => {
      const employee_status_id = context.parent?.employee_status_id;
      if (employee_status_id !== 4) {
        return Yup.number()
          .positive("Pay Schedule is Required")
          .required("Pay Schedule is Required");
      }
      return Yup.number().notRequired();
    }),
    subcontractor_id: Yup.lazy((value, context) => {
      const employee_status_id = context.parent?.employee_status_id;
      if (employee_status_id === 4) {
        return Yup.number()
          .positive(
            "Subcontractor is required when Staff Type is Contact Worker"
          )
          .required(
            "Subcontractor is required when Staff Type is Contact Worker"
          );
      }
      return Yup.number().notRequired();
    }),
    pay_group_id: Yup.lazy((value, context) => {
      const location_id = context.parent?.location_id; // Accessing parent context
      if (location_id) {
        // If location_id is truthy (i.e., selected)
        return Yup.number().required(
          "Pay Group is required when Location is selected"
        );
      }
      return Yup.number().notRequired(); // If location_id is not selected, pay_group_id is not required
    }),

    // assign_rate: Yup.number()
    // .required("Assign rate is required")
    // .test(
    //     "is-greater-than-default",
    //     "You cannot assign a value less than the default rate",
    //     function (value) {
    //         const { default_rate } = this.parent; // Access the default_rate from the parent context
    //         if (value !== undefined && default_rate !== undefined) {
    //             return value >= default_rate; // Ensure assign_rate is not less than default_rate
    //         }
    //         return true;
    //     }
    // ),
    //     staff_location_id: Yup.lazy((value, context) => {
    //         // const employee_status_id = context.parent?.employee_status_id;
    //         if (row.location_id === 4) {
    //               return Yup.number()
    //               .positive('Subcontractor is required when Staff Type is Contact Worker')
    //               .required('Subcontractor is required when Staff Type is Contact Worker');
    //         }
    //         return Yup.number().notRequired();
    //   }),
  });

  const [isInvite, setIsInvite] = useState(0);

  const formik = useFormik({
    initialValues: {
      avatar: null,
      first_name: "",
      last_name: "",
      email: "",
      mobile_no: "",
      employee_status_id: 0,
      access_level: "",
      subcontractor_id: 0,
      pay_schedule_id: 0,
      preferred_name: "",
      gender: "",
      address: "",
      relation: "",
      emergency_name: "",
      emergency_email: "",
      emergency_number: "",
      dob: "",
      staff_locations: [
        {
          location_id: 0,
          pay_group_id: 0,
          pay_group_rates: [
            {
              pay_category_id: 0,
              default_rate: 0,
              assign_rate: 0,
            },
          ],
        },
      ],
      staff_leaves: [
        {
          leave_type_id: 0,
          default_hours: 0,
          used_leave_hours: 0,
          available_hours: 0,
        },
      ],
      weekly_working_hours: 0,
      daily_working_hours: 0,
      subcontractor_name: "",
      start_date: "",
      bank_name: "",
      bank_holder_name: "",
      acc_no: "",
      bsb_no: "",
      superannuation_fund_name: "",
      superannuation_account_no: "",
      tfn_no: "",
      tfn_file_path: null,
      is_invite: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        // for (const row of rows) {
        //     for (const rate of row.pay_group_rates) {
        //         if (rate.assign_rate < rate.default_rate) {
        //             toast.error("Assign Rate cannot be less than Default Rate.");
        //             setSubmitting(false);
        //             return;
        //         }
        //     }
        // }

        const staffid = localStorage.getItem("clickedItemId");

        const staffLeaves =
          emptype === 4 && formik.values.employee_status_id === 4
            ? leaveSections.map((section) => ({
                leave_type_id: 0,
                default_hours: 0,
                used_leave_hours: 0,
                available_hours: 0,
              }))
            : leaveSections.map((section) => ({
                leave_type_id: section.selectedLeaveId,
                default_hours: section.defaultHours,
                used_leave_hours: Number(section.usedHours),
                available_hours: section.available_hours,
              }));
        const formattedValues = {
          ...values,
          staff_leaves: staffLeaves,
          staff_locations: rows,
          tfn_file_path: TFNfile,
          is_invite: isInvite,
          id: staffid,
        };

        const formData = new FormData();

        Object.keys(formattedValues).forEach((key) => {
          const value = formattedValues[key as keyof typeof formattedValues];

          if (Array.isArray(value)) {
            value.forEach((item, index) => {
              if (typeof item === "object" && item !== null) {
                if (key === "staff_locations") {
                  Object.keys(item).forEach((subKey) => {
                    if (
                      subKey === "pay_group_rates" &&
                      Array.isArray((item as RowNewData)[subKey])
                    ) {
                      (item as RowNewData)[subKey].forEach(
                        (rate, rateIndex) => {
                          Object.keys(rate).forEach((rateKey) => {
                            const rateValue = rate[rateKey as keyof PayRate];
                            formData.append(
                              `${key}[${index}][${subKey}][${rateIndex}][${rateKey}]`,
                              rateValue?.toString() || ""
                            );
                          });
                        }
                      );
                    } else {
                      const itemValue = (item as any)[subKey];
                      formData.append(
                        `${key}[${index}][${subKey}]`,
                        itemValue?.toString() || ""
                      );
                    }
                  });
                } else {
                  Object.keys(item).forEach((subKey) => {
                    const itemValue = (item as any)[subKey];
                    formData.append(
                      `${key}[${index}][${subKey}]`,
                      itemValue?.toString() || ""
                    );
                  });
                }
              }
            });
          } else {
            if (key !== "avatar") {
              formData.append(key, value?.toString() || "");
            }
          }
        });

        if (formik.values.avatar) {
          formData.append("avatar", formik.values.avatar);
        }

        if (TFNfile) {
          formData.append("tfn_file_path", TFNfile);
        }

        const response = await updateStaffDetail(formData);
        if (response.status === 1) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
          return;
        }
      } catch (error) {
        console.error("Error Adding/Updating Staff:", error);
        toast.error("Error Staff. Please try again later.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const hasFirstTabError =
    (!!formik.touched.first_name && !!formik.errors.first_name) ||
    (!!formik.touched.last_name && !!formik.errors.last_name) ||
    (!!formik.touched.email && !!formik.errors.email) ||
    (!!formik.touched.gender && !!formik.errors.gender);

  const hasSecondTabError =
    (!!formik.touched.access_level && !!formik.errors.access_level) ||
    (!!formik.touched.pay_schedule_id && !!formik.errors.pay_schedule_id) ||
    (!!formik.touched.employee_status_id &&
      !!formik.errors.employee_status_id) ||
    (!!formik.touched.subcontractor_id && !!formik.errors.subcontractor_id);

  // const hasFourthTabError = !!formik.touched?.[`pay_group_id_${index}` as keyof typeof formik.touched] &&
  // !!formik.errors?.[`pay_group_id_${index}` as keyof typeof formik.errors]

  const handleUpdateAndInvite = () => {
    setIsInvite(1);
    formik.handleSubmit();
  };

  const [emptype, setEmptype] = useState<number>(0);

  const handleEmpTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = Number(event.target.value);
    formik.setFieldValue("employee_status_id", newValue);
    formik.setFieldValue("subcontractor_id", 0);
    setEmptype(newValue);
  };

  useEffect(() => {
    const updatedRows = rows.map((row) => {
      if (emptype === 4 && formik.values.employee_status_id === 4) {
        return {
          ...row,
          pay_group_id: 0,
          pay_group_rates: [
            {
              pay_category_id: 0,
              category_name: "",
              default_rate: 0,
              assign_rate: 0,
              pay_rate_id: 0,
            },
          ],
        };
      }
      return row;
    });
    setRows(updatedRows);
    if (emptype === 4 && formik.values.employee_status_id === 4) {
      formik.setFieldValue("pay_schedule_id", 0);
    }
  }, [emptype]);

  // const [file, setFile] = useState<File | null>(null);
  // const [showUploadButton, setShowUploadButton] = useState(true);

  const baseavatarurl = process.env.REACT_APP_IMAGE_URL;

  const imageSrc =
    typeof selectedImage === "string"
      ? selectedImage
      : avatarlogo
      ? `${baseavatarurl}/${avatarlogo}`
      : blank;

  const [isRight, setIsRight] = useState(false);

  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };

  const [TFNfile, setTFNFile] = useState<File | null>(null);
  const [showUploadButtonTFN, setShowUploadButtonTFN] = useState(true);

  const handleTFNDocumentChnage = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      setTFNFile(selectedFiles[0]);
      setShowUploadButtonTFN(false);
      formik.setFieldValue("tfn_file_path", selectedFiles[0]);
    }
  };

  const handleRemoveFileTFN = () => {
    setTFNFile(null);
    setShowUploadButtonTFN(true);
    formik.setFieldValue("tfn_file_path", null);
  };

  const baseurl = process.env.REACT_APP_IMAGE_URL;

  const handleOpenDocument = (path: string) => {
    const fullPath = `${baseurl}/${path}`;
    window.open(fullPath, "_blank");
  };

  const handleFilePreview = (file: File | null) => {
    if (file) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    } else {
      return;
    }
  };

  // Staff Document List API Integration //
  //================================================================================================//

  const [totalRows, setTotalRows] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState<number | null>(null);

  const [staffComplianceDetail, setStaffComplianceDetail] = useState<
    SubComplianceMasterData[]
  >([]);
  const [defaultValue, setDefaultValue] = useState({
    page: 1,
    page_size: "10",
    search_term: "",
    sort: {
      sort_order: "ASC",
      sort_by: "",
    },
    is_archive: 0,
  });
  const handlePageChange = (page: number) => {
    setDefaultValue((prevPayload) => ({
      ...prevPayload,
      page: page,
    }));
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setDefaultValue((prevPayload) => ({
      ...prevPayload,
      page_size: String(newPerPage),
      page: page,
    }));
  };

  const staffid = Number(localStorage.getItem("clickedItemId"));

  const fetchStaffComplianceDetails = async () => {
    if (staffid > 0) {
      try {
        const response = await getStaffDocumentDetail(inputValue, staffid);
        setStaffComplianceDetail(response.data.data_list);
        setTotalRows(response.data.total_records);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDefaultValue((prevPayload) => ({
        ...prevPayload,
        search_term: inputValue,
      }));
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSort = (column: any, sortDirection: "asc" | "desc") => {
    const columnSelector = typeof column === "function" ? column.name : column;

    setDefaultValue((prevState) => ({
      ...prevState,
      sort: {
        sort_by: columnSelector,
        sort_order: sortDirection.toUpperCase(),
      },
    }));
  };

  useEffect(() => {
    if (key === "fifth") {
      fetchStaffComplianceDetails();
    }
  }, [defaultValue, staffid, key === "fifth"]);

  // Staff Document Edit Get by Details
  // ================================================================================================================================ //

  const editStaffDocumentDetail = async (id: number) => {
    try {
      const response = await getByStaffDocumentDetail(id);
      const data = response.data;
      Complianceformik.setValues({
        document_type: data.document_type,
        ref_no: data.ref_no,
        reminder: data.reminder,
        expiry_date: data.expiry_date,
        is_critical: data.is_critical,
        state_id: data.state_id,
        document_path: data.document_path,
        document_name: data.document_name,
      });
      setIsEditing(true);
      setmodal_standard(true);
      setdocname(data.document_name);
      setDocumentType(data.document_type);
      setShowUploadButton(!data.document_path && !file);
      setEditId(id);
      fetchStaffComplianceDetails();
    } catch (error) {
      console.error("Error fetching Subcontractor Compliance details:", error);
      toast.error("Error fetching details. Please try again later.");
    }
  };

  // Staff Document Add API Integration //
  //=======================================================================================//

  const [file, setFile] = useState<File | null>(null);
  const [showUploadButton, setShowUploadButton] = useState(true);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      setFile(selectedFiles[0]);
      setShowUploadButton(false);
      Complianceformik.setFieldValue("document_path", selectedFiles[0]);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setShowUploadButton(true);
  };

  const [documentType, setDocumentType] = useState("Licenses");

  const handleDocumentTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setDocumentType(e.target.value);
    setFile(null);
    setShowUploadButton(true);
    setIsEditing(false);
    setEditId(null);
    Complianceformik.setFieldValue("document_path", null);
    setdocname("");
    Complianceformik.resetForm();
    Complianceformik.setFieldValue("document_type", e.target.value);
    Complianceformik.setFieldValue("reminder", "");
    Complianceformik.setFieldValue("is_critical", "");
    Complianceformik.setFieldValue("document_name", "");
  };

  const handleCheckboxChange = (e: { target: { checked: any } }) => {
    Complianceformik.setFieldValue("is_critical", e.target.checked ? 1 : 0);
  };

  const [docname, setdocname] = useState("");
  const handleDocumentNameChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedId = event.target.value;
    setdocname(selectedId);
    Complianceformik.setFieldValue("document_name", selectedId);
    const selectedDocument = DropdownComplianceDetail.find(
      (item) => item.compliance_name === selectedId
    );

    if (selectedDocument) {
      Complianceformik.setFieldValue(
        "reminder",
        selectedDocument.reminder_days
      );
      Complianceformik.setFieldValue(
        "is_critical",
        selectedDocument.is_critical
      );
    }

    Complianceformik.handleChange(event);
  };

  const [isUpdateClicked, setIsUpdateClicked] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
        if (e) e.preventDefault();
    
        setIsUpdateClicked(true);
    
        const isPayGroupRequired = (row: any) => {
          return (
            
            formik.values.employee_status_id !== 4 &&
            row.location_id !== undefined &&
            row.location_id !== 0 
          );
        };
    
        const invalidPayGroup = rows.some(
          (row) => isPayGroupRequired(row) && !row.pay_group_id
        );
    
        if (invalidPayGroup) {
          setRows([...rows]);
          return;
        }
    
        const invalidAssignRateRow = rows.some((row) =>
          row?.pay_group_rates?.some((rate) => rate.assign_rate < rate.default_rate)
        );
    
        if (invalidAssignRateRow) {
          return;
        }
  
        formik.handleSubmit();
      };
  const Complianceformik = useFormik({
    initialValues: {
      document_type: "Licenses",
      document_name: "",
      reminder: "",
      expiry_date: "",
      is_critical: 0,
      ref_no: "",
      state_id: "",
      document_path: null,
    },
    validationSchema: Yup.object({
      // document_type: Yup.string().required('Document Type is required'),
      document_path: Yup.mixed().required("Document is required"),
      reminder: Yup.number().typeError("Reminder must be a number"),
      // date: Yup.date().required('Expiry date is required'),
      // state: Yup.string().required('State is required'),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const staff_id = localStorage.getItem("clickedItemId");
      const dataToSend = { ...values, staff_id };
      try {
        if (isEditing && editId !== null) {
          const response = await updateStaffDocumentDetail(
            editId,
            values.document_type,
            values.document_path,
            values.document_name,
            values.ref_no,
            values.reminder,
            values.state_id,
            values.expiry_date,
            values.is_critical
          );
          if (response.status === 1) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
            return;
          }
        } else {
          const response = await addStaffComplianceDetail(dataToSend);
          if (response.status === 1) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
            return;
          }
        }
        Complianceformik.resetForm();
        fetchStaffComplianceDetails();
        setFile(null);
        setmodal_standard(false);
        setShowUploadButton(true);
        setIsEditing(false);
        setEditId(null);
      } catch (error) {
        console.error("Error Adding/Updating Staff Document:", error);
        toast.error("Error Staff Document. Please try again later.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  // Staff Document Archive Details
  // ================================================================================================================================ //

  const [archiveId, setArchiveId] = useState<number | undefined>(undefined);
  const [archiveLoader, setArchiveLoader] = useState(false);

  const handleArchiveCompliance = async (id: number): Promise<void> => {
    setArchiveId(id);
    setmodal_center(true);
  };

  const [modal_center, setmodal_center] = useState<boolean>(false);
  function tog_center() {
    setmodal_center(!modal_center);
  }

  const archiveSubcontractorComplianceMaster = async (id: number) => {
    setArchiveLoader(true);
    try {
      const response = await archiveStaffDocumentDetail(id);
      fetchStaffComplianceDetails();
      toast.success(response.message);
      setmodal_center(false);
      setArchiveLoader(false);
    } catch (error) {
      console.error("Error Archive Staff Document:", error);
      toast.error("Error Archive Please try again later.");
    }
  };

  // State Dropdown Details
  // ================================================================================================================================ //

  const [defaultPayloadStateValue, setDefaultPayloadStateValue] = useState({
    page: 1,
    page_size: "10",
    search_term: "",
    sort_order: "DESC",
    is_archive: 0,
  });

  const [StateMasterDetail, setStateMasterDetail] = useState<StateMasterData[]>(
    []
  );

  const fetchStateetails = async () => {
    try {
      const response = await getStateMasterDetail(
        defaultPayloadStateValue.page,
        defaultPayloadStateValue.page_size,
        defaultPayloadStateValue.search_term,
        defaultPayloadStateValue.sort_order,
        defaultPayloadStateValue.is_archive
      );
      setStateMasterDetail(response.data.data_list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (key === "fifth") {
      fetchStateetails();
    }
  }, [key === "fifth"]);

  // Compliance Dropdown //
  //================================================================================================================//

  const [DropdownComplianceDetail, setDropdownComplianceDetail] = useState<
    ComplianceData[]
  >([]);

  const fetchDropdownComplianceDetails = async () => {
    try {
      const searchTerm = "";
      const response = await getDropdownComplianceDetail(searchTerm);
      setDropdownComplianceDetail(response.data.data_list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (key === "fifth") {
      fetchDropdownComplianceDetails();
    }
  }, [key === "fifth"]);

  // Staff Location Dropdown Details
  //================================================================================================================//

  const [DropdownStaffLocationDetail, setDropdownStaffLocationDetail] =
    useState<LocationData[]>([]);

  const [selectedLocations, setSelectedLocations] = useState<number[]>([]);

  const availableLocations = DropdownStaffLocationDetail.filter(
    (location) => !selectedLocations.includes(location.value)
  );

  const fetchDropdownStaffLocationDetails = async () => {
    try {
      const search_term = "";
      const response = await getStaffLocationDetail(search_term);
      setDropdownStaffLocationDetail(response.data.data_list);
    } catch (error) {
      console.log(error);
    }
  };

  const [DropdownPayGroupDetail, setDropdownPayGroupDetail] = useState<
    [PayGroupData[], PayGroupData[]]
  >([[], []]);

  const fetchDropdownPayGroupDetails = async () => {
    try {
      const search_term = "";

      // Fetch regular pay groups (is_patrol_site = 0)
      const regularResponse = await getPayGroupDetail(search_term, 0);
      const regularPayGroups = regularResponse.data.data_list;

      // Fetch patrol pay groups (is_patrol_site = 1)
      const patrolResponse = await getPayGroupDetail(search_term, 1);
      const patrolPayGroups = patrolResponse.data.data_list;

      // Update state with both pay group lists
      setDropdownPayGroupDetail([regularPayGroups, patrolPayGroups]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (key === "fourth") {
      fetchDropdownStaffLocationDetails();
      fetchDropdownPayGroupDetails();
    }
  }, [key === "fourth"]);

  const defaultRow: RowNewData = {
    location_id: 0,
    pay_group_id: 0,
    is_patrol_site: 0,
    pay_group_rates: [
      {
        pay_category_id: 0,
        category_name: "",
        default_rate: 0,
        assign_rate: 0,
        pay_rate_id: 0,
      },
    ],
    isOpen: true,
  };

  const [rows, setRows] = useState<RowNewData[]>([defaultRow]);

  const toggleTable = (index: number) => {
    const updatedRows = [...rows];
    updatedRows[index].isOpen = !updatedRows[index].isOpen; // Toggle the `isOpen` value
    setRows(updatedRows); // Update the state
  };

  //   const handleAddRow = () => {
  //     setRows([...rows, defaultRow]);
  //   };

  // const handleAddRow = () => {
  //   // Ensure the new row has `isOpen` set to true and all other necessary default values
  //   const newRow = {
  //     ...defaultRow,
  //     isOpen: true, // Open the new row by default
  //     location_id: 0, // Initialize as 0 (empty or unselected)
  //     pay_group_id: 0, // Initialize as 0 (empty or unselected)
  //     pay_group_rates: [], // Empty array as default
  //   };

  //   // Prepend the new row to the top of the existing rows
  //   setRows([newRow, ...rows]);
  // };

  const handleAddRow = () => {
    setIsUpdateClicked(false);
    
    const newRow: RowNewData = {
      ...defaultRow,
      location_id: 0,   // Ensure it starts with 0 (no pre-selection)
      is_patrol_site: 0,
      pay_group_id: 0,
      pay_group_rates: [],
      isOpen: true,
    };
  
    setRows([newRow, ...rows]); // Add new row at the top
  };
  const handleDeleteRow = (index: number) => {
    const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
    setRows(newRows);
  };

  // const handleRowChange = async (index: number, field: keyof RowNewData, value: string) => {
  //     if (field === 'pay_group_id' && value) {
  //         await getPayGroupDetails(Number(value), index);
  //     } else {
  //         const newRows = rows.map((row, rowIndex) =>
  //             rowIndex === index ? { ...row, [field]: value } : row
  //         );
  //         setRows(newRows);
  //     }
  // };

  const handleRowChange = async (
    index: number,
    field: keyof RowNewData,
    value: string | number
  ) => {
    // Handle location_id change
    if (field === "location_id") {
      const selectedLocation = DropdownStaffLocationDetail.find(
        (option) => option.value === Number(value)
      );
      const isPatrolSite = selectedLocation?.is_petrol_site ?? 0;

      const newRows = rows.map((row, rowIndex) =>
        rowIndex === index
          ? {
              ...row,
              location_id: Number(value),
              is_patrol_site: isPatrolSite,
              pay_group_id: 0,
            }
          : row
      );

      // Update selected locations (excluding value 0)
      const newSelectedLocations = newRows
        .map((row) => row.location_id)
        .filter((id) => id !== 0);
      setSelectedLocations(newSelectedLocations);

      setRows(newRows); // Update rows with new location_id
    }
    // Handle pay_group_id change
    else if (field === "pay_group_id" && value) {
      await getPayGroupDetails(Number(value), index); // Fetch pay group details based on new ID
    }
    // Handle other field changes (e.g., non-specific fields)
    else {
      const newRows = rows.map((row, rowIndex) =>
        rowIndex === index ? { ...row, [field]: value } : row
      );
      setRows(newRows); // Update the field in the rows array
    }
  };

  const getPayGroupDetails = async (id: number, index: number) => {
    try {
      if (emptype === 4 && formik.values.employee_status_id === 4) {
        const newRows = [...rows];
        newRows[index] = {
          ...newRows[index],
          pay_group_id: 0,
          pay_group_rates: [
            {
              pay_category_id: 0,
              category_name: "",
              default_rate: 0,
              assign_rate: 0,
              pay_rate_id: 0,
            },
          ],
        };
        setRows(newRows);
        return;
      }

      const response = await PayGroupByIdDetail(id);
      const data = response.data;

      const newRows = [...rows];
      newRows[index] = {
        ...newRows[index],
        pay_group_id: data.id,
        pay_group_rates: data.pay_group_rates.map(
          (rate: {
            pay_rate_id: number;
            pay_category_id: number;
            default_rate: number;
            assign_rate: number;
            category_name: string;
          }) => ({
            pay_rate_id: rate.pay_rate_id,
            pay_category_id: rate.pay_category_id,
            default_rate: rate.default_rate,
            assign_rate: rate.assign_rate,
            category_name: rate.category_name,
          })
        ),
      };
      setRows(newRows);
    } catch (error) {
      console.error("Error fetching Pay Group details:", error);
      toast.error("Error fetching details. Please try again later.");
    }
  };

  const handleAssignRateChange = (
    rowIndex: number,
    payRateId: number,
    newRate: number
  ) => {
    const updatedRows = rows.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          pay_group_rates: row.pay_group_rates
            ? row.pay_group_rates.map((rate) =>
                rate.pay_rate_id === payRateId
                  ? {
                      ...rate,
                      assign_rate: newRate,
                    }
                  : rate
              )
            : [],
        };
      }
      return row;
    });

    setRows(updatedRows);
  };

  // Pay Schedule Dropdown Details
  //================================================================================================================//

  const [PayScheduleList, SetPayScheduleList] = useState<any[]>([]);

  const fetchScheduleDropdownDetails = async () => {
    try {
      const searchTerm = "";
      const response = await getPayScheduleDropdownDetail(searchTerm);
      SetPayScheduleList(response.data.data_list);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePayScheduleChange = (
    selectedOption: { value: number; label: string; days: number } | null
  ) => {
    if (selectedOption) {
      formik.setFieldValue("pay_schedule_id", selectedOption.value);
    }
  };

  useEffect(() => {
    if (key === "second") {
      fetchScheduleDropdownDetails();
    }
  }, [key === "second"]);

  //================================================================================================================//

  const openDocForm = () => {
    setIsEditing(false);
    setEditId(null);
    Complianceformik.resetForm();
    setmodal_standard(true);
    setFile(null);
    setDocumentType("Licenses");
    setShowUploadButton(true);
    setdocname("");
  };

  //================================================================================================================//

  const [send, setSend] = useState(2);

  const handleSendOnboardingLink = () => {
    setSend(2);
    sendformik.handleSubmit();
  };

  const sendformik = useFormik({
    initialValues: {
      staff_id: staffid,
      is_invite: 0,
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const staffid = localStorage.getItem("clickedItemId");
        const updatedvalues = {
          ...values,
          is_invite: send,
        };
        const response = await SendOnboardingLink(updatedvalues);

        if (response.status === 1) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error Adding/Updating Staff:", error);
        toast.error("Error Staff. Please try again later.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const hasFourthTabError = rows.some(
        (row) =>
          isUpdateClicked && 
          !(emptype === 4 || formik.values.employee_status_id === 4) && // New condition
          row.location_id !== undefined &&
          row.location_id !== 0 &&
          !row.pay_group_id
      );

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb
              title="Edit Staff"
              pageTitle="Staff"
              pageTitleLink="/staff-page"
            />
            <Row className="edit-staff">
              <Col xl={12} className="user-section">
                <Tab.Container
                  id="left-tabs-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k as string)}
                >
                  <Form onSubmit={formik.handleSubmit}>
                    <Row className="d-flex align-items-center flex-wrap gap-2 mb-4">
                      <div className="col-md d-flex order-1">
                        <Nav
                          variant="pills"
                          className="arrow-navtabs nav-secondary gap-2 flex-grow-1"
                        >
                          <Nav.Item>
                            <Nav.Link eventKey="first" href="#personalDetails">
                              Personal Details
                              {hasFirstTabError && (
                                <i
                                  className={`ms-2 ${
                                    key == "first"
                                      ? "text-white"
                                      : "text-danger"
                                  } bi bi-exclamation-circle-fill`}
                                  title="Validation Error"
                                  style={{ fontSize: "20px" }}
                                ></i>
                              )}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="second"
                              href="#professionaldetails"
                            >
                              Professional Details
                              {hasSecondTabError && (
                                <i
                                  className={`ms-2 ${
                                    key == "second"
                                      ? "text-white"
                                      : "text-danger"
                                  } bi bi-exclamation-circle-fill`}
                                  title="Validation Error"
                                  style={{ fontSize: "20px" }}
                                ></i>
                              )}
                            </Nav.Link>
                          </Nav.Item>
                          {canAccess && (
                            <Nav.Item>
                              <Nav.Link eventKey="third" href="#bankdetails">
                                Bank Details{" "}
                              </Nav.Link>
                            </Nav.Item>
                          )}
                          <Nav.Item>
                            <Nav.Link eventKey="fourth" href="#securityPrivacy">
                              Working Locations
                              {hasFourthTabError && (
                                                               <i
                                                               className={`ms-2 ${
                                                                 key == "second"
                                                                   ? "text-white"
                                                                   : "text-danger"
                                                               } bi bi-exclamation-circle-fill`}
                                                               title="Validation Error"
                                                               style={{ fontSize: "20px" }}
                                                             ></i>
                                                            )}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="fifth"
                              href="#licences/compliance"
                            >
                              Licences / Compliance
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <span className="d-flex gap-3">
                          <Button
                            color="primary"
                            className="back-bnt-btn"
                            href="/staff-page"
                          >
                            Back
                          </Button>
                          <Button
                            type="submit"
                            className="sub-update-btn"
                            onClick={handleSubmit} // Trigger custom validation before submitting
                            disabled={formik.isSubmitting}
                          >
                            {formik.isSubmitting ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              >
                                <span className="visually-hidden">
                                  Updating...
                                </span>
                              </Spinner>
                            ) : (
                              "Update"
                            )}
                          </Button>
                          {StaffDetail?.invited === 0 && (
                            <Button
                              onClick={handleUpdateAndInvite}
                              className="sub-update-btn"
                              disabled={formik.isSubmitting}
                            >
                              {formik.isSubmitting ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                >
                                  <span className="visually-hidden">
                                    Updating...
                                  </span>
                                </Spinner>
                              ) : (
                                "Update & Invite"
                              )}
                            </Button>
                          )}
                          {StaffDetail?.invited === 2 && (
                            <Button
                              onClick={handleSendOnboardingLink}
                              className="sub-update-btn"
                              disabled={sendformik.isSubmitting}
                            >
                              {sendformik.isSubmitting ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                >
                                  <span className="visually-hidden">
                                    Updating...
                                  </span>
                                </Spinner>
                              ) : (
                                "Send Onboarding Link"
                              )}
                            </Button>
                          )}
                        </span>
                      </div>
                    </Row>
                    {key === "first" && (
                      <Tab.Content>
                        <Tab.Pane eventKey="first" id="personalDetails">
                          <Card className="section-1">
                            <Card.Header>
                              <h5 className="card-title personal-details-title mb-0">
                                Personal Details
                              </h5>
                            </Card.Header>
                            <Card.Body>
                              <SimpleBar
                                style={{ maxHeight: "576px" }}
                                className="px-3"
                              >
                                <Row>
                                  <Col lg="12">
                                    <p className="basic-details-title">
                                      Basic Details
                                    </p>
                                  </Col>
                                  <Col lg="12" className="mb-3">
                                    <div className="profile-user position-relative d-inline-block mx-auto">
                                      <img
                                        src={imageSrc ? imageSrc : blank}
                                        alt=""
                                        className={`avatar-lg rounded-circle object-fit-cover img-thumbnail user-profile-image`}
                                      />
                                      <div className="avatar-xs p-0 rounded-circle profile-photo-edit position-absolute end-0 bottom-0">
                                        <input
                                          id="profile-img-file-input"
                                          type="file"
                                          className="profile-img-file-input d-none"
                                          onChange={handleImageChange}
                                          onBlur={formik.handleBlur}
                                        />
                                        <label
                                          htmlFor="profile-img-file-input"
                                          className="profile-photo-edit avatar-xs"
                                        >
                                          <span className="avatar-title rounded-circle bg-light text-body">
                                            <i className="bi bi-camera"></i>
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>First name*</Form.Label>
                                      <div className="position-relative form-icon">
                                        <Form.Control
                                          type="name"
                                          name="first_name"
                                          className="form-control-lg form-control-icon name-input"
                                          value={formik.values.first_name}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          placeholder="Enter First Name"
                                          isInvalid={
                                            !!(
                                              formik.touched.first_name &&
                                              formik.errors.first_name
                                            )
                                          }
                                        />
                                        <i
                                          style={{ fontSize: "16px" }}
                                          className="bx bx-face"
                                        ></i>
                                        <Form.Control.Feedback type="invalid">
                                          {formik.errors.first_name}
                                        </Form.Control.Feedback>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Last name*</Form.Label>
                                      <div className="position-relative form-icon">
                                        <Form.Control
                                          type="name"
                                          name="last_name"
                                          value={formik.values.last_name}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          isInvalid={
                                            !!(
                                              formik.touched.last_name &&
                                              formik.errors.last_name
                                            )
                                          }
                                          className="form-control-lg form-control-icon name-input"
                                          placeholder="Enter Last Name"
                                        />
                                        <i
                                          style={{ fontSize: "16px" }}
                                          className="bx bx-face"
                                        ></i>
                                        <Form.Control.Feedback type="invalid">
                                          {formik.errors.last_name}
                                        </Form.Control.Feedback>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Preferred name</Form.Label>
                                      <div className="position-relative form-icon">
                                        <Form.Control
                                          type="name"
                                          name="preferred_name"
                                          value={formik.values.preferred_name}
                                          onChange={formik.handleChange}
                                          // isInvalid={!!(formik.touched.preferred_name && formik.errors.preferred_name)}
                                          className="form-control-lg form-control-icon name-input"
                                          placeholder="Enter Preferred name"
                                        />
                                        <i
                                          style={{ fontSize: "16px" }}
                                          className="bx bx-face"
                                        ></i>
                                        {/* <Form.Control.Feedback type="invalid">
                                                                                {formik.errors.preferred_name}
                                                                            </Form.Control.Feedback> */}
                                      </div>
                                    </Form.Group>
                                  </Col>
                                  <Col lg="6">
                                    <Form.Group className="mb-3">
                                      <Form.Label>Email address*</Form.Label>
                                      <div className="position-relative form-icon">
                                        <Form.Control
                                          type="name"
                                          name="email"
                                          value={formik.values.email}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          isInvalid={
                                            !!(
                                              formik.touched.email &&
                                              formik.errors.email
                                            )
                                          }
                                          className="form-control-lg form-control-icon name-input"
                                          placeholder="Enter Email address"
                                        />
                                        <i
                                          style={{ fontSize: "16px" }}
                                          className="ri-mail-line"
                                        ></i>
                                        <Form.Control.Feedback type="invalid">
                                          {formik.errors.email}
                                        </Form.Control.Feedback>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} className="mb-3">
                                    <Form.Group className="mb-3">
                                      <Form.Label>Contact Number*</Form.Label>
                                      <div className="position-relative form-icon">
                                        <Form.Control
                                          type="text"
                                          name="mobile_no"
                                          className="form-control-lg form-control-icon name-input"
                                          placeholder="Enter number"
                                          value={formik.values.mobile_no}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          isInvalid={
                                            !!(
                                              formik.touched.mobile_no &&
                                              formik.errors.mobile_no
                                            )
                                          }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {formik.errors.mobile_no}
                                        </Form.Control.Feedback>
                                        <i
                                          style={{ fontSize: "16px" }}
                                          className="ri-keyboard-line"
                                        ></i>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-3 d-flex flex-column form-icon">
                                      <Form.Label htmlFor="JoiningdatInput">
                                        Birth date
                                      </Form.Label>
                                      <Flatpickr
                                        className="form-control-lg form-control-icon"
                                        name="dob"
                                        options={{
                                          dateFormat: "d-m-Y",
                                          maxDate: "today",
                                        }}
                                        placeholder="Select date"
                                        value={formik.values.dob}
                                        onChange={(dates: any[]) => {
                                          const formattedDate = format(
                                            dates[0],
                                            "dd-MM-yyyy"
                                          );
                                          formik.setFieldValue(
                                            "dob",
                                            formattedDate
                                          );
                                        }}
                                      />
                                      <i
                                        style={{ fontSize: "16px" }}
                                        className="ri-calendar-event-line mt-4"
                                      ></i>
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group className="mb-3 position-relative">
                                      <Form.Label>Gender</Form.Label>
                                      <div className="position-relative form-icon">
                                        <Form.Select
                                          name="gender"
                                          value={formik.values.gender}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          className="form-control-lg form-control-icon name-input"
                                          isInvalid={
                                            !!formik.touched.gender &&
                                            !!formik.errors.gender
                                          }
                                        >
                                          <option defaultValue="">
                                            Select
                                          </option>
                                          <option value="Male">Male</option>
                                          <option value="Female">Female</option>
                                        </Form.Select>
                                        <i
                                          style={{ fontSize: "16px" }}
                                          className="bx bx-face"
                                        ></i>
                                        <Form.Control.Feedback
                                          type="invalid"
                                          className="position-absolute"
                                        >
                                          {formik.errors.gender}
                                        </Form.Control.Feedback>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                  <Col lg="6">
                                    <Form.Group className="mb-3">
                                      <Form.Label>Address</Form.Label>
                                      <SearchableAddress
                                        label="Address"
                                        name="address"
                                        placeholder="Enter Address"
                                        onChange={handleAddressChange}
                                        invalid={false}
                                        defaultValue={{
                                          latitude: "",
                                          longitude: "",
                                          address: address
                                            ? address
                                            : formik.values.address,
                                        }}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg="12">
                                    <p className="basic-details-title">
                                      Emergency Details
                                    </p>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Full name</Form.Label>
                                      <div className="position-relative form-icon">
                                        <Form.Control
                                          type="name"
                                          name="emergency_name"
                                          className="form-control-lg form-control-icon name-input"
                                          placeholder="Enter Name"
                                          value={formik.values.emergency_name}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          isInvalid={
                                            !!(
                                              formik.touched.emergency_name &&
                                              formik.errors.emergency_name
                                            )
                                          }
                                        />
                                        <i
                                          style={{ fontSize: "16px" }}
                                          className="bx bx-face"
                                        ></i>
                                      </div>
                                    </Form.Group>
                                  </Col>

                                  <Col lg={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Relation</Form.Label>
                                      <div className="position-relative form-icon">
                                        <Form.Control
                                          type="name"
                                          name="relation"
                                          className="form-control-lg form-control-icon name-input"
                                          placeholder="Enter Relation"
                                          value={formik.values.relation}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          isInvalid={
                                            !!(
                                              formik.touched.relation &&
                                              formik.errors.relation
                                            )
                                          }
                                        />
                                        <i
                                          style={{ fontSize: "16px" }}
                                          className="bx bx-face"
                                        ></i>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} className="mb-3">
                                    <Form.Group className="mb-3">
                                      <Form.Label>Contact Number*</Form.Label>
                                      <div className="position-relative form-icon">
                                        <Form.Control
                                          type="text"
                                          name="emergency_number"
                                          className="form-control-lg form-control-icon name-input"
                                          placeholder="Enter number"
                                          value={formik.values.emergency_number}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          isInvalid={
                                            !!(
                                              formik.touched.emergency_number &&
                                              formik.errors.emergency_number
                                            )
                                          }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {formik.errors.emergency_number}
                                        </Form.Control.Feedback>
                                        <i
                                          style={{ fontSize: "16px" }}
                                          className="ri-keyboard-line"
                                        ></i>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                  <Col lg="6">
                                    <Form.Group className="mb-3">
                                      <Form.Label>Email address*</Form.Label>
                                      <div className="position-relative form-icon">
                                        <Form.Control
                                          type="name"
                                          name="emergency_email"
                                          className="form-control-lg form-control-icon name-input"
                                          placeholder="Enter Email address"
                                          value={formik.values.emergency_email}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          isInvalid={
                                            !!(
                                              formik.touched.emergency_email &&
                                              formik.errors.emergency_email
                                            )
                                          }
                                        />
                                        <i
                                          style={{ fontSize: "16px" }}
                                          className="ri-mail-line"
                                        ></i>
                                        <Form.Control.Feedback type="invalid">
                                          {formik.errors.emergency_email}
                                        </Form.Control.Feedback>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </SimpleBar>
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                      </Tab.Content>
                    )}
                    {key === "second" && (
                      <Tab.Content>
                        <Tab.Pane eventKey="second" id="changePassword">
                          <Card className="section-1">
                            <Card.Header>
                              <h5 className="card-title personal-details-title mb-0">
                                Professional Details
                              </h5>
                            </Card.Header>
                            <Card.Body>
                              <SimpleBar
                                style={{ maxHeight: "576px" }}
                                className="px-3"
                              >
                                <Form action="#">
                                  <Row>
                                    <Col lg={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>
                                          Daily Working Hours
                                        </Form.Label>
                                        <div className="position-relative form-icon">
                                          <Form.Control
                                            type="number"
                                            name="daily_working_hours"
                                            className="form-control-lg form-control-icon name-input"
                                            placeholder="Enter Daily Working Hours"
                                            value={
                                              formik.values.daily_working_hours
                                                ? formik.values
                                                    .daily_working_hours
                                                : ""
                                            }
                                            onChange={(e) => {
                                              let value = parseFloat(
                                                e.target.value
                                              );
                                              if (value < 0) value = 0; // Enforce minimum value of 1
                                              formik.setFieldValue(
                                                "daily_working_hours",
                                                value
                                              );
                                            }}
                                          />
                                          <i
                                            style={{ fontSize: "16px" }}
                                            className="ri-time-line"
                                          ></i>
                                        </div>
                                      </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>
                                          Weekly Working Hours
                                        </Form.Label>
                                        <div className="position-relative form-icon">
                                          <Form.Control
                                            type="number"
                                            name="weekly_working_hours"
                                            className="form-control-lg form-control-icon name-input"
                                            placeholder="Enter Weekly Working Hours"
                                            value={
                                              formik.values.weekly_working_hours
                                                ? formik.values
                                                    .weekly_working_hours
                                                : ""
                                            }
                                            onChange={(e) => {
                                              let value = parseFloat(
                                                e.target.value
                                              );
                                              if (value < 0) value = 0; // Enforce minimum value of 1
                                              formik.setFieldValue(
                                                "weekly_working_hours",
                                                value
                                              );
                                            }}
                                          />
                                          <i
                                            style={{ fontSize: "16px" }}
                                            className="ri-time-line"
                                          ></i>
                                        </div>
                                      </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="mb-3 d-flex flex-column form-icon">
                                        <Form.Label htmlFor="implement Start Date ">
                                          Joining date
                                        </Form.Label>
                                        <Flatpickr
                                          className="form-control-lg form-control-icon"
                                          name="start_date"
                                          options={{
                                            dateFormat: "d-m-Y",
                                            minDate: "today",
                                          }}
                                          placeholder="Select date"
                                          defaultValue="30 Oct, 2023"
                                          value={formik.values.start_date}
                                          onChange={(dates: any[]) => {
                                            const formattedDate = format(
                                              dates[0],
                                              "dd-MM-yyyy"
                                            );
                                            formik.setFieldValue(
                                              "start_date",
                                              formattedDate
                                            );
                                          }}
                                        />
                                        <i
                                          style={{ fontSize: "16px" }}
                                          className="ri-calendar-event-line mt-4"
                                        ></i>
                                      </div>
                                    </Col>
                                    {canAccess && (
                                      <Col lg={6}>
                                        <Form.Group className="mb-3 form-icon">
                                          <Form.Label className="text-muted">
                                            Staff Type
                                          </Form.Label>
                                          <Form.Select
                                            className="form-select-lg form-control-icon"
                                            name="employee_status_id"
                                            id="Staff Type"
                                            value={
                                              formik.values.employee_status_id
                                            }
                                            onChange={handleEmpTypeChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={
                                              !!(
                                                formik.touched
                                                  .employee_status_id &&
                                                formik.errors.employee_status_id
                                              )
                                            }
                                          >
                                            <option value="">Select</option>
                                            {EmpTypeDetail.map((empType) => {
                                              return (
                                                <option
                                                  key={empType.id}
                                                  value={empType.id}
                                                >
                                                  {empType.type}
                                                </option>
                                              );
                                            })}
                                          </Form.Select>
                                          <i
                                            style={{ fontSize: "16px" }}
                                            className="bi bi-briefcase mt-4"
                                          ></i>
                                          {formik.touched.employee_status_id &&
                                          formik.errors.employee_status_id ? (
                                            <div className="invalid-feedback d-block">
                                              {formik.errors.employee_status_id}
                                            </div>
                                          ) : null}
                                        </Form.Group>
                                      </Col>
                                    )}
                                    {canAccess && (
                                      <Col lg={6}>
                                        <Form.Group className="mb-3 form-icon">
                                          <Form.Label className="text-muted">
                                            Access Level
                                          </Form.Label>
                                          <Form.Select
                                            className="form-select-lg form-control-icon"
                                            id="Staff Type"
                                            name="access_level"
                                            value={formik.values.access_level}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={
                                              !!(
                                                formik.touched.access_level &&
                                                formik.errors.access_level
                                              )
                                            }
                                          >
                                            <option defaultValue="">
                                              Select
                                            </option>
                                            {AccessLevelDetail.map((level) => {
                                              return (
                                                <option
                                                  key={level.id}
                                                  value={level.name}
                                                >
                                                  {level.name}
                                                </option>
                                              );
                                            })}
                                          </Form.Select>
                                          <i
                                            style={{ fontSize: "16px" }}
                                            className=" ri-shield-user-line mt-4"
                                          ></i>
                                          {formik.touched.access_level &&
                                          formik.errors.access_level ? (
                                            <div className="invalid-feedback d-block">
                                              {formik.errors.access_level}
                                            </div>
                                          ) : null}
                                        </Form.Group>
                                      </Col>
                                    )}
                                    {(emptype === 4 ||
                                      formik.values.employee_status_id === 4) &&
                                      canAccess && (
                                        <Col lg={6}>
                                          <Form.Group className="mb-3 form-icon">
                                            <Form.Label className="text-muted">
                                              Subcontractor
                                            </Form.Label>
                                            <Select
                                              inputId="subcontractorSelect"
                                              name="subcontractor_id"
                                              options={SubcontractorList}
                                              value={SubcontractorList.find(
                                                (option) =>
                                                  option.value ===
                                                  formik.values.subcontractor_id
                                              )}
                                              onChange={(selectedOption: any) =>
                                                formik.setFieldValue(
                                                  "subcontractor_id",
                                                  selectedOption
                                                    ? selectedOption.value
                                                    : ""
                                                )
                                              }
                                              onBlur={formik.handleBlur}
                                              classNamePrefix="react-select"
                                              className={
                                                formik.touched
                                                  .subcontractor_id &&
                                                formik.errors.subcontractor_id
                                                  ? "is-invalid"
                                                  : ""
                                              }
                                            />
                                            <i
                                              style={{ fontSize: "16px" }}
                                              className="bi bi-briefcase mt-4"
                                            ></i>
                                            {formik.touched.subcontractor_id &&
                                            formik.errors.subcontractor_id ? (
                                              <div className="invalid-feedback d-block">
                                                {formik.errors.subcontractor_id}
                                              </div>
                                            ) : null}
                                          </Form.Group>
                                        </Col>
                                      )}
                                    {!(
                                      emptype === 4 ||
                                      formik.values.employee_status_id === 4
                                    ) && (
                                      <Col lg="6">
                                        <Form.Group className="mb-3 form-icon">
                                          <Form.Label>Pay Schedule</Form.Label>
                                          <div
                                            className={`react-select-container ${
                                              formik.touched.pay_schedule_id &&
                                              formik.errors.pay_schedule_id
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                          >
                                            <Select
                                              inputId="pay_schedule_id"
                                              name="pay_schedule_id"
                                              options={PayScheduleList}
                                              value={PayScheduleList.find(
                                                (option) =>
                                                  option.value ===
                                                  formik.values.pay_schedule_id
                                              )}
                                              onChange={handlePayScheduleChange}
                                              onBlur={formik.handleBlur}
                                              classNamePrefix="react-select"
                                              className="react-select"
                                            />
                                            <i
                                              style={{ fontSize: "16px" }}
                                              className=" ri-bank-card-line mt-4"
                                            ></i>
                                            {formik.touched.pay_schedule_id &&
                                            formik.errors.pay_schedule_id ? (
                                              <div className="invalid-feedback d-block">
                                                {formik.errors.pay_schedule_id}
                                              </div>
                                            ) : null}
                                          </div>
                                        </Form.Group>
                                      </Col>
                                    )}
                                    {!(
                                      emptype === 4 ||
                                      formik.values.employee_status_id === 4
                                    ) && (
                                      <>
                                        <h6 className="input-title">
                                          Leave Details
                                        </h6>
                                        <Col lg="12">
                                          {leaveSections.map((leaveSection) => (
                                            <Row key={leaveSection.id}>
                                              <Col lg="6">
                                                <Form.Group className="mb-3 form-icon">
                                                  <Form.Label>
                                                    Leave Type
                                                  </Form.Label>
                                                  <Form.Select
                                                    className="form-select-lg form-control-icon"
                                                    id={`leaveType-${leaveSection.id}`}
                                                    onChange={(e) =>
                                                      handleLeaveChange(
                                                        leaveSection.id,
                                                        parseInt(e.target.value)
                                                      )
                                                    }
                                                    value={
                                                      leaveSection.selectedLeaveId ||
                                                      ""
                                                    }
                                                  >
                                                    <option value="">
                                                      Select Leave Type
                                                    </option>
                                                    {leaveDetail.map(
                                                      (leave) => (
                                                        <option
                                                          key={leave.id}
                                                          value={leave.id}
                                                        >
                                                          {leave.leave_name}
                                                        </option>
                                                      )
                                                    )}
                                                  </Form.Select>
                                                  <i
                                                    style={{ fontSize: "16px" }}
                                                    className="ri-time-line mt-4"
                                                  ></i>
                                                </Form.Group>
                                              </Col>
                                              <Col lg={3}>
                                                <Form.Group className="mb-3 form-icon">
                                                  <Form.Label>
                                                    Available Hours
                                                  </Form.Label>
                                                  <Form.Control
                                                    className="form-select-lg form-control-icon"
                                                    type="number"
                                                    id={`leaveHours-${leaveSection.id}`}
                                                    value={
                                                      leaveSection.available_hours
                                                        ? leaveSection.available_hours
                                                        : leaveSection.defaultHours
                                                    }
                                                    disabled
                                                  />
                                                  <i
                                                    style={{ fontSize: "16px" }}
                                                    className="ri-time-line mt-4"
                                                  ></i>
                                                </Form.Group>
                                              </Col>
                                              <Col lg={3}>
                                                <Form.Group className="mb-3 form-icon">
                                                  <Form.Label>
                                                    Used Leave Hours{" "}
                                                  </Form.Label>
                                                  <Form.Control
                                                    className="form-select-lg form-control-icon"
                                                    type="number"
                                                    id={`usedLeaveHours-${leaveSection.id}`}
                                                    value={
                                                      leaveSection.used_leave_hours ||
                                                      "00"
                                                    }
                                                    disabled
                                                  />
                                                  <i
                                                    style={{ fontSize: "16px" }}
                                                    className="ri-time-line mt-4"
                                                  ></i>
                                                </Form.Group>
                                              </Col>
                                            </Row>
                                          ))}
                                        </Col>
                                        <Col lg={12}>
                                          <Button
                                            onClick={handleAddLeave}
                                            className="add-btn p-0"
                                          >
                                            <i className="ri-add-fill"></i> Add
                                            Leave
                                          </Button>
                                        </Col>
                                      </>
                                    )}
                                  </Row>
                                </Form>
                              </SimpleBar>
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                      </Tab.Content>
                    )}
                    {key === "third" && (
                      <Tab.Content>
                        <Tab.Pane eventKey="third" id="education">
                          <Card className="section-1">
                            <Card.Header>
                              <h5 className="card-title personal-details-title mb-0">
                                Bank Details
                              </h5>
                            </Card.Header>
                            <Card.Body>
                              <SimpleBar
                                style={{ maxHeight: "576px" }}
                                className="px-3"
                              >
                                <Form action="#">
                                  <Row>
                                    <Col lg={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Bank Name</Form.Label>
                                        <div className="position-relative form-icon">
                                          <Form.Control
                                            type="name"
                                            name="bank_name"
                                            className="form-control-lg form-control-icon name-input"
                                            placeholder="Enter your bank Name"
                                            value={formik.values.bank_name}
                                            onChange={formik.handleChange}
                                          />
                                          <i
                                            style={{ fontSize: "16px" }}
                                            className=" ri-lock-password-line"
                                          ></i>
                                        </div>
                                      </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>A/C Holder Name</Form.Label>
                                        <div className="position-relative form-icon">
                                          <Form.Control
                                            type="name"
                                            name="bank_holder_name"
                                            className="form-control-lg form-control-icon name-input"
                                            placeholder="Enter Account Holder Name"
                                            value={
                                              formik.values.bank_holder_name
                                            }
                                            onChange={formik.handleChange}
                                          />
                                          <i
                                            style={{ fontSize: "16px" }}
                                            className="bx bx-face"
                                          ></i>
                                        </div>
                                      </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Account Number</Form.Label>
                                        <div className="position-relative form-icon">
                                          <Form.Control
                                            type="name"
                                            name="acc_no"
                                            className="form-control-lg form-control-icon name-input"
                                            placeholder="Enter your Account Number"
                                            value={formik.values.acc_no}
                                            onChange={formik.handleChange}
                                          />
                                          <i
                                            style={{ fontSize: "16px" }}
                                            className="bx bx-dialpad-alt"
                                          ></i>
                                        </div>
                                      </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>BSB Number</Form.Label>
                                        <div className="position-relative form-icon">
                                          <Form.Control
                                            type="name"
                                            name="bsb_no"
                                            className="form-control-lg form-control-icon name-input"
                                            placeholder="Enter your BSB"
                                            value={formik.values.bsb_no}
                                            onChange={formik.handleChange}
                                          />
                                          <i
                                            style={{ fontSize: "16px" }}
                                            className="bx bx-dialpad-alt"
                                          ></i>
                                        </div>
                                      </Form.Group>
                                    </Col>

                                    <h6 className="input-title">
                                      Superannuation Details
                                    </h6>
                                    <Col lg={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>
                                          Superannuation Fund name*
                                        </Form.Label>
                                        <div className="position-relative form-icon">
                                          <Form.Control
                                            type="name"
                                            name="superannuation_fund_name"
                                            className="form-control-lg form-control-icon name-input"
                                            placeholder="Enter your Superannuation Fund name*"
                                            value={
                                              formik.values
                                                .superannuation_fund_name
                                            }
                                            onChange={formik.handleChange}
                                          />
                                          <i
                                            style={{ fontSize: "16px" }}
                                            className="ri-book-open-line"
                                          ></i>
                                        </div>
                                      </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>
                                          Superannuation Account Number
                                        </Form.Label>
                                        <div className="position-relative form-icon">
                                          <Form.Control
                                            type="name"
                                            name="superannuation_account_no"
                                            className="form-control-lg form-control-icon name-input"
                                            placeholder="Enter your Superannuation Account Number"
                                            value={
                                              formik.values
                                                .superannuation_account_no
                                            }
                                            onChange={formik.handleChange}
                                          />
                                          <i
                                            style={{ fontSize: "16px" }}
                                            className="bx bx-dialpad-alt"
                                          ></i>
                                        </div>
                                      </Form.Group>
                                    </Col>
                                    <h6 className="input-title">TFN Details</h6>
                                    <Col lg={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>TFN Number</Form.Label>
                                        <div className="position-relative form-icon">
                                          <Form.Control
                                            type="name"
                                            name="tfn_no"
                                            className="form-control-lg form-control-icon name-input"
                                            placeholder="Enter your TFN Number"
                                            value={formik.values.tfn_no}
                                            onChange={formik.handleChange}
                                          />
                                          <i
                                            style={{ fontSize: "16px" }}
                                            className="bx bx-dialpad-alt"
                                          ></i>
                                        </div>
                                      </Form.Group>
                                    </Col>
                                    <Col lg="6" className="upload-section-col">
                                      <Form.Group>
                                        <Form.Label className="doc-label">
                                          Upload Document
                                        </Form.Label>
                                        <div className="uploadsection">
                                          {showUploadButtonTFN ? (
                                            <div className="d-flex align-item-center">
                                              <Form.Label
                                                htmlFor="TFNfile"
                                                className="uploadlabel"
                                              >
                                                <Form.Control
                                                  type="file"
                                                  className="form-control-lg"
                                                  style={{ display: "none" }}
                                                  id="TFNfile"
                                                  onChange={
                                                    handleTFNDocumentChnage
                                                  }
                                                />
                                                <i className="bx bx-plus"></i>{" "}
                                                Choose File
                                              </Form.Label>
                                            </div>
                                          ) : (
                                            <Col
                                              lg="12"
                                              className="closeuser py-0"
                                            >
                                              <p>
                                                File Selected:{" "}
                                                {TFNfile?.name
                                                  ? TFNfile?.name
                                                  : formik.values.tfn_file_path}
                                              </p>
                                              <Button
                                                onClick={handleRemoveFileTFN}
                                                color="red"
                                              >
                                                X
                                              </Button>
                                            </Col>
                                          )}
                                        </div>
                                      </Form.Group>
                                      {(formik.values.tfn_file_path ||
                                        TFNfile) && (
                                        <div className="closeuser d-flex gap-2 align-items-center justify-content-start">
                                          <i
                                            onClick={() => {
                                              if (TFNfile) {
                                                handleFilePreview(TFNfile);
                                              } else if (
                                                formik.values.tfn_file_path
                                              ) {
                                                handleOpenDocument(
                                                  formik.values.tfn_file_path
                                                );
                                              }
                                            }}
                                            className="ri-eye-line link-info"
                                            style={{
                                              fontSize: "18px",
                                              cursor: "pointer",
                                            }}
                                          ></i>
                                          <p className="mb-0">
                                            File Selected:{" "}
                                            {TFNfile?.name
                                              ? TFNfile?.name
                                              : formik.values.tfn_file_path}
                                          </p>
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                </Form>
                              </SimpleBar>
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                      </Tab.Content>
                    )}
                    {key === "fourth" && (
                      <Tab.Content>
                        <Tab.Pane eventKey="fourth" id="changePassword">
                          <Card className="section-1">
                            <Card.Header className="d-flex justify-content-between align-items-center">
                              <h5 className="card-title personal-details-title mb-0">
                                Working Locations
                              </h5>
                              <Button
                                className="btn btn-primary mb-3"
                                onClick={handleAddRow}
                              >
                                + Add Location
                              </Button>
                            </Card.Header>
                            <Card.Body>
                              <Row>
                                <Col lg="12">
                                  <SimpleBar
                                    autoHide={false}
                                    // style={{ maxHeight: "61vh", overflowX: "hidden" }}
                                    style={{
                                      maxHeight: "550px",
                                      overflowX: "hidden",
                                    }}
                                  >
                                    {rows.map((row, index) => (
                                      <Row key={index}>
                                        <Col lg="3">
                                          <Form.Group className="mb-4 form-icon">
                                            <Form.Label>Location</Form.Label>
                                            <Select
                                              inputId={`staff_location_id_${index}`}
                                              name={`staff_location_id_${index}`}
                                              // options={
                                              //   DropdownStaffLocationDetail
                                              // }
                                              options={DropdownStaffLocationDetail.filter(
                                                                                                  (option) =>
                                                                                                    !rows.some(
                                                                                                      (row, rowIndex) =>
                                                                                                        rowIndex !== index &&
                                                                                                        row.location_id ===
                                                                                                          option.value
                                                                                                    )
                                                                                                )}
                                              classNamePrefix="react-select"
                                              className="react-select"
                                              value={DropdownStaffLocationDetail.find(
                                                (option) =>
                                                  option.value ===
                                                  row.location_id
                                              ) || null }
                                              onChange={(option: {
                                                value: any;
                                              }) =>
                                                handleRowChange(
                                                  index,
                                                  "location_id",
                                                  option?.value
                                                )
                                              }
                                            />
                                            <i
                                              style={{ fontSize: "16px" }}
                                              className="ri-map-pin-line mt-4"
                                            ></i>
                                          </Form.Group>
                                        </Col>
                                        {!(
                                          emptype === 4 ||
                                          formik.values.employee_status_id === 4
                                        ) && (
                                          <Col lg="3">
                                            <Form.Group className="mb-4 form-icon">
                                              <Form.Label>Pay Group</Form.Label>
                                              <Select
                                                inputId={`pay_group_id_${index}`}
                                                name={`pay_group_id_${index}`}
                                                options={
                                                  DropdownPayGroupDetail[
                                                    row.is_patrol_site ? 1 : 0
                                                  ]
                                                } // Choose based on is_patrol_site
                                                classNamePrefix="react-select"
                                                className="react-select"
                                                value={
                                                  DropdownPayGroupDetail[
                                                    row.is_patrol_site ? 1 : 0
                                                  ].find(
                                                    (option) =>
                                                      option.value ===
                                                      rows[index]?.pay_group_id
                                                  ) || null
                                                }
                                                onChange={(option: any) => {
                                                  handleRowChange(
                                                    index,
                                                    "pay_group_id",
                                                    option?.value
                                                  );
                                                  rows[index].isOpen = true;
                                                  setRows([...rows]);
                                                }}
                                                isDisabled={!row.location_id}
                                                isInvalid={
                                                  !!(
                                                    (formik.touched as any)[
                                                      `pay_group_id_${index}`
                                                    ] &&
                                                    (formik.errors as any)[
                                                      `pay_group_id_${index}`
                                                    ]
                                                  )
                                                }
                                              />
                                              {isUpdateClicked &&
                                                row.location_id !== undefined &&
                                                row.location_id !== 0 &&
                                                !row.pay_group_id && 
                                                (
                                                  <div
                                                    className="error-message position-absolute"
                                                    style={{
                                                                                                              color: "red",
                                                                                                              fontSize: "12px",
                                                                                                              marginTop: "0px",
                                                                                                              left: 0,
                                                                                                            }}
                                                  >
                                                    Pay Group is required when
                                                    Location is selected
                                                  </div>
                                                )}

                                              <i
                                                style={{ fontSize: "16px" }}
                                                className="ri-bank-card-2-line mt-4"
                                              ></i>
                                            </Form.Group>
                                          </Col>
                                        )}

                                        {/* <Col
                                          lg="1"
                                          className="d-flex align-items-center"
                                        >
                                          <Col>
                                            <Button
                                              onClick={() =>
                                                handleDeleteRow(index)
                                              }
                                              className="btn btn-subtle-danger btn-icon btn-md remove-item-btn"
                                            >
                                              <i
                                                className="ph-trash"
                                                style={{ fontSize: "15px" }}
                                              ></i>
                                            </Button>
                                          </Col>
                                          {row.pay_group_id > 0 && 
                                            <Col>
                                              <Button
                                                className="btn btn-subtle-danger btn-icon btn-md remove-item-btn"
                                                onClick={() =>
                                                  toggleTable(index)
                                                } // Trigger the toggleTable function on click
                                              >
                                                {row.isOpen ? (
                                                  <i className="ri-arrow-up-s-line"></i> // Collapse icon (up arrow)
                                                ) : (
                                                  <i className="ri-arrow-down-s-line"></i> // Expand icon (down arrow)
                                                )}
                                              </Button>
                                            </Col>
                                          }
                                        </Col> */}

                                        <Col
                                            lg="2"
                                            className="d-flex align-items-center justify-content-start gap-2"
                                          >
                                            <Button
                                              onClick={() =>
                                                handleDeleteRow(index)
                                              }
                                              className="btn btn-subtle-danger btn-icon btn-md remove-item-btn"
                                            >
                                              <i
                                                className="ph-trash"
                                                style={{ fontSize: "15px" }}
                                              ></i>
                                            </Button>

                                            {!(
                                              emptype === 4 ||
                                              formik.values
                                                .employee_status_id === 4
                                            ) && row.pay_group_id > 0 && (
                                              <Button
                                                className="btn btn-subtle-danger btn-icon btn-md remove-item-btn"
                                                onClick={() =>
                                                  toggleTable(index)
                                                }
                                              >
                                                {row.isOpen ? (
                                                  <i className="ri-arrow-up-s-line"></i> // Collapse icon
                                                ) : (
                                                  <i className="ri-arrow-down-s-line"></i> // Expand icon
                                                )}
                                              </Button>
                                            )}
                                          </Col>

                                        {/* Table rendering logic */}
                                        {!(
                                          emptype === 4 ||
                                          formik.values.employee_status_id === 4
                                        ) &&
                                          row.pay_group_id !== 0 &&
                                          row.isOpen && (
                                            // Render table only if `isOpen` is true
                                            <Col lg="7" className="mb-3">
                                              <Table className="align-middle table-nowrap mb-0">
                                                <thead className="table-light">
                                                  <tr>
                                                    <th scope="col">
                                                      Category Name
                                                    </th>
                                                    <th scope="col">
                                                      Default Rate
                                                    </th>
                                                    <th scope="col">
                                                      Assign Rate
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {row?.pay_group_rates?.map(
                                                    (rate) => {
                                                      const isAssignRateInvalid =
                                                        rate.assign_rate <
                                                        rate.default_rate;

                                                      return (
                                                        <React.Fragment
                                                          key={rate.pay_rate_id}
                                                        >
                                                          <tr>
                                                            <td>
                                                              {
                                                                rate.category_name
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                rate.default_rate
                                                              }
                                                            </td>
                                                            <td>
                                                              <Form.Control
                                                                type="number"
                                                                className="form-control-lg form-control-icon name-input"
                                                                onWheel={(e) => e.currentTarget.blur()} 
                                                                value={
                                                                  rate.assign_rate
                                                                }
                                                                onChange={(e) =>
                                                                  handleAssignRateChange(
                                                                    index,
                                                                    rate.pay_rate_id,
                                                                    parseFloat(
                                                                      e.target
                                                                        .value
                                                                    )
                                                                  )
                                                                }
                                                              />
                                                            </td>
                                                          </tr>

                                                          {/* Show error below the row if assign_rate is less than default_rate */}
                                                          {isUpdateClicked &&
                                                            isAssignRateInvalid && (
                                                              <tr>
                                                                <td colSpan={3}>
                                                                  <div
                                                                    className="error-message"
                                                                    style={{
                                                                      color:
                                                                        "red",
                                                                      fontSize:
                                                                        "12px",
                                                                    }}
                                                                  >
                                                                    Assign Rate
                                                                    cannot be
                                                                    less than
                                                                    Default Rate
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                            )}
                                                        </React.Fragment>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </Table>
                                            </Col>
                                          )}
                                      </Row>
                                    ))}
                                  </SimpleBar>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                      </Tab.Content>
                    )}
                  </Form>
                  {key === "fifth" && (
                    <Tab.Content>
                      <Tab.Pane eventKey="fifth" id="securityPrivacy">
                        <Card className="section-document">
                          <Card.Header>
                            <h5 className="card-title personal-details-title mb-0">
                              Licenses / Compliance
                            </h5>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col lg={12}>
                                <div className="d-flex justify-content-end gap-2">
                                  <div className="app-search d-none d-md-inline-flex">
                                    <div className="position-relative">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        style={{ height: "47px" }}
                                        id="search-options"
                                        value={inputValue}
                                        onChange={handleChange}
                                      />
                                      <span
                                        style={{ top: "5px" }}
                                        className="mdi mdi-magnify search-widget-icon"
                                      ></span>
                                      <span
                                        className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                        id="search-close-options"
                                      ></span>
                                    </div>
                                  </div>
                                  <Button
                                    variant="primary"
                                    className="add-company"
                                    onClick={openDocForm}
                                  >
                                    <i className=" ri-add-fill"></i> Add
                                  </Button>
                                </div>
                              </Col>
                              <Col lg="12" className="mt-3">
                                <SimpleBar style={{ maxHeight: "589px" }}>
                                  <StaffDocumentTable
                                    data={staffComplianceDetail}
                                    archiveMaster={
                                      archiveSubcontractorComplianceMaster
                                    }
                                    handleArchiveCompliance={
                                      handleArchiveCompliance
                                    }
                                    handleSort={handleSort}
                                    editMaster={editStaffDocumentDetail}
                                    handlePageChange={handlePageChange}
                                    handlePerRowsChange={handlePerRowsChange}
                                    paginationTotalRows={totalRows}
                                  />
                                </SimpleBar>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Tab.Pane>
                    </Tab.Content>
                  )}
                </Tab.Container>
              </Col>
            </Row>
          </Container>
        </div>
        <Offcanvas
          className="archive-canvas"
          show={isRight}
          onHide={toggleRightCanvas}
          placement="end"
        >
          <Offcanvas.Header className="border-bottom" closeButton>
            <Offcanvas.Title id="offcanvasRightLabel">
              Archive Staff
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="p-0 overflow-hidden">
            <Row>
              <Col lg="12">
                <div className="d-flex align-items-center user-sec">
                  <div className="chat-user-img flex-shrink-0 me-2">
                    <div className="chat-user-img flex-shrink-0">
                      <div>
                        <img
                          src={userpf}
                          className="rounded-circle img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="user-name text-truncate mb-0">Adams Smith</p>
                    <div className="text-muted user-role text-truncate">
                      Doctor
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="12">
                <div className="archive-info-note">
                  <i className="ri-information-line"></i>
                  <p>
                    Archiving will revoke any access granted to this person. Are
                    you sure you want to archive this Person?
                  </p>
                </div>
              </Col>
            </Row>
          </Offcanvas.Body>
          <Row>
            <Col lg="12">
              <div className="offcanvas-footer border-top-0 p-4 text-center">
                <Row className="approve-reject-btn">
                  <Col lg={12} className="Apply-section px-0">
                    <Button color="primary" className="next-btn">
                      Archive Staff
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Offcanvas>
        <Modal
          size="xl"
          id="myModal"
          show={modal_standard}
          onHide={() => {
            tog_standard();
          }}
        >
          <Modal.Header className="modal-title fs-xl" id="myModalLabel">
            {isEditing ? "Edit" : "Add"} Licenses / Compliance
          </Modal.Header>
          <Form onSubmit={Complianceformik.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col lg="12">
                  <Row className="add-doc">
                    <Col lg="3">
                      <Form.Group className="mb-3 form-icon">
                        <Form.Label>Document Type</Form.Label>
                        <Form.Select
                          className="form-select-lg form-control-icon"
                          id="floatingSelect"
                          name="document_type"
                          onChange={handleDocumentTypeChange}
                          value={documentType}
                        >
                          <option value="Licenses">Licenses</option>
                          <option value="Compliance">Compliance</option>
                          <option value="General">General</option>
                        </Form.Select>
                        <i
                          style={{ fontSize: "16px" }}
                          className="ri-article-line mt-4"
                        ></i>
                      </Form.Group>
                    </Col>
                    {documentType === "Compliance" && (
                      <Col lg={3}>
                        <Form.Group className="mb-3 form-icon">
                          <Form.Label className="text-muted">
                            Document Name
                          </Form.Label>
                          <Form.Select
                            className="form-select-lg form-control-icon"
                            name="document_name"
                            id="doc name"
                            value={docname}
                            onChange={handleDocumentNameChange}
                          >
                            <option value="">Select</option>
                            {DropdownComplianceDetail.map((item) => {
                              return (
                                <option
                                  key={item.id}
                                  value={item.compliance_name}
                                >
                                  {item.compliance_name}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <i
                            style={{ fontSize: "16px" }}
                            className="bi bi-briefcase mt-4"
                          ></i>
                        </Form.Group>
                      </Col>
                    )}
                    {documentType === "Licenses" && (
                      <Col lg="3">
                        <Form.Group className="mb-3">
                          <Form.Label>License</Form.Label>
                          <div className="position-relative form-icon">
                            <Form.Control
                              type="name"
                              name="ref_no"
                              className="form-control-lg form-control-icon name-input"
                              placeholder="License#"
                              onChange={Complianceformik.handleChange}
                              value={Complianceformik.values.ref_no}
                            />
                            <i
                              style={{ fontSize: "16px" }}
                              className=" ri-article-line"
                            ></i>
                          </div>
                        </Form.Group>
                      </Col>
                    )}
                    {documentType === "General" && (
                      <Col lg="3">
                        <Form.Group className="mb-3">
                          <Form.Label>Document Name</Form.Label>
                          <div className="position-relative form-icon">
                            <Form.Control
                              type="text"
                              name="document_name"
                              className="form-control-lg form-control-icon"
                              placeholder="Enter Document Name"
                              onChange={Complianceformik.handleChange}
                              value={Complianceformik.values.document_name}
                            />
                            <i
                              style={{ fontSize: "16px" }}
                              className="ri-file-list-line"
                            ></i>
                          </div>
                        </Form.Group>
                      </Col>
                    )}
                    {(documentType === "Licenses" ||
                      documentType === "Compliance" ||
                      documentType === "General") && (
                      <Col lg="3">
                        <div className="mb-3 d-flex flex-column form-icon">
                          <Form.Label htmlFor="implement Start Date ">
                            Expiry date
                          </Form.Label>
                          <Flatpickr
                            className="form-control-lg form-control-icon"
                            options={{
                              dateFormat: "d M, Y",
                              minDate: "today",
                            }}
                            placeholder="Select date"
                            style={{ border: "1px solid #dde1ef" }}
                            name="expiry_date"
                            value={Complianceformik.values.expiry_date}
                            onChange={(dates: any[]) => {
                              const formattedDate = format(
                                dates[0],
                                "dd-MM-yyyy"
                              );
                              Complianceformik.setFieldValue(
                                "expiry_date",
                                formattedDate
                              );
                            }}
                          />
                          <i
                            style={{ fontSize: "16px" }}
                            className="ri-calendar-event-line mt-4"
                          ></i>
                        </div>
                      </Col>
                    )}
                    {(documentType === "Licenses" ||
                      documentType === "Compliance" ||
                      documentType === "General") && (
                      <Col lg="3">
                        <Form.Group className="mb-3">
                          <Form.Label>Reminder</Form.Label>
                          <div className="position-relative form-icon">
                            <Form.Control
                              type="text"
                              name="reminder"
                              className={`form-control-lg form-control-icon name-input ${
                                Complianceformik.touched.reminder &&
                                Complianceformik.errors.reminder
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="in Days"
                              onChange={Complianceformik.handleChange}
                              value={Complianceformik.values.reminder}
                            />
                            <i
                              style={{ fontSize: "16px" }}
                              className=" ri-time-line"
                            ></i>
                            {Complianceformik.touched.reminder &&
                            Complianceformik.errors.reminder ? (
                              <div className="invalid-feedback">
                                {Complianceformik.errors.reminder}
                              </div>
                            ) : null}
                          </div>
                        </Form.Group>
                      </Col>
                    )}
                    {(documentType === "Licenses" ||
                      documentType === "Compliance" ||
                      documentType === "General") && (
                      <Col lg="3" className="upload-section-col">
                        <Form.Group>
                          <Form.Label className="doc-label">
                            File Upload
                          </Form.Label>
                          <div
                            className={`uploadsection ${
                              !!(
                                Complianceformik.touched.document_path &&
                                Complianceformik.errors.document_path
                              )
                                ? "invalid-border"
                                : ""
                            }`}
                          >
                            {showUploadButton ? (
                              <div className="d-flex align-item-center">
                                <Form.Label
                                  htmlFor="file"
                                  className="uploadlabel"
                                >
                                  <Form.Control
                                    type="file"
                                    className={`form-control-lg ${
                                      Complianceformik.touched.reminder &&
                                      Complianceformik.errors.reminder
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    style={{ display: "none" }}
                                    id="file"
                                    name="document_path"
                                    onChange={handleFileChange}
                                    isInvalid={
                                      !!(
                                        Complianceformik.touched
                                          .document_path &&
                                        Complianceformik.errors.document_path
                                      )
                                    }
                                  />
                                  <i className="bx bx-plus"></i> Choose File
                                </Form.Label>
                              </div>
                            ) : (
                              <Col lg="12" className="closeuser py-0">
                                <p
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  className="file-name"
                                >
                                  {file
                                    ? file.name
                                    : Complianceformik.values.document_path}
                                </p>
                                <Button onClick={handleRemoveFile} color="red">
                                  X
                                </Button>
                              </Col>
                            )}
                          </div>
                          {Complianceformik.touched.document_path &&
                          Complianceformik.errors.document_path ? (
                            <div className="invalid-feedback d-block">
                              {Complianceformik.errors.document_path}
                            </div>
                          ) : null}
                          {(Complianceformik.values.document_path || file) && (
                            <div className="closeuser d-flex gap-2 align-items-center justify-content-center">
                              <i
                                onClick={() => {
                                  if (file) {
                                    handleFilePreview(file);
                                  } else if (
                                    Complianceformik.values.document_path
                                  ) {
                                    handleOpenDocument(
                                      Complianceformik.values.document_path
                                    );
                                  }
                                }}
                                className="ri-eye-line link-info"
                                style={{ fontSize: "18px", cursor: "pointer" }}
                              ></i>
                              {/* <p className="mb-0">{file?.name ? file?.name : Complianceformik.values.document_path}</p> */}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    )}
                    {documentType === "Licenses" && (
                      <>
                        <Col lg="3">
                          <Form.Group className="mb-3 form-icon">
                            <Form.Label>State</Form.Label>
                            <Form.Select
                              className="form-select-lg form-control-icon"
                              id="state"
                              name="state_id"
                              onChange={Complianceformik.handleChange}
                              value={Complianceformik.values.state_id}
                            >
                              <option value="">Select State</option>
                              {StateMasterDetail.map((stateDetail) => (
                                <option
                                  key={stateDetail.id}
                                  value={stateDetail.id}
                                >
                                  {stateDetail.state_name}
                                </option>
                              ))}
                            </Form.Select>
                            <i
                              style={{ fontSize: "16px" }}
                              className=" ri-map-pin-line mt-4"
                            ></i>
                          </Form.Group>
                        </Col>
                      </>
                    )}
                    {(documentType === "Licenses" ||
                      documentType === "Compliance" ||
                      documentType === "General") && (
                      <Col
                        style={{ flex: "0.2" }}
                        className="d-flex align-items-center"
                      >
                        <Form.Group className="mb-3">
                          <Form.Label>Critical</Form.Label>
                          <div className="form-check form-switch form-switch-custom form-switch-lg mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id={`break-`}
                              onChange={handleCheckboxChange}
                              checked={
                                Complianceformik.values.is_critical === 1
                              }
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <div className="modal-footer">
              <Button variant="light" onClick={() => tog_standard()}>
                Close
              </Button>
              {Complianceformik.isSubmitting ? (
                <Button color="primary" className="add-company" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button type="submit" className="add-company" color="primary">
                  {isEditing ? "Update" : "Add"}
                </Button>
              )}
            </div>
          </Form>
        </Modal>
        <Modal
          show={modal_center}
          onHide={() => {
            tog_center();
          }}
          size="sm"
          centered
        >
          <Modal.Body className="text-center p-4">
            <i className="ri-delete-bin-line text-danger display-5"></i>
            <div className="mt-4">
              <h4 className="mb-3">Are you sure?</h4>
              <p className="text-muted mb-4">
                You want to delete this document.
              </p>
              <div className="hstack gap-2 justify-content-center">
                <Button variant="light" onClick={() => setmodal_center(false)}>
                  Close
                </Button>
                {archiveLoader ? (
                  <Button color="primary" className="next-btn" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Button>
                ) : (
                  <Button
                    variant="danger"
                    onClick={() => {
                      if (archiveId !== undefined) {
                        archiveSubcontractorComplianceMaster(archiveId);
                      } else {
                        toast.error("No Archive ID provided.");
                      }
                    }}
                  >
                    Delete
                  </Button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default EditStaff;
