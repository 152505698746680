import React from 'react';
import DataTable from 'react-data-table-component';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface ClientDocData {
    id: number;
    document_name: string;
}

type SortOrder = 'asc' | 'desc';

interface ClientDocTableProps {
    data: ClientDocData[];
    editClientDocument: (id: number) => Promise<void>;
    handlePageChange: (page: number) => void;
    handlePerRowsChange: (newPerPage: number, page: number) => void;
    paginationTotalRows: number;
    handleArchiveClientDoc: (id: number) => Promise<void>;
    handleSort: (column: string, sortDirection: 'asc' | 'desc') => void;
}

const ClientDocumentTable: React.FC<ClientDocTableProps> = ({ data, editClientDocument,handleArchiveClientDoc,handlePageChange,handlePerRowsChange ,handleSort, paginationTotalRows}) => {

    const baseurl = process.env.REACT_APP_IMAGE_URL

    const handleOpenDocument = (path: string) => {
        const fullPath = `${baseurl}/${path}`;
        window.open(fullPath, '_blank');
    };


    const columns = [
        {
            name: <span className='font-weight-bold fs-sm'>Document Name</span>,
            selector: (row: any) => row.document_name,
            sortField: 'document_name',
            sortable: true,
            left: true,
            // cell: (row: any) => <div style={{ textAlign: 'left' }}>{row.term_name}</div>
        },
        {
            name: <span className='font-weight-bold fs-sm text-right'>Action</span>,
            // sortable: true,
            right: true,
            cell: (row: any) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="edit-tooltip">Edit</Tooltip>}>
                      <i 
                        onClick={() => editClientDocument(row.id)} 
                        className="ri-edit-2-line link-success" 
                        style={{ fontSize: '18px', cursor: 'pointer' }}
                      ></i>
                    </OverlayTrigger>
                  
                    <OverlayTrigger placement="top" overlay={<Tooltip id="view-tooltip">View</Tooltip>}>
                      <i 
                        onClick={() => handleOpenDocument(row.document_path)} 
                        className="ri-eye-line link-info" 
                        style={{ fontSize: '18px', cursor: 'pointer' }}
                      ></i>
                    </OverlayTrigger>
                  
                    <OverlayTrigger placement="top" overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}>
                      <i 
                        onClick={() => handleArchiveClientDoc(row.id)} 
                        style={{ fontSize: '18px', cursor: 'pointer' }} 
                        className="ri-delete-bin-line link-danger"
                      ></i>
                    </OverlayTrigger>
                  </div>
                );
            },
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={paginationTotalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            onSort={(column: any, sortDirection: SortOrder) => {
                if (column.sortField) {
                    handleSort(column.sortField, sortDirection);
                }
            }}
            sortServer
        />
    );
};

export default ClientDocumentTable;
