// HolidayTable.tsx

import { useProfile } from 'Common/Hooks/UserHooks';
import React from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface HolidayMasterData {
    id: number;
    year: number;
    state_id: number;
    description: string;
    date: Date | null;
    start_time: string;
    finish_time: string;
}
type SortOrder = 'asc' | 'desc';

interface HolidayTableProps {
    data: HolidayMasterData[];
    editMaster: (id: number) => Promise<void>;
    handlePageChange: (page: number) => void;
    handlePerRowsChange: (newPerPage: number, page: number) => void;
    paginationTotalRows: number;
    handleArchiveCompliance: (id: number) => Promise<void>;
    handleSort: (column: string, sortDirection: 'asc' | 'desc') => void;
}


const HolidayTable: React.FC<HolidayTableProps> = ({ data, editMaster, handlePageChange, handlePerRowsChange, paginationTotalRows, handleArchiveCompliance, handleSort }) => {

    const { userProfile } = useProfile();
    const userRoles = userProfile.roles;
    const allowedRoles = ['DEVELOPER', 'SUPER_ADMIN', 'ADMIN', 'MANAGER'];
    const hasRoles = (roles: string[]) => roles.some(role => userRoles.includes(role));
    const canAccess = hasRoles(allowedRoles);

    const columns = [
        {
            name: <span className='font-weight-bold fs-sm'>Holiday Name</span>,
            selector: (row: any) => row.description,
            sortable: true,
            sortField: 'description',
        },
        {
            name: <span className='font-weight-bold fs-sm'>State</span>,
            selector: (row: any) => row.state_name,
            // sortable: true
        },
        {
            name: <span className='font-weight-bold fs-sm'>Date</span>,
            selector: (row: any) => row.date,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-sm'>Time</span>,
            cell: (row: any) => {
                return (
                    <>
                        {row.start_time} - {row.finish_time}
                    </>
                );
            },
            // sortable: true
        },
        {
            name: <span className='font-weight-bold fs-sm'>Year</span>,
            selector: (row: any) => row.year,
            // sortable: true
        },
        {
            name: canAccess && userRoles[0] !== "MANAGER" ? (
                <span className="font-weight-bold fs-sm">Action</span>
            ) : null,
            // sortable: true,
            cell: (row: any) => {
                return (canAccess && userRoles[0] !== "MANAGER" &&
                    <>
                        <div className="d-flex gap-2 list-unstyled mb-0">
                            {/* Edit Icon with Tooltip */}
                            <OverlayTrigger placement="top" overlay={<Tooltip id="edit-tooltip">Edit</Tooltip>}>
                                <i
                                    className="ri-edit-2-line link-success"
                                    onClick={() => editMaster(row.id)}
                                    style={{ fontSize: '18px', cursor: 'pointer' }}
                                ></i>
                            </OverlayTrigger>

                            {/* Delete Icon with Tooltip */}
                            <OverlayTrigger placement="top" overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}>
                                <i
                                    className="ri-delete-bin-line link-danger"
                                    onClick={() => handleArchiveCompliance(row.id)}
                                    style={{ fontSize: '18px', cursor: 'pointer' }}
                                ></i>
                            </OverlayTrigger>
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={paginationTotalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            onSort={(column: any, sortDirection: SortOrder) => {
                if (column.sortField) {
                    handleSort(column.sortField, sortDirection);
                }
            }}
            sortServer
        />
    );
};

export default HolidayTable;
